import { components } from 'react-select';
import { NoSearchData } from 'Components';
import classes from './NoOptionsMessagePart.module.scss';

const { NoOptionsMessage } = components;
const NoOptionsMessagePart = props => (
  <NoOptionsMessage {...props} className={classes.noMessageWrap}>
    <NoSearchData />
  </NoOptionsMessage>
);
export default NoOptionsMessagePart;
