import { number, string } from 'prop-types';
import colors from 'styles/_colors.scss';

export function ArrowApply({ width = 17, height = 13, color = colors.white }) {
  return (
    <svg width={width} height={height} viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.05 12.075C5.91667 12.075 5.79167 12.0542 5.675 12.0125C5.55833 11.9708 5.45 11.9 5.35 11.8L1.05 7.49999C0.866667 7.31666 0.779167 7.07916 0.7875 6.78749C0.795834 6.49583 0.891667 6.25833 1.075 6.07499C1.25833 5.89166 1.49167 5.79999 1.775 5.79999C2.05833 5.79999 2.29167 5.89166 2.475 6.07499L6.05 9.64999L14.525 1.17499C14.7083 0.991661 14.9458 0.899994 15.2375 0.899994C15.5292 0.899994 15.7667 0.991661 15.95 1.17499C16.1333 1.35833 16.225 1.59583 16.225 1.88749C16.225 2.17916 16.1333 2.41666 15.95 2.59999L6.75 11.8C6.65 11.9 6.54167 11.9708 6.425 12.0125C6.30833 12.0542 6.18333 12.075 6.05 12.075Z" fill={color} />
    </svg>
  );
}

ArrowApply.propTypes = {
  width: number,
  height: number,
  color: string
};
