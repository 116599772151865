import { number, func } from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import classes from './RangeSlider.module.scss';

const SliderRange = ({ currentRangeStart = 0, currentRangeEnd = 100, minRange = 0, maxRange = 100, step = 0.01, onChange = () => null }) => {
  const [rangeStart, setRangeStart] = useState(currentRangeStart);
  const [rangeEnd, setRangeEnd] = useState(currentRangeEnd);
  const rangeStartRef = useRef(currentRangeStart);
  const rangeEndRef = useRef(currentRangeEnd);
  const rangeRef = useRef(null);

  useEffect(() => {
    currentRangeStart !== rangeStart && setRangeStart(currentRangeStart);
    rangeStartRef.current = currentRangeStart;
  }, [currentRangeStart]);

  useEffect(() => {
    currentRangeEnd !== rangeEnd && setRangeEnd(currentRangeEnd);
    rangeEndRef.current = currentRangeEnd;
  }, [currentRangeEnd]);

  useEffect(() => {
    onChange({ rangeStart, rangeEnd });
  }, [rangeStart, rangeEnd]);

  const rangeStyle = {
    left: `${((rangeStart - minRange) / (maxRange - minRange)) * 100}%`,
    width: `${((rangeEnd - rangeStart) / (maxRange - minRange)) * 100}%`
  };

  return (
    <div className={classes.rangeSliderWrapper}>
      <input
        type="range"
        min={minRange}
        max={maxRange}
        step={step}
        value={rangeStart}
        onChange={(e) => {
          const value = Math.min(Number(e.target.value), rangeEnd - step);
          setRangeStart(value);
          rangeStartRef.current = value;
        }}
        className={cn(classes.thumb, classes.thumbLeft)}
        style={{ zIndex: rangeStart > minRange && '5' }}
      />
      <input
        type="range"
        min={minRange}
        max={maxRange}
        step={step}
        value={rangeEnd}
        onChange={(e) => {
          const value = Math.max(Number(e.target.value), rangeStart + step);
          setRangeEnd(value);
          rangeEndRef.current = value;
        }}
        className={cn(classes.thumb, classes.thumbRight)}
      />

      <div className={classes.sliderWrapper}>
        <div className={classes.sliderTrack} />
        <div
          ref={rangeRef}
          className={classes.sliderRange}
          style={rangeStyle}
        />
      </div>
    </div>
  );
};

SliderRange.propTypes = {
  currentRangeStart: number,
  currentRangeEnd: number,
  minRange: number,
  maxRange: number.isRequired,
  onChange: func,
  step: number
};

export default SliderRange;
