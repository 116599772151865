import { useMemo } from 'react';
import { bool, shape, string } from 'prop-types';
import { components } from 'react-select';
import cn from 'classnames';
import { Icon } from 'Components';
import classes from './OptionPart.module.scss';

const { Option } = components;
const OptionPart = (props) => {
  const { data = { label: '' }, isSelected = false, selectProps = {}, innerProps = {} } = props;
  const selectInnerProps = {
    ...innerProps,
    onClick: (e) => { e.stopPropagation(); e.preventDefault(); }
  };
  const selectedOptions = selectProps?.value || [];
  const isExcluded = useMemo(() => isSelected && selectedOptions.find(item => item?.value === data?.value && item?.isExclude === true), [isSelected, selectedOptions, data?.value]);
  const isInluded = useMemo(() => isSelected && selectedOptions.find(item => item?.value === data?.value && item?.isExclude === false), [isSelected, selectedOptions, data?.value]);

  function onClickIcon(value) {
    const findOption = selectedOptions?.find(item => item?.value === data?.value);
    const optionHasValue = findOption && findOption.isExclude !== value.isExclude;
    const addValue = selectedOptions.concat({ ...data, ...value });
    const removeOption = optionHasValue
      ? selectedOptions.filter(item => item?.value !== data?.value).concat({ ...data, ...value })
      : selectedOptions.filter(item => item?.value !== data?.value);

    const newOptions = isSelected ? removeOption : addValue;
    selectProps.onChange(newOptions);
  }

  return (
    <Option {...props} innerProps={selectInnerProps} className={classes.optionPart}>
      <div className={classes.optionPart}>
        <p className={classes.hiddenText}>
          {!!data?.icon && <Icon type={data.icon} />}
          {data.label}
        </p>
        <div className={cn(classes.selectBox)}>
          <Icon
            type="add_round"
            width={20}
            height={20}
            isSelected={!!isInluded}
            onClick={() => { onClickIcon({ isExclude: false }); }}
          />
          <Icon
            type="minus_round"
            width={20}
            height={20}
            isSelected={!!isExcluded}
            onClick={() => { onClickIcon({ isExclude: true }); }}
          />

        </div>
      </div>
    </Option>
  );
};

OptionPart.propTypes = {
  data: shape({ label: string }),
  isSelected: bool,
  selectProps: shape({}),
  innerProps: shape({})
};

export default OptionPart;
