import { useEffect, useMemo } from 'react';
import { changeSearchTab, clearSearchFilterList, addNewSearchFilter, selectSavedSearchFilter, editSearchFilterName, restoreDefaultSearchFilter, editArticlesTab } from 'storage/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { useGetSearchFilters, useCreateEditSearchFilter, useDeleteSearchFilter, useGetAllFavoriteSearchCount } from 'storage/queryHooks';
import t from 'utils/translateFunc';
import { searchQuerySelector } from 'storage/selectors';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { Typography } from 'antd';
import { sendSearchEvent } from 'services/amplitude/events';
import { clearEmtyValues } from 'utils/common';
import classes from './SearchFilters.module.scss';
import FiltersHeader from './FiltersHeader';
import FiltersList from './FiltersList';
import FilterEditable from './FilterEditable';

const { Paragraph } = Typography;
function SearchFilters() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const searchQuery = useSelector(searchQuerySelector);
  const { userSearchFilters, filters, searchTab, articlesTab, selectedSearchFilter, counts } = useSelector(state => state?.search);
  useGetSearchFilters();
  const { mutateAsync: createEditSearchFilter } = useCreateEditSearchFilter();
  const { mutateAsync: deleteSearchFilter } = useDeleteSearchFilter();
  const { data: allFavoriteCount } = useGetAllFavoriteSearchCount();

  useEffect(() => {
    if (searchTab === 'favorite' && articlesTab === 'socialnetwork' && counts?.socialnetwork === 0 && counts?.media > 0) {
      dispatch(editArticlesTab('media'));
    } else if (searchTab === 'favorite' && articlesTab === 'media' && counts?.media === 0 && counts?.socialnetwork > 0) {
      dispatch(editArticlesTab('socialnetwork'));
    }
  }, [counts, searchTab]);

  function checkUnsavedChanges() {
    if (!selectedSearchFilter?.id) return true;
    const etalonFilter = !!userSearchFilters?.length && !!selectedSearchFilter?.id && userSearchFilters?.find(item => item?.id === selectedSearchFilter?.id);
    const currentFilter = { ...selectedSearchFilter, ...filters };
    return !isEqual(etalonFilter, currentFilter);
  }
  const hasFiltersChanges = useMemo(() => Object.values(omit(filters, ['query'])).some(item => !!item?.length), [filters]);
  const hasChanges = useMemo(() => !!selectedSearchFilter?.name && hasFiltersChanges && checkUnsavedChanges(), [userSearchFilters, filters, selectedSearchFilter]);

  function eventFunc(event, properties = null) {
    sendSearchEvent({ category: `Search_Filters_Tab_${searchTab}`, event, properties });
  }

  function onResetFilters() {
    dispatch(clearSearchFilterList());
    eventFunc('Reset_filters');
  }
  function addNewFilter() {
    dispatch(addNewSearchFilter({ id: null, name: t('search', 'NEW_FILTER') }));
    eventFunc('Click_Add_New_Filter', { filters: clearEmtyValues(filters) });
  }

  function onSelectFilter(filter) {
    !filter?.id && dispatch(clearSearchFilterList());
    eventFunc('Click_Custom_Filter', { filters: clearEmtyValues(filters) });
    dispatch(selectSavedSearchFilter(filter));
  }

  function onChangeTab(tabValue) {
    if (tabValue !== searchTab) {
      dispatch(clearSearchFilterList());
      dispatch(changeSearchTab(tabValue));
      eventFunc(`Click_${tabValue}_tab`);
      queryClient.refetchQueries({ queryKey: 'getFavoriteSocialnetworkArticles' });
      queryClient.refetchQueries({ queryKey: 'getFavoriteMediaArticles' });
    }
  }

  function onSaveChanges(currentSelectedFilter) {
    const filter = { ...currentSelectedFilter, ...searchQuery };
    createEditSearchFilter(filter);
    eventFunc(!filter?.id ? 'Click_Save_New_Filter' : 'Click_Save_Filter_Changes', { filters: clearEmtyValues(filters) });
  }
  function onRename({ name }) {
    dispatch(editSearchFilterName({ name }));
  }

  function onRemoveFilter(id) {
    deleteSearchFilter(id);
    eventFunc('Remove_filter');
  }
  return (
    <div className={classes.searchFilters} id="search-filters">
      <FiltersHeader
        tab={searchTab}
        favoriteCount={allFavoriteCount?.count || 0}
        hasChanges={hasChanges}
        onResetFilters={onResetFilters}
        addNewFilter={addNewFilter}
        onChangeTab={onChangeTab}
      />
      <div className={classes.customFiltersListWrapp}>
        { !!userSearchFilters?.length && (
        <div className={classes.customFiltersList}>
          { userSearchFilters.map((filterItem, i) => (
            <div
              key={i}
              className={cn(
                classes.customFiltersItem,
                filterItem?.id === selectedSearchFilter?.id && classes.customFiltersItem_active
              )}
              onClick={() => { onSelectFilter(filterItem); }}
            >
              <Paragraph style={{ maxWidth: '56px' }} ellipsis={{ rows: 2 }}>
                {filterItem?.name}
              </Paragraph>
            </div>
          )) }
        </div>
        )}
        <div className={classes.filtersListWrapp}>
          {selectedSearchFilter && (
          <FilterEditable
            hasChanges={hasChanges}
            onSelectFilter={onSelectFilter}
            onSaveChanges={onSaveChanges}
            onRemoveFilter={onRemoveFilter}
            onRename={onRename}
            onRestoreDefault={() => {
              eventFunc('Restore_default_filter');
              dispatch(restoreDefaultSearchFilter());
            }}
            filter={selectedSearchFilter}
          />
          )}
          <FiltersList {...filters} eventFunc={eventFunc} searchTab={searchTab} />
        </div>
      </div>
    </div>
  );
}

export default SearchFilters;
