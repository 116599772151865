import { useState, useMemo } from 'react';
import t from 'utils/translateFunc';
import { FilterCustom, Switcher, Animated } from 'Components';
import { useGetCountries, useGetRegions, useGetCities } from 'storage/queryHooks';
import useDebounceValue from 'hooks/useDebounce';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { hasFlag } from 'country-flag-icons';
import { arrayOf, func, shape } from 'prop-types';
import classes from './FilterGeography.module.scss';

const FilterGeography = ({ eventFunc = () => null, filterList = [], countries = [], regions = [], cities = [], onFilterList = () => null,
  onChange = () => null }) => {
  const [additional, setAddditional] = useState(false);
  const [filterSearchCountry, setFilterSearchCountry] = useState('');
  const [filterSearchRegion, setFilterSearchRegion] = useState('');
  const [filterSearchCity, setFilterSearchCity] = useState('');

  const icontainsCountry = useDebounceValue(filterSearchCountry, 1000);
  const icontainsRegion = useDebounceValue(filterSearchRegion, 1000);
  const icontainsCity = useDebounceValue(filterSearchCity, 1000);

  const getCountriesId = filterList?.filter(filter => filter.filterName === 'country')
    ?.reduce((acc, el) => (!acc ? acc = `${el.id}` : acc = `${acc},${el.id}`), '');
  const getRegionsId = filterList?.filter(filter => filter.filterName === 'region')
    ?.reduce((acc, el) => (!acc ? acc = `${el.id}` : acc = `${acc},${el.id}`), '');

  const { isLoading: countriesLoading, isRefetching: countriesRefetching } = useGetCountries({
    query: { name: icontainsCountry }, enabled: true });
  const { isLoading: regionsLoading, isRefetching: regionsRefetching } = useGetRegions({
    query: { country: getCountriesId, name: icontainsRegion }, enabled: additional });
  const { isLoading: citiesLoading, isRefetching: citiesRefetching } = useGetCities({
    query: { country: getCountriesId, region: getRegionsId, name: icontainsCity }, enabled: additional });

  const itemTypeCountry = t('filtrationPanel', 'COUNTRY');
  const itemTypeRegion = t('filtrationPanel', 'REGION');
  const itemTypeCity = t('filtrationPanel', 'CITY');

  const selectDataCountries = useMemo(() => filterList?.filter(listItem => listItem?.filterName === 'country'), [filterList?.length]);
  const selectDataRegions = useMemo(() => filterList?.filter(listItem => listItem?.filterName === 'region'), [filterList?.length]);
  const selectDataCities = useMemo(() => filterList?.filter(listItem => listItem?.filterName === 'city'), [filterList?.length]);

  const disableCountry = !selectDataCountries?.length && (!!selectDataRegions?.length || !!selectDataCities?.length);
  const disableRegion = (!selectDataCountries?.length && !selectDataRegions?.length) && !!selectDataCities?.length;

  return (
    <div className={classes.filterGeography}>
      <label className={classes.seletMenuTitle}>
        {t('filtrationPanel', 'GEOGRAPHY')}
        <Switcher
          className={classes.customSwitcher}
          onСhange={(value) => {
            eventFunc(value ? 'Open_Additional_Geography_Filters' : 'Close_Additional_Geography_Filters');
            setAddditional(value);
          }}
          value={additional}
          labelText={t('filtrationPanel', 'ADDITIONAL_FILTERS_GEOGRAPHY')}
          isTooltip
          small
        />
      </label>
      <FilterCustom
        options={countries ? countries?.map(({ id, label, value }) => ({ value: id, label, flag: hasFlag(value?.toUpperCase()) ? getUnicodeFlagIcon(value) : value })) : null}
        filterName="country"
        filterType={itemTypeCountry}
        labelPlaceholderPart={disableCountry ? '- - -' : t('filtrationPanel', 'ALL_COUNTRIES')}
        placeholder={t('filtrationPanel', 'ENTER_NAME')}
        endingText={{ one: t('filtrationPanel', 'COUNTRY').toLowerCase(), many: t('filtrationPanel', 'COUNTRIES').toLowerCase() }}
        onChange={onChange}
        onFilterList={onFilterList}
        isLoading={countriesLoading || countriesRefetching}
        isDisabled={disableCountry}
        inputValue={filterSearchCountry}
        onInputChange={setFilterSearchCountry}
      />
      <Animated visible={additional} duration={350} className={classes.additionalFiltersList}>
        <FilterCustom
          options={regions ? regions?.map(({ id, label }) => ({ value: id, label })) : null}
          filterName="region"
          filterType={itemTypeRegion}
          labelPlaceholderPart={disableRegion ? '- - -' : t('filtrationPanel', 'ALL_REGIONS')}
          placeholder={t('filtrationPanel', 'ENTER_NAME')}
          endingText={{ one: t('filtrationPanel', 'REGION').toLowerCase(), many: t('filtrationPanel', 'REGIONS').toLowerCase() }}
          onChange={onChange}
          onFilterList={onFilterList}
          isLoading={regionsLoading || regionsRefetching}
          isDisabled={disableRegion}
          inputValue={filterSearchRegion}
          onInputChange={setFilterSearchRegion}
        />
        <FilterCustom
          options={cities ? cities?.map(({ id, label }) => ({ value: id, label })) : null}
          filterName="city"
          filterType={itemTypeCity}
          labelPlaceholderPart={t('filtrationPanel', 'ALL_CITIES')}
          placeholder={t('filtrationPanel', 'ENTER_NAME')}
          endingText={{ one: t('filtrationPanel', 'CITY').toLowerCase(), many: t('filtrationPanel', 'CITIES').toLowerCase() }}
          onChange={onChange}
          onFilterList={onFilterList}
          isLoading={citiesLoading || citiesRefetching}
          inputValue={filterSearchCity}
          onInputChange={setFilterSearchCity}
        />
      </Animated>
    </div>
  );
};

FilterGeography.propTypes = {
  eventFunc: func,
  filterList: arrayOf(shape({})),
  countries: arrayOf(shape({})),
  regions: arrayOf(shape({})),
  cities: arrayOf(shape({})),
  onFilterList: func,
  onChange: func
};
export default FilterGeography;
