import t from 'utils/translateFunc';

function validateQuery({ query, language }) {
  try {
    const checkBrackets = (q) => {
      const openers = '([{';
      const closers = ')]}';
      const matchBrackets = (opener, closer) => openers.indexOf(opener) === closers.indexOf(closer);
      let position = 0;
      let message;
      let example;
      const stack = [];
      const openerInStack = closer => stack.includes(openers[closers.indexOf(closer)]);
      let balanced = true;
      let missingCloser = true;
      while (position < q.length && balanced) {
        const char = q[position];
        if (openers.includes(char)) {
          stack.push(char);
        } else if (closers.includes(char)) {
          if (!stack.length) {
            balanced = false;
            missingCloser = false;
          } else {
            const top = stack.pop();
            if (!matchBrackets(top, char)) {
              balanced = false;
              missingCloser = openerInStack(char);
            }
          }
        }
        position += 1;
      }
      balanced = balanced && !stack.length;
      if (!balanced) {
        message = t('error', missingCloser ? 'NO_CLOSE_BRACKET' : 'NO_OPEN_BRACKET', language);
        example = t('error', 'NO_BRACKET_EXAMPLE', language);
      }
      return message ? { position: position - 1, message, example } : null;
    };

    const checkDotComma = (q) => {
      let position = 0;
      let message;
      let example;
      const fullRe = /\/.*{\d+\.\d+}.*\//g;
      const rangeRe = /{\d+\.\d+}/g;
      const fullMatch = q.match(fullRe);
      if (fullMatch) {
        message = t('error', 'POINT_COMMA', language);
        example = t('error', 'POINT_COMMA_EXAMPLE', language);
        position = fullMatch.map(m => m.match(rangeRe).map(rm => q.indexOf(rm) + rm.indexOf('.'))[0])[0];
      }
      return message ? { position, message, example } : null;
    };

    const checkRegexSlashes = (q) => {
      let position = 0;
      let message;
      let example;
      const slashCount = (q.match(/\//g) || []).length;
      if (slashCount % 2 !== 0) {
        message = t('error', 'NO_SLASH', language);
        example = t('error', 'NO_SLASH_EXAMPLE', language);
        position = position || q.length - 1;
      }
      return message ? { position, message, example } : null;
    };

    const checkQuotes = (q) => {
      let position = 0;
      let message;
      let example;
      const quoteCount = (q.match(/"/g) || []).length;
      if (quoteCount % 2 !== 0) {
        message = t('error', 'QUOTE_MARK', language);
        example = t('error', 'QUOTE_MARK_EXAMPLE', language);
        position = position || q.length - 1;
      }
      return message ? { position, message, example } : null;
    };

    const checkColon = (q) => {
      let position = 0;
      let message;
      let example;
      const colonCount = (q.match(/:/g) || []).length;
      if (colonCount) {
        const colonAtEnd = q.match(/:\s*$/g);
        if (colonAtEnd) {
          message = t('error', 'SEARCH_WORD_AFTER', language);
          example = t('error', 'SEARCH_WORD_AFTER_EXAMPLE', language);
          position = q.indexOf(colonAtEnd);
        }
        const colonWithSpaces = q.match(/(\s+:)|(^:)/g);
        if (!message && colonWithSpaces) {
          message = t('error', 'SEARCH_WORD_BEFORE', language);
          example = t('error', 'SEARCH_WORD_BEFORE_EXAMPLE', language);
          position = q.indexOf(colonWithSpaces) || 1;
        }
      }
      return message ? { position, message, example } : null;
    };

    const checkAndOr = (q) => {
      let position = 0;
      let message;
      let example;
      const andOrRegex = /(?:^|\s)(AND\sNOT|AND|OR)(?=\s|$)/g;
      (q.match(andOrRegex) || []).forEach((m) => {
        const start = andOrRegex.exec(q).index;
        const end = start + m.length;
        if (q[start] !== ' ') {
          position = start || 2;
          message = t('error', 'NO_SPACE_BEFORE', language);
          example = t('error', 'NO_SPACE_BEFORE_EXAMPLE', language);
        } else if (q[end] !== ' ') {
          position = end;
          message = t('error', 'NO_SPACE_AFTER', language);
          example = t('error', 'NO_SPACE_AFTER_EXAMPLE', language);
        }
      });

      if (!message) {
        const specialCharsRegex = /^(AND NOT|OR|AND|\(|\)|\{|\}|\[|\])$/g;
        const andOrSpacesRegex = /(\sAND\sNOT\s)|(\sOR\s)|(\sAND\s)/g;
        const qList = q.split(andOrSpacesRegex).filter(el => !!el?.length);
        qList.forEach((m, index) => {
          const operator = m.match(andOrRegex);
          if (operator) {
            const lastWord = qList[index + 1]?.trim() || qList[index]?.trim() || '';
            if (index === 0 || !qList.slice(0, index).join('').length || qList[index - 1]?.match(specialCharsRegex)) {
              position = index === 0 ? 2 : qList.slice(0, index).join('').length;
              message = t('error', 'NO_SEARCH_BEFORE', language);
              example = t('error', 'NO_SEARCH_BEFORE_EXAMPLE', language);
            } else if (qList.length < (index + 2) || lastWord?.match(specialCharsRegex)) {
              position = qList.slice(0, index).join('').length + m.length;
              message = t('error', 'NO_SEARCH_AFTER', language);
              example = t('error', 'NO_SEARCH_AFTER_EXAMPLE', language);
            }
          }
        });
      }

      return message ? { position: position - 1, message, example } : null;
    };

    return checkColon(query) || checkRegexSlashes(query) || checkBrackets(query) || checkQuotes(query) || checkDotComma(query) || checkAndOr(query);
  } catch (e) {
    console.log(e);
    return null;
  }
}

export { validateQuery };
