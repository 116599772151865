import t from 'utils/translateFunc';
import cn from 'classnames';
import { node, shape, string, oneOfType, array } from 'prop-types';
import classes from './ChooseIndicator.module.scss';

function ChooseIndicator({ className, data, children }) {
  if (!data) return null;
  return !data?.length ? (
    <div className={cn(classes.chooseWrap, className)}>
      <div className={classes.textWrapp}>
        <span className={classes.noDataText}>{t('search', 'NO_METRICS_SELECTED')}</span>
        <div className={classes.image} />
        <span className={classes.noDataSubText}>
          {t('search', 'SELECT_METRICS_TO_DISPLAY')}
        </span>
      </div>
      {/* <div className={classes.curvesArrow} /> */}
    </div>
  ) : children;
}
ChooseIndicator.propTypes = { className: string, data: oneOfType([array, shape({})]), children: oneOfType([node, shape({})]) };

export default ChooseIndicator;
