import { useState, useEffect } from 'react';
import { string, func, shape, number, bool, oneOfType } from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { Button, BodyHtml } from 'Components';
import { customAlphabet } from 'nanoid';
import { sendAlertsEvent } from 'services/amplitude/events';
import classes from './BodyConfirmEditReport.module.scss';

const BodyConfirmEditReport = ({ className = '',
  onCancel = () => null,
  onClose = () => null,
  onCreateEditReport = () => null,
  language = 'uk',
  data = { name: '', id: 0 },
  isConfirm = false
}) => {
  const [text, setText] = useState('');
  const nanoid = customAlphabet('0123456789', 5);

  const onSubmit = (e) => {
    e.preventDefault();
    if (data?.errors) {
      onCancel();
    }
    if (!data?.errors) {
      const fileredTimeList = !!data?.time_list?.length && data.time_list.filter(item => !!item?.time && item?.time?.length === 5);
      const removeDuplicates = fileredTimeList?.length && [...new Map(fileredTimeList.map(obj => [JSON.stringify({ day: obj.day, time: obj.time }), obj])).values()];
      const removeDuplicatesWithIds = removeDuplicates?.length && removeDuplicates.map(item => ({ ...item, id: item?.id || nanoid() }));
      const uniqueTimeList = removeDuplicatesWithIds?.length ? removeDuplicatesWithIds.sort((a, b) => (a.day - b.day || a.time.localeCompare(b.time))) : null;

      const reportBody = {
        id: data?.id,
        body: {
          name: data?.name || t('reports', 'NO_NAME'),
          emails: data?.delivery_type === 'email' && data?.emails?.length ? data?.emails : null,
          keyword: data?.keyword?.id || null,
          delivery_type: (data?.delivery_type === 'telegram' && data?.telegram_users?.length ? 'telegram' : null) || (data?.delivery_type === 'whatsapp' && data?.whatsapp_accounts?.length ? 'whatsapp' : null) || 'email',
          time_list: uniqueTimeList,
          page: data?.page?.id || data?.page || null,
          report_customization: !!data?.report_customization?.id && data.report_customization.id !== 'default' ? data.report_customization.id : null,
          telegram_users: data?.delivery_type === 'telegram' && data?.telegram_users?.length ? data?.telegram_users.map(item => item?.id) : [],
          whatsapp_accounts: data?.delivery_type === 'whatsapp' && data?.whatsapp_accounts?.length ? data?.whatsapp_accounts.map(item => item?.id) : [],
          portal: data?.portal?.id || null,
          user: data?.user?.id || null,
          is_enabled: data?.is_enabled,
          editable: data?.editable,
          report_type: data?.report_type || 'html',
          frequency: data?.frequency || '',
          template_language: data?.template_language || 'uk'
        }
      };

      const properties = {
        isEmails: !!reportBody.body?.emails?.length,
        isKeyword: !!reportBody.body?.keyword,
        deliveryType: reportBody.body?.delivery_type || '',
        time: reportBody.body?.time || '',
        timeList: reportBody.body?.time_list || '',
        isTimeListDay: reportBody.body?.time_list_day || '',
        isTimeListWeek: reportBody.body?.time_list_week || '',
        isTimeListMonth: reportBody.body?.time_list_month || '',
        page: reportBody.body?.page || '',
        reportCustomization: reportBody.body?.report_customization || '',
        isTelegramUsers: !!reportBody.body?.telegram_users?.length,
        isWhatsappUsers: !!reportBody.body?.whatsapp_accounts?.length,
        portal: reportBody.body?.portal,
        isEnabled: reportBody.body?.is_enabled,
        isEditable: reportBody.body?.editable,
        reportType: reportBody.body?.report_type || '',
        frequency: reportBody.body?.frequency || '',
        templateLanguage: reportBody.body?.template_language,
        ...(reportBody?.id ? { user: reportBody.body?.user || '' } : {})
      };
      sendAlertsEvent({ category: 'Edit report', event: 'Clicked_Confirm_Changes_Button_In_Modal_Edit_Report', properties });
      onCreateEditReport(reportBody);
    }
  };

  useEffect(() => {
    let bodyText = `${t('reports', 'SAVE_CHANGES', language)} <strong>${data?.name || t('reports', 'NO_NAME')}</strong>?`;
    isConfirm && (bodyText = t('reports', 'HAVE_UNSAVED_CHANGES', language));

    setText(bodyText);
  }, [isConfirm, data?.name]);

  function handleClose() {
    onClose(data?.id);
  }

  return (
    <form className={cn(classes.bodyConfirmEditAlert, className)}>
      <div className={classes.discription}>
        <BodyHtml html={text} />
      </div>
      <div className={classes.buttonBox}>
        <Button
          onClick={isConfirm ? handleClose : onCancel}
          textButton={t('btn', isConfirm ? 'CANCEL_CHANGES' : 'CANCEL', language)}
          className={classes.buttonCancel}
          purpleTransparent32
        />
        <Button
          type="submit"
          onClick={onSubmit}
          textButton={t('btn', 'CONFIRM_CHANGES', language)}
          green32
        />
      </div>
    </form>
  );
};

BodyConfirmEditReport.propTypes = {
  className: string,
  data: oneOfType([shape({ name: string, id: number })]),
  onCancel: func,
  onClose: func,
  onCreateEditReport: func,
  language: string,
  isConfirm: bool
};

export default BodyConfirmEditReport;
