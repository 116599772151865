import { useState } from 'react';
import t from 'utils/translateFunc';
import { FilterCustom } from 'Components';
import { useGetCreators } from 'storage/queryHooks';
import useDebounceValue from 'hooks/useDebounce';
import { arrayOf, bool, func, shape } from 'prop-types';

const FilterCreator = ({ creators = [], onFilterList = () => null, onChange = () => null, enabled = false }) => {
  const [filterCreator, setFilterCreator] = useState('');
  const icontains = useDebounceValue(filterCreator, 1000);
  const { isLoading: creatorsLoading } = useGetCreators({ enabled, query: { icontains } });
  const filterType = t('filtrationPanel', 'CREATOR');

  return (
    <FilterCustom
      options={creators ? creators?.map(({ id, name }) => ({ value: id, label: name })) : null}
      filterName="creator"
      filterType={filterType}
      placeholder={t('filtrationPanel', 'ENTER_NAME')}
      labelPlaceholderPart={t('filtrationPanel', 'ALL_CREATORS')}
      endingText={{ one: t('filtrationPanel', 'CREATOR').toLowerCase(), many: t('filtrationPanel', 'CREATORS').toLowerCase() }}
      onChange={onChange}
      onFilterList={onFilterList}
      isLoading={creatorsLoading}
      inputValue={filterCreator}
      onInputChange={setFilterCreator}
    />
  );
};
FilterCreator.propTypes = {
  creators: arrayOf(shape({})),
  onFilterList: func,
  onChange: func,
  enabled: bool
};
export default FilterCreator;
