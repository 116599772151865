import { useState } from 'react';
import t from 'utils/translateFunc';
import { FilterCustom } from 'Components';
import { useGetPublicationPlaces } from 'storage/queryHooks';
import useDebounceValue from 'hooks/useDebounce';
import { arrayOf, bool, func, shape } from 'prop-types';

const FilterPlacePublication = ({ publication_places = [], onFilterList = () => null, onChange = () => null, enabled = false }) => {
  const [filterPlace, setFilterPlace] = useState('');
  const icontains = useDebounceValue(filterPlace, 1000);
  const { isLoading: placesLoading } = useGetPublicationPlaces({ enabled, query: { icontains } });
  const filterType = t('filtrationPanel', 'PUBLICATION_PLACE');

  return (
    <FilterCustom
      options={publication_places ? publication_places?.map(({ id, name }) => ({ value: id, label: name })) : null}
      filterName="publication_place"
      filterType={filterType}
      labelPlaceholderPart={t('filtrationPanel', 'ALL_PUBLICATION_PLACES')}
      placeholder={t('filtrationPanel', 'ENTER_NAME')}
      endingText={{ one: t('filtrationPanel', 'PUBLICATION_PLACE').toLowerCase(), many: t('filtrationPanel', 'PUBLICATION_PLACES').toLowerCase() }}
      onChange={onChange}
      onFilterList={onFilterList}
      isLoading={placesLoading}
      inputValue={filterPlace}
      onInputChange={setFilterPlace}
    />
  );
};
FilterPlacePublication.propTypes = {
  publication_places: arrayOf(shape({})),
  onFilterList: func,
  onChange: func,
  enabled: bool
};
export default FilterPlacePublication;
