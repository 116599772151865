import { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { func, arrayOf, string } from 'prop-types';
import { dataNavTypes } from 'globalPropTypes';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import { Icons, FilterBtn } from 'Components';
import classes from './NavFilters.module.scss';

const NavFilters = ({ dataNav = [], onRemove = () => null, language = 'en' }) => {
  const params = useParams();

  const navWrapRef = useRef();
  const navRef = useRef();
  const btnRef = useRef();

  const [arrowLeft, setArrowLeft] = useState(false);
  const [arrowRight, setArrowRight] = useState(false);
  const [indexFirstVisibleChips, setIndexFirstVisibleChips] = useState(0);
  const [hiddenWidth, setHiddenWidth] = useState(0);
  const [maxHiddenWidth, setMaxHiddenWidth] = useState(0);

  const [resizeWindow, setResizeWindow] = useState(0);

  const [maxBtnWidth, setMaxBtnWidth] = useState(0);

  const [dataLength, setDataLength] = useState(0);

  const resizeWindowFunc = () => {
    if (navWrapRef && navWrapRef.current) {
      setResizeWindow(navWrapRef.current?.offsetWidth);
    }
  };

  const debouncedResizeWindowFunc = useCallback(
    debounce(resizeWindowFunc, 300),
    []
  );

  const hiddenWidthFunc = currentIndex => dataNav?.reduce(
    (totalWidth, item, index) => (index <= currentIndex
      ? totalWidth + navRef.current.children[index].offsetWidth + 8
      : totalWidth),
    0
  );

  const indexFirstVisibleFunc = (maxWidth) => {
    let totalWidth = 0;
    let firstIndex = 0;
    dataNav?.forEach((item, index) => {
      if (totalWidth <= maxWidth) {
        totalWidth = totalWidth + navRef.current.children[index].offsetWidth + 8;
        firstIndex = index;
      }
    });
    return firstIndex;
  };

  useEffect(() => {
    window.addEventListener('resize', debouncedResizeWindowFunc);
    return () => {
      window.removeEventListener('resize', debouncedResizeWindowFunc);
      debouncedResizeWindowFunc.cancel();
    };
  }, []);

  useEffect(() => {
    const navWrapWidth = navWrapRef?.current?.offsetWidth;
    const dataNavWidth = navRef?.current?.offsetWidth;
    const btnWidth = btnRef?.current?.offsetWidth;

    if (maxBtnWidth < btnWidth) {
      setMaxBtnWidth(btnWidth);
    }
    const burgerTriger = navWrapWidth - 52 - dataNavWidth < maxBtnWidth;

    const visibleWidth = burgerTriger
      ? navWrapWidth - 20 - 24 - 16 - 12
      : navWrapWidth - btnWidth - 24 - 16 - 12;

    setMaxHiddenWidth(dataNavWidth - visibleWidth);

    if (dataNavWidth > visibleWidth) {
      if (
        dataNavWidth - visibleWidth
        < hiddenWidthFunc(indexFirstVisibleChips - 1)
      ) {
        setArrowRight(false);
        setHiddenWidth(dataNavWidth - visibleWidth);
      } else {
        setArrowRight(true);
      }
    } else {
      setArrowRight(false);
      setArrowLeft(false);
      setHiddenWidth(0);
      setIndexFirstVisibleChips(0);
    }
  }, [navWrapRef, navRef, btnRef, dataNav, resizeWindow]);

  useEffect(() => {
    const idChips = Number(params.page);
    const lastIndex = dataNav.length - 1;
    const someIndex = dataNav?.some((item, index) => index === lastIndex && item.id === idChips);
    if (dataNav.length < dataLength && !dataNav?.some((item, index) => index >= indexFirstVisibleChips && item.id === idChips)) {
      setArrowLeft(false);
      setHiddenWidth(0);
      setIndexFirstVisibleChips(0);
      if (maxHiddenWidth > 0) {
        setArrowRight(true);
      }
    }
    if (someIndex && maxHiddenWidth > 0) {
      setArrowRight(false);
      setArrowLeft(true);
      setHiddenWidth(maxHiddenWidth);
      setIndexFirstVisibleChips(indexFirstVisibleFunc(maxHiddenWidth) + 1);
    }
    setDataLength(dataNav.length);
  }, [params, dataNav.length]);

  const OnSwipeLeft = () => {
    setHiddenWidth(hiddenWidthFunc(indexFirstVisibleChips - 2));
    if (indexFirstVisibleChips === 1) {
      setArrowLeft(false);
    }
    if (maxHiddenWidth <= hiddenWidthFunc(indexFirstVisibleChips)) {
      setArrowRight(true);
    }

    if (indexFirstVisibleChips > 0) {
      setIndexFirstVisibleChips(prev => prev - 1);
    }
  };

  const OnSwipeRight = () => {
    if (indexFirstVisibleChips === 0) {
      setArrowLeft(true);
    }
    if (maxHiddenWidth <= hiddenWidthFunc(indexFirstVisibleChips)) {
      setArrowRight(false);
      setHiddenWidth(maxHiddenWidth);
    } else {
      setHiddenWidth(hiddenWidthFunc(indexFirstVisibleChips));
    }

    if (indexFirstVisibleChips < dataNav.length - 1) {
      setIndexFirstVisibleChips(prev => prev + 1);
    }
  };

  return (
    <div className={classes.navFilters} ref={navWrapRef}>
      <div
        className={classes.chipsContainer}
        ref={navRef}
        style={{ left: `-${hiddenWidth}px` }}
      >
        {dataNav.map(filter => (
          <span key={filter.id} className={classes.filter}>
            <FilterBtn
              type={filter.type}
              text={filter.name}
              isExclude={!!filter?.isExclude}
              filterName={filter.filterName}
              onRemove={() => onRemove(filter)}
              language={language}
            />
          </span>
        ))}
      </div>
      {arrowLeft && (
      <button
        className={cn(classes.arrowBtn, classes.arrowBtn_left)}
        onClick={OnSwipeLeft}
        type="button"
      >
        <Icons
          icon="Icn_Arrow_swiper"
          className={cn(classes.iconArrow, classes.iconArrow_left)}
        />
      </button>
      )}
      {arrowRight && (
        <button
          className={cn(classes.arrowBtn, classes.arrowBtn_right)}
          onClick={OnSwipeRight}
          type="button"
        >
          <Icons
            icon="Icn_Arrow_swiper"
            className={cn(classes.iconArrow, classes.iconArrow_right)}
          />
        </button>
      )}
    </div>
  );
};

NavFilters.propTypes = {
  dataNav: arrayOf(dataNavTypes),
  onRemove: func,
  language: string
};

export default NavFilters;
