import moment from 'moment';
import {
  bool, func, oneOfType, shape, string
} from 'prop-types';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { BtnWithDropDown } from 'Components';
import {
  hourPars, minutePars, getDaysArray, getYearsArray
} from '../../helpers';
import classes from './DateBox.module.scss';
import List from './List';

const DateBox = ({
  className = '',
  errorText = false,
  start = '',
  end = '',
  timeStart = { hour: '', min: '' },
  timeEnd = '',
  trigerClick = false,
  setTrigerClick = () => null,
  handleStart = () => null,
  handleEnd = () => null,
  language = 'uk'
}) => {
  const hours = hourPars();
  const minutes = minutePars();

  const onChangeMonth = ({ month, period, onChange }) => {
    const days = getDaysArray(month);
    const dayIn = days.includes(String(period.day));
    const lastDay = days[days.length - 1];
    dayIn ? onChange({ ...period, month }) : onChange({ ...period, month, day: lastDay });
  };

  return (
    <div className={cn(classes.dateBox, className)}>
      <div className={cn(classes.dateInfo, className)}>
        <div>
          <p className={classes.labelDate}>{t('datepicker', 'BEGIN_DATE', language) }</p>
          <div className={classes.inputWrap}>
            <BtnWithDropDown
              classNameBodyDropDown={cn(classes.bodyDropDown, classes.bodyDropDown_dateStart)}
              bodyToggle={(
                <input
                  className={cn(
                    classes.date,
                    errorText && classes.date_error,
                    classes.date_day
                  )}
                  type="text"
                  name="startDate"
                  value={`${moment(start).format('DD')} ${moment.monthsShort('-MMM-', moment(start).format('MM') - 1)} ${moment(start).format('YYYY')}`}
                  onChange={() => {}}
                  readOnly
                />
              )}
              arrow={false}
              noCloseOnClickDropDown
              trigerClick={trigerClick}
              setTrigerClick={setTrigerClick}
              language={language}
            >
              <div className={classes.timeBodyDropDown}>
                <List dataArr={getDaysArray(timeStart.month)} type="day" onClick={day => handleStart({ ...timeStart, ...day })} />
                <List dataArr={moment.monthsShort()} type="month" onClick={month => onChangeMonth({ period: { ...timeStart }, onChange: handleStart, ...month })} />
                <List dataArr={getYearsArray()} type="year" onClick={year => handleStart({ ...timeStart, ...year })} />
              </div>
            </BtnWithDropDown>
            <BtnWithDropDown
              classNameBodyDropDown={classes.bodyDropDown}
              bodyToggle={(
                <input
                  className={cn(
                    classes.date,
                    errorText && classes.date_error,
                    classes.date_time
                  )}
                  type="text"
                  name="startTime"
                  value={`${timeStart.hour}:${timeStart.min}`}
                  onChange={() => {}}
                  readOnly
                />
              )}
              arrow={false}
              noCloseOnClickDropDown
              trigerClick={trigerClick}
              setTrigerClick={() => null}
              language={language}
            >
              <div className={classes.timeBodyDropDown}>
                <List
                  dataArr={hours}
                  type="hour"
                  onClick={(hour) => { handleStart({ ...timeStart, ...hour }, true); }}
                />
                <List
                  dataArr={minutes}
                  type="min"
                  onClick={(min) => { handleStart({ ...timeStart, ...min }, true); }}
                />
              </div>
            </BtnWithDropDown>
          </div>
        </div>
        <p className={classes.dash}>–</p>
        <div>
          <p className={classes.labelDate}>{t('datepicker', 'END_DATE', language) }</p>
          <div className={classes.inputWrap}>
            <BtnWithDropDown
              classNameBodyDropDown={cn(classes.bodyDropDown, classes.bodyDropDown_dateEnd)}
              bodyToggle={(
                <input
                  className={cn(
                    classes.date,
                    errorText && classes.date_error,
                    classes.date_day
                  )}
                  type="text"
                  name="endDate"
                  value={`${moment(end).format('DD')} ${moment.monthsShort('-MMM-', moment(end).format('MM') - 1)} ${moment(end).format('YYYY')}`}
                  onChange={() => {}}
                  readOnly
                />
              )}
              arrow={false}
              noCloseOnClickDropDown
              trigerClick={trigerClick}
              setTrigerClick={setTrigerClick}
              language={language}
            >
              <div className={classes.timeBodyDropDown}>
                <List
                  dataArr={getDaysArray(timeEnd.month)}
                  type="day"
                  onClick={day => handleEnd({ ...timeEnd, ...day })}
                />
                <List
                  dataArr={moment.monthsShort()}
                  type="month"
                  onClick={month => onChangeMonth({ period: { ...timeEnd }, onChange: handleEnd, ...month })}
                />
                <List
                  dataArr={getYearsArray()}
                  type="year"
                  onClick={year => handleEnd({ ...timeEnd, ...year })}
                />
              </div>
            </BtnWithDropDown>
            <BtnWithDropDown
              classNameBodyDropDown={classes.bodyDropDown}
              bodyToggle={(
                <input
                  className={cn(
                    classes.date,
                    errorText && classes.date_error,
                    classes.date_time
                  )}
                  type="text"
                  name="endTime"
                  value={`${timeEnd.hour}:${timeEnd.min}`}
                  onChange={() => {}}
                  readOnly
                />
              )}
              arrow={false}
              noCloseOnClickDropDown
              trigerClick={trigerClick}
              setTrigerClick={() => null}
              language={language}
            >
              <div className={classes.timeBodyDropDown}>
                <List dataArr={hours} type="hour" onClick={hour => handleEnd({ ...timeEnd, ...hour }, true)} />
                <List dataArr={minutes} type="min" onClick={min => handleEnd({ ...timeEnd, ...min }, true)} />
              </div>
            </BtnWithDropDown>
          </div>
        </div>
      </div>
      {errorText && <p className={classes.errorText}>{errorText}</p>}
    </div>
  );
};

DateBox.propTypes = {
  className: string,
  errorText: oneOfType([string, bool]),
  start: string,
  end: string,
  timeStart: shape({
    hour: string,
    min: string
  }),
  timeEnd: shape({
    hour: string,
    min: string
  }),
  handleStart: func,
  handleEnd: func,
  trigerClick: bool,
  setTrigerClick: func,
  language: string
};

export default DateBox;
