import { components } from 'react-select';
import { SkeletonFilterList } from 'Components';
import classes from './LoadingMessage.module.scss';

const { LoadingMessage } = components;
const LoadingMessagePart = props => (
  <LoadingMessage {...props} className={classes.loadingMessage}>
    <SkeletonFilterList fetching />
  </LoadingMessage>
);
export default LoadingMessagePart;
