import { useMemo } from 'react';
import cn from 'classnames';
import { Icon, ErrorBoundarySentry, Loader, TooltipForTruncate, SelectCustom, BodyHtml, IconSVG } from 'Components';
import { getSourceColors } from 'data/colorsConfig';
import moment from 'moment';
import t from 'utils/translateFunc';
import { string, shape, number, arrayOf, func, bool } from 'prop-types';
import { SOCIAL_NETWORKS } from 'data/sourceConfig';
import creatorClasses from 'Components/Popovers/CreatorPlacePopover/CreatorPlace.module.scss';
import sourceClasses from 'Components/Popovers/SourcePopover/SourcePopover.module.scss';
import colors from 'styles/_colors.scss';
import classes from './ArticlesListPreview.module.scss';
import { stylesPeriod } from './stylesSelect';

function ArticlesListPreview({ previewData = { articles: [], originals_count: 0, all_count: 0 }, name = '', error = false, highlights = '',
  language = 'uk', onChangePeriod = () => null, optionsPeriod = [], period = null, className = '' }) {
  const regex = useMemo(() => {
    try {
      const rx = new RegExp(`${highlights}`, 'gi');
      return rx;
    } catch (e) {
      console.log(e);
      return '';
    }
  }, [highlights]);

  function showBodyArticle(article) {
    article.url && window.open(article.url, '_blank');
  }

  return (
    <ErrorBoundarySentry language={language}>
      <div className={cn(classes.articlesListPreviewWrap, className)}>
        {!!name && !error && (
          <div className={cn(classes.articlesHeader)}>
            <TooltipForTruncate
              style={{ maxWidth: '100px' }}
              tooltipContent={name}
            >
              <span className={classes.keywordName}>
                {name}
              </span>
            </TooltipForTruncate>
            <span>
              {previewData?.all_count || 0}
              {t('mentions', 'TOTAL', language)}
              /
            </span>
            <span>
              {previewData?.originals_count || 0}
              {t('mentions', 'ORIGINALS', language)}
            </span>
            <span className={cn(classes.articlesPeriod)}>
              {optionsPeriod?.length && (
              <SelectCustom
                value={period}
                onChange={onChangePeriod}
                options={optionsPeriod}
                className={classes.select}
                styles={stylesPeriod}
              />
              )}
            </span>
          </div>
        )}
        {previewData?.articles?.length && !!previewData.articles[0] && !error ? (
          <div className={classes.articlesListWrap}>
            {previewData?.articles?.map((article) => {
              const sourceName = article?.source || article?.source_name;
              const mediaType = article?.source_type || article.media_type;
              const iconType = mediaType === 'socialnetwork' && SOCIAL_NETWORKS.includes(sourceName) ? sourceName : mediaType;
              const { background, text, backgroundActive } = getSourceColors(iconType);

              return (
                <ErrorBoundarySentry language={language} key={article?.id}>
                  <div
                    className={cn(classes.article, classes.article_neutral)}
                    id={article?.id}
                  >
                    <div className={cn(classes.contentWrapper)}>
                      <div
                        className={cn(classes.headerWrapper)}
                        onClick={() => { showBodyArticle(article); }}
                      >
                        <div className={cn(classes.titleWrapper)}>
                          <label htmlFor={article.id} className={classes.checkboxLabel}>
                            <input
                              type="checkbox"
                              className={classes.visually_hidden}
                              value={article.id}
                              id={article.id}
                            />
                            <div className={cn(classes.checkbox)} />
                          </label>
                          <span>
                            <span className={classes.titleContentWrapper}>
                              <BodyHtml
                                className={classes.titleContentWrapper_body}
                                html={regex ? article?.title?.replaceAll(regex, match => (`<b class=${classes.titleHighLight}>${match}</b>`)) : article?.title}
                              />
                            </span>
                          </span>
                        </div>
                        <div className={cn(classes.date)}>{moment(article?.created_date || article?.date).format('DD MMM YYYY HH:mm')}</div>
                      </div>
                      <div className={cn(classes.bodyWrapper)}>
                        {article?.highlight?.text
                          ?.filter((_, i) => i < 2)
                          ?.map((bodyText, i) => (
                            <span className={cn(classes.textContentWrapper)} key={i}>
                              <BodyHtml
                                className={classes.textContentWrapper_body}
                                html={`...${bodyText}...`}
                              />
                            </span>
                          )) }
                      </div>
                      <div className={cn(classes.footerWrapper)} id={`article-footer-${article?.id}`}>
                        <div className={cn(classes.footerWrapper_container)}>
                          {(article?.source || article?.source_name) && (
                            <div className={sourceClasses.sourceWrapper}>
                              <IconSVG type={iconType} iconWrapClass={sourceClasses.iconWrap} />
                              <div className={sourceClasses.sourcePopoverWrapper}>
                                <a
                                  href={article?.url}
                                  target="_blank"
                                  className={sourceClasses.link}
                                  style={{
                                    backgroundColor: background,
                                    color: text,
                                    '--background-active': backgroundActive
                                  }}
                                  rel="noopener noreferrer"
                                >
                                  {!article?.post_type
                                    ? <b>{sourceName || ''}</b>
                                    : `${t('postTypes', article?.post_type, language)}`}
                                </a>
                                <Icon
                                  type="arrow_drop_down"
                                  className={cn(sourceClasses.iconClass)}
                                  wrapStyles={{
                                    backgroundColor: background,
                                    '--background-active': backgroundActive
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {(article?.author || article?.author_name) && (
                            <div className={cn(creatorClasses.creatorTitle, classes.creatorTitle)}>
                              {!!article?.author_subscribers && (
                                <Icon
                                  type="people"
                                  role="button"
                                  tooltipText={t('creatorPlaceCard', 'CREATOR_AUDIENCE', language)}
                                  text={article?.author_subscribers}
                                  className={cn(creatorClasses.creatorPeople)}
                                  btnType={cn(creatorClasses.creatorPeopleIcon)}
                                  textClass={creatorClasses.creatorTextClass}
                                />
                              )}
                              <Icon
                                type="arrow_drop_down"
                                role="button"
                                tooltipText={t('creatorPlaceCard', 'CREATOR', language)}
                                text={article?.author || article?.author_name}
                                className={cn(creatorClasses.creatorPeople, creatorClasses.creatorPeople_reverse)}
                                btnType={cn(creatorClasses.creatorPeopleIcon, creatorClasses.creatorPeopleIcon_reverse)}
                              />
                            </div>
                          )}
                          {article?.publication_place_name && (
                            <div className={cn(creatorClasses.creatorTitle, classes.creatorTitle)}>
                              <Icon
                                type="next_arrow"
                                className={cn(creatorClasses.creatorNextArrow)}
                                btnType={cn(creatorClasses.creatorPeopleIcon)}
                              />
                              {!!article?.publication_place_subscribers && (
                              <Icon
                                type="people"
                                role="button"
                                tooltipText={t('creatorPlaceCard', 'AUDIENCE_PLACE', language)}
                                text={article?.publication_place_subscribers}
                                className={cn(creatorClasses.creatorPeople)}
                                btnType={cn(creatorClasses.creatorPeopleIcon)}
                                textClass={creatorClasses.creatorTextClass}
                              />
                              )}
                              <Icon
                                type="arrow_drop_down"
                                role="button"
                                tooltipText={t('creatorPlaceCard', 'PLACE_MENTION', language)}
                                text={article?.publication_place_name}
                                className={cn(creatorClasses.creatorPeople, creatorClasses.creatorPeople_reverse)}
                                btnType={cn(creatorClasses.creatorPeopleIcon, creatorClasses.creatorPeopleIcon_reverse)}
                              />
                            </div>
                          )}
                        </div>
                        <div className={cn(classes.footerWrapper_container, classes.footerWrapper_icons)}>
                          {(article?.duplicates_count || article?.duplicates_count === 0) && (
                            <Icon
                              type="doubles"
                              role="button"
                              tooltipText={t('tooltip', 'DOUBLES', language)}
                              text={article?.duplicates_count || '0'}
                              className={cn(classes.iconText)}
                              textClass={cn(classes.iconText)}
                              btnType={cn(classes.btnIcon)}
                            />
                          )}
                          {(article?.views_count || article?.views_count === 0) && (
                            <Icon
                              type="view"
                              role="button"
                              tooltipText={t('tooltip', 'VIEWING', language)}
                              text={article?.views_count || '0'}
                              className={cn(classes.iconText)}
                              textClass={cn(classes.iconText)}
                              btnType={cn(classes.btnIcon)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </ErrorBoundarySentry>
              );
            }) }
          </div>
        ) : (
          <span className={classes.noArticles}>
            <div className={cn(classes.noArticlesImage, !!error && classes.noArticlesImage_error)} />
            <div className={classes.noArticlesContent}>
              <div className={classes.noArticlesHeader}>{t('notFound', !error ? 'NO_ARTICLES_BY' : 'ERROR_QUERY', language)}</div>
              <div className={classes.noArticlesBody}>{t('notFound', 'EDIT_QUERY', language)}</div>
            </div>
          </span>
        )}
      </div>
      <Loader
        size={45}
        className={classes.nextLoader}
        fetching={previewData?.isFetchingNextPage}
        color={colors.font35}
      />
    </ErrorBoundarySentry>
  );
}

ArticlesListPreview.propTypes = {
  previewData: shape({
    articles: arrayOf(shape({})),
    originals_count: number,
    all_count: number
  }),
  optionsPeriod: arrayOf(shape({})),
  period: shape({}),
  language: string,
  className: string,
  name: string,
  highlights: string,
  error: bool,
  onChangePeriod: func
};

export default ArticlesListPreview;
