import { string, bool, oneOfType, number, func } from 'prop-types';
import { Icons } from 'Components';
import t from 'utils/translateFunc';
import cn from 'classnames';
import classes from './FilterBtn.module.scss';

const FilterBtn = ({ text = '', isExclude = false, filterName = '', language = 'uk', onRemove = () => null }) => (
  <div className={cn(classes.filterBtn, isExclude && classes.filterBtn_isExclude)}>
    {!!filterName && (
      <p className={classes.discription}>
        {t('filtrationPanel', String(filterName || '').toUpperCase(), language)}
        :
        <span className={classes.text}>{text}</span>
      </p>
    )}
    <button type="button" className={classes.btnClose} onClick={() => {}}>
      <Icons icon="close_black_24" className={classes.iconClose} onClick={onRemove} />
    </button>
  </div>
);
FilterBtn.propTypes = {
  text: oneOfType([number, string]),
  isExclude: bool,
  filterName: string,
  language: string,
  onRemove: func
};

export default FilterBtn;
