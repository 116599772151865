import { useState, useEffect, useMemo, memo } from 'react';
import { nanoid } from 'nanoid';
import { string, func, number, shape, bool, oneOfType } from 'prop-types';
import cn from 'classnames';
import { useGetKeyword, useSendUserEvent, useGetPreviewAricles, useGetPreviewCountries } from 'storage/queryHooks.js';
import t from 'utils/translateFunc';
import { Button, TextField, FilterOption, ArticlesListPreview, Loader, InfoBtn, EditableLabel, ErrorBoundarySentry } from 'Components';
import Select from 'react-select';
import { mediaTypes as mediaTypesFunc } from 'data/persistentData';
import useDebounceValue from 'hooks/useDebounce';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { sendFeedEvent } from 'services/amplitude/events';
import colors from 'styles/_colors.scss';
import classes from './BodyAddEditKW.module.scss';
import { customSelectStyles } from './selectStyles';
import { MultiValueLabel, DropdownIndicator } from './SelectComponents';

const BodyAddEditKW = memo(({
  className = '',
  data = { name: '', id: 0 },
  onCreateEdit = () => null,
  onManyMentions = () => null,
  onConfirmDel = () => null,
  onConfirmEdit = () => null,
  respError = '',
  language = 'uk',
  isLoading = false
}) => {
  const fishOr = [{ label: t('bodyAddTagModal', 'EXAMPLE_APPLE', language), value: 'example_1' }];
  const fishAnd = [{ label: t('bodyAddTagModal', 'EXAMPLE_iPHONE', language), value: 'example_1' }];
  const fishNot = [{ label: t('bodyAddTagModal', 'EXAMPLE_APPLEFRUIT', language), value: 'example_1' }];
  const [preview, setPreview] = useState(false);
  const [name, setName] = useState('');
  const [keyword, setKeyword] = useState('');
  const [countries, setCountries] = useState([]);
  const [mediaType, setMediaType] = useState([]);
  const [operationOr, setOperationOr] = useState(fishOr);
  const [operationAnd, setOperationAnd] = useState(fishAnd);
  const [operationNot, setOperationNot] = useState(fishNot);
  const [errorName, setErrorName] = useState('');
  const [errorKeyword, setErrorKeyword] = useState('');
  const [errorOr, setErrorOr] = useState('');
  const [errorAnd, setErrorAnd] = useState('');
  const [errorNot, setErrorNot] = useState('');
  const [inputCountry, setInputCountry] = useState('');
  const [geographyData, setGeographyData] = useState(false);
  const [period, setPeriod] = useState({ value: 'lastWeek', label: t('bodyAddTagModal', 'LAST_WEEK', language) });
  const keywordDebounce = useDebounceValue(keyword, 1000);
  const counryDebounce = useDebounceValue(inputCountry, 1000);

  const lastWeekDayIso = useMemo(() => moment().startOf('day').subtract(1, 'week').toISOString(), [period]);
  const last2WeekDayIso = useMemo(() => moment().startOf('day').subtract(2, 'week').toISOString(), [period]);
  const todayIso = useMemo(() => moment().startOf('day').toISOString(), [period]);
  useSendUserEvent({ enabled: !data?.id, body: { event: 'open_add_keyword' } });
  const { data: countriesData, isLoading: countriesLoading } = useGetPreviewCountries({ query: { name: counryDebounce }, enabled: true });
  const { data: keywordData } = useGetKeyword(data?.id);
  const { data: previewAllData, isLoading: isPreviewLoading } = useGetPreviewAricles({
    body: !!preview && createPreviewBody(),
    enabled: !!preview && keywordDebounce?.length >= 2 && !!createPreviewBody(),
    period: {
      start: period?.value === 'lastWeek' ? lastWeekDayIso : last2WeekDayIso,
      end: todayIso
    } });
  const usedOrWords = useMemo(() => operationOr?.filter(item => !item.value.includes('example'))?.length, [operationOr]);

  const previewData = useMemo(() => ({
    articles: previewAllData?.articles || [],
    all_count: previewAllData?.all_count || 0,
    originals_count: previewAllData?.originals_count || 0
  }), [previewAllData, isPreviewLoading]);

  const optionsPeriod = [
    { value: 'lastWeek', label: t('bodyAddTagModal', 'LAST_WEEK', language) },
    { value: 'last2Weeks', label: t('bodyAddTagModal', 'LAST_2WEEK', language) }
  ];
  const query_builder = useMemo(() => ({
    keyword,
    operation_or: operationOr?.length ? operationOr?.filter(item => !item.value.includes('example')).map(item => item?.label) : [],
    operation_and: operationAnd?.length ? operationAnd?.filter(item => !item.value.includes('example')).map(item => item?.label) : [],
    operation_not: operationNot?.length ? operationNot?.filter(item => !item.value.includes('example')).map(item => item?.label) : [],
    countries: countries?.length ? countries.map(item => (item?.value === 'not_defined' ? item?.value : item?.label)) : [],
    media_types: mediaType?.length ? mediaType.map(item => item?.id) : []
  }), [keyword, operationOr, operationNot, operationAnd, countries, mediaType]);

  useEffect(() => {
    keywordDebounce?.length < 2 && !!preview && setPreview(false);
  }, [keywordDebounce]);

  useEffect(() => {
    if (String(respError)?.includes('duplicate')) {
      setErrorName(t('notif', 'DUPLICATE_NAME', language));
    }
  }, [respError]);

  useEffect(() => {
    if (data) {
      data?.name && setName(data?.name);
      data?.query_builder?.keyword && setKeyword(data.query_builder.keyword);
      data?.query_builder?.operation_or?.length && setOperationOr(data?.query_builder?.operation_or.map(item => createOption(item)));
      data?.query_builder?.operation_and?.length && setOperationAnd(data?.query_builder?.operation_and.map(item => createOption(item)));
      data?.query_builder?.operation_not?.length && setOperationNot(data?.query_builder?.operation_not.map(item => createOption(item)));
      data?.query_builder?.countries?.length && setCountries(data?.query_builder?.countries.map(item => createOption(item)));
      data?.query_builder?.media_types?.length && setMediaType(data?.query_builder?.media_types.map(item => createMediaTypeOption(item)));
    }
  }, [data]);

  useEffect(() => {
    if (keywordData && !data?.query_builder) {
      keywordData?.name && setName(keywordData?.name);
      keywordData?.query_builder?.keyword && setKeyword(keywordData.query_builder.keyword);
      keywordData?.query_builder?.operation_or?.length && setOperationOr(keywordData?.query_builder?.operation_or.map(item => createOption(item)));
      keywordData?.query_builder?.operation_and?.length && setOperationAnd(keywordData?.query_builder?.operation_and.map(item => createOption(item)));
      keywordData?.query_builder?.operation_not?.length && setOperationNot(keywordData?.query_builder?.operation_not.map(item => createOption(item)));
      keywordData?.query_builder?.countries?.length && setCountries(keywordData?.query_builder?.countries.map(item => createOption(item)));
      keywordData?.query_builder?.media_types?.length && setMediaType(keywordData?.query_builder?.media_types.map(item => createMediaTypeOption(item)));
    }
  }, [keywordData]);

  useEffect(() => {
    if (countriesData?.specific_countries && countriesData?.data?.length) {
      setCountries(geographyData ? countriesData?.data?.filter(item => item?.value !== 'not_defined') : countriesData?.data);
    }
  }, [geographyData, countriesData]);

  const sendAmplitudeEvent = (event, category = `${data?.id ? 'Edit' : 'Add'} keyword Basic QB`) => {
    sendFeedEvent({ category, event });
  };

  const onChangeName = (e) => {
    !e?.target?.value?.length && !errorName && setErrorName(t('error', 'ERR_REQUIRED', language));
    const wordsCount = !!e?.target?.value && e.target.value.split(' ').length;
    wordsCount >= 4 && setErrorName(t('error', 'ERR_TAG_INTERVAL', language));
    wordsCount < 4 && errorName && setErrorName('');
    setName(e.target.value);
  };

  const onChangeKeyword = (e) => {
    !e?.target?.value?.length && !errorKeyword && setErrorKeyword(t('error', 'ERR_REQUIRED', language));
    e.target.value.length && errorKeyword && setErrorKeyword('');
    setKeyword(e.target.value);
  };

  const onSubmit = (e, route) => {
    e.preventDefault();
    !route && sendAmplitudeEvent(`Clicked_${data?.id ? 'Save' : 'Add'}_Button`);
    if (checkRequiredFields()) {
      const body = { name, query_builder };
      const manyMentions = previewData.all_count >= 10000;
      if (manyMentions && !route) {
        onManyMentions({ body, keyword_id: data?.id });
        return;
      }
      if (data?.id && route) {
        onCreateEdit({ body, keyword_id: data?.id, route });
        return;
      }
      data?.id
        ? onConfirmEdit({ body, keyword_id: data?.id, route })
        : onCreateEdit({ body, keyword_id: null });
    }
  };
  function createOption(label) {
    return ({ label: label === 'not_defined' ? t('nodata', 'UNDEFINED', language) : label, value: label });
  }
  function createMediaTypeOption(id) {
    return mediaTypesFunc(language).find(item => item.id === id);
  }

  function checkIsEqualFields() {
    if (data?.id) {
      const insideFields = { name, query_builder };
      const outsideFields = {
        name: data?.name,
        query_builder: data?.query_builder
      };
      return isEqual(insideFields, outsideFields);
    }
    return false;
  }

  function addNewWordOR(e) {
    e.preventDefault();
    sendAmplitudeEvent('Clicked_Add_Word_Button_OR_Operator');
    if (operationOr?.length >= 10) return;
    setOperationOr(prev => [...prev, { label: '', value: `example_${nanoid()}` }]);
  }

  function addNewWordAND(e) {
    e.preventDefault();
    sendAmplitudeEvent('Clicked_Add_Word_Button_AND_Operator');
    setOperationAnd(prev => [...prev, { label: '', value: `example_${nanoid()}` }]);
  }

  function addNewWordNOT(e) {
    e.preventDefault();
    sendAmplitudeEvent('Clicked_Add_Word_Button_NOT_Operator');
    setOperationNot(prev => [...prev, { label: '', value: `example_${nanoid()}` }]);
  }

  function removeWordOR(item) {
    errorOr && setErrorOr('');
    setOperationOr(prev => prev.filter(el => el?.value !== item?.value));
  }

  function removeWordAND(item) {
    errorAnd && setErrorAnd('');
    setOperationAnd(prev => prev.filter(el => el?.value !== item?.value));
  }

  function removeWordNOT(item) {
    errorNot && setErrorNot('');
    setOperationNot(prev => prev.filter(el => el?.value !== item?.value));
  }

  const onPreview = () => {
    sendAmplitudeEvent('Clicked_Next_Button_in_Keyword_Modal');
    keyword.length >= 2 && !preview && setPreview(true);
    keyword.length < 2 && !preview && setErrorKeyword(t('error', 'MIN_SYMBOLS', language));
    preview && setPreview(false);
  };

  const onToggle = (filter, isSelect) => {
    const keywordFilterEvents = ['internet', 'agency', 'press', 'radio', 'socialnetwork', 'tv'];
    keywordFilterEvents.includes(filter?.id) && !isSelect && sendAmplitudeEvent(`keyword_filter_${filter?.id}`);

    isSelect
      ? setMediaType(prev => prev.filter(item => item?.id !== filter.id))
      : setMediaType(prev => prev.concat([{ id: filter.id, title: filter?.title }]));
  };

  function checkRequiredFields() {
    name.length < 2 && setErrorName(t('error', 'MIN_SYMBOLS', language));
    !name.length && setErrorName(t('error', 'ERR_REQUIRED', language));
    keyword.length < 2 && setErrorKeyword(t('error', 'MIN_SYMBOLS', language));
    !keyword.length && setErrorKeyword(t('error', 'ERR_REQUIRED', language));
    return name.length >= 2 && keyword.length >= 2;
  }

  function createPreviewBody() {
    return !!preview && !!keywordDebounce && {
      keyword: keywordDebounce,
      operation_or: operationOr?.length ? operationOr.filter(item => !item.value.includes('example'))?.map(item => item?.value) : [],
      operation_and: operationAnd?.length ? operationAnd.filter(item => !item.value.includes('example'))?.map(item => item?.value) : [],
      operation_not: operationNot?.length ? operationNot.filter(item => !item.value.includes('example'))?.map(item => item?.value) : [],
      countries: countries?.length ? countries.map(item => (item?.value === 'not_defined' ? item?.value : item?.label)) : [],
      media_types: mediaType?.length ? mediaType.map(item => item?.id) : [],
      limit: '50'
    };
  }

  const onChangePrevPeriod = (params) => {
    const keywordFilterEvents = ['lastWeek', 'last2Weeks'];
    keywordFilterEvents.includes(params?.value) && params?.value !== period?.value && sendAmplitudeEvent(`keyword_${params?.value}`);
    setPeriod(params);
  };

  const highlights = useMemo(() => {
    const higlightKeyword = keywordDebounce ? [keywordDebounce] : [];
    const higlightOr = operationOr?.length ? operationOr?.filter(item => !item.value.includes('example')).map(item => item?.value.trim()) : [];
    const higlightAnd = operationAnd?.length ? operationAnd?.filter(item => !item.value.includes('example')).map(item => item?.value.trim()) : [];
    return [...higlightKeyword, ...higlightOr, ...higlightAnd]?.join(',').replaceAll(',', '|');
  }, [keywordDebounce, operationOr, operationAnd]);

  const keywordsCount = useMemo(() => keyword.split(' ').filter(item => !!item)?.length || 0, [keyword]);

  return (
    <div className={classes.bodyWrapper}>
      <form
        onKeyDown={(e) => { (e.key === 'Enter' || e.keyCode === 13) && e.preventDefault(); }}
        className={cn(classes.bodyKwModal, className)}
      >
        {data?.id && keyword && (
          <div
            className={classes.switchAdvanced}
            onClick={(e) => {
              sendFeedEvent({ category: 'Edit keyword Basic QB', event: 'Clicked_Switch_to_Advanced_Mode_Button' });
              onSubmit(e, `/advanced_builder/keyword/${data?.id}`);
            }}
          >
            {t('bodyAddTagModal', 'TO_ADVANCED_MODE', language)}
          </div>
        )}
        <TextField
          labelText={t('bodyAddTagModal', 'NAME', language)}
          value={name}
          onChange={onChangeName}
          placeholder={t('bodyAddTagModal', 'ENTER_NAME_KEYWORD', language)}
          boxShadow
          errorText={errorName}
        />
        <TextField
          labelText={t('bodyAddTagModal', 'KEYWORD', language)}
          value={keyword}
          onChange={onChangeKeyword}
          placeholder={t('bodyAddTagModal', 'ENTER_EXAMPLE', language)}
          boxShadow
          errorText={errorKeyword}
          className={cn(classes.kwField, keywordsCount > 6 && classes.kwField_many)}
          additionalInfo={keywordsCount > 6 ? t('bodyAddTagModal', 'RECOMEND_TEXT', language) : ''}
          labelInfo={t('bodyAddTagModal', 'SET_INTERVAL_KW', language)}
        />
        <div className={classes.creatableWrapper}>
          <div className={classes.wordButton}>OR</div>
          <ErrorBoundarySentry language={language}>
            <div className={classes.selectedList}>
              {operationOr.map(item => (
                <EditableLabel
                  key={item?.value}
                  dataItem={item}
                  onChange={(newOperation) => { setOperationOr(newOperation); }}
                  error={errorOr}
                  onChangeError={(errorValue) => { setErrorOr(errorValue ? t('error', errorValue, language) : ''); }}
                  onRemove={() => removeWordOR(item)}
                  operation={operationOr}
                />
              ))}
              <button className={cn(classes.addButton)} onClick={addNewWordOR}>
                {t('bodyAddTagModal', 'ADD_WORD', language)}
              </button>
            </div>
          </ErrorBoundarySentry>
          {errorOr && <div className={cn(classes.infoText, classes.infoText_error)}>{errorOr}</div>}
          <div className={cn(classes.infoText)}>
            {t('bodyAddTagModal', 'USED', language)}
            <b>{` ${usedOrWords} / 10`}</b>
          </div>
        </div>
        <div className={classes.creatableWrapper}>
          <div className={classes.wordButton}>AND</div>
          <ErrorBoundarySentry language={language}>
            <div className={classes.selectedList}>
              {operationAnd.map(item => (
                <EditableLabel
                  key={item?.value}
                  dataItem={item}
                  onChange={(newOperation) => { setOperationAnd(newOperation); }}
                  error={errorOr}
                  onChangeError={(errorValue) => { setErrorAnd(errorValue ? t('error', errorValue, language) : ''); }}
                  onRemove={() => removeWordAND(item)}
                  operation={operationAnd}
                />
              ))}
              <button className={cn(classes.addButton)} onClick={addNewWordAND}>
                {t('bodyAddTagModal', 'ADD_WORD', language)}
              </button>
            </div>
          </ErrorBoundarySentry>
          {errorAnd && <div className={cn(classes.infoText, classes.infoText_error)}>{errorAnd}</div>}
        </div>
        <div className={classes.creatableWrapper}>
          <div className={classes.wordButton}>NOT</div>
          <ErrorBoundarySentry language={language}>
            <div className={classes.selectedList}>
              {operationNot.map(item => (
                <EditableLabel
                  dataItem={item}
                  key={item?.value}
                  onChange={(newOperation) => { setOperationNot(newOperation); }}
                  error={errorOr}
                  onChangeError={(errorValue) => { setErrorNot(errorValue ? t('error', errorValue, language) : ''); }}
                  onRemove={() => removeWordNOT(item)}
                  operation={operationNot}
                />
              ))}
              <button className={cn(classes.addButton)} onClick={addNewWordNOT}>
                {t('bodyAddTagModal', 'ADD_WORD', language)}
              </button>
            </div>
          </ErrorBoundarySentry>
          {errorNot && <div className={cn(classes.infoText, classes.infoText_error)}>{errorNot}</div>}
        </div>
        {countriesData?.specific_countries ? (
          <div className={classes.countriesSwitcher}>
            <label className={cn(classes.switch)}>
              <input
                type="checkbox"
                onChange={() => {
                  sendAmplitudeEvent('Clicked_Switcher_Geo_Data');
                  setGeographyData(!geographyData);
                }}
                checked={!geographyData}
              />
              <span className={cn(classes.slider, classes.round)} />
            </label>
            <span>
              {t('bodyAddTagModal', 'DISPLAY_MENTIONS', language)}
            </span>
          </div>
        ) : (
          <div className={cn(classes.creatableWrapper, classes.creatableWrapper_label)}>
            <p className={classes.labelText}>
              {t('bodyAddTagModal', 'COUNTRIES', language)}
            </p>
            <ErrorBoundarySentry language={language}>
              <Select
                noOptionsMessage={() => t('nodata', 'NOT_FOUND', language)}
                isClearable
                isMulti
                loadingMessage={() => t('bodyAddTagModal', 'LOADING', language)}
                isLoading={countriesLoading}
                inputValue={inputCountry}
                value={countries?.length ? countries.filter(item => item?.value !== 'all') : [{ value: 'all', label: t('bodyAddTagModal', 'ALL_COUNTRIES', language) }]}
                options={countriesData?.data || [{ value: 'all', label: t('bodyAddTagModal', 'ALL_COUNTRIES', language) }]}
                onChange={(value) => {
                  value?.length > countries?.length && sendAmplitudeEvent('Selected_Country_From_Dropdown');
                  setCountries(() => value.filter(item => item?.value !== 'all'));
                }}
                onInputChange={(inputText, e) => { e.action !== 'input-blur' && e.action !== 'menu-close' && setInputCountry(inputText); }}
                placeholder={t('bodyAddTagModal', 'SELECT_COUNTRIES', language)}
                components={{ DropdownIndicator, IndicatorSeparator: null, MultiValueLabel }}
                styles={customSelectStyles}
                maxMenuHeight={130}
              />
            </ErrorBoundarySentry>
          </div>
        )}
        <div className={cn(classes.creatableWrapper, classes.creatableWrapper_options)}>
          <p className={classes.labelText}>
            {t('bodyAddTagModal', 'MEDIA_TYPE', language)}
          </p>
          <div className={cn(classes.filterWithOptions)}>
            <ul className={classes.listOptions}>
              <ErrorBoundarySentry language={language}>
                <li className={classes.option}>
                  <FilterOption
                    text={t('bodyAddTagModal', 'ALL_SOURCES', language)}
                    checked={!mediaType?.length}
                    onChange={() => {
                      sendAmplitudeEvent('Clicked_Source_Type_Button_All_Sources');
                      setMediaType([]);
                    }}
                    mode32={false}
                  />
                </li>
                {mediaTypesFunc(language).map((option) => {
                  const isSelect = mediaType?.some(selectDataItem => selectDataItem.id === option.id);
                  return (
                    <li key={option.id} className={classes.option}>
                      <FilterOption
                        text={option?.title}
                        icon={option.icon}
                        checked={isSelect}
                        onChange={() => onToggle(option, isSelect)}
                        mode32={false}
                      />
                    </li>
                  );
                })}
              </ErrorBoundarySentry>
            </ul>
          </div>
        </div>
      </form>
      <div className={cn(classes.previewWrapp, preview && classes.previewWrapp_opened)}>
        <Loader size={65} fetching={isPreviewLoading} color={colors.font35}>
          {preview && (
            <div className={classes.previewBody}>
              <div className={classes.previewHeader}>
                {t('bodyAddTagModal', 'RESULT_HEADER', language)}
                <InfoBtn
                  className={cn(classes.infoButton)}
                  text={t('bodyAddTagModal', 'RESULT_KEYWORD_HEADER_INFO', language)}
                  icon="error_outline"
                />
              </div>
              <ArticlesListPreview
                previewData={previewData}
                name={keywordDebounce}
                highlights={highlights || ''}
                language={language}
                className={classes.articlesKeywords}
                onChangePeriod={onChangePrevPeriod}
                optionsPeriod={optionsPeriod}
                period={period}
                type="basic"
                searchType="keyword"
                isEdit={!!data?.id}
              />
            </div>
          )}
        </Loader>
      </div>
      <div className={classes.buttonBox}>
        {data?.id && (
        <div
          onClick={() => {
            sendFeedEvent({ category: 'Delete keyword Basic QB', event: 'Clicked_Delete_Button_In_Basic_Query_Builder' });
            onConfirmDel({ keyword_id: data?.id, name: data?.name });
          }}
          className={classes.buttonDelete}
        >
          {t('btn', 'DELETE', language)}
        </div>
        )}
        {!preview && (
        <Button
          onClick={onPreview}
          textButton={t('btn', 'NEXT', language)}
          green32
        />
        )}
        {preview && (
          <Button
            type="submit"
            onClick={onSubmit}
            disabled={isPreviewLoading || checkIsEqualFields() || isLoading}
            textButton={t('btn', data?.id ? 'SAVE' : 'ADD', language)}
            green32
          />
        )}
      </div>
    </div>
  );
});

BodyAddEditKW.propTypes = {
  className: string,
  data: oneOfType([shape({ name: string, id: number })]),
  onCreateEdit: func,
  onManyMentions: func,
  onConfirmDel: func,
  onConfirmEdit: func,
  respError: string,
  language: string,
  isLoading: bool
};

export default BodyAddEditKW;
