import { useState } from 'react';
import t from 'utils/translateFunc';
import { FilterCustom, Icon, Switcher, Animated } from 'Components';
import { useGetLevelSources, useGetSources, useGetSubjectSources } from 'storage/queryHooks';
import useDebounceValue from 'hooks/useDebounce';
import { sendFeedEvent } from 'services/amplitude/events';
import { arrayOf, func, shape } from 'prop-types';
import colors from 'styles/_colors.scss';
import { PROJECT } from 'config';
import classes from './SourceFilter.module.scss';

const SourceFilter = ({ levels = [], subjects = [], sources = [], eventFunc = () => null, onFilterList = () => null,
  onChange = () => null, onOpenModal = () => null }) => {
  const [additional, setAddditional] = useState(false);
  const [filterSearch, setFilterSearch] = useState('');
  const itemTypeSource = t('filtrationPanel', 'SOURCE');
  const itemTypeLevel = t('filtrationPanel', 'LEVEL');
  const itemTypeSubject = t('filtrationPanel', 'SUBJECT');

  const icontains = useDebounceValue(filterSearch, 1000);
  const { isLoading: sourceLoading, isRefetching: sourceRefetching } = useGetSources({ query: { icontains, ordering: 'relevance' }, enabled: true });
  const { isLoading: levelLoading } = useGetLevelSources({ enabled: additional });
  const { isLoading: subjectLoading } = useGetSubjectSources({ enabled: additional });

  return (
    <div className={classes.sourceFilter}>
      <label className={classes.seletMenuTitle}>
        {itemTypeSource}
        <Switcher
          isTooltip
          className={classes.customSwitcher}
          onСhange={(value) => {
            eventFunc(value ? 'Open_Additional_Source_Filters' : 'Close_Additional_Source_Filters');
            setAddditional(value);
          }}
          value={additional}
          labelText={t('filtrationPanel', 'ADDITIONAL_FILTERS_SOURCE')}
          small
        />
      </label>
      <FilterCustom
        options={sources ? sources?.map(({ id, name, icon }) => ({ value: id, label: name, icon })) : null}
        filterName="source"
        filterType={itemTypeSource}
        labelPlaceholderPart={t('filtrationPanel', 'ALL_SOURCES')}
        placeholder={t('filtrationPanel', 'ENTER_NAME')}
        endingText={{ one: t('filtrationPanel', 'SOURCE').toLowerCase(), many: t('filtrationPanel', 'SOURCES').toLowerCase() }}
        onChange={onChange}
        onFilterList={onFilterList}
        isLoading={sourceLoading || sourceRefetching}
        inputValue={filterSearch}
        onInputChange={setFilterSearch}
      />
      <Animated visible={additional} duration={350} className={classes.additionalFiltersList}>
        <FilterCustom
          eventFunc={eventFunc}
          options={levels ? levels?.map(({ id, name }) => ({ value: id, label: name })) : null}
          filterName="level"
          filterType={itemTypeLevel}
          labelPlaceholderPart={t('filtrationPanel', 'ALL_LEVELS')}
          placeholder={t('filtrationPanel', 'ENTER_NAME')}
          endingText={{ one: t('filtrationPanel', 'LEVEL').toLowerCase(), many: t('filtrationPanel', 'LEVELS').toLowerCase() }}
          onChange={onChange}
          onFilterList={onFilterList}
          isLoading={levelLoading}
        />
        <FilterCustom
          eventFunc={eventFunc}
          options={subjects ? subjects?.map(({ id, title }) => ({ value: id, label: title })) : null}
          filterName="subject"
          filterType={itemTypeSubject}
          labelPlaceholderPart={t('filtrationPanel', 'ALL_SUBJECTS')}
          placeholder={t('filtrationPanel', 'ENTER_NAME')}
          endingText={{ one: t('filtrationPanel', 'SUBJECT').toLowerCase(), many: t('filtrationPanel', 'SUBJECTS').toLowerCase() }}
          onChange={onChange}
          onFilterList={onFilterList}
          isLoading={subjectLoading}
        />
      </Animated>
      <button
        type="button"
        onClick={() => {
          sendFeedEvent({ category: 'Filters', event: 'Clicked_Suggest_A_Source_Button' });
          onOpenModal({ action: 'proposeSource', info: null });
        }}
        className={classes.btnAdd}
      >
        <Icon
          type="add_round"
          btnType={classes.addButton}
          color={PROJECT === 'metricom' ? colors.green800 : colors.purple500}
          width={18}
          height={18}
        />
        <p>{t('sourceFilter', 'PROPOSE_SOURCE')}</p>
      </button>
    </div>
  );
};
SourceFilter.propTypes = {
  levels: arrayOf(shape({})),
  subjects: arrayOf(shape({})),
  sources: arrayOf(shape({})),
  eventFunc: func,
  onFilterList: func,
  onChange: func,
  onOpenModal: func
};
export default SourceFilter;
