import { string, arrayOf, shape, func } from 'prop-types';
import cn from 'classnames';
import { FilterOption } from 'Components';
import t from 'utils/translateFunc';
import { openModal } from 'storage/actions';
import { useDispatch } from 'react-redux';
import classes from './FilterContext.module.scss';

const FilterContext = ({ selectData = [], language = 'uk', eventFunc = () => null }) => {
  const dispatch = useDispatch();
  const onChange = (value) => { dispatch(openModal({ action: 'addPopular', ...value })); };
  const context = [{ id: 'words', title: t('filterContext', 'WORDS', language) }];

  return (
    <div className={cn(classes.filterWithOptions)}>
      <label className={classes.seletMenuTitle}>
        {t('filtrationPanel', 'CONTEXT', language)}
      </label>
      <ul className={classes.listOptions}>
        {context.map((option) => {
          const isSelect = selectData?.some(
            selectDataItem => selectDataItem.id === option.id
          );
          return (
            <li key={option.id} className={classes.option}>
              <FilterOption
                text={option?.title}
                icon={option.icon}
                checked={isSelect}
                onChange={() => {
                  eventFunc('Clicked_Popular_Words_Button');
                  onChange({ type: option?.id, title: option?.title });
                }}
                optionIconRight="Icn_arrow_forward_nofill"
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

FilterContext.propTypes = {
  selectData: arrayOf(
    shape({
      id: string,
      name: string
    })
  ),
  language: string,
  eventFunc: func
};

export default FilterContext;
