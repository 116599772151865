import { number, string } from 'prop-types';
import cn from 'classnames';
import colors from 'styles/_colors.scss';
import { PROJECT } from 'config';

export const Logo = ({ width = 96, height = 16, className = '' }) => (
  PROJECT === 'metricom' ? (
    <svg
      width={142}
      height={24}
      className={cn(className, 'icon icon-logo')}
      viewBox="0 0 142 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_23_30781)">
        <path d="M19.8 0H4.2C1.8804 0 0 1.8804 0 4.2V19.8C0 22.1196 1.8804 24 4.2 24H19.8C22.1196 24 24 22.1196 24 19.8V4.2C24 1.8804 22.1196 0 19.8 0Z" fill="url(#paint0_linear_23_30781)" />
        <path d="M3.35999 6.0258H6.20532L6.44265 7.5418H6.53732C7.02665 7.0538 7.54799 6.6298 8.10265 6.2738C8.65599 5.9178 9.31998 5.74047 10.0933 5.74047C10.9307 5.74047 11.6067 5.9098 12.12 6.2498C12.6333 6.5898 13.04 7.07647 13.34 7.70714C13.8613 7.1698 14.4107 6.70714 14.988 6.32047C15.564 5.93247 16.248 5.73914 17.0387 5.73914C18.3027 5.73914 19.232 6.1618 19.824 7.00714C20.416 7.8538 20.7133 9.01114 20.7133 10.4805V17.7818H17.2293V10.9298C17.2293 10.0765 17.1147 9.49247 16.8853 9.17514C16.656 8.86047 16.288 8.70047 15.7827 8.70047C15.1973 8.70047 14.5267 9.08047 13.768 9.83914V17.7805H10.2827V10.9285C10.2827 10.0751 10.168 9.49114 9.93865 9.1738C9.70932 8.85914 9.34265 8.69914 8.83732 8.69914C8.23599 8.69914 7.57332 9.07914 6.84665 9.8378V17.7791H3.35999V6.0258Z" fill="white" />
      </g>
      <path d="M32.0083 6.02419H34.645L34.8702 7.88483H34.9787C35.5544 7.27574 36.1802 6.75843 36.856 6.3329C37.5319 5.90737 38.2912 5.69043 39.1422 5.69043C40.1435 5.69043 40.9445 5.90737 41.5452 6.3329C42.146 6.75843 42.6049 7.35918 42.9219 8.13514C43.5727 7.43427 44.2486 6.85021 44.9494 6.38296C45.6503 5.92406 46.4346 5.69043 47.3107 5.69043C48.7542 5.69043 49.8138 6.15768 50.5063 7.09218C51.1905 8.02667 51.5326 9.36167 51.5326 11.1055V19.6578H48.312V11.5227C48.312 10.3963 48.1367 9.59529 47.7946 9.13639C47.4526 8.67748 46.9269 8.44386 46.226 8.44386C45.375 8.44386 44.4238 9.01123 43.3641 10.1376V19.6578H40.1685V11.5227C40.1685 10.3963 39.9933 9.59529 39.6512 9.13639C39.3091 8.67748 38.7751 8.44386 38.0575 8.44386C37.2065 8.44386 36.2553 9.01123 35.1956 10.1376V19.6578H32V6.03252L32.0083 6.02419Z" fill="white" />
      <path d="M54.1613 12.8578C54.1613 11.748 54.3365 10.7468 54.6869 9.86236C55.0373 8.97792 55.5046 8.227 56.0886 7.61791C56.6727 7.00882 57.3402 6.53322 58.0995 6.19947C58.8588 5.86572 59.6347 5.69885 60.4274 5.69885C61.3535 5.69885 62.1629 5.85738 62.8721 6.17444C63.573 6.4915 64.1654 6.93372 64.6326 7.50944C65.0999 8.08515 65.4586 8.76933 65.7006 9.56198C65.9426 10.3546 66.0594 11.2307 66.0594 12.1736C66.0594 12.7993 66.0093 13.3 65.9175 13.6754H57.2568C57.407 14.9186 57.8325 15.8698 58.55 16.5373C59.2593 17.2048 60.1771 17.5386 61.2868 17.5386C61.8792 17.5386 62.4299 17.4468 62.9388 17.2716C63.4478 17.0964 63.9484 16.8544 64.449 16.5373L65.5337 18.5315C64.8829 18.957 64.1654 19.3074 63.3727 19.5828C62.5801 19.8581 61.7457 20 60.8779 20C59.9351 20 59.059 19.8414 58.2413 19.516C57.4236 19.1906 56.7144 18.7234 56.1053 18.1143C55.4962 17.5052 55.0207 16.7626 54.6786 15.8782C54.3365 15.0021 54.1613 13.9925 54.1613 12.8661V12.8578ZM63.2893 11.6396C63.2893 10.5465 63.064 9.69549 62.6051 9.0864C62.1462 8.47731 61.4537 8.16858 60.5108 8.16858C59.6931 8.16858 58.9839 8.46062 58.3748 9.04468C57.7657 9.62874 57.3819 10.4965 57.2401 11.6396H63.2893Z" fill="white" />
      <path d="M68.7878 8.5523H66.8438V6.166L68.9547 6.02416L69.3469 2.30286H72.0085V6.02416H75.4795V8.5523H72.0085V15.0437C72.0085 16.6374 72.651 17.43 73.9276 17.43C74.1695 17.43 74.4115 17.405 74.6618 17.3466C74.9121 17.2882 75.1374 17.2214 75.3376 17.1213L75.8967 19.4826C75.5296 19.6161 75.104 19.7245 74.6368 19.833C74.1695 19.9415 73.6606 19.9832 73.1266 19.9832C72.3339 19.9832 71.6581 19.8664 71.1157 19.6244C70.5734 19.3824 70.1228 19.0487 69.7807 18.6148C69.4386 18.1809 69.1883 17.6636 69.0298 17.0629C68.8713 16.4621 68.7962 15.7946 68.7962 15.052V8.56064L68.7878 8.5523Z" fill="white" />
      <path d="M77.9742 6.02422H80.6108L80.8361 8.43556H80.9446C81.4285 7.54278 82.0042 6.86694 82.6968 6.39135C83.3809 5.91576 84.0818 5.68213 84.8077 5.68213C85.4585 5.68213 85.9758 5.77391 86.3597 5.95747L85.8006 8.73593C85.5587 8.66084 85.3417 8.61078 85.1331 8.56906C84.9245 8.52734 84.6826 8.51065 84.3822 8.51065C83.8482 8.51065 83.2808 8.71924 82.6884 9.13642C82.096 9.55361 81.587 10.2795 81.1615 11.3141V19.6412H77.9659V6.01587L77.9742 6.02422Z" fill="white" />
      <path d="M89.5713 3.63786C88.9956 3.63786 88.5284 3.47098 88.1529 3.13723C87.7858 2.80348 87.5939 2.36961 87.5939 1.83561C87.5939 1.30162 87.7774 0.859398 88.1529 0.517306C88.52 0.175214 88.9956 0 89.5713 0C90.147 0 90.6143 0.175214 90.9898 0.517306C91.3569 0.859398 91.5488 1.30162 91.5488 1.83561C91.5488 2.36961 91.3652 2.80348 90.9898 3.13723C90.6226 3.47098 90.147 3.63786 89.5713 3.63786ZM87.961 6.02416H91.1566V19.6494H87.961V6.02416Z" fill="white" />
      <path d="M93.9852 12.8578C93.9852 11.7314 94.1687 10.7218 94.5442 9.82899C94.9113 8.94455 95.4119 8.19362 96.0461 7.58453C96.6718 6.97544 97.3977 6.50819 98.2238 6.18279C99.0498 5.85738 99.9092 5.69885 100.819 5.69885C101.728 5.69885 102.479 5.84904 103.138 6.15776C103.797 6.46648 104.356 6.84194 104.815 7.28415L103.288 9.31168C102.921 8.99462 102.546 8.75265 102.179 8.57743C101.812 8.40221 101.411 8.31043 100.986 8.31043C99.8758 8.31043 98.9747 8.71928 98.2905 9.5453C97.6063 10.3713 97.2642 11.4727 97.2642 12.8578C97.2642 14.2428 97.598 15.3275 98.2738 16.1452C98.9497 16.9712 99.8258 17.38 100.894 17.38C101.453 17.38 101.962 17.2632 102.437 17.0296C102.913 16.796 103.33 16.529 103.697 16.2119L105.032 18.2394C104.381 18.8152 103.672 19.249 102.896 19.5411C102.12 19.8331 101.344 19.9833 100.568 19.9833C99.6422 19.9833 98.7745 19.8248 97.9735 19.5077C97.1725 19.1906 96.4716 18.7317 95.8792 18.1226C95.2868 17.5135 94.8279 16.7626 94.4941 15.8782C94.1604 14.9937 93.9935 13.9841 93.9935 12.8494L93.9852 12.8578Z" fill="white" />
      <path d="M105.784 12.8578C105.784 11.7314 105.959 10.7218 106.31 9.82899C106.66 8.94455 107.136 8.19362 107.736 7.58453C108.337 6.97544 109.03 6.50819 109.806 6.18279C110.582 5.85738 111.399 5.69885 112.25 5.69885C113.101 5.69885 113.919 5.85738 114.703 6.18279C115.488 6.50819 116.18 6.97544 116.773 7.58453C117.365 8.19362 117.841 8.94455 118.199 9.82899C118.558 10.7218 118.742 11.723 118.742 12.8578C118.742 13.9925 118.558 14.9937 118.199 15.8865C117.841 16.7709 117.365 17.5219 116.773 18.131C116.18 18.7401 115.488 19.2073 114.703 19.516C113.919 19.8248 113.101 19.9916 112.25 19.9916C111.399 19.9916 110.582 19.8331 109.806 19.516C109.03 19.199 108.337 18.7401 107.736 18.131C107.136 17.5219 106.66 16.7709 106.31 15.8865C105.959 15.0021 105.784 13.9925 105.784 12.8578ZM109.063 12.8578C109.063 14.2261 109.347 15.3275 109.906 16.1452C110.473 16.9712 111.249 17.38 112.25 17.38C113.251 17.38 114.036 16.9712 114.612 16.1452C115.187 15.3191 115.471 14.2261 115.471 12.8578C115.471 11.4894 115.187 10.363 114.612 9.5453C114.036 8.71928 113.251 8.31043 112.25 8.31043C111.249 8.31043 110.473 8.71928 109.906 9.5453C109.338 10.3713 109.063 11.4727 109.063 12.8578Z" fill="white" />
      <path d="M121.544 6.02419H124.181L124.406 7.88483H124.515C125.091 7.27574 125.716 6.75843 126.392 6.3329C127.068 5.90737 127.827 5.69043 128.678 5.69043C129.68 5.69043 130.481 5.90737 131.081 6.3329C131.682 6.75843 132.141 7.35918 132.458 8.13514C133.109 7.43427 133.785 6.85021 134.486 6.38296C135.186 5.92406 135.971 5.69043 136.847 5.69043C138.29 5.69043 139.35 6.15768 140.042 7.09218C140.727 8.02667 141.069 9.36167 141.069 11.1055V19.6578H137.848V11.5227C137.848 10.3963 137.673 9.59529 137.331 9.13639C136.989 8.67748 136.463 8.44386 135.762 8.44386C134.911 8.44386 133.96 9.01123 132.9 10.1376V19.6578H129.705V11.5227C129.705 10.3963 129.529 9.59529 129.187 9.13639C128.845 8.67748 128.311 8.44386 127.594 8.44386C126.743 8.44386 125.791 9.01123 124.732 10.1376V19.6578H121.536V6.03252L121.544 6.02419Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_23_30781" x1="12" y1="24" x2="12" y2="0" gradientUnits="userSpaceOnUse">
          <stop stopColor="#3C5EAB" />
          <stop offset="0.05" stopColor="#3B6FB2" />
          <stop offset="0.17" stopColor="#3B93C2" />
          <stop offset="0.28" stopColor="#3BACCD" />
          <stop offset="0.38" stopColor="#3BBCD4" />
          <stop offset="0.46" stopColor="#3BC2D7" />
          <stop offset="0.58" stopColor="#38BFD1" />
          <stop offset="0.73" stopColor="#31B7C0" />
          <stop offset="0.91" stopColor="#26ABA5" />
          <stop offset="1" stopColor="#1FA393" />
        </linearGradient>
        <clipPath id="clip0_23_30781">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width={width || 96}
      height={height || 16}
      className={cn(className, 'icon icon-logo')}
      viewBox="0 0 84 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M9.0127 7.007C9.0127 2.76063 12.2958 0 16.2349 0C20.1546 0 23.4377 2.81838 23.4377 7.007C23.4377 11.2543 20.1546 13.9956 16.2349 13.9956C12.2958 13.9956 9.0127 11.2735 9.0127 7.007ZM12.5937 7.007C12.5937 9.632 14.4444 10.8876 16.2349 10.8876C18.0263 10.8876 19.8567 9.9225 19.8567 7.007C19.8567 4.20788 18.006 3.108 16.2155 3.108C14.325 3.108 12.5946 4.40125 12.5946 7.007H12.5937Z" fill={colors.purpleA10} />
      <path d="M0 0.231934H3.5616V10.6558H8.15773V13.7446H0V0.231934Z" fill={colors.purpleA10} />
      <path fillRule="evenodd" clipRule="evenodd" d="M41.2041 7.007C41.2041 2.76063 44.4872 0 48.4263 0C52.346 0 55.6291 2.81838 55.6291 7.007C55.6291 8.64675 55.1384 10.0608 54.3116 11.1808L55.1455 11.9893L53.072 14L52.1241 13.0795C51.0365 13.6736 49.7686 13.9956 48.4263 13.9956C44.4872 13.9956 41.2041 11.2735 41.2041 7.007ZM49.4971 6.50825L51.7784 8.722C51.9499 8.24513 52.0481 7.679 52.0481 7.007C52.0481 4.20788 50.1974 3.108 48.4069 3.108C46.5164 3.108 44.7852 4.40125 44.7852 7.007C44.7852 9.632 46.6358 10.8876 48.4263 10.8876C48.8508 10.8876 49.2769 10.8325 49.6819 10.7109L47.4245 8.52075L49.4971 6.50825Z" fill={colors.pink200} />
      <path fillRule="evenodd" clipRule="evenodd" d="M32.3311 0C28.3911 0 25.1089 2.76063 25.1089 7.007C25.1089 11.2735 28.3911 13.9956 32.3311 13.9956C36.2508 13.9956 39.533 11.2543 39.533 7.007C39.533 2.81838 36.2508 0 32.3311 0ZM32.3311 10.8876C30.5406 10.8876 28.6899 9.63288 28.6899 7.007C28.6899 4.40125 30.4212 3.108 32.3108 3.108C34.1013 3.108 35.952 4.20788 35.952 7.007C35.952 9.9225 34.1216 10.8876 32.3311 10.8876Z" fill={colors.purpleA10} />
      <path d="M65.4369 7.74118L62.4925 0.154928H57.7178V13.7437H61.1989V5.36643L62.2334 7.9923L63.9842 11.7758H66.8897L68.6404 7.9923L69.675 5.36555V13.7454H73.157V0.154053H68.4415L65.4369 7.7403V7.74118ZM84.0001 0.231928V3.1273H78.8663V5.25005H83.0796V8.1463H78.8663V10.8098H84.0001V13.7446H75.4444V0.231928H84.0001Z" fill={colors.purpleA10} />
    </svg>
  )
);

Logo.propTypes = {
  width: number,
  height: number,
  className: string
};
