import cn from 'classnames';
import { bool, func, string } from 'prop-types';
import { Tooltip } from 'Components';
import classes from './Switcher.module.scss';

export function Switcher({ labelText = '', className = '', onСhange = () => null, value = false, isTooltip = false, small = false }) {
  return (
    <Tooltip className={cn(classes.switcherWrap, small && classes.switcherWrap_small, className)} text={isTooltip ? labelText : ''}>
      {!isTooltip && (
        <span className={classes.label}>
          {labelText}
        </span>
      )}
      <label className={cn(classes.switch)}>
        <input
          type="checkbox"
          onChange={() => { onСhange(!value); }}
          checked={value}
        />
        <span className={cn(classes.slider, classes.round)} />
      </label>
    </Tooltip>
  );
}

Switcher.propTypes = { labelText: string, className: string, onСhange: func, value: bool, isTooltip: bool, small: bool };
