import { useDispatch, useSelector } from 'react-redux';
import { arrayOf, number, bool, shape, oneOfType, string, object } from 'prop-types';
import t from 'utils/translateFunc';
import cn from 'classnames';
import { Tooltip } from 'Components';
import { sendFeedEvent } from 'services/amplitude/events';
import { addFilterItem, removeFilterItemByName } from 'storage/actions';
import classes from './Progressbar.module.scss';

const Progressbar = ({ progressbarData = [], isPopover = false, className = '', width = 0, amplitudeCategory = '', amplitudeEvent = '', keyword = null }) => {
  const dispatch = useDispatch();
  const language = useSelector(state => state?.userInfo?.language);
  const { filterList } = useSelector(state => state?.filters);
  const total = progressbarData?.reduce((totals, item) => totals + item.value, 0);

  const percentFunc = (value) => {
    if (total === 0) {
      return 100 / progressbarData.length;
    }
    return (value / total) * 100;
  };

  function handleClick(tone = 'neutral') {
    if (amplitudeCategory && amplitudeEvent) sendFeedEvent({ category: amplitudeCategory, event: amplitudeEvent });
    if (tone && keyword) {
      const toneFilters = filterList.filter(item => item?.filterName === 'tone');
      const kwFilters = filterList.filter(item => item?.filterName === 'keyword');
      if (toneFilters?.length) dispatch(removeFilterItemByName('tone'));
      if (kwFilters?.length) dispatch(removeFilterItemByName('keyword'));
      dispatch(addFilterItem({
        id: tone,
        name: t('tonePopover', tone.toUpperCase(), language),
        type: t('filtrationPanel', 'TONE', language),
        filterName: 'tone'
      }));
      dispatch(addFilterItem({
        id: keyword.id,
        name: keyword.name,
        type: t('filtrationPanel', 'KEYWORD', language),
        filterName: 'keyword'
      }));
    }
  }

  const showPercent = (item) => {
    if (width) {
      const lineWidth = (width * 1.7) * (item.value / total);
      return lineWidth < 15;
    }
    return false;
  };
  return (
    <div className={cn(classes.progressbar, classes.progressbarBtn, className)}>
      {!!progressbarData?.length && progressbarData?.map((item, i) => (
        <a
          key={`${item.color}-${i}`}
          style={{
            backgroundColor: item.color,
            width: `${percentFunc(item.value)}%`
          }}
          onClick={() => handleClick(item.text)}
          className={classes.link}
          rel="noopener noreferrer"
        >
          {!!item.value && (
          <Tooltip
            text={(
              <div className={classes.tooltipContent}>
                {!isPopover && <div className={cn(classes.tooltipContent_color)} style={{ background: item?.color }} />}
                <div
                  className={cn(classes.tooltipContent_text, isPopover && classes.tooltipContent_text_popover)}
                  style={{ ...(isPopover ? { color: item?.color } : null) }}
                >
                  {item.value}
                </div>
                <div
                  className={cn(classes.tooltipContent_percent, isPopover && classes.tooltipContent_percent_popover)}
                  style={{ ...(isPopover ? { color: item?.color } : null) }}
                >
                  {isPopover ? `${Math.round(percentFunc(item.value))}%` : `(${Math.round(percentFunc(item.value))}%)`}
                </div>
              </div>
              )}
            {...(isPopover && { background: 'transparent' })}
            className={cn(
              classes.tooltip,
              isPopover && classes.tooltip_card,
              item?.value > 0 && showPercent(item) && classes.show
            )}
          />
          )}
        </a>
      ))}
    </div>
  );
};

Progressbar.propTypes = {
  progressbarData: arrayOf(
    shape({
      color: string,
      value: number,
      text: oneOfType([string, number]),
      link: string
    })
  ),
  width: number,
  className: string,
  isPopover: bool,
  amplitudeCategory: string,
  amplitudeEvent: string,
  keyword: object
};

export default Progressbar;
