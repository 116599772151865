import { useState } from 'react';
import { FilterCustom } from 'Components';
import { useGetLanguages } from 'storage/queryHooks';
import { useSelector } from 'react-redux';
import t from 'utils/translateFunc';
import useDebounceValue from 'hooks/useDebounce';
import { arrayOf, func, shape } from 'prop-types';
import classes from './FilterLang.module.scss';

const FilterLang = ({ languages = [], onFilterList = () => null,
  onChange = () => null }) => {
  const [filterLang, setFilterLang] = useState('');
  const { language } = useSelector(state => state?.userInfo);
  const filterType = t('filtrationPanel', 'LANGUAGE', language);
  const icontains = useDebounceValue(filterLang, 1000);
  const { isLoading: langLoading } = useGetLanguages({ enabled: true, query: { icontains } });

  return (
    <div className={classes.autorLngFilter}>
      <FilterCustom
        options={languages ? languages?.map(({ id, name, code }) => ({ value: id, label: name, code })) : null}
        filterName="language"
        filterType={filterType}
        labelPlaceholderPart={t('filtrationPanel', 'ALL_LANGUAGES')}
        placeholder={t('filtrationPanel', 'ENTER_NAME')}
        endingText={{ one: t('filtrationPanel', 'LANGUAGE').toLowerCase(), many: t('filtrationPanel', 'LANGUAGES').toLowerCase() }}
        onChange={onChange}
        onFilterList={onFilterList}
        isLoading={langLoading}
        inputValue={filterLang}
        onInputChange={setFilterLang}
      />
    </div>
  );
};
FilterLang.propTypes = {
  languages: arrayOf(shape({})),
  onFilterList: func,
  onChange: func
};
export default FilterLang;
