import cn from 'classnames';
import { Icons, Icon, TagsPopover, TagsList, CreatorPlacePopover, SourcePopover, IconsListMedia, ErrorBoundarySentry, Tooltip, BodyHtml } from 'Components';
import moment from 'moment';
import t from 'utils/translateFunc';
import { string, func, shape, number, oneOfType } from 'prop-types';
import { useRef, useEffect, useState } from 'react';
import useResizeObserver from 'hooks/useResizeObserver';
import colors from 'styles/_colors.scss';
import { PROJECT } from 'config';
import classes from './ArticlesList.module.scss';

function ArticleCard({ article, isSelect = () => null, onChange = () => null, onSubmit = () => null,
  onRemoveTag = () => null, onVisible = () => null, language = 'uk', keyword = null, detailsId = '', regex = '' }) {
  const containerRef = useRef(null);
  const { width, height, targetRef } = useResizeObserver();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    onChangeOverflowBtn();
  }, [article?.tags?.length, width, height]);

  function onChangeOverflowBtn() {
    const container = containerRef.current;
    if (container) {
      // setIsOverflowing(container.scrollHeight > container.clientHeight);
      setIsOverflowing(container.clientHeight > 22);
    }
  }

  const creatorsPlaces = [
    { subtype: 'creator', tooltip: 'CREATOR', tooltipBtn: 'CREATOR_AUDIENCE' },
    { subtype: 'publication_place', tooltip: 'PLACE_MENTION', tooltipBtn: 'AUDIENCE_PLACE' }
  ];

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div
      className={cn(
        classes.article,
        article.tone === 'positive' && classes.article_positive,
        article.tone === 'negative' && classes.article_negative,
        article.tone === 'neutral' && classes.article_neutral,
        !article?.viewed && classes.article_viewed
      )}
      key={article.id}
      ref={targetRef}
    >
      <div
        className={cn(
          classes.contentWrapper,
          article?.id === detailsId && classes.contentWrapper_arrow,
          article?.id === detailsId && !article?.viewed && classes.contentWrapper_arrow_viewed
        )}
        onClick={(e) => {
          const isShow = /contentWrapper|titleWrapper|textContentWrapper|footerWrapper|date/.test(e.target.className);
          isShow && onVisible({ keyword_id: keyword?.id, article_id: article.id, viewed: article?.viewed });
        }}
      >
        {!article?.viewed && (
        <Tooltip text={t('btn', 'UNREAD_MENTION', language)} className={cn(classes.tooltip)}>
          <p className={cn(classes.viewed)} />
        </Tooltip>
        )}
        {!!article?.bookmarked && <Icon type="original_bookmark" className={classes.iconOriginal} />}
        <div
          className={cn(classes.headerWrapper)}
        >
          <div className={cn(classes.titleWrapper)}>
            <label htmlFor={article.id} className={classes.checkboxLabel}>
              <input
                type="checkbox"
                className={classes.visually_hidden}
                onChange={e => onChange(e, { keyword_id: keyword?.id, keyword_name: keyword?.name, article, action: 'toggle' })}
                value={article.id}
                id={article.id}
              />
              <div
                className={cn(
                  classes.checkbox,
                  isSelect({ id: article.id }) && classes.checkbox_selected
                )}
              >
                {isSelect({ id: article.id }) && (
                <Icons icon="check" className={classes.iconCheck} />
                )}
              </div>
            </label>
            <span className={classes.textContentWrapper}>
              <BodyHtml
                className={classes.textContentWrapper_body}
                html={regex ? article.title.replaceAll(regex, match => (`<b class=${classes.titleHighLight}>${match}</b>`)) : article.title}
              />
            </span>
          </div>
          <div className={cn(classes.date)}>{moment(article?.date).format('DD MMM YYYY HH:mm')}</div>
        </div>
        <div className={cn(classes.tagsWrapper, isExpanded && classes.tagsWrapper_expanded)}>
          <div className={cn(classes.tagsList)} ref={containerRef}>
            <TagsList
              onRemove={onRemoveTag}
              tags={article?.tags}
              article_id={article?.id}
            />
          </div>
          <div className={classes.tagsButtonBox}>
            {!!isOverflowing && (
            <div
              className={cn(classes.showMoreBtn, !isOverflowing && classes.showMoreBtn_hide)}
              onClick={toggleExpand}
            >
              <Icon
                type="arrow_left"
                role="button"
                tooltipText={t('btn', isExpanded ? 'COLLAPSE' : 'SHOW_ALL_TAGS', language)}
                width={16}
                color={PROJECT === 'metricom' ? colors.green800 : colors.purple500}
                className={cn(classes.arrow, !isExpanded && classes.arrow_down)}
                btnType={classes.arrowBtn}
              />
            </div>
            )}
            <TagsPopover
              chekedArticlesArr={[article?.id]}
              checkedTags={article?.tags?.length ? article?.tags?.map(el => ({ ...el, filterName: 'tagPopoup', type: t('filtrationPanel', 'TAG', language) })) : []}
              language={language}
              idBind={`article-footer-${article?.id}`}
              assignId="assign_button"
              eventPlace="Feed_Article_Card"
              placement={article?.tags?.length > 5 ? 'bottomRight' : 'bottomLeft'}
              overflow={{ adjustX: false, adjustY: false }}
              offset={article?.tags?.length > 5 ? [37, 0] : [-30, 0]}
              isButton
            />
          </div>
        </div>
        <div className={cn(classes.footerWrapper)} id={`article-footer-${article?.id}`}>
          <div className={cn(classes.footerWrapper_container)}>
            <ErrorBoundarySentry language={language}>
              <SourcePopover onSelect={onSubmit} info={article} language={language} />
            </ErrorBoundarySentry>
            <div className={classes.creatorsPlaces}>
              {!!creatorsPlaces?.length && creatorsPlaces.map((card, i) => (
                <CreatorPlacePopover
                  key={i}
                  nextArrow={i === 1}
                  info={article}
                  idBind={`article-footer-${article?.id}`}
                  subtype={card.subtype}
                  language={language}
                  eventPlace="Feed_Article_Card"
                  tooltip={t('creatorPlaceCard', card.tooltip, language)}
                  tooltipBtn={t('creatorPlaceCard', card.tooltipBtn, language)}
                  onSubmit={onSubmit}
                />
              ))}
            </div>
          </div>
          <div className={cn(classes.footerWrapper_container, classes.footerWrapper_icons)}>
            <IconsListMedia info={article} language={language} onSubmit={onSubmit} idBind={`article-footer-${article?.id}`} />
          </div>
        </div>
      </div>
    </div>
  );
}

ArticleCard.propTypes = {
  article: shape({}),
  isSelect: func,
  onChange: func,
  onSubmit: func,
  onRemoveTag: func,
  onVisible: func,
  language: string,
  keyword: shape({}),
  detailsId: oneOfType([string, number]),
  regex: oneOfType([shape(RegExp), string])
};

export default ArticleCard;
