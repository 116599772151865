import { PROJECT } from 'config';
import colors from 'styles/_colors.scss';

export function MobileLogo() {
  return (
    PROJECT === 'metricom' ? (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_23_30767)">
          <path d="M19.8 0H4.2C1.8804 0 0 1.8804 0 4.2V19.8C0 22.1196 1.8804 24 4.2 24H19.8C22.1196 24 24 22.1196 24 19.8V4.2C24 1.8804 22.1196 0 19.8 0Z" fill="url(#paint0_linear_23_30767)" />
          <path d="M3.35999 6.0258H6.20532L6.44265 7.5418H6.53732C7.02665 7.0538 7.54799 6.6298 8.10265 6.2738C8.65599 5.9178 9.31998 5.74047 10.0933 5.74047C10.9307 5.74047 11.6067 5.9098 12.12 6.2498C12.6333 6.5898 13.04 7.07647 13.34 7.70714C13.8613 7.1698 14.4107 6.70714 14.988 6.32047C15.564 5.93247 16.248 5.73914 17.0387 5.73914C18.3027 5.73914 19.232 6.1618 19.824 7.00714C20.416 7.8538 20.7133 9.01114 20.7133 10.4805V17.7818H17.2293V10.9298C17.2293 10.0765 17.1147 9.49247 16.8853 9.17514C16.656 8.86047 16.288 8.70047 15.7827 8.70047C15.1973 8.70047 14.5267 9.08047 13.768 9.83914V17.7805H10.2827V10.9285C10.2827 10.0751 10.168 9.49114 9.93865 9.1738C9.70932 8.85914 9.34265 8.69914 8.83732 8.69914C8.23599 8.69914 7.57332 9.07914 6.84665 9.8378V17.7791H3.35999V6.0258Z" fill="white" />
        </g>
        <defs>
          <linearGradient id="paint0_linear_23_30767" x1="12" y1="24" x2="12" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#3C5EAB" />
            <stop offset="0.05" stopColor="#3B6FB2" />
            <stop offset="0.17" stopColor="#3B93C2" />
            <stop offset="0.28" stopColor="#3BACCD" />
            <stop offset="0.38" stopColor="#3BBCD4" />
            <stop offset="0.46" stopColor="#3BC2D7" />
            <stop offset="0.58" stopColor="#38BFD1" />
            <stop offset="0.73" stopColor="#31B7C0" />
            <stop offset="0.91" stopColor="#26ABA5" />
            <stop offset="1" stopColor="#1FA393" />
          </linearGradient>
          <clipPath id="clip0_23_30767">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ) : (
      <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 15.015C0 5.91563 7.01242 0 15.4262 0C23.7984 0 30.8108 6.03938 30.8108 15.015C30.8108 18.5288 29.7626 21.5588 27.9968 23.9588L29.7777 25.6913L25.3489 30L23.3244 28.0275C21.0014 29.3006 18.2931 29.9906 15.4262 29.9906C7.01242 29.9906 0 24.1575 0 15.015ZM17.7133 13.9462L22.5859 18.69C22.9523 17.6681 23.1619 16.455 23.1619 15.015C23.1619 9.01688 19.2091 6.66 15.3846 6.66C11.3468 6.66 7.64888 9.43125 7.64888 15.015C7.64888 20.64 11.6017 23.3306 15.4262 23.3306C16.3327 23.3306 17.243 23.2125 18.108 22.9519L13.2864 18.2588L17.7133 13.9462Z" fill={colors.pink200} />
      </svg>
    )
  );
}
