import { createSlice } from '@reduxjs/toolkit';
import { randomHexColor } from 'utils/common';
import hasOwn from 'lodash/has';

const initialState = {
  keywords: [],
  media_types: [],
  tones: [],
  tags: [],
  post_types: [],
  countries: [],
  regions: [],
  cities: [],
  sources: [],
  levels: [],
  subjects: [],
  publication_places: [],
  creators: [],
  creators_gender: [],
  languages: [],
  characters: [],
  search: '',
  contexts: [],
  words: [],
  links: [],
  hashtags: [],
  duplicates: false,
  settings: null,
  filterList: [],
  filtersQuery: { duplicates: 'false', ordering: '-date' },
  ordering: '-date',
  articles_per_page: 10,
  tabItem: null
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    changeFilter(state, { payload }) {
      const name = payload?.filterName || '';
      const nameExclude = `${name}_exclude`;
      const values = payload?.filterValues || [];
      const includes = values?.filter(item => !item?.isExclude)?.map(({ id }) => id) || null;
      const excludes = values?.filter(item => !!item?.isExclude)?.map(({ id }) => id) || null;
      includes?.length && (state.filtersQuery[name] = includes);
      excludes?.length && (state.filtersQuery[nameExclude] = excludes);
      !includes?.length && hasOwn(state.filtersQuery, name) && delete state.filtersQuery[name];
      !excludes?.length && hasOwn(state.filtersQuery, nameExclude) && delete state.filtersQuery[nameExclude];

      if (state?.filterList?.length) {
        const clearRemoved = state?.filterList?.filter(item => ((item?.filterName === name && values?.find(v => v.id === item.id))) || item?.filterName !== name);
        const replacedFilterValues = clearRemoved?.map((item) => {
          const newValue = values?.find(el => el?.id === item?.id);
          return newValue && newValue?.isExclude !== item?.isExclude ? newValue : item;
        });
        const filterIncludedFilters = values.filter(item => !state?.filterList?.find(v => v.id === item.id));
        state.filterList = replacedFilterValues.concat(filterIncludedFilters);
      }
      !state?.filterList?.length && (state.filterList = values);
    },
    addKeywords(state, { payload }) {
      const uniqKeywordList = payload?.length && payload.reduce((acc, curr) => (acc?.some(art => art?.id === curr?.id) ? acc : [...acc, curr]), []);
      state.keywords = uniqKeywordList || [];
    },
    addKeyword(state, { payload }) {
      state.keywords = state.keywords.concat([payload]);
    },
    editKeyword(state, { payload }) {
      state.keywords = state.keywords.map(item => (item?.id === payload?.id ? payload : item));
    },
    removeKeywordFromList(state, { payload }) {
      state.keywords = state.keywords.filter(el => el?.id !== payload?.keyword_id);
    },
    addMediaTypes(state, { payload }) {
      state.media_types = payload || [];
    },
    addTones(state, { payload }) {
      state.tones = payload || [];
    },
    addTags(state, { payload }) {
      state.tags = payload || [];
    },
    removeTagFromList(state, { payload }) {
      state.tags = state.tags.filter(el => el?.id !== payload?.tag_id);
    },
    addPostTypes(state, { payload }) {
      state.post_types = payload || [];
    },
    addCountries(state, { payload }) {
      state.countries = payload || [];
    },
    addRegions(state, { payload }) {
      state.regions = payload || [];
    },
    addCities(state, { payload }) {
      state.cities = payload || [];
    },
    addSources(state, { payload }) {
      state.sources = payload?.results || [];
    },
    addLevels(state, { payload }) {
      state.levels = payload || [];
    },
    addSubjects(state, { payload }) {
      state.subjects = payload || [];
    },
    addPublicationPlaces(state, { payload }) {
      state.publication_places = payload?.results || [];
    },
    addCreators(state, { payload }) {
      state.creators = payload?.results || [];
    },
    addLanguages(state, { payload }) {
      state.languages = payload || [];
    },
    addCharacters(state, { payload }) {
      state.characters = payload || [];
    },
    addSearch(state, { payload }) {
      state.search = payload || [];
    },
    addContexts(state, { payload }) {
      state.contexts = payload || [];
    },
    addWords(state, { payload }) {
      if (!state.words?.length && !!payload?.length) {
        state.words = payload.map(el => ({ value: el.count, text: el?.value, color: randomHexColor() }));
      }
      if (!!state.words?.length && !!payload?.length) {
        state.words = payload.map(el => state.words.find(item => item.text === el.value) || ({ value: el.count, text: el?.value, color: randomHexColor() }));
      }
    },
    addLinks(state, { payload }) {
      if (!state.links?.length && !!payload?.length) {
        state.links = payload.map(el => ({ value: el.count, text: el?.value, color: randomHexColor() }));
      }
      if (!!state.links?.length && !!payload?.length) {
        state.words = payload.map(el => state.links.find(item => item.text === el.value) || ({ value: el.count, text: el?.value, color: randomHexColor() }));
      }
    },
    addHashtags(state, { payload }) {
      if (!state.hashtags?.length && !!payload?.length) {
        state.hashtags = payload.map(el => ({ value: el.count, text: el?.value, color: randomHexColor() }));
      }
      if (!!state.hashtags?.length && !!payload?.length) {
        state.words = payload.map(el => state.hashtags.find(item => item.text === el.value) || ({ value: el.count, text: el?.value, color: randomHexColor() }));
      }
    },
    addSettings(state, { payload }) {
      state.settings = payload || null;
      // if (payload && hasOwn(payload, 'doubles')) {
      //   state.duplicates = payload.doubles;
      //   state.filtersQuery.duplicates = String(payload.doubles);
      // }
      if (payload && hasOwn(payload, 'sort_by')) {
        state.ordering = payload.sort_by;
        state.filtersQuery.ordering = payload.sort_by;
      }
      if (payload && hasOwn(payload, 'articles_per_page')) {
        state.articles_per_page = payload.articles_per_page;
      }
    },
    addFilterItem(state, { payload }) {
      const key = payload?.filterName;
      const id = payload?.id;
      const idString = [
        'icontains', 'creator_age_from', 'creator_age_to', 'audience_from', 'audience_to', 'publication_place_rate_range'
      ].includes(id) || key === 'icontains';
      if (idString) {
        state.filtersQuery[key] = payload?.name;
      } else {
        const filterDuplicatesIds = state.filtersQuery[key]?.filter(keyIds => keyIds !== String(id));
        state.filtersQuery[key] ? (state.filtersQuery[key] = filterDuplicatesIds?.concat(String(id))) : (state.filtersQuery[key] = [String(id)]);
      }
      const filterDuplicatesItems = state?.filterList?.filter(listItem => listItem?.id !== id);
      state.filterList = filterDuplicatesItems?.concat({ ...payload, filterName: String(key).replace(/_exclude/g, '') });
    },
    removeFilterItem(state, { payload }) {
      const name = payload?.filterName;
      const nameExclude = `${payload?.filterName}_exclude`;
      const id = payload?.id;
      const idString = [
        'icontains', 'creator_age_from', 'creator_age_to', 'audience_from', 'audience_to', 'publication_place_rate_range'
      ].includes(id);
      if ((state.filtersQuery[name] || state.filtersQuery[nameExclude]) && !idString) {
        const removeValue = state.filtersQuery[name]?.filter(el => String(el) !== String(id));
        const removeExcludeValue = state.filtersQuery[nameExclude]?.filter(el => String(el) !== String(id));
        removeValue?.length ? (state.filtersQuery[name] = removeValue) : delete state.filtersQuery[name];
        removeExcludeValue?.length ? (state.filtersQuery[nameExclude] = removeExcludeValue) : delete state.filtersQuery[nameExclude];
      } else {
        state.filtersQuery[name] && delete state.filtersQuery[name];
        state.filtersQuery[nameExclude] && delete state.filtersQuery[nameExclude];
      }
      state.filterList = state?.filterList?.filter(listItem => listItem.id !== id);
    },
    removeFilterItemByName(state, { payload }) {
      state.filterList = state?.filterList?.filter(listItem => listItem.filterName !== payload);
      delete state.filtersQuery[payload];
    },
    clearFilterList(state) {
      state.filterList = [];
      state.filtersQuery = { ordering: '-date', duplicates: 'false' };
      const tab = state.tabItem?.action;
      tab && (state.filtersQuery[tab] = 'true');
      state.duplicates = false;
      state.ordering = '-date';
    },
    changeOrdering(state, { payload }) {
      state.ordering = payload;
      state.filtersQuery.ordering = payload;
    },
    toggleDuplicates(state, { payload }) {
      state.duplicates = payload;
      state.filtersQuery.duplicates = String(payload);
    },
    onChangeTab(state, { payload }) {
      !!state.filtersQuery?.bookmarked && delete state.filtersQuery.bookmarked;
      !!state.filtersQuery?.processed && delete state.filtersQuery.processed;
      !!state.filtersQuery?.deleted && delete state.filtersQuery.deleted;
      if (payload?.action) {
        payload?.action === 'unprocessed' ? (state.filtersQuery.processed = 'false') : (state.filtersQuery[payload?.action] = 'true');
      }
      state.tabItem = payload;
    }
  }
});

export const { addKeywords, addKeyword, editKeyword, addMediaTypes, addTones, addTags, removeTagFromList, addPostTypes, addCountries, addRegions, addCities, addSources,
  addLevels, addSubjects, addCreators, addLanguages, addCharacters, addSearch, addContexts, addWords, toggleDuplicates, addPublicationPlaces,
  addLinks, addHashtags, addSettings, addFilterItem, removeFilterItem, removeFilterItemByName, clearFilterList, changeOrdering, onChangeTab,
  removeKeywordFromList, changeFilter } = filtersSlice.actions;
export default filtersSlice.reducer;
