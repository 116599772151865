import colors from 'styles/_colors.scss';
import { string } from 'prop-types';

export function FullScreen({ color = colors.font70 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <mask id="mask0_1484_25357" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill={colors.iconMainColor} />
      </mask>
      <g mask="url(#mask0_1484_25357)">
        <path d="M2.66667 14C2.47778 14 2.31944 13.9361 2.19167 13.8083C2.06389 13.6806 2 13.5222 2 13.3333V9.33333C2 9.14444 2.06389 8.98611 2.19167 8.85833C2.31944 8.73056 2.47778 8.66667 2.66667 8.66667C2.85556 8.66667 3.01389 8.73056 3.14167 8.85833C3.26944 8.98611 3.33333 9.14444 3.33333 9.33333V11.7333L11.7333 3.33333H9.33333C9.14444 3.33333 8.98611 3.26944 8.85833 3.14167C8.73056 3.01389 8.66667 2.85556 8.66667 2.66667C8.66667 2.47778 8.73056 2.31944 8.85833 2.19167C8.98611 2.06389 9.14444 2 9.33333 2H13.3333C13.5222 2 13.6806 2.06389 13.8083 2.19167C13.9361 2.31944 14 2.47778 14 2.66667V6.66667C14 6.85556 13.9361 7.01389 13.8083 7.14167C13.6806 7.26944 13.5222 7.33333 13.3333 7.33333C13.1444 7.33333 12.9861 7.26944 12.8583 7.14167C12.7306 7.01389 12.6667 6.85556 12.6667 6.66667V4.26667L4.26667 12.6667H6.66667C6.85556 12.6667 7.01389 12.7306 7.14167 12.8583C7.26944 12.9861 7.33333 13.1444 7.33333 13.3333C7.33333 13.5222 7.26944 13.6806 7.14167 13.8083C7.01389 13.9361 6.85556 14 6.66667 14H2.66667Z" fill={color || colors.font70} />
      </g>
    </svg>
  );
}
FullScreen.propTypes = {
  color: string
};
