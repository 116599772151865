import { useMemo } from 'react';
import { bool, shape, string } from 'prop-types';
import { components } from 'react-select';
import cn from 'classnames';
import { Icon, Tooltip } from 'Components';
import colors from 'styles/_colors.scss';
import { sendSearchEvent } from 'services/amplitude/events';
import { useSelector } from 'react-redux';
import { clearEmtyValues } from 'utils/common';
import classes from './OptionPart.module.scss';

const { Option } = components;
const OptionPart = (props) => {
  const { data = { label: '' }, isSelected = false, selectProps = {}, innerProps = {} } = props;
  const { filters, selectedSearchFilter, searchTab } = useSelector(state => state?.search);

  const selectInnerProps = {
    ...innerProps,
    onClick: (e) => {
      const element = e.target.nodeName;
      if (element === 'SPAN' || element === 'DIV') {
        const optionPart = e?.target?.parentElement?.className?.includes('option-active');
        const optionText = e?.target?.className?.includes('option-active');
        (optionPart || optionText) && onChangeOption();
      }
      e.stopPropagation();
      e.preventDefault();
    }
  };
  const selectedOptions = selectProps?.value || [];
  const isExcluded = useMemo(() => isSelected && selectedOptions.find(item => item?.value === data?.value && item?.isExclude === true), [isSelected, selectedOptions, data?.value]);
  const isInluded = useMemo(() => isSelected && selectedOptions.find(item => item?.value === data?.value && item?.isExclude === false), [isSelected, selectedOptions, data?.value]);

  function onChangeOption() {
    const removeOption = selectedOptions.filter(item => item?.value !== data?.value);
    const addValue = selectedOptions.concat({ ...data, isExclude: false });
    const newOptions = isSelected ? removeOption : addValue;
    selectProps.onChange(newOptions);
    eventFunc({ isExclude: false });
  }
  function eventFunc(value) {
    const valueName = typeof props?.data?.value === 'number' ? (props?.data?.code || props?.data?.label) : props?.data?.value;
    const filterType = () => {
      if (!selectedSearchFilter) return 'filtration_only';
      return selectedSearchFilter?.id ? 'filtration_in_user_filter' : 'filtration_in_new_filter';
    };
    sendSearchEvent({
      category: `Search_Filters_Tab_${searchTab}`,
      event: value?.isExclude
        ? `Select_${selectProps?.name}_minus`
        : `Select_${selectProps?.name}_plus`,
      properties: {
        filter: filterType(),
        filters: clearEmtyValues(filters),
        name: valueName
      }
    });
  }

  function onClickIcon(value) {
    const findOption = selectedOptions?.find(item => item?.value === data?.value);
    const optionHasValue = findOption && findOption.isExclude !== value.isExclude;
    const addValue = selectedOptions.concat({ ...data, ...value });
    const removeOption = optionHasValue
      ? selectedOptions.filter(item => item?.value !== data?.value).concat({ ...data, ...value })
      : selectedOptions.filter(item => item?.value !== data?.value);
    eventFunc(value);
    const newOptions = isSelected ? removeOption : addValue;
    selectProps.onChange(newOptions);
  }

  const postTypeIcons = ['post', 'repost', 'extendedRepost', 'comment', 'replyComment'];
  const iconProps = data?.value && postTypeIcons.includes(data?.value) ? { color: colors.textPrimary, width: 16 } : {};
  const link = data?.icon && data?.icon?.includes('http');
  const code = (data?.code && !data?.flag) ? `${data?.code?.toUpperCase()} - ` : '';
  const text = (data?.code && !data?.flag) ? data?.label?.toLowerCase() : data?.label;

  return (
    <Option {...props} innerProps={selectInnerProps} className={cn(classes.optionPart, 'option-active')}>
      <div className={cn(classes.optionPart, 'option-active')}>
        <p className={cn(classes.hiddenText, 'option-active')}>
          {!!data?.icon && <Icon type={data.icon} {...iconProps} />}
          {!!data?.flag && <span className={classes.flag}>{data?.flag}</span>}
          {!!link && (
            <img
              alt="icon"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.style.display = 'none';
              }}
              className={classes.image}
              src={data?.icon}
              importance="high"
              loading="eager"
              referrerPolicy="no-referrer"
            />
          )}
          {code}
          <Tooltip
            text={text.length > 25 ? text : ''}
            truncate
            className="option-active"
          >
            {text}
          </Tooltip>
        </p>
        <div className={cn(classes.selectBox)}>
          <Icon
            type="add_round"
            width={20}
            height={20}
            isSelected={!!isInluded}
            onClick={() => { onClickIcon({ isExclude: false }); }}
          />
          <Icon
            type="minus_round"
            width={20}
            height={20}
            isSelected={!!isExcluded}
            onClick={() => { onClickIcon({ isExclude: true }); }}
          />

        </div>
      </div>
    </Option>
  );
};

OptionPart.propTypes = {
  data: shape({ label: string }),
  isSelected: bool,
  selectProps: shape({}),
  innerProps: shape({})
};

export default OptionPart;
