import { useState, useEffect } from 'react';
import { string, func, arrayOf, shape } from 'prop-types';
import t from 'utils/translateFunc';
import { Icon } from 'Components';
import SliderRange from 'Components/SliderRange';
import cn from 'classnames';
import classes from './FilterPublicationPlaceRate.module.scss';

const FilterPublicationPlaceRate = ({ filterList = [], addItem = () => null, filterName = '', filterType = '',
  language = 'en', eventFunc = () => null }) => {
  const [currentRangeStart, setCurrentRangeStart] = useState(0);
  const [currentRangeEnd, setCurrentRangeEnd] = useState(5);
  const hasFilter = filterList?.find(filter => filter?.id === filterName);
  const filterValue = filterList?.find(filter => filter?.id === filterName)?.name;

  useEffect(() => {
    updateInterval();
  }, [filterList?.length]);

  function updateInterval() {
    if (!hasFilter) {
      currentRangeStart !== 0 && setCurrentRangeStart(0);
      currentRangeEnd !== 5 && setCurrentRangeEnd(5);
    }
    if (filterValue) {
      const rangeValues = filterValue.split('-');
      const rangeStart = Number(rangeValues[0]) || 0;
      const rangeEnd = Number(rangeValues[1]) || 5;
      currentRangeStart !== rangeStart && setCurrentRangeStart(rangeStart);
      currentRangeStart !== rangeEnd && setCurrentRangeEnd(rangeEnd);
    }
  }

  const handleSubmit = () => {
    eventFunc(`filter_${filterName}`, { rangeStart: parseFloat(currentRangeStart), rangeEnd: parseFloat(currentRangeEnd) });
    addItem({ id: filterName, name: `${currentRangeStart}-${currentRangeEnd}`, type: filterType, filterName });
  };
  const handleInputChange = (type, value) => {
    const cleanValue = value.replace(/[^0-9.,]/g, '').replace(',', '.');
    if (cleanValue === '') {
      type === 'start' && setCurrentRangeStart(0);
      if (type === 'end') {
        setCurrentRangeStart(0);
        setCurrentRangeEnd(0);
      }
      return;
    }

    if (cleanValue.endsWith('.') || cleanValue.endsWith(',') || cleanValue.endsWith('.0') || cleanValue.endsWith(',0')) {
      type === 'start' && setCurrentRangeStart(value);
      type === 'end' && setCurrentRangeEnd(value);
      return;
    }

    const newValue = parseFloat(Math.max(0, Math.min(5, parseFloat(cleanValue)).toFixed(2)));
    if (type === 'start') {
      newValue > currentRangeEnd && setCurrentRangeEnd(5);
      setCurrentRangeStart(newValue);
    } else if (type === 'end') {
      newValue < currentRangeStart && setCurrentRangeStart(0);
      setCurrentRangeEnd(newValue);
    }
  };

  return (
    <div className={classes.publicationPlaceRateWrapper}>
      <label className={classes.seletMenuTitle}>
        {filterType}
      </label>
      <div className={classes.formWrapper}>
        <div className={classes.filterIntervalWrapper}>
          <input
            value={currentRangeStart}
            className={classes.intervalInput}
            onChange={e => handleInputChange('start', e.target.value)}
          />
          <span>&mdash;</span>
          <input
            value={currentRangeEnd}
            className={classes.intervalInput}
            onChange={e => handleInputChange('end', e.target.value)}
          />
        </div>
        <Icon
          type="arrow_apply"
          role="button"
          // btnType={cn(classes.btnApply, disabled && classes.btnApply_disabled)}
          btnType={cn(classes.btnApply)}
          tooltipText={t('btn', 'APPLY', language)}
          onClick={handleSubmit}
        />
      </div>

      <SliderRange
        currentRangeStart={parseFloat(currentRangeStart)}
        currentRangeEnd={parseFloat(currentRangeEnd)}
        maxRange={5}
        onChange={({ rangeStart, rangeEnd }) => {
          setCurrentRangeStart(parseFloat(rangeStart.toFixed(2)));
          setCurrentRangeEnd(parseFloat(rangeEnd.toFixed(2)));
        }}
      />
    </div>
  );
};

FilterPublicationPlaceRate.propTypes = {
  filterName: string,
  filterList: arrayOf(shape({})),
  filterType: string,
  language: string,
  eventFunc: func,
  addItem: func
};

export default FilterPublicationPlaceRate;
