import { useState, useEffect } from 'react';
import cn from 'classnames';
import { string, func, bool, shape, arrayOf } from 'prop-types';
import t from 'utils/translateFunc';
import classes from './FilterInterval.module.scss';

function FilterInterval({
  className = '',
  addItem = () => null,
  removeItem = () => null,
  filterList = [],
  language = 'uk',
  age = false,
  filterType = '',
  filterTypeTo = '',
  filterTypeFrom = '',
  filterNameFrom = '',
  filterNameTo = ''
}) {
  const textFrom = t('filterAudience', 'FROM', language);
  const textTo = t('filterAudience', 'TO', language);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const hasFromFilter = filterList?.find(filter => filter?.id === filterNameFrom);
  const hasToFilter = filterList?.find(filter => filter?.id === filterNameTo);
  const valueFrom = hasFromFilter?.name || '';
  const valueTo = hasToFilter?.name || '';

  useEffect(() => {
    updateInterval();
  }, [filterList?.length, hasFromFilter, hasToFilter]);

  function updateInterval() {
    hasFromFilter && valueFrom !== from && setFrom(valueFrom);
    !hasFromFilter && from && setFrom('');
    hasToFilter && valueTo !== to && setTo(valueTo);
    !hasToFilter && to && setTo('');
  }

  function onChangeFrom({ target }) {
    if (!age) {
      setFrom(target?.value);
    }
    if (age && target.value.length < 3) {
      setFrom(target?.value);
    }
  }

  function onChangeTo({ target }) {
    if (!age) {
      setTo(target?.value);
    }
    if (age && target?.value.length < 3) {
      setTo(target?.value);
    }
  }

  function onBlurFrom() {
    if (from) {
      valueFrom !== from && addItem({ id: filterNameFrom, name: from, type: filterTypeFrom, filterName: filterNameFrom });
    } else {
      removeItem({ id: filterNameFrom, filterName: filterNameFrom });
    }
    if (+to && +from && +from > +to) {
      setTo(+from + 1);
      addItem({ id: filterNameTo, name: +from + 1, type: filterTypeTo, filterName: filterNameTo });
    }
  }

  function onBlurTo() {
    if (to) {
      valueFrom !== to && addItem({ id: filterNameTo, name: to, type: filterTypeTo, filterName: filterNameTo });
    } else {
      removeItem({ id: filterNameTo, filterName: filterNameTo });
    }
    if (+to && +from && +from > +to) {
      setFrom(+to - 1);
      addItem({ id: filterNameFrom, name: +to - 1, type: filterTypeFrom, filterName: filterNameFrom });
    }
  }

  return (
    <div className={classes.filterIntervalWrapper}>
      <label className={classes.seletMenuTitle}>
        {filterType}
      </label>
      <div className={cn(className, classes.filterInterval, age && classes.filterInterval_inserted)}>
        <input
          value={from}
          className={cn(classes.intervalInput, age && classes.intervalInput_inserted)}
          onChange={onChangeFrom}
          onBlur={onBlurFrom}
          type="number"
          placeholder={textFrom}
        />
        <span>&mdash;</span>
        <input
          value={to}
          className={cn(classes.intervalInput, age && classes.intervalInput_inserted)}
          onChange={onChangeTo}
          onBlur={onBlurTo}
          type="number"
          placeholder={textTo}
        />
      </div>
    </div>
  );
}

FilterInterval.propTypes = {
  filterTypeTo: string,
  filterTypeFrom: string,
  filterNameFrom: string,
  filterNameTo: string,
  filterType: string,
  className: string,
  addItem: func,
  removeItem: func,
  filterList: arrayOf(shape({})),
  language: string,
  age: bool
};

export default FilterInterval;
