import { memo } from 'react';
import { SelectCustom, Icon } from 'Components';
import { func, bool, shape, oneOfType, array, string } from 'prop-types';
import colors from 'styles/_colors.scss';
import t from 'utils/translateFunc';
import { PROJECT } from 'config';
import classes from './FilterCustom.module.scss';
import Option from './SelectParts/OptionPart';
import Placeholder from './SelectParts/PlaceholderPart';
import NoOptionsMessage from './SelectParts/NoOptionsMessagePart';
import LoadingMessage from './SelectParts/LoadingMessage';
import getOptions from './options';

const FilterCustom = memo(({ filterName = '', filterType = '', options = null, endingText = { one: '', many: '' },
  placeholder = '', onChange = () => null, onFilterList = () => null, onAdd = () => null, isLoading = false,
  usedKeywords = { limit: 0, saved: 0 }, isDisabled = false, ...rest }) => {
  const { customStyles } = getOptions();
  const list = onFilterList({ filterName, isExclude: false });
  const listExclude = onFilterList({ filterName, isExclude: true });
  const allLists = [...list, ...listExclude];

  function onChangeOptions(values) {
    const editedValues = values.map(({ value, label, isExclude }) => ({ id: value, name: label, filterName, isExclude, filterType }));
    onChange({ filterValues: editedValues, filterName });
  }
  const limit = usedKeywords?.limit || 0;
  const saved = usedKeywords?.saved || 0;

  return (
    <div className={classes.selectWrapper}>
      <label className={classes.seletMenuTitle}>
        {filterType}
      </label>
      <SelectCustom
        value={[...list, ...listExclude]}
        name={filterName}
        options={options}
        placeholder={placeholder}
        onChange={onChangeOptions}
        isMulti
        closeMenuOnSelect={false}
        styles={customStyles}
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
        endingPlaceholderPart={allLists.length === 1 ? endingText?.one : endingText?.many}
        components={{ Option, Placeholder, NoOptionsMessage, LoadingMessage }}
        isLoading={isLoading}
        isSearchable
        isDisabled={isDisabled}
        {...rest}
      />
      {(filterName === 'keyword' || filterName === 'tag') && (
      <div className={classes.usedKeywords} onClick={() => { filterName === 'tag' && onAdd(filterName); }}>
        <Icon
          type="add_round"
          btnType={classes.addButton}
          color={PROJECT === 'metricom' ? colors.green800 : colors.purple500}
          width={18}
          height={18}
          onClick={() => { filterName === 'keyword' && onAdd(filterName); }}
          {...(filterName === 'keyword' ? { tooltipText: t('kSTegFilter', 'ADD_KEYWORD'), role: 'button' } : {})}
        />
        {filterName === 'keyword' && !!(limit || saved) ? (
          <span>
            {t('kSTegFilter', 'USED')}
            {saved}
            /
            {limit}
          </span>
        ) : (
          <span className={classes.addText}>
            {t('kSTegFilter', filterName === 'keyword' ? 'ADD_KEYWORD' : 'ADD_TEG')}
          </span>
        ) }
      </div>
      )}
    </div>
  );
});

FilterCustom.propTypes = {
  filterName: string,
  filterType: string,
  onChange: func,
  onFilterList: func,
  onAdd: func,
  options: oneOfType([array, bool, shape({})]),
  endingText: shape({}),
  placeholder: string,
  isLoading: bool,
  isDisabled: bool,
  usedKeywords: shape({})
};

export default FilterCustom;
