import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntercom as UseIntercom } from 'react-use-intercom';

export const useIntercom = () => {
  const user = useSelector(state => state?.userInfo?.user);
  const auth = useSelector(state => state?.auth?.isAuthenticated);
  const language = useSelector(state => state?.userInfo?.language);
  const { boot, update, show, shutdown } = UseIntercom();
  const userPayload = {
    email: user?.email || '',
    userId: user?.id || '',
    name: user?.username || '',
    languageOverride: language === 'ro' ? 'en' : language || 'en'
  };
  useEffect(() => {
    auth && user?.id && update(userPayload);
  }, [auth, userPayload]);

  const openIntercom = useCallback(() => {
    auth && user?.id && boot(userPayload);
    (!auth || !user?.id) && shutdown();
  }, [auth, userPayload]);

  return { openIntercom, show };
};
