import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import t from 'utils/translateFunc';
import { useUnblockSources, useGetSettings, useCreateSettings, useChangeSettings } from 'storage/queryHooks';
import { useSelector } from 'react-redux';
import { Icons, Icon, SelectCustom, AnimatePage } from 'Components';
import { sendFeedEvent } from 'services/amplitude/events';
import classes from './FeedSettings.module.scss';

const FeedSettings = () => {
  const history = useNavigate();
  const { language } = useSelector(state => state?.userInfo);
  const portal = useSelector(state => state?.portals?.currentPortal?.id) || '';
  const { settings, duplicates, ordering, articles_per_page } = useSelector(state => state?.filters);
  useGetSettings();
  const { mutateAsync: createSettings } = useCreateSettings();
  const { mutateAsync: changeSettings } = useChangeSettings();
  const { mutateAsync: unblockSources } = useUnblockSources();
  const articlesCountList = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' }
  ];

  function onSubmit({ value, id }) {
    sendFeedEvent({ category: 'News feed settings', event: value });
    const ublockBody = {};
    value === 'source' && (ublockBody.source_ids = [id]);
    value === 'creator' && (ublockBody.creator_ids = [id]);
    value === 'place' && (ublockBody.publication_place_ids = [id]);
    unblockSources({ body: ublockBody });
  }

  function onCancel() {
    history(-1);
  }

  function onChangeArticlesShowCount({ value }) {
    value && sendFeedEvent({ category: 'News feed settings', event: `Selected_Number_Of_News_${value}` });

    !settings?.id
      ? createSettings({ articles_per_page: value, doubles: duplicates, sort_by: ordering, portal })
      : changeSettings({ articles_per_page: value });
  }

  return (
    <AnimatePage>
      <div className={classes.feedSettingsWrap}>
        <button
          className={classes.exitBtn}
          onClick={onCancel}
          type="button"
        >
          <Icons
            icon="Icn_Clear"
            className={cn(classes.icon)}
          />
        </button>
        <div className={classes.feedSettings}>
          <h2 className={classes.title}>
            {t('feedSettings', 'SETTING_FEED', language)}
          </h2>
          <div className={classes.feedSettings_wrapper}>
            <div className={classes.blockedWrapper}>
              <div className={classes.blockedTitle}>
                {t('feedSettings', 'BLOCKED_SOURCE', language)}
                :
              </div>
              <div className={classes.blockedList}>

                {settings?.unused_sources?.length ? settings.unused_sources.map(item => (
                  <div key={item?.id} className={classes.blockedListItem}>
                    {item?.name}
                    <Icon
                      type="backup"
                      tooltipText={t('feedSettings', 'UNBLOCK', language)}
                      role="button"
                      onClick={() => { onSubmit({ value: 'source', id: item?.id }); }}
                      className={classes.iconWrapper}
                      btnType={classes.btnType}
                    />
                  </div>
                )) : <div className={classes.blockedListItem}>{t('feedSettings', 'NO_BLOCKED_SOURCE', language)}</div>}
              </div>
            </div>
            <div className={classes.blockedWrapper}>
              <div className={classes.blockedTitle}>
                {t('feedSettings', 'BLOCKED_CREATORS', language)}
                :
              </div>
              <div className={classes.blockedList}>
                {settings?.unused_creators?.length ? settings.unused_creators.map(item => (
                  <div key={item?.id} className={classes.blockedListItem}>
                    {item?.name}
                    <Icon
                      type="backup"
                      tooltipText={t('feedSettings', 'UNBLOCK', language)}
                      role="button"
                      onClick={() => { onSubmit({ value: 'creator', id: item?.id }); }}
                      className={classes.iconWrapper}
                      btnType={classes.btnType}
                    />
                  </div>
                )) : <div className={classes.blockedListItem}>{t('feedSettings', 'NO_BLOCKED_CREATORS', language)}</div>}
              </div>
            </div>
            <div className={classes.blockedWrapper}>
              <div className={classes.blockedTitle}>
                {t('feedSettings', 'BLOCKED_PLACES', language)}
                :
              </div>
              <div className={classes.blockedList}>
                {settings?.unused_publication_place?.length ? settings.unused_publication_place.map(item => (
                  <div key={item?.id} className={classes.blockedListItem}>
                    {item?.name}
                    <Icon
                      type="backup"
                      tooltipText={t('feedSettings', 'UNBLOCK', language)}
                      role="button"
                      onClick={() => { onSubmit({ value: 'place', id: item?.id }); }}
                      className={classes.iconWrapper}
                      btnType={classes.btnType}
                    />
                  </div>
                )) : <div className={classes.blockedListItem}>{t('feedSettings', 'NO_BLOCKED_PLACES', language)}</div>}
              </div>
            </div>
            <div className={classes.blockedWrapper}>
              <div className={classes.blockedTitle}>
                {t('feedSettings', 'KW_NUMBER', language)}
              </div>
              <div className={classes.blockedList}>
                <SelectCustom
                  className={classes.selectDropDown}
                  value={{ value: articles_per_page, label: String(articles_per_page) }}
                  onChange={onChangeArticlesShowCount}
                  options={articlesCountList}
                  menuPlacement="top"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AnimatePage>
  );
};

export default FeedSettings;
