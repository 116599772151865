export const translateBase = {
  default: 'en',
  translate: {
    name: {
      dashboard: {
        ru: 'Дашборды',
        en: 'Dashboards',
        ro: 'Tablouri de bord',
        uk: 'Дашборди'
      }
    },
    nodata: {
      NODATA: {
        ru: 'Нет данных',
        en: 'No data',
        ro: 'Nu există date',
        uk: 'Немає даних'
      },
      NO_DASHBOARDS: {
        ru: 'У вас нет дашбордов',
        en: 'You do not have dashboards',
        ro: 'Nu aveți tablouri de bord',
        uk: 'Ви ще не маєте дашбордів'
      },
      NO_CHARTS: {
        ru: 'У вас нет графиков',
        en: 'You do not have charts',
        ro: 'Nu aveți diagrame',
        uk: 'Ви ще не маєте графіків'
      },
      UNDEFINED: {
        ru: 'Неопределено',
        en: 'Undefined',
        ro: 'Nedefinit',
        uk: 'Не визначено'
      },
      NOT_FOUND: {
        ru: 'Не найдено',
        en: 'Not found',
        ro: 'Nu a fost găsit',
        uk: 'Не знайдено'
      }
    },
    maintenance: {
      TITLE: {
        ru: 'Великие дела уже в пути',
        en: 'Great things are on the way',
        ro: 'Lucruri grozave sunt pe drum',
        uk: 'Великі справи вже в дорозі'
      },
      SUBTITLE: {
        ru: 'Эта страница находится на техническом обслуживании прямо сейчас. Пожалуйста, зайдите позже.',
        en: 'This page is under maintenance right now. Please check back later.',
        ro: 'Această pagină este sub întreținere chiar acum.Vă rugăm să verificați mai târziu.',
        uk: 'Ця сторінка знаходиться на технічному обслуговуванні зараз. Будь ласка, зайдіть пізніше.'
      },
      BUTTON: {
        ru: 'Вернутся на сайт',
        en: 'Back to site',
        ro: 'Înapoi la site',
        uk: 'Повернутися на сайт'
      }
    },
    notAvalailable: {
      UPS: {
        ru: 'Упс... что-то пошло не так!',
        en: 'Oops...something went wrong!',
        ro: 'Ups! Ceva a mers prost!',
        uk: 'Упс...щось пішло не так!'
      },
      SORRY: {
        ru: 'Упс...  не удалось загрузить новости по выбранным фильтрам',
        en: 'Oops... failed to load news by selected filters',
        ro: 'Oops ... nu a reușit să încarce știri de filtrele selectate',
        uk: 'Упс... не вдалося завантажити новини за обраними фільтрами '
      },
      WE_KNOW: {
        ru: 'Мы уже знаем о проблеме',
        en: 'We already know about the problem',
        ro: 'Știm deja despre problemă',
        uk: 'Ми вже знаємо про проблему'
      },
      WE_WORK: {
        ru: 'Наша команда работает над ее решением',
        en: 'Our team is working on its solution',
        ro: 'Echipa noastră lucrează la soluția sa',
        uk: 'Наша команда працює над її вирішенням'
      }
    },
    notFound: {
      TITLE: {
        ru: 'Великие дела уже в пути',
        en: 'Great things are on the way',
        ro: 'Lucruri grozave sunt pe drum',
        uk: 'Великі справи вже в дорозі'
      },
      SUBTITLE: {
        ru: 'Страница отсутствует или некорректная ссылка.',
        en: 'The page is missing or the link is incorrect.',
        ro: 'Pagina lipsește sau linkul este incorect.',
        uk: 'Сторінка відсутня або неправильне посилання.'
      },
      PORTAL_DENIED: {
        ru: 'У Вас нет доступ к этому порталу',
        en: 'You don\'t have access to this portal',
        ro: 'Nu aveți acces la acest portal',
        uk: 'У Вас немає доступу до цього порталу'
      },
      BUTTON: {
        ru: 'Вернутся',
        en: 'Return back',
        ro: 'Reveni înapoi',
        uk: 'Повернуться'
      },
      NO_MENTIONS: {
        ru: 'Публикации не найдены',
        en: 'Publications not found',
        ro: 'Publicațiile nu au fost găsite',
        uk: 'Публікації не знайдені'
      },
      NO_ARTICLES: {
        ru: 'Статьи не найдены',
        en: 'Articles not found',
        ro: 'Articole care nu au fost găsite',
        uk: 'Статті не знайдені'
      },
      NO_ARTICLES_BY: {
        ru: 'Статьи по указанным параметрам не найдены',
        en: 'No articles were found for the specified parameters',
        ro: 'Nu au fost găsite articole pentru parametrii specificați',
        uk: 'Статті за вказаними параметрами не знайдені'
      },
      EDIT_QUERY: {
        ru: 'Отредактируйте запрос или обратитесь в поддержку',
        en: 'Edit your request or contact support',
        ro: 'Editați cererea sau asistența de contact',
        uk: 'Відредагуйте запит або зверніться в підтримку'
      },
      ERROR_QUERY: {
        ru: 'В вашем запросе присутствуют ошибки',
        en: 'Your request contains errors',
        ro: 'Cererea dvs. conține erori',
        uk: 'У вашому запиті присутні помилки'
      }
    },
    dragWidget: {
      DRAG_WIDGET: {
        ru: 'Выберите виджет из списка',
        en: 'Select a widget from the list',
        ro: 'Selectați un widget din listă',
        uk: 'Виберіть віджет зі списку'
      }
    },
    menuConfig: {
      DOWNLOAD: {
        ru: 'Скачать картинкой',
        en: 'Download picture',
        ro: 'Descărcați poza',
        uk: 'Завантажити зображення'
      },
      COPY_IN: {
        ru: 'Скопировать в...',
        en: 'Copy to...',
        ro: 'Copiaza in...',
        uk: 'Скопіювати в...'
      },
      COPY: {
        ru: 'Cоздать копию',
        en: 'Create a copy',
        ro: 'Creați o copie',
        uk: 'Створити копію'
      },
      DELETE_WIDGET: {
        ru: 'Удалить виджет',
        en: 'Delete',
        ro: 'Șterge',
        uk: 'Видалити'
      },
      EDIT: {
        ru: 'Редактировать',
        en: 'Edit',
        ro: 'Editați',
        uk: 'Редагувати'
      },
      RENAME_WIDGET: {
        ru: 'Редактировать виджеты ',
        en: 'Edit Widgets',
        ro: 'Editați widget-uri',
        uk: 'Редагувати віджети'
      },
      DELETE: {
        ru: 'Удалить',
        en: 'Delete',
        ro: 'Șterge',
        uk: 'Видалити'
      },
      SAVE_CHANGES: {
        ru: 'Сохранить изменения',
        en: 'Save changes',
        ro: 'Salvează modificările',
        uk: 'Зберегти зміни' },
      RENAME: {
        ru: 'Переименовать',
        en: 'Rename',
        ro: 'Redenumire',
        uk: 'Перейменувати'
      },
      HTML: {
        ru: 'HTML',
        en: 'HTML',
        ro: 'HTML',
        uk: 'HTML'
      },
      XLS: {
        ru: 'XLS',
        en: 'XLS',
        ro: 'XLS',
        uk: 'XLS'
      },
      DOCX: {
        ru: 'DOCX',
        en: 'DOCX',
        ro: 'DOCX',
        uk: 'DOCX'
      },
      PROFILE: {
        ru: 'Настройки профиля',
        en: 'Profile settings',
        ro: 'Setarile profilului',
        uk: 'Налаштування профілю'
      },
      EXIT: {
        ru: 'Выход',
        en: 'Logout',
        ro: 'Deconectare',
        uk: 'Вихід'
      }
    },
    userMenu: {
      ACCOUNT: {
        ru: 'Настройки профиля',
        en: 'Profile settings',
        ro: 'Setarile profilului',
        uk: 'Налаштування профілю'
      },
      EXIT: {
        ru: 'Выход',
        en: 'Logout',
        ro: 'Deconectare',
        uk: 'Вихід'
      },
      FEED: {
        ru: 'Настройки ленты',
        en: 'Feed settings',
        ro: 'Setări de alimentare',
        uk: 'Налаштування стрічки'
      }
    },
    navigationMenu: {
      MENTIONS: {
        ru: 'Упоминания',
        en: 'Mentions',
        ro: 'Mențiuni',
        uk: 'Згадування'
      },
      DASHBOARDS: {
        ru: 'Дашборды',
        en: 'Dashboards',
        ro: 'Tablouri de bord',
        uk: 'Дашборди'
      },
      SEARCH: {
        ru: 'Поиск',
        en: 'Search',
        ro: 'Căutare',
        uk: 'Пошук'
      },
      ALERTS: {
        ru: 'Оповещения',
        en: 'Alerts',
        ro: 'Alerte',
        uk: 'Сповіщення'
      },
      DOWNLOADS: {
        ru: 'Загрузки',
        en: 'Downloads',
        ro: 'Descărcări',
        uk: 'Завантаження'
      },
      CHECKIT: {
        ru: 'Чекит',
        en: 'Checkit',
        ro: 'Verifică',
        uk: 'Чекіт'
      },
      ENCODING: {
        ru: 'Кодировка',
        en: 'Encoding',
        ro: 'Codificare',
        uk: 'Кодування'
      },
      ENCODING_STAT: {
        ru: 'Статистика кодировки',
        en: 'Encoding statistics',
        ro: 'Codificarea statisticilor',
        uk: 'Статистика кодування'
      },
      TRACKING: {
        ru: 'Трекинг',
        en: 'Tracking',
        ro: 'Urmărire',
        uk: 'Трекінг'
      },
      TRACKING_BOTS: {
        ru: 'Трекинг бота',
        en: 'Tracking bots',
        ro: 'Urmărirea roboților',
        uk: 'Трекінг бота'
      },
      ADMIN: {
        ru: 'Админ',
        en: 'Admin',
        ro: 'Administrator',
        uk: 'Адмін'
      }
    },
    reportsMenu: {
      DEFAULT: {
        ru: 'По умолчанию',
        en: 'Default',
        ro: 'Mod implicit',
        uk: 'За замовчуванням'
      },
      QUARTERLY: {
        ru: 'Квартальный отчет 3',
        en: 'Quarterly report 3',
        ro: 'Raport trimestrial 3',
        uk: 'Квартальний звіт 3'
      },
      DAILY: {
        ru: 'Ежедневный отчет',
        en: 'Daily report',
        ro: 'Raport zilnic',
        uk: 'Щоденний звіт'
      },
      ANNUAL: {
        ru: 'Годовой отчет',
        en: 'Annual report',
        ro: 'Raport anual',
        uk: 'Річний звіт'
      }
    },
    chipsButton: {
      OPTION: { ru: 'Вариант', en: 'Version', uk: 'Варіант', ro: 'Opțiune' }
    },
    helpMenu: {
      CHAT: {
        ru: 'Чат',
        en: 'Chat',
        ro: 'conversație',
        uk: 'Чат'
      },
      WHATS_NEW: {
        ru: 'Что нового в системе',
        en: 'Whats new in the system',
        ro: 'Ce este nou în sistem',
        uk: 'Що нового в системі'
      },
      WHATS_NEW_LOOQME: {
        ru: 'Что нового в системе LOOQME',
        en: 'Whats new in the system LOOQME',
        ro: 'Ce este nou în sistem looqme',
        uk: 'Що нового в системі LOOQME'
      },
      BASE_KNOLEDGES: {
        ru: 'База знаний',
        en: 'Knowledge base',
        ro: 'Bază de cunoștințe',
        uk: 'База знань'
      },
      BLOG: {
        ru: 'Блог',
        en: 'Blog',
        ro: 'Blog',
        uk: 'Блог'
      },
      FEEDBACK: {
        ru: 'Отправить отзыв',
        en: 'Feedback',
        ro: 'Părere',
        uk: 'Відправити відгук'
      },
      TELEGRAM_CONNECTED: {
        ru: '✅ Telegram аккаунт подключен',
        en: '✅ Telegram account is connected',
        ro: '✅ Contul Telegram este conectat',
        uk: '✅ Telegram акаунт підключено'
      },
      WHATSAPP_CONNECTED: {
        ru: '✅ WhatsApp аккаунт подключен',
        en: '✅ WhatsApp account is connected',
        ro: '✅ Contul WhatsApp este conectat',
        uk: '✅ WhatsApp акаунт підключено'
      },
      WHATSAPP_ERROR: {
        ru: 'Ошибка WhatsApp.',
        en: 'WhatsApp error.',
        ro: 'Eroare WhatsApp.',
        uk: 'Помилка WhatsApp.'
      }

    },
    tonePopover: {
      POSITIVE: {
        ru: 'Позитив',
        en: 'Positive',
        ro: 'Pozitiv',
        uk: 'Позитив'
      },
      NEUTRAL: {
        ru: 'Нейтрал',
        en: 'Neutral',
        ro: 'Neutru',
        uk: 'Нейтрал'
      },
      NEGATIVE: {
        ru: 'Негатив',
        en: 'Negative',
        ro: 'Negativ',
        uk: 'Негатив'
      }
    },
    sourcePopover: {
      COPY_LINK: {
        ru: 'Копировать ссылку публикации',
        en: 'Copy post link',
        ro: 'Copiați linkul de postare',
        uk: 'Копіювати посилання публікації'
      },
      FILTER_MENTIONS: {
        ru: 'Показать упоминания только из этого источника',
        en: 'Show mentions from this source only',
        ro: 'Arată mențiuni doar de la această sursă',
        uk: 'Показати згадки тільки з цього джерела'
      },
      BLOCK_SOURCE: {
        ru: 'Заблокировать источник',
        en: 'Block source',
        ro: 'Sursa blocului',
        uk: 'Заблокувати джерело'
      },
      SHOW_CARD: {
        ru: 'Показать карточку источника',
        en: 'Show source card',
        ro: 'Afișați cardul sursă',
        uk: 'Показати картку джерела'
      },
      REPORT_PROBLEM: {
        ru: 'Сообщить о проблеме с этим источником',
        en: 'Report a problem with this source',
        ro: 'Raportați o problemă cu această sursă',
        uk: 'Повідомити про проблему з цим джерелом'
      }
    },
    sourceAssignTeg: {
      CURRENT_MOMENT: {
        ru: 'С текущего момента',
        en: 'From the current moment',
        ro: 'Din momentul curent',
        uk: 'З поточного моменту'
      },
      TODAY: {
        ru: 'За сегодня',
        en: 'For today',
        ro: 'Pentru astazi',
        uk: 'За сьогодні'
      },
      YESTERDAY: {
        ru: 'За вчера',
        en: 'For yesterday',
        ro: 'Pentru ieri',
        uk: 'За вчора'
      },
      LAST_WEEK: {
        ru: 'За прошедшую неделю',
        en: 'For the past week',
        ro: 'Pentru săptămâna trecută',
        uk: 'За минулий тиждень'
      },
      LAST_MONTH: {
        ru: 'За прошлый месяц',
        en: 'For the past month',
        ro: 'În ultima lună',
        uk: 'За минулий місяць'
      },
      LAST_3MONTH: {
        ru: 'За прошедшие 3 месяца',
        en: 'For the past 3 months',
        ro: 'În ultimele 3 luni',
        uk: 'За минулих 3 місяці'
      },
      LAST_6MONTH: {
        ru: 'За прошедшие полгода',
        en: 'For the past 6 months',
        ro: 'În ultimele 6 luni',
        uk: 'За минулих півроку'
      },
      LAST_YEAR: {
        ru: 'За прошедший год',
        en: 'For the past year',
        ro: 'În ultimul an',
        uk: 'За минулий рік'
      },
      LAST_2YEARS: {
        ru: 'За прошедшие 2 года',
        en: 'For the past 2 year',
        ro: 'În ultimii 2 ani',
        uk: 'За минулі 2 роки'
      }
    },
    sourceProblem: {
      SOURCE_PROBLEM: {
        ru: 'Проблема с источником',
        en: 'Source problem',
        ro: 'Problema sursă',
        uk: 'Проблема із джерелом'
      },
      SOURCE: {
        ru: 'Источник',
        en: 'Source',
        ro: 'Sursă',
        uk: 'Джерело'
      },
      CHOOSE: {
        ru: 'Выберите',
        en: 'Choose',
        ro: 'Alege',
        uk: 'Виберіть'
      },
      CHOOSE_PROBLEM: {
        ru: 'Выберите проблему',
        en: 'Choose a problem',
        ro: 'Alegeți o problemă',
        uk: 'Виберіть проблему'
      },
      SPECIFY_PROBLEM: {
        ru: 'Уточните, что именно не так',
        en: 'Specify what is wrong',
        ro: 'Specificați ce este greșit',
        uk: 'Уточніть, що саме не так'
      },
      WRONG_NAME: {
        ru: 'Неправильно указано название источника',
        en: 'Wrong source name',
        ro: 'Nume sursă greșită',
        uk: 'Неправильно вказано назву джерела'
      },
      WRONG_TOPIC: {
        ru: 'Неправильно тематика название источника',
        en: 'Wrong topic source name',
        ro: 'Numele sursă de subiect greșit',
        uk: 'Неправильно тема назва джерела'
      },
      WRONG_GEOGRAPHY: {
        ru: 'Неправильно география название источника',
        en: 'Wrong geography source name',
        ro: 'Nume sursă de geografie greșită',
        uk: 'Неправильно географія назва джерела'
      },
      WRONG_AUDIENCE: {
        ru: 'Неправильно указана аудитория источника',
        en: 'Incorrectly specified audience source',
        ro: 'Sursa de audiență specificată incorect',
        uk: 'Неправильно вказана аудиторія джерела'
      },
      WRONG_DATE: {
        ru: 'Дата в оригинале не совпадает с датой в системе',
        en: 'The date in the original does not match the date in the system',
        ro: 'Data din original nu se potrivește cu data din sistem',
        uk: 'Дата в оригіналі не збігається з датою в системі'
      },
      ANOTHER: {
        ru: 'Другая проблема',
        en: 'Another problem',
        ro: 'Altă problema',
        uk: 'Інша проблема'
      },
      MISSED_ARTICLE: {
        ru: 'Пропущенная статья',
        en: 'Missed article',
        ro: 'Articol ratat',
        uk: 'Пропущена стаття'
      }
    },
    tabsMenu: {
      ALL_MENTIONS: {
        ru: 'Все',
        en: 'All',
        ro: 'Toate',
        uk: 'Усі'
      },
      FAVORITE: {
        ru: 'Избранные',
        en: 'Favorites',
        ro: 'Favorite',
        uk: 'Обрані'
      },
      PROCESSED: {
        ru: 'Обработанные',
        en: 'Processed',
        ro: 'Procesat',
        uk: 'Оброблені'
      },
      UNPROCESSED: {
        ru: 'Необработанные',
        en: 'Unprocessed',
        ro: 'Neprocesat',
        uk: 'Необроблені'
      },
      DELETED: {
        ru: 'Удаленные',
        en: 'Deleted',
        ro: 'Șters',
        uk: 'Видалені'
      }
    },
    filtration: {
      COMPARE: {
        ru: 'Сравнить с',
        en: 'Compare with',
        ro: 'Compara cu',
        uk: 'Порівняти з'
      },
      ENTER_KW: {
        ru: 'Введите КС',
        en: 'Enter the KW',
        ro: 'Intrați în KW',
        uk: 'Введіть КС'
      },
      ENTER_TAG: {
        ru: 'Введите тег',
        en: 'Enter tag',
        ro: 'Introduceți eticheta',
        uk: 'Введіть тег'
      },
      NO_TAG: {
        ru: 'Без тега',
        en: 'No tag',
        ro: 'Fără etichetă',
        uk: 'Без тегу'
      },
      KEY_WORD: {
        ru: 'Ключевое слово',
        en: 'Keyword',
        ro: 'Cuvânt cheie',
        uk: 'Ключове слово'
      },
      TAG: {
        ru: 'Тег',
        en: 'Tag',
        ro: 'Etichetă',
        uk: 'Тег'
      },
      SELECTED: {
        ru: 'Выбрано',
        en: 'Selected',
        ro: 'Selectat',
        uk: 'Вибрано'
      },
      KW: {
        ru: 'KC',
        en: 'Keyword',
        ro: 'Cuvânt cheie',
        uk: 'КС'
      },
      TAGv1: {
        ru: 'тег',
        en: 'tag',
        ro: 'etichetă',
        uk: 'tег'
      },
      TAGv2: {
        ru: 'тега',
        en: 'tag',
        ro: 'etichetă',
        uk: 'тега'
      },
      TAGv3: {
        ru: 'тегов',
        en: 'tags',
        ro: 'etichete',
        uk: 'тегів'
      }
    },
    feed_ordering: {
      DATE_NEWEST: {
        ru: 'По дате (сначала новые)',
        en: 'By date (newest first)',
        ro: 'Până la data (cel mai nou prim)',
        uk: 'За датою (спочатку нові)'
      },
      DATE_OLDEST: {
        ru: 'По дате (сначала старые)',
        en: 'By date (oldest first)',
        ro: 'Până la data (cea mai veche mai întâi)',
        uk: 'За датою (спочатку старі)'
      },
      DUBLICATES: {
        ru: 'По количеству дублей',
        en: 'By number of duplicates',
        ro: 'După numărul de duplicate',
        uk: 'За кількістю дублів'
      },
      AUDIENCE: {
        ru: 'По аудитории источника',
        en: 'By source audience',
        ro: 'De către audiența sursă',
        uk: 'За аудиторією джерела'
      },
      VIEWS: {
        ru: 'По среднему количеству просмотров',
        en: 'By average number of views',
        ro: 'După numărul mediu de vizualizări',
        uk: 'За середньою кількістю переглядів'
      },
      CREATORS_AUDIENCE: {
        ru: 'По аудитории автора',
        en: 'By the author\'s audience',
        ro: 'De către autorul autorului',
        uk: 'За аудиторією автора'
      },
      ENGAGEMENT: {
        ru: 'По вовлеченности',
        en: 'By engagement',
        ro: 'Bi engagement',
        uk: 'За залученістю'
      },
      BY_VIEWS: {
        ru: 'По просмотрам',
        en: 'By views',
        ro: 'După vizualizări',
        uk: 'За переглядами'
      },
      BY_POTENTIAL_REACH: {
        ru: 'По аудитории',
        en: 'By audience',
        ro: 'În funcție de audiență',
        uk: 'За аудиторією'
      },
      PLACE_AUDIENCE: {
        ru: 'По аудитории места публикации',
        en: 'By audience of the place of publication',
        ro: 'De publicul locului de publicare',
        uk: 'За аудиторією місця публікації'
      },
      SOCIAL_NETWORKS: {
        ru: 'Социальные сети',
        en: 'Social media',
        ro: 'Rețele socială',
        uk: 'Соціальні мережі'
      },
      MEDIA: {
        ru: 'СМИ (Медиа)',
        en: 'Media',
        ro: 'Media',
        uk: 'ЗМІ (Медіа)'
      }
    },
    ordering: {
      DATE_NEWEST: {
        ru: 'По дате (сначала новые)',
        en: 'By date (newest first)',
        ro: 'Până la data (cel mai nou prim)',
        uk: 'За датою (спочатку нові)'
      },
      BY_DATE_NEWEST_FIRST: {
        ru: 'По дате (сначала новейшие)',
        en: 'By date (newest first)',
        ro: 'După dată (cele mai noi mai întâi)',
        uk: 'За датою (спочатку найновіші)'
      },
      BY_DATE_OLDEST_FIRST: {
        ru: 'По дате (сначала старейшие)',
        en: 'By date (oldest first)',
        ro: 'După dată (cele mai vechi mai întâi)',
        uk: 'За датою (спочатку найстаріші)'
      },
      DATE_OLDEST: {
        ru: 'По дате (сначала старые)',
        en: 'By date (oldest first)',
        ro: 'Până la data (cea mai veche mai întâi)',
        uk: 'За датою (спочатку старі)'
      },
      DUBLICATES: {
        ru: 'По количеству дублей',
        en: 'By number of duplicates',
        ro: 'După numărul de duplicate',
        uk: 'За кількістю дублів'
      },
      AUDIENCE: {
        ru: 'По аудитории источника (СМИ)',
        en: 'By source audience (media)',
        ro: 'De către audiența sursă (media)',
        uk: 'За аудиторією джерела (ЗМІ)'
      },
      VIEWS: {
        ru: 'По среднему количеству просмотров (СМИ)',
        en: 'By average number of views (media)',
        ro: 'După numărul mediu de vizualizări (media)',
        uk: 'За середньою кількістю переглядів (ЗМІ)'
      },
      CREATORS_AUDIENCE: {
        ru: 'По аудитории автора (соцсети)',
        en: 'By the author\'s audience (social)',
        ro: 'De către autorul autorului (rețele sociale)',
        uk: 'За аудиторією автора (соцмережі)'
      },
      ENGAGEMENT: {
        ru: 'По вовлеченности (соцсети)',
        en: 'By engagement (social)',
        ro: 'Prin implicare (rețele sociale)',
        uk: 'По залученості (соцмережі)'
      },
      BY_VIEWS: {
        ru: 'По просмотрам (соцсети)',
        en: 'By views (social)',
        ro: 'După vizualizări (rețele sociale)',
        uk: 'За переглядами'
      },
      BY_POTENTIAL_REACH: {
        ru: 'По аудитории (соцсети)',
        en: 'By audience (social)',
        ro: 'În funcție de audiență (rețele sociale)',
        uk: 'За аудиторією'
      },
      BY_MOST_POPULAR: {
        ru: 'По популярности (сначала самые популярные)',
        en: 'By popularity (most popular first)',
        ro: 'După popularitate (cel mai popular mai întâi)',
        uk: 'За популярністю (спочатку найпопулярніші)'
      },
      BY_LESS_POPULAR: {
        ru: 'По популярности (сначала менее популярные)',
        en: 'By popularity (less popular first)',
        ro: 'După popularitate (mai puțin popular mai întâi)',
        uk: 'За популярністю (спочатку менш популярні)'
      },
      PLACE_AUDIENCE: {
        ru: 'По аудитории места публикации (соцсети)',
        en: 'By audience of the place of publication (social)',
        ro: 'De publicul locului de publicare (rețele sociale)',
        uk: 'За аудиторією місця публікації (соцмережі)'
      },
      SOURCE_ATTENDANCE: {
        ru: 'По посещаемости источника',
        en: 'By source attendance',
        ro: 'După prezența sursei',
        uk: 'За відвідуваністю джерела'
      }
    },
    iconsBlock: {
      READ_ALL: {
        ru: 'Отметить все, как прочитанное',
        en: 'Mark all as read',
        ro: 'Marcheaza totul ca fiind citit',
        uk: 'Позначити все, як прочитане'
      },
      EXPAND: {
        ru: 'Развернуть список публикаций',
        en: 'Expand the list of publications',
        ro: 'Extindeți lista de publicații',
        uk: 'Розгорнути список публікацій'
      },
      DOWNLOAD: {
        ru: 'Скачать отчет',
        en: 'Download report',
        ro: 'Descărcați raportul',
        uk: 'Завантажити звіт'
      },
      SEARCH: {
        ru: 'Поиск по публикациям',
        en: 'Publication search',
        ro: 'Căutare publicații',
        uk: 'Пошук за публікаціями'
      },
      CHOOSE_TEMPLATE: {
        ru: 'Выберите шаблон',
        en: 'Choose a template',
        ro: 'Alegeți un șablon',
        uk: 'Виберіть шаблон'
      }
    },
    LoginPage: {
      SIGN_IN: { ru: 'Войдите', en: 'Sign in', uk: 'Увійдіть', ro: 'Conectare' },
      ACCOUNT: {
        ru: 'в свой аккаунт',
        en: 'to your account',
        ro: 'în contul tău',
        uk: 'в свій аккаунт'
      },
      USERNAME: {
        ru: 'Имя пользователя',
        en: 'Username',
        ro: 'Nume de utilizator',
        uk: "Ім'я користувача"
      },
      PASSWORD: { ru: 'Пароль', en: 'Password', uk: 'Пароль', ro: 'Parola' },
      ENTER: { ru: 'Войти', en: 'Log in', uk: 'Увійти', ro: 'Log in' },
      FORGOT_PASS: {
        ru: 'Забыли пароль?',
        en: 'Forgot password??',
        ro: 'Aţi uitat parola??',
        uk: 'Забули пароль?'
      },
      RESET_TEXT: {
        ru:
          'Введите свой адрес электронной почты, и мы пришлем вам ссылку для сброса пароля',
        en:
          'Introduceți adresa de e -mail și vă vom trimite un link pentru a vă reseta parola',
        ro:
          'Enter your email address and we will send you a link to reset your password',
        uk:
          'Введіть свою адресу електронної пошти, і ми надішлемо вам посилання для відновлення пароля'
      },
      SEND_LINK: {
        ru: 'Отправить ссылку',
        en: 'Send a link',
        ro: 'Trimiteți un link',
        uk: 'Надіслати посилання'
      },
      RESEND_TEXT: {
        ru:
          'На указанный адрес электронной почты, было отправлено письмо с инструкциями по сбросу пароля. Если вы не получили электронное письмо, убедитесь, что ввели корректную почту и проверьте папку со спамом. ',
        en:
          ' "Am trimis instrucțiuni de resetare a parolei la adresa dvs. de e -mail. Dacă nu este primit niciun e -mail în termen de zece minute, verificați dacă adresa trimisă este corectă sau verificați folderul de spam.",',
        ro:
          "We've sent password reset instructions to your email address. If no email is received within ten minutes, check that the submitted address is correct or check your spam folder.",
        uk:
          'На вказану адресу електронної пошти, було відправлено лист з інструкціями щодо відновлення паролю. Якщо ви не отримали електронного листа, переконайтеся, що ввели коректну пошту або перевірте папку зі спамом.'
      },
      RESEND_LINK: {
        ru: 'Отправить еще раз',
        en: 'Send again',
        ro: 'Trimite din nou',
        uk: 'Відправити ще раз'
      },
      CANCEL_RESET_PASS: {
        ru: 'Отменить',
        en: 'Cancel',
        ro: 'Anulare',
        uk: 'Відмінити'
      },
      SUBSCRIBE: {
        ru: 'Оформить подписку',
        en: 'Subscribe',
        ro: 'Abonati-va',
        uk: 'Оформити підписку'
      },
      ENTER_EMAIL: {
        ru: 'Введите адрес электронной почты',
        en: 'Enter your e-mail',
        ro: 'Introduceți adresa dvs. de email',
        uk: 'Введіть адресу електронної пошти'
      }
    },
    feedSettings: {
      SETTING_FEED: {
        ru: 'Настройки ленты',
        en: 'Feed settings',
        ro: 'Setări de alimentare',
        uk: 'Налаштування стрічки'
      },
      BLOCKED_SOURCE: {
        ru: 'Заблокированные источники',
        en: 'Blocked sources',
        ro: 'Surse blocate',
        uk: 'Заблоковані джерела'
      },
      NO_BLOCKED_SOURCE: {
        ru: 'Нет заблокированных источников',
        en: 'No blocked sources',
        ro: 'Fără surse blocate',
        uk: 'Немає заблокованих джерел'
      },
      NO_BLOCKED_CREATORS: {
        ru: 'Нет заблокированных авторов',
        en: 'No blocked authors',
        ro: 'Nu există autori blocați',
        uk: 'Немає заблокованих авторів'
      },
      NO_BLOCKED_PLACES: {
        ru: 'Нет заблокированных мест публикаций',
        en: 'No blocked publication places',
        ro: 'Fără locuri de publicare blocate',
        uk: 'Немає заблокованих місць публікацій'
      },
      BLOCKED_CREATORS: {
        ru: 'Заблокированные автор',
        en: 'Blocked authors',
        ro: 'Autori blocați',
        uk: 'Заблоковані автори'
      },
      BLOCKED_PLACES: {
        ru: 'Заблокированные места публикации',
        en: 'Blocked publication places',
        ro: 'Locuri de publicare blocate',
        uk: 'Заблоковані місця публікації'
      },
      KW_NUMBER: {
        ru: 'Количество новостей под КС:',
        en: 'Number of news under the KW:',
        ro: 'Numărul de știri sub KW:',
        uk: 'Кількість новин під КС:'
      },
      UNBLOCK: {
        ru: 'Разблокировать',
        en: 'Unblock',
        ro: 'Deblocați',
        uk: 'Розблокувати'
      }
    },
    userProfile: {
      SETTING_PROFILE: {
        ru: 'Настройки профиля',
        en: 'Profile settings',
        ro: 'Setarile profilului',
        uk: 'Налаштування профілю'
      },
      SETTING_AUTH: {
        ru: 'Настройки аутентификации',
        en: 'Authentication settings',
        ro: 'Setări de autentificare',
        uk: 'Налаштування аутентифікації'
      },
      SETTING_FEED: {
        ru: 'Настройки ленты',
        en: 'Feed settings',
        ro: 'Setări de alimentare',
        uk: 'Налаштування стрічки'
      },
      PERSONAL_SETTING: {
        ru: 'Персональные настройки',
        en: 'Personal settings',
        ro: 'Setari personale',
        uk: 'Персональні налаштування'
      },
      ENTER_USERNAME: {
        ru: 'Введите имя пользователя',
        en: 'Enter your username',
        ro: 'Introduceți numele de utilizator',
        uk: "Введіть ім'я користувача"
      },
      CLARIFICATION: {
        ru:
          'Обязательное поле. Не более 30 символов. Только буквы, цифры и символы @/./+/-/_.',
        en:
          'Required field. No more than 30 characters. Only letters, numbers and symbols @ /. / + / - / _.',
        ro:
          'Câmp necesar.Nu mai mult de 30 de caractere.Doar litere, numere și simboluri @ /./ + / - / _.',
        uk:
          "Обов'язкове поле. Не більше 30 символів. Тільки букви, цифри і символи @ /. / + / - / _."
      },
      LANGUAGE: {
        ru: 'Язык',
        en: 'Language',
        ro: 'Limba',
        uk: 'Мова'
      },
      PASSWORD_OLD: {
        ru: 'Старый пароль',
        en: 'Old password',
        ro: 'Parola veche',
        uk: 'Старий пароль'
      },
      ENTER_PASSWORD_OLD: {
        ru: 'Введите старый пароль',
        en: 'Enter your old password',
        ro: 'Introduceți vechea parolă',
        uk: 'Введіть старий пароль'
      },
      FIRSTNAME: {
        ru: 'Имя',
        en: 'First name',
        ro: 'Nume',
        uk: "Ім'я"
      },
      ENTER_FIRSTNAME: {
        ru: 'Введите имя',
        en: 'Enter your first name',
        ro: 'Introduceți prenumele',
        uk: "Введіть ім'я"
      },
      LASTNAME: {
        ru: 'Фамилия',
        en: 'Last name',
        ro: 'Nume',
        uk: 'Прізвище'
      },
      ENTER_LASTNAME: {
        ru: 'Введите фамилию',
        en: 'Enter your last name',
        ro: 'Introduceți numele de familie',
        uk: 'Введіть прізвище'
      },
      TIMEZONE: {
        ru: 'Часовой пояс',
        en: 'Timezone',
        ro: 'Fus orar',
        uk: 'Часовий пояс'
      },
      EMAIL: {
        ru: 'Адрес электронной почты',
        en: 'E-mail address',
        ro: 'Adresa de e-mail',
        uk: 'Адреса електронної пошти'
      },
      ENTER_EMAIL: {
        ru: 'Введите адрес электронной почты',
        en: 'Enter your e-mail',
        ro: 'Introduceți adresa dvs. de email',
        uk: 'Введіть адресу електронної пошти'
      }
    },
    createFirstRelease: {
      ADD_TITLE: {
        ru: 'Добавьте название к вашему инфоповоду',
        en: 'Add a title to your campaign',
        ro: 'Adăugați un titlu la campania dvs.',
        uk: 'Додайте назву до вашого інфоприводу'
      },
      ADD_VERSION_TITLE: {
        ru: 'Добавьте название к новому варианту инфоповода',
        en: 'Add a title to the new version of the campaign',
        ro: 'Adăugați un titlu la noua versiune a campaniei',
        uk: 'Додайте назву до нового варіанту інфопривода'
      },
      ADD_TITLE_HEADING: {
        ru: 'Добавьте название',
        en: 'Add a title',
        ro: 'Adăugați un titlu',
        uk: 'Додайте назву'
      },
      ADD_HEADING: {
        ru: 'Добавьте заголовок',
        en: 'Add a headline',
        ro: 'Adăugați un titlu',
        uk: 'Додайте заголовок'
      },

      EDIT_TEXT: {
        ru: 'Редактировать текст',
        en: 'Edit text',
        ro: 'Editează textul',
        uk: 'Редагувати текст'
      },
      ADD_NEW_VERSION: {
        ru: 'Добавьте новый вариант инфоповода',
        en: 'Add a new version of the campaign',
        ro: 'Adăugați o nouă versiune a campaniei',
        uk: 'Додайте новий варіант інфоприводу'
      },
      CREATE_RELEASE: {
        ru: 'Создайте новый инфоповод',
        en: 'Create a new campaign',
        ro: 'Creați o nouă campanie',
        uk: 'Створіть новий інфопривід'
      },
      ADD_TEXT: {
        ru: 'Или добавьте ниже текст вашего инфоповода:',
        en: 'Or add your campaign text below:',
        ro: 'Sau adăugați textul campaniei de mai jos:',
        uk: 'Або додайте нижче текст вашого інфопривода:'
      },
      SEARCHING_RELEASE: {
        ru: 'Идет поиск вашего инфоповода',
        en: 'Searching for your campaign',
        ro: 'Căutarea campaniei dvs.',
        uk: 'Йде пошук вашого інфопривода'
      },
      FOUND: { ru: 'Найдено:', en: 'Found:', uk: 'Знайдено:', ro: 'Găsite:' },
      NOT_FOUND: {
        ru: 'Не найдено! Попробуйте воспользоваться поиском по тексту',
        en: 'Not found! Try using text search',
        ro: 'Nu a fost găsit!Încercați să utilizați căutare de text',
        uk: 'Не знайдено! Спробуйте скористатися пошуком по тексту'
      },
      NOT_FOUND_TEXT: {
        ru: 'Мы не нашли инфоповодов с данным текстом',
        en: 'We did not find any campaign with this text',
        ro: 'Nu am găsit nicio campanie cu acest text',
        uk: 'Ми не знайшли інфоприводів з даним текстом'
      },
      NAME: { ru: 'Название', en: 'Name', uk: 'Назва', ro: 'Nume' },
      TITLE: { ru: 'Заголовок', en: 'Title', uk: 'Заголовок', ro: 'Titlu' },
      TEXT: {
        ru: 'Текст инфоповода',
        en: 'Campaign text',
        ro: 'Text de campanie',
        uk: 'Текст інфопривода'
      },
      CANCEL: { ru: 'Отменить', en: 'Cancel', uk: 'Скасувати', ro: 'Anulare' },
      SEARCH: { ru: 'Поиск', en: 'Search', uk: 'Пошук', ro: 'Căutare' },
      SAVE: { ru: 'Сохранить', en: 'Save', uk: 'Зберегти', ro: 'Salvați' }
    },
    tooltip: {
      AUDIENCE: {
        ru: 'Суммарная аудитория источника за месяц',
        en: 'Monthly source audience',
        ro: 'Publicul sursă lunar',
        uk: 'Місячна аудиторія джерела'
      },
      CIRCULATION: {
        ru: 'Тираж',
        en: 'Сirculation',
        ro: 'Originea',
        uk: 'Тираж'
      },
      VIEWING: {
        ru: 'Среднее количество просмотров материала на этом источнике',
        en: 'Average views on this source',
        ro: 'Vizualizări medii asupra acestei surse',
        uk: 'Середня кількість переглядів новини на цьому джерелі'
      },
      VIEW: {
        ru: 'Просмотры',
        en: 'Views',
        ro: 'Vizualizări',
        uk: 'Перегляди'
      },
      COMMENTS: {
        ru: 'Комментарии',
        en: 'Comments',
        ro: 'Comentarii',
        uk: 'Коментарі'
      },
      RATING: {
        ru: 'Рейтинг',
        en: 'Rating',
        ro: 'Rating',
        uk: 'Рейтинг'
      },
      AUDIENCE_CREATOR: {
        ru: 'Аудитория автора',
        en: 'Author subscribers',
        ro: 'Abonați de autor',
        uk: 'Аудитория автора'
      },
      ENGAGEMENT: {
        ru: 'Вовлеченность',
        en: 'Engagement',
        ro: 'Logodnă',
        uk: 'Залученість'
      },
      POTENTIAL_REACH: {
        ru: 'Аудитория',
        en: 'Audience',
        ro: 'Audiența',
        uk: 'Аудиторія'
      },
      DOUBLES: {
        ru: 'Дубли',
        en: 'Duplicates',
        ro: 'Duplicate',
        uk: 'Дублі'
      },
      PROCESSED: {
        ru: 'Обработанные',
        en: 'Processed',
        ro: 'Procesat',
        uk: 'Оброблені'
      },
      UNPROCESSED: {
        ru: 'Необработанные',
        en: 'Unprocessed',
        ro: 'Neprocesat',
        uk: 'Необроблені'
      },
      ADD_PROCESSED: {
        ru: 'Добавить в обработанное',
        en: 'Add to processed',
        ro: 'Adăugați la procesat',
        uk: 'Додати до обробленого'
      },
      ADD_UNPROCESSED: {
        ru: 'Добавить в необработанное',
        en: 'Add to unprocessed',
        ro: 'Adăugați la neprocesat',
        uk: 'Додати до необробленого'
      },
      POSITIVE: {
        ru: 'Позитив',
        en: 'Positive',
        ro: 'Pozitiv',
        uk: 'Позитив'
      },
      NEUTRAL: {
        ru: 'Нейтрал',
        en: 'Neutral',
        ro: 'Neutru',
        uk: 'Нейтрал'
      },
      NEGATIVE: {
        ru: 'Негатив',
        en: 'Negative',
        ro: 'Negativ',
        uk: 'Негатив'
      },
      MINOR: {
        ru: 'Второстепенная',
        en: 'Secondary',
        ro: 'Secundar',
        uk: 'Другорядна'
      },
      COMPARE_DISABLED: {
        ru: 'Функция сравнения доступна только в интерфейсе Дашбордов',
        en: 'The comparison feature is only available in the Dashboards interface',
        ro: 'Funcția de comparație este disponibilă numai în interfața Tablouri de bord',
        uk: 'Функція порівняння доступна лише в інтерфейсі Дашбордів'
      },
      SHARE: {
        ru: 'Поделиться',
        en: 'Share',
        ro: 'Acțiune',
        uk: 'Поділитися'
      },
      BY_DATE: {
        ru: 'По дате',
        en: 'By date',
        ro: 'După dată',
        uk: 'За датою'
      },
      RELEASE: {
        ru: 'Выхода',
        en: 'Release',
        ro: 'Eliberare',
        uk: 'Виходу'
      },
      PRODUCTION: {
        ru: 'Постановки',
        en: 'Proceed',
        ro: 'Continua',
        uk: 'Постановки'
      },
      AT_SOURCE: {
        ru: ' статья была опубликована на источнике',
        en: ' the article was published on the source',
        ro: ' articolul a fost publicat pe sursă',
        uk: ' стаття була опублікована на джерелі'
      },
      AT_LOOQME: {
        ru: ' статья появилась в системе LOOQME',
        en: ' the article аdded in the LOOQME system',
        ro: ' articolul a fost redus în sistemul looqme',
        uk: ' стаття з\'явилася в системі LOOQME'
      },
      AT_METRICOM: {
        ru: ' статья появилась в системе METRICOM',
        en: ' the article аdded in the METRICOM system',
        ro: ' articolul a fost redus în sistemul METRICOM',
        uk: ' стаття з\'явилася в системі METRICOM'
      },
      TONE: {
        ru: 'Тональность',
        en: 'Sentiment',
        ro: 'Tonalitate',
        uk: 'Тональність'
      },
      REACTIONS: {
        ru: 'Реакции',
        en: 'Reactions',
        ro: 'Reacții',
        uk: 'Реакції'
      },
      LIKES: {
        ru: 'Лайки',
        en: 'Likes',
        ro: 'Îi place',
        uk: 'Лайки'
      },
      DISLIKES: {
        ru: 'Дизлайки',
        en: 'Dislikes',
        ro: 'Impuls',
        uk: 'Дизлайки'
      },
      REPOSTS: {
        ru: 'Репосты',
        en: 'Reposts',
        ro: 'Repostează',
        uk: 'Репости'
      },
      REPORT_ENCODING: {
        ru: 'Сообщить о проблеме с кодировкой',
        en: 'Report an encoding problem',
        ro: 'Raportați o problemă de codificare',
        uk: 'Повідомити про проблему з кодуванням'
      },
      VIEWS: {
        ru: 'Просмотры',
        en: 'Views',
        ro: 'Vizualizări',
        uk: 'Перегляди'
      },
      ON_SOURCE: {
        ru: 'На источнике',
        en: 'On the source',
        ro: 'Pe sursă',
        uk: 'На джерелі' },
      APPEARED: {
        ru: 'Появилось в системе',
        en: 'Appeared in the system',
        ro: 'A apărut în sistem',
        uk: 'З’явилося в системі' },
      CREATE_FILTER: { ru: 'Создать новый фильтр', en: 'Create new filter', ro: 'Creați un filtru nou', uk: 'Створити новий фільтр' },
      RESET_FILTERS: { ru: 'Сбросить все фильтры', en: 'Reset all filters', ro: 'Resetați toate filtrele', uk: 'Скинути всі фільтри' },
      AVERAGE_NEWS_VIEWS: {
        ru: 'Среднее количество просмотров новостей на этом источнике',
        en: 'Average news views on this source',
        ro: 'Numărul mediu de vizualizări ale știrilor pe această sursă',
        uk: 'Середня кількість переглядів новини на цьому джерелі'
      },
      MONTHLY_SOURCE_AUDIENCE: {
        ru: 'Месячная аудитория источника',
        en: 'Monthly source audience',
        ro: 'Audiența lunară a sursei',
        uk: 'Місячна аудиторія джерела'
      },
      RATE: {
        ru: 'Оценка',
        en: 'Rate',
        ro: 'Rata',
        uk: 'Оцінка'
      },
      PUBLICATION_PLACE_RATE: {
        ru: 'Рейтинг места публикации',
        en: 'Rating of the publication place',
        ro: 'Ratingul locului de publicare',
        uk: 'Рейтинг місця публікації'
      }
    },
    details: {
      KEYWORDS: {
        ru: 'Ключевые слова в публикации',
        en: 'Keywords in the publication',
        ro: 'Cuvinte cheie din publicație',
        uk: 'Ключові слова в публікації'
      },
      DUPLICATES: {
        ru: 'Дубли',
        en: 'Duplicates',
        ro: 'Duplicate',
        uk: 'Дублі'
      },
      ORIGINAL: {
        ru: 'Оригинал публикации',
        en: 'Original publication',
        ro: 'Publicare originală',
        uk: 'Оригінал публікації'
      },
      FAVORITE: {
        ru: 'Избранная публикация',
        en: 'Favorite publication',
        ro: 'Publicația preferată',
        uk: 'Вибрана публікація'
      },
      NOT_SUPPORTED: {
        ru: 'Ваш браузер не поддерживает данный тип файлов.',
        en: 'Ваш браузер не підтримує цей тип файлів.',
        ro: 'Browserul dvs. nu acceptă acest tip de fișier.',
        uk: 'Your browser does not support this file type.'
      },
      TRANSLATE: {
        ru: 'Перевести',
        en: 'Translate',
        ro: 'Traduceți',
        uk: 'Перекласти'
      },
      SHOW_ORIGINAL: {
        ru: 'Показать оригинал',
        en: 'Show original',
        ro: 'Afișați originalul',
        uk: 'Показати оригінал'
      },
      INDICATORS: {
        ru: 'Показатели',
        en: 'Indicators',
        ro: 'Indicatori',
        uk: 'Показники'
      },
      CONTENT: {
        ru: 'Содежание',
        en: 'Content',
        ro: 'Conținut',
        uk: 'Вміст'
      },
      PUBLICATION_CONTEXT: {
        ru: 'Контекст упоминания',
        en: 'Publication context',
        ro: 'Contextul publicației',
        uk: 'Контекст згадки'
      },
      POSTS_CHAIN: {
        ru: 'Просмотр цепочки публикации',
        en: 'Viewing the post thread',
        ro: 'Vizualizarea firului postării',
        uk: 'Перегляд ланцюжка публікації'
      }
    },
    error: {
      ERR_REQUIRED: {
        ru: '* Обязательное поле',
        en: '* This field is required',
        ro: '* Acest câmp este obligatoriu',
        uk: '* Обов\'язкове поле'
      },
      ERR_REQUIRED_ALL: {
        ru: '* Заполните все обязательные поля',
        en: '* Should fill in required fields',
        ro: '* Ar trebui să completeze câmpurile necesare',
        uk: '* Заповніть усі обов\'язкові всі поля'
      },
      ERR_TAG_INTERVAL: {
        ru: 'Максимум 3 отдельных слова',
        en: 'Maximum of 3 separate words',
        ro: 'Maxim 3 cuvinte separate',
        uk: 'Максимум 3 окремих слова'
      },
      MIN_WORDS: {
        ru: 'Минимум 1 альтернативное слово',
        en: 'At least 1 alternative word',
        ro: 'Cel puțin 1 cuvânt alternativ',
        uk: 'Мінімум 1 альтернативне слово'
      },
      MIN_SYMBOLS: {
        ru: 'Минимум 2 символа',
        en: 'Minimum 2 characters',
        ro: 'Minim 2 caractere',
        uk: 'Мінімум 2 символи'
      },
      EXAMPLE: {
        ru: 'Например',
        en: 'For example',
        ro: 'De exemplu',
        uk: 'Наприклад'
      },
      NO_CLOSE_BRACKET: {
        ru: 'Отсутствует закрывающая дужка',
        en: 'No closing bracket',
        ro: 'Fără suport de închidere',
        uk: 'Відсутня закриваюча дужка'
      },
      NO_OPEN_BRACKET: {
        ru: 'Отсутствует открывающая дужка',
        en: 'No opening bracket',
        ro: 'Fără suport de deschidere',
        uk: 'Відсутня відкриваюча дужка'
      },
      NO_BRACKET_EXAMPLE: {
        ru: '/Шевченк.{1,4/ или /Пе[вец.{0,2}/',
        en: '/Shevchenko.{1,4/ or /Art[is.{0,2}/',
        ro: '/Shevchenko.{1,4/ sau /cântărea[ță.{0,2}/',
        uk: '/Шевченк.{1,4/ або /Сп[иівак.{0,2}/'
      },
      POINT_COMMA: {
        ru: 'Точка вместо запятой',
        en: 'Point instead of comma',
        ro: 'Punct în loc de virgulă',
        uk: 'Крапка замість коми'
      },
      POINT_COMMA_EXAMPLE: {
        ru: '/Шевченко.{0.2}/',
        en: '/Shevchenko.{0.2}',
        ro: '/Shevchenko.{0.2}',
        uk: '/Шевченко.{0.2}/'
      },
      NO_SLASH: {
        ru: 'Отсутствует или лишний открывающий или закрывающий слэш',
        en: 'Missing or unnecessary opening or closing slash',
        ro: 'Deschiderea sau închiderea sau închiderea inutilă',
        uk: 'Відсутній або зайвий відкриваючий або закриваючий слеш '
      },
      NO_SLASH_EXAMPLE: {
        ru: '//Шевченко.{0,2}/',
        en: '//Shevchenko.{0,2}/',
        ro: '//Shevchenko.{0,2}/',
        uk: '//Шевченко.{0,2}/'
      },
      QUOTE_MARK: {
        ru: 'Отсутствует кавычка, лишние кавычки',
        en: 'Missing or extra quotation mark',
        ro: 'Marcaj de cotație lipsă sau suplimentar',
        uk: 'Відсутня лапка, зайві лапки'
      },
      QUOTE_MARK_EXAMPLE: {
        ru: '" "/Тарас.{0,2}/ /Шевченко.{0,2}/"',
        en: '" "/Taras.{0,2}/ /Shevchenko.{0,2}/"',
        ro: '" "/Taras.{0,2}/ /Shevchenko.{0,2}/"',
        uk: '" "/Тарас.{0,2}/ /Шевченко.{0,2}/"'
      },
      SEARCH_WORD_AFTER: {
        ru: 'Отсутствует поисковое слово после :',
        en: 'Missing search word after :',
        ro: 'Lipsește cuvântul de căutare după:',
        uk: 'Відсутнє пошукове слово після :'
      },
      SEARCH_WORD_AFTER_EXAMPLE: {
        ru: '/Шевченко.{0,2}/ text: ',
        en: '/Shevchenko.{0,2}/ text: ',
        ro: '/Shevchenko.{0,2}/ text: ',
        uk: '/Шевченко.{0,2}/ text: '
      },
      SEARCH_WORD_BEFORE: {
        ru: 'Отсутствует название поля перед :',
        en: 'The name of the field before :',
        ro: 'Numele câmpului înainte:',
        uk: 'Відсутня назва поля перед :'
      },
      SEARCH_WORD_BEFORE_EXAMPLE: {
        ru: ' : /Шевченко.{0,2}/',
        en: ' : /Shevchenko.{0,2}/',
        ro: ' : /Shevchenko.{0,2}/',
        uk: ' : /Шевченко.{0,2}/'
      },
      NO_SPACE_BEFORE: {
        ru: 'Отсутствует пробел перед оператором',
        en: 'No space before the operator',
        ro: 'Fără spațiu înainte de operator',
        uk: 'Відсутній пробіл перед оператором'
      },
      NO_SPACE_BEFORE_EXAMPLE: {
        ru: 'AND /Франко.{0,2}/',
        en: 'AND /Franko.{0,2}/',
        ro: 'AND /Franko.{0,2}/',
        uk: 'AND /Франко.{0,2}/'
      },
      NO_SPACE_AFTER: {
        ru: 'Отсутствует пробел после оператора',
        en: 'No space after the operator',
        ro: 'Fără spațiu după operator',
        uk: 'Відсутній пробіл після оператора'
      },
      NO_SPACE_AFTER_EXAMPLE: {
        ru: '/Шевченко.{0,2}/ AND',
        en: '/Shevchenko.{0,2}/ AND',
        ro: '/Shevchenko.{0,2}/ AND',
        uk: '/Шевченко.{0,2}/ AND'
      },
      NO_SEARCH_BEFORE: {
        ru: 'Отсутствует поисковое слово перед оператором',
        en: 'No search word before the operator',
        ro: 'Niciun cuvânt de căutare înaintea operatorului',
        uk: 'Відсутнє пошукове слово перед оператором'
      },
      NO_SEARCH_BEFORE_EXAMPLE: {
        ru: ' AND /Франко.{0,2}/',
        en: ' AND /Franko.{0,2}/ ',
        ro: ' AND /Franko. [02}/',
        uk: ' AND /Франко.{0,2}/'
      },
      NO_SEARCH_AFTER: {
        ru: 'Отсутствует поисковое слово после оператора',
        en: 'No search word after the operator',
        ro: 'Niciun cuvânt de căutare după operator',
        uk: 'Відсутнє пошукове слово після оператора'
      },
      NO_SEARCH_AFTER_EXAMPLE: {
        ru: '/Шевченко.{0,2}/ AND ',
        en: '/Shevchenko.{0,2}/ AND ',
        ro: '/ShevChenko. [02}/ AND ',
        uk: '/Шевченко.{0,2}/ AND '
      },
      MAX_SYMBOLS: {
        ru: 'Превышено максимальное количество символов',
        en: 'Maximum number of characters exceeded',
        ro: 'Numărul maxim de caractere depășite',
        uk: 'Перевищено максимальну кількість символів'
      },
      NAME_ERROR: {
        ru: 'Название ключевого слова не совпадает',
        en: 'The name of the keyword does not match',
        ro: 'Numele cuvântului cheie nu se potrivește',
        uk: 'Назва ключового слова не співпадає'
      },
      ALREADY_ADDED: {
        ru: 'Уже добавлено такое слово',
        en: 'Such a word has already been added',
        ro: 'Un astfel de cuvânt a fost deja adăugat',
        uk: 'Вже додано таке слово  '
      },
      ERR_DATE: {
        ru: 'Дата окончания не может быть раньше даты начала',
        en: 'The end date cannot be earlier than the start date',
        ro: 'Data de sfârșit nu poate fi mai devreme decât data de început',
        uk: 'Дата окончания не может быть раньше даты начала'
      },
      ERR_LOAD: {
        ru: 'Ошибка загрузки данных',
        en: 'Data loading error',
        ro: 'Eroare de încărcare a datelor',
        uk: 'Помилка завантаження даних'
      },
      ERR_TEXT: {
        ru: 'Извините, что-то пошло не так...',
        en: 'Sorry, something went wrong...',
        ro: 'Scuze, ceva a mers greșit...',
        uk: 'Вибачте, щось пішло не так...'
      },
      ERR_WIDGET: {
        ru: 'Ошибка виджета',
        en: 'Widget error',
        ro: 'Eroare widget',
        uk: 'Помилка віджета'
      },
      ERR_DATA: {
        ru: 'ошибка данных виджета',
        en: 'widget data error',
        ro: 'eroare de date widget',
        uk: 'помилка даних віджета'
      },
      ERR_USERNAME: {
        ru: 'Неверное имя пользователя',
        en: 'Incorrect username',
        ro: 'Nume utilizator incorect',
        uk: "Неправильне ім'я користувача"
      },
      ALREADY_EXISTS: {
        ru: 'Email already exists',
        en: 'Email already exists',
        ro: 'E-mail există deja',
        uk: 'Email already exists'
      },
      HUB_AUTH_ERROR: {
        ru: 'Unable to log in using hub',
        en: 'Unable to log in using hub',
        ro: 'Imposibil de conectat folosind hub',
        uk: 'Unable to log in using hub'
      },
      ERR_PASS: {
        ru: 'Неверный пароль',
        en: 'Incorrect password login',
        ro: 'Conectare incorectă a parolei',
        uk: 'Неправильний пароль'
      },
      ERR_TRIAL: {
        ru: 'У вас закончился тестовый период',
        en: 'Your trial has ended',
        ro: 'Perioada de încercare a expirat',
        uk: 'У вас закінчився тестовий період'
      },
      ERR_SERVER: {
        ru: 'Проблемы с сервером, попробуйте еще раз',
        en: 'Server is not responding, please try again',
        ro: 'Serverul nu răspunde, vă rugăm să încercați din nou',
        uk: 'Проблеми з сервером, спробуйте ще раз'
      },
      ERR_DIFFERENT_PASS: {
        ru: 'Пароли не совпадают',
        en: 'Passwords do not match',
        ro: 'parolele nu se potrivesc',
        uk: 'Паролі не співпадають'
      },
      ERR_REGISTRATION: {
        ru: 'Мы не нашли зарегистрированных пользователей с этой почтой',
        en: 'We have not found registered users with this e-mail',
        ro: 'Nu am găsit utilizatori înregistrați la acest e-mail',
        uk: 'Ми не знайшли зареєстрованих користувачів з цією поштою'
      },
      ERR_LINK: {
        ru:
          'Ссылка на сброс пароля недействительна. Пожалуйста, запросите новый сброс пароля.',
        ro:
          'Linkul de resetare a parolei nu este valid. Vă rugăm să urmați din nou procedura de resetare a parolei.',
        en:
          'Link to reset password is invalid. Please request a new password reset.',
        uk:
          'Посилання на скидання пароля недійсне. Будь ласка, пройдіть процедуру скидання пароля заново.'
      },
      ERR_BIG_PHOTO: {
        ru: 'Изображение должно быть меньше 2 МБ!',
        en: 'Image must smaller than 2MB!',
        ro: 'Imaginea trebuie să fie mai mică de 2 MB!',
        uk: 'Розмір зображення має бути менше 2 МБ!'
      },
      ERR_FORMAT_PHOTO: {
        ru: 'Вы можете загружать только файлы JPG/PNG!',
        en: 'You can only upload JPG/PNG file!',
        ro: 'Puteți încărca doar fișierul JPG/PNG!',
        uk: 'Ви можете завантажувати лише файл JPG/PNG!'
      },
      ERR_EMAIL: {
        ru: 'Ошибка. Не более 30 символов. Только буквы, цифры и символы @/./+/-/_.',
        en: 'Error. No more than 30 characters. Only letters, numbers and @/./+/-/_ symbols.',
        ro: 'Eroare. Nu mai mult de 30 de caractere. Numai litere, numere și simboluri @/./+/-/_.',
        uk: 'Помилка. Не більше 30 символів. Тільки букви, цифри та символи @/./+/-/_.'
      },
      ERR_EMAIL_ADD: {
        ru: 'Введите почту или же удалите получателя',
        en: 'Enter the email or delete the recipient',
        ro: 'Introduceți e-mailul sau ștergeți destinatarul',
        uk: 'Введіть пошту або ж видаліть отримувача'
      },
      ERR_EMAIL_REQUIRED: {
        ru:
          'Обязательное поле. Не более 30 символов. Только буквы, цифры и символы @/./+/-/_.',
        en:
          'Câmp necesar.Nu mai mult de 30 de caractere.Doar litere, numere și simboluri @ /./ + / - / _.',
        ro:
          'Required field. No more than 30 characters. Only letters, numbers and symbols @ /. / + / - / _.',
        uk:
          "Обов'язкове поле. Не більше 30 символів. Тільки букви, цифри і символи @ /. / + / - / _."
      },
      MAX_NAME_LENGTH_100: {
        ru: 'Максимальная длина названия  - 100 символов',
        en: 'The maximum length name is 100 characters',
        ro: 'Lungimea maximă  este de 100 de caractere.',
        uk: 'Максимальна довжина назви - 100 символів'
      },
      MAX_ALERT_LENGTH_100: {
        ru: 'Максимальная длина названия оповещения - 100 символов',
        en: 'The maximum length of the notification name is 100 characters',
        ro: 'Lungimea maximă a denumirii notificării este de 100 de caractere.',
        uk: 'Максимальна довжина назви сповіщення - 100 символів'
      },
      MAX_REPORT_LENGTH_100: {
        ru: 'Максимальная длина названия отчета - 100 символов',
        en: 'The maximum length of the report title is 100 characters',
        ro: 'Lungimea maximă a titlului raportului este de 100 de caractere.',
        uk: 'Максимальна довжина назви звіту - 100 символів'
      }
    },
    passwordReset: {
      NEW_PASS: {
        ru: 'Новый пароль',
        en: 'New password',
        ro: 'Parolă Nouă',
        uk: 'Новий пароль'
      },
      CONFIRM_PASS: {
        ru: 'Подтверждение нового пароля',
        en: 'Confirm new password',
        ro: 'Confirmă noua parolă',
        uk: 'Підтвердження нового пароля'
      },
      ENTER_NEW_PASS: {
        ru: 'Введите новый пароль',
        en: 'Enter a new password',
        ro: 'Introduceți o parolă nouă',
        uk: 'Введіть новий пароль'
      },
      SAVE: { ru: 'Сохранить', en: 'Save', uk: 'Зберегти', ro: 'Salvați' }
    },
    profilePhoto: {
      PHOTO: {
        ru: 'Фото профиля',
        en: 'Profile photo',
        ro: 'Fotografie de profil',
        uk: 'Фото профіля'
      },
      CHOOSE_PHOTO: {
        ru: 'Выбрать фото',
        en: 'Choose photo',
        ro: 'Alege fotografia',
        uk: 'Обрати фото'
      }
    },
    notif: {
      TAG_ADDED: {
        ru: 'Тег добавлен',
        en: 'Tag added',
        ro: 'Tag adăugat',
        uk: 'Тег доданий'
      },
      TAG_INCLUDED: {
        ru: 'Все новые упоминания будут содержать данный тег',
        en: 'All new mentions will include this tag',
        ro: 'Toate noile mențiuni vor include această etichetă',
        uk: 'Всі нові згадки будуть містити даний тег'
      },
      TAG_WILL_ADDED: {
        ru: 'В ближайшее время мы проставим теги в упоминаниях по указанным параметрам',
        en: 'We will add tags to mentions based on the specified parameters soon',
        ro: 'Vom adăuga etichete la mențiuni pe baza parametrilor specificați în curând',
        uk: 'Найближчим часом ми проставимо теги в згадках за вказаними параметрами'
      },
      TAG_NOTIF: {
        ru: 'Как только тег будет проставлен, мы сообщим вам',
        en: 'Once the tag is added, we will notify you',
        ro: 'Odată ce eticheta este adăugată, vă vom anunța',
        uk: 'Як тільки тег буде проставлено, ми повідомимо вас'
      },
      TAG_EDITED: {
        ru: 'Тег изменен',
        en: 'Tag changed',
        ro: 'Eticheta schimbată',
        uk: 'Тег змінено'
      },
      TAG_DELETED: {
        ru: 'Тег удален',
        en: 'Tag deleted',
        ro: 'Etichetă ștersă',
        uk: 'Тег видалений'
      },
      KEYWORD_ADDED: {
        ru: 'Ключевое слово добавлен',
        en: 'Keyword added',
        ro: 'Cuvânt cheie adăugat',
        uk: 'Ключове слово додано'
      },
      KEYWORD_EDITED: {
        ru: 'Ключевое слово изменено',
        en: 'Keyword changed',
        ro: 'Cuvânt cheie a fost schimbat',
        uk: 'Ключове слово змінено'
      },
      KEYWORD_DELETED: {
        ru: 'Ключевое слово удалено',
        en: 'Keyword deleted',
        ro: 'Cuvânt cheie șters',
        uk: 'Ключове слово видалено'
      },
      COPY_LINK: {
        ru: 'Cсылка скопирована',
        en: 'The link has been copied to clipboard',
        ro: 'Link copiat',
        uk: 'Посилання скопійоване'
      },
      COPY_EXAMPLE: {
        ru: 'Пример скопирован',
        en: 'Example copied to clipboard',
        ro: 'Exemplu copiat în clipboard',
        uk: 'Приклад скопійований'
      },
      COPY_CHART_TO: {
        ru: 'График скопирован',
        en: 'The chart has been copied',
        ro: 'Graficul a fost copiat',
        uk: 'Графік скопійований'
      },
      DASHBOARD_RENAME: {
        ru: 'Дашборд переименован',
        en: 'Dashboard renamed',
        ro: 'Redenumire de bord',
        uk: 'Дашборд перейменовано'
      },
      DASHBOARD_CREATED: {
        ru: 'Дашборд создан',
        en: 'Dashboard created',
        ro: 'Bord creat',
        uk: 'Дашборд створено'
      },
      DASHBOARD_DELETED: {
        ru: 'Дашборд удален',
        en: 'Dashboard removed',
        ro: 'Tabloul de bord eliminat',
        uk: 'Дашборд видалено'
      },
      PAGE_RENAME: {
        ru: 'Название страницы изменено',
        en: 'Page name changed',
        ro: 'Numele paginii modificat',
        uk: 'Назва сторінки змінено'
      },
      CHANGES_SAVED: {
        ru: 'Изменения сохранены',
        en: 'Changes saved',
        ro: 'schimbarile au fost salvate',
        uk: 'Зміни збережені'
      },
      CREATE_PAGE: {
        ru: 'Страница создана',
        en: 'Page created',
        ro: 'Pagina creată',
        uk: 'Сторінку створено'
      },
      PAGE_DELETED: {
        ru: 'Страница удалена',
        en: 'Page removed',
        ro: 'Pagina eliminată',
        uk: 'Сторінка видалена'
      },
      WIDGET_DOWNLOADING: {
        ru: 'Идет загрузка виджетов',
        en: 'Widgets are loading',
        ro: 'Widgeturile se încarcă acum',
        uk: 'Триває завантаження віджетів'
      },
      WIDGET_OK: {
        ru: 'Виджет загружен',
        en: 'Widget loaded',
        ro: 'Widget încărcat',
        uk: 'Віджет завантажений'
      },
      WIDGET_ADD: {
        ru: 'Виджет добавлен',
        en: 'Widget added',
        ro: 'Widget adăugat',
        uk: 'Віджет додано'
      },
      WIDGET_DELETE: {
        ru: 'Виджет удален',
        en: 'Widget removed',
        ro: 'Widget eliminat',
        uk: 'Віджет видалено'
      },
      WIDGET_EDIT: {
        ru: 'Виджет изменен',
        en: 'Widget changed',
        ro: 'Widget schimbat',
        uk: 'Віджет змінено'
      },
      WIDGET_MOVED: {
        ru: 'Виджет перемещен',
        en: 'Widget moved',
        ro: 'Widget mutat',
        uk: 'Віджет переміщено'
      },
      CHART_ADD: {
        ru: 'График добавлен',
        en: 'Chart added',
        ro: 'A adăugat grafic',
        uk: 'Графік доданий'
      },
      CHART_DELETE: {
        ru: 'График удален',
        en: 'Chart deleted',
        ro: 'Graficul șters',
        uk: 'Графік видалено'
      },
      CHART_EDIT: {
        ru: 'График изменен',
        en: 'Chart changed',
        ro: 'Graficul schimbat',
        uk: 'Графік змінено'
      },
      CHART_MOVED: {
        ru: 'График перемещен',
        en: 'Chart moved',
        ro: 'Graficul a fost mutat',
        uk: 'Графік переміщено'
      },
      CHART_COPY: {
        ru: 'Копия графика создана',
        en: 'A copy of the chart has been created',
        ro: 'A fost creată o copie a graficului',
        uk: 'Копію графіка створено'
      },
      CHART_DOWNLOADING: {
        ru: 'Идет загрузка графика',
        en: 'Chart loading in progress',
        ro: 'Încărcare grafică în curs',
        uk: 'Триває завантаження графіка'
      },
      CHART_OK: {
        ru: 'График загружен',
        en: 'Chart loaded',
        ro: 'Grafic încărcat',
        uk: 'Графік завантажений'
      },
      ANALYTICS_DOWNLOADING: {
        ru: 'Идет загрузка страницы дашбордов...',
        en: 'Dashboards page loading',
        ro: 'Încărcarea paginilor de tablouri de bord',
        uk: 'Триває завантаження сторінки дашбордів...'
      },
      DOWNLOAD_PAGE_SUCCESS: {
        ru: 'Загрузка успешно завершена',
        en: 'Download completed successfully',
        ro: 'Descărcați finalizat cu succes',
        uk: 'Завантаження успішно завершено'
      },
      DOWNLOAD_PAGE_ERROR: {
        ru: 'Загрузка не удалась',
        en: 'Download failed',
        ro: 'Descarcare esuata',
        uk: 'Завантаження не вдалося'
      },
      DASHBOARDS_DOWNLOADING: {
        ru: 'Идет загрузка страницы дашбардов...',
        en: 'Dashboard page loading',
        ro: 'Încărcarea paginii de bord',
        uk: 'Триває завантаження сторінки дашбардів...'
      },
      MENTIONS_DOWNLOADING: {
        ru: 'Идет загрузка страницы упоминаний...',
        en: 'Mentions page is loading...',
        ro: 'Menționează pagina se încarcă ...',
        uk: 'Триває завантаження сторінки згадок...'
      },
      PASS_CHANGE: {
        ru: 'Пароль успешно изменен',
        en: 'Your password has been changed successfully',
        ro: 'Parola dvs. a fost schimbată cu succes',
        uk: 'Пароль успішно змінений'
      },
      FEEDBACK: {
        ru: 'Спасибо за отзыв!',
        en: 'Thanks for the feedback!',
        ro: 'Multumesc pentru feedback!',
        uk: 'Дякуємо за відгук!'
      },
      CHANGED_DATA: {
        ru: 'Данные пользователя изменены',
        en: 'User data changed',
        ro: 'Date de utilizator modificate',
        uk: 'Дані користувача змінено'
      },
      READ_ALL: {
        ru: 'Все публикации отмечены, как прочитанные',
        en: 'All posts are marked as read',
        ro: 'Toate postările sunt marcate ca citite',
        uk: 'Усі публікації зазначені як прочитані'
      },
      SOURCE_BLOCKED: {
        ru: 'Источник заблокирован',
        en: 'Source blocked',
        ro: 'Sursă blocată',
        uk: 'Джерело заблоковано'
      },
      CREATOR_BLOCKED: {
        ru: 'Автор заблокирован',
        en: 'Author blocked',
        ro: 'Autorul blocat',
        uk: 'Автора заблоковано'
      },
      PLACE_BLOCKED: {
        ru: 'Место публикации заблокировано',
        en: 'Publication place blocked',
        ro: 'Locul de publicare blocat',
        uk: 'Місце публікації заблоковано'
      },
      SOURCES_VIEWED: {
        ru: 'Все упоминания были обозначены как прочитанные',
        en: 'All mentions have been marked as read',
        ro: 'Toate mențiunile au fost marcate ca citite',
        uk: 'Усі згадки були позначені як прочитані'
      },
      SOURCE_UNBLOCKED: {
        ru: 'Источник разблокирован',
        en: 'Source unblocked',
        ro: 'Sursă deblocată',
        uk: 'Джерело розблоковано'
      },
      CREATOR_UNBLOCKED: {
        ru: 'Автор разблокирован',
        en: 'Author unlocked',
        ro: 'Autorul deblocat',
        uk: 'Автор розблоковано'
      },
      PLACE_UNBLOCKED: {
        ru: 'Место публикации разблокировано',
        en: 'Publication place unlocked',
        ro: 'Locul de publicare deblocat',
        uk: 'Місце публікації розблоковано'
      },
      CHECK_REPORT: {
        ru: 'Ваше обращение отправлено на проверку',
        en: 'Your request has been sent for review',
        ro: 'Cererea dvs. a fost trimisă pentru revizuire',
        uk: 'Ваше звернення надіслано на перевірку'
      },
      TAGS_ADDED_ARTICLE_ONE: {
        ru: 'Выбраный тег  добавлен к упоминанию',
        en: 'Selected tag added to mention',
        ro: 'Etichetă selectată adăugată pentru a menționa',
        uk: 'Вибраний тег доданий до згадки'
      },
      TAGS_ADDED_ARTICLE: {
        ru: 'Выбраные теги  добавлены к упоминанию',
        en: 'Selected tags added to mention',
        ro: 'Etichete selectate adăugate pentru a menționa',
        uk: 'Вибрані теги додані до згадки'
      },
      TAGS_REMOVED_ARTICLE: {
        ru: 'Выбраные теги удалены из упоминания',
        en: 'Selected tags removed from mention',
        ro: 'Etichete selectate eliminate din mențiune',
        uk: 'Вибрані теги видалені із згадки'
      },
      TAGS_REMOVED_ARTICLE_ONE: {
        ru: 'Выбраный тег удален из упоминания',
        en: 'Selected tag removed from mention',
        ro: 'Eticheta selectată eliminată din mențiune',
        uk: 'Вибраний тег видалений із згадки'
      },
      TAGS_CHANGED_ARTICLE: {
        ru: 'У данного упоминания были изменены выбранные теги',
        en: 'The selected tags have been changed for this mention',
        ro: 'Etichetele selectate au fost schimbate pentru această mențiune',
        uk: 'У цієї згадки було змінено вибрані теги'
      },
      TAGS_ADDED: {
        ru: 'Всем отмеченным упоминаниям добавлены выбраные теги',
        en: 'Selected tags are added to all marked mentions',
        ro: 'Etichetele selectate sunt adăugate la toate mențiunile marcate',
        uk: 'Всім відміченим згадкам додані вибрані теги'
      },
      TAG_ADDED_ONE: {
        ru: 'Всем отмеченным упоминаниям добавлен выбранный тег',
        en: 'Selected tag are added to all marked mentions',
        ro: 'Eticheta selectată sunt adăugate la toate mențiunile marcate',
        uk: 'Всім відміченим згадкам доданий вибраний тег'
      },
      TAGS_CHANGED: {
        ru: 'У всех отмеченных упоминаний были изменены выбранные теги',
        en: 'All selected mentions have had their selected tags changed',
        ro: 'Toate mențiunile selectate au avut etichetele selectate modificate',
        uk: 'У всіх зазначених згадок було змінено вибрані теги'
      },
      TAGS_REMOVED: {
        ru: 'Из всем отмеченных упоминаний удалены выбраные теги',
        en: 'Selected tags have been removed from all tagged mentions',
        ro: 'Etichetele selectate au fost eliminate din toate mențiunile etichetate',
        uk: 'Із усіх відмічених згадок видалені вибрані теги'
      },
      TAGS_REMOVED_ONE: {
        ru: 'Из всем отмеченных упоминаний удален выбранный тег',
        en: 'Selected tag have been removed from all tagged mentions',
        ro: 'Eticheta selectată a fost eliminată din toate mențiunile etichetate',
        uk: 'Із усіх відмічених згадок видалений вибраний тег'
      },
      MENTION_BEEN_REMOVED: {
        ru: 'Упоминание удалено',
        en: 'Mention have been removed',
        ro: 'Menționarea au fost eliminate',
        uk: 'Згадка видалена'
      },
      MENTIONS_REMOVED: {
        ru: 'Все отмеченные упоминания удалены',
        en: 'All marked mentions have been removed',
        ro: 'Toate mențiunile marcate au fost eliminate',
        uk: 'Усі відмічені згадки видалені'
      },
      MENTIONS_RESTORED: {
        ru: 'Все отмеченные упоминания восстановлены',
        en: 'All marked mentions have been restored',
        ro: 'Toate mențiunile marcate au fost restaurate',
        uk: 'Усі зазначені згадки відновлені'
      },
      MENTIONS_ADD_PROCESSED: {
        ru: 'Все отмеченные упоминания добавлены в обработанные',
        en: 'All marked mentions added to processed',
        ro: 'Toate mențiunile marcate adăugate la neprocesat',
        uk: 'Всі відмічені згадки додані в оброблені'
      },
      MENTIONS_ADD_UNPROCESSED: {
        ru: 'Отмеченные упоминания добавлены в необработанные',
        en: 'Marked mentions added to unprocessed',
        ro: 'Mențiuni marcate adăugate la neprocesat',
        uk: 'Відмічені згадки додані в необроблені'
      },
      SOURCE_ADD_PROCESSED: {
        ru: 'Статья добавлен в обработанные',
        en: 'Article added to processed',
        ro: 'Articol adăugat la neprocesat',
        uk: 'Стаття додана до обробленого'
      },
      SOURCE_ADD_UNPROCESSED: {
        ru: 'Статья добавлен в необработанные',
        en: 'Article added to unprocessed',
        ro: 'Articolul adăugat la neprocesat',
        uk: 'Стаття додана до необробленого'
      },
      TONE_CHANGED: {
        ru: 'Тональность источника изменена',
        en: 'Source sentiment changed',
        ro: 'Tonul sursă este schimbat',
        uk: 'Тональність джерела змінена'
      },
      ROLE_CHANGED: {
        ru: 'Роль источника изменена',
        en: 'Source role changed',
        ro: 'Rolul sursei a fost schimbat',
        uk: 'Роль джерела змінена'
      },
      ADDED_FAVORITE: {
        ru: 'Добавлено в избранное',
        en: 'Added to Favorites',
        ro: 'Adăugat la Favorite',
        uk: 'Додано у обрані'
      },
      REMOVED_FAVORITE: {
        ru: 'Удалено из избранного',
        en: 'Removed from Favorites',
        ro: 'Eliminat din favorite',
        uk: 'Видалено з обраного'
      },
      SUBSCRIBED_DUBLICATES: {
        ru: 'Подписались на дубли',
        en: 'You subscribed to duplicates',
        ro: 'V-ați abonat la duplicate',
        uk: 'Ви підписались дублі'
      },
      UNSUBSCRIBED: {
        ru: 'Вы отменили подписку на дубли',
        en: 'You have unsubscribed from duplicates',
        ro: 'Aveți dezabonat de la duplicate',
        uk: 'Ви скасували підписку на дублі'
      },
      ALL: {
        ru: 'Все ',
        en: 'All ',
        ro: 'Toate ',
        uk: 'Усі '
      },
      MENTION_ADDED: {
        ru: ' упоминания добавлены в ',
        en: ' mentions added to ',
        ro: ' mențiuni adăugate la ',
        uk: ' згадки додані в '
      },
      MENTION_REMOVED: {
        ru: ' упоминания удалены из ',
        en: ' mention removed from ',
        ro: ' menționare eliminată din ',
        uk: ' згадки видалені із '
      },
      MENTIONS_CHANGED: {
        ru: ' Все отмеченные упоминания изменены',
        en: ' All marked mentions changed',
        ro: ' Toate referințele marcate au fost modificate',
        uk: ' Усі відмічені згадки змінені'
      },
      CANCELED: {
        ru: 'Действие отменено',
        en: 'Action canceled',
        ro: 'Acțiune anulată',
        uk: 'Дія скасована'
      },
      SOMETHING_WRONG: {
        ru: 'Что-то пошло не так. Попробуйте позже',
        en: 'Something went wrong. Please, try later',
        ro: 'Ceva n-a mers bine. Te rog incearca mai tarziu',
        uk: 'Щось пішло не так. Спробуй пізніше'
      },
      DUPLICATE_NAME: {
        ru: 'Название КС аналогично предыдущему. Используйте другое название.',
        en: 'The name of the keyword is similar to the previous one. Use a different name.',
        ro: 'Numele cuvântului cheie este similar cu cel anterior.Folosiți un alt nume.',
        uk: 'Назва КС аналогічна попередній. Використайте іншу назву.'
      },
      COMPLAINT_SENT: {
        ru: 'Жалоба успешно отправлена',
        en: 'Complaint sent successfully',
        ro: 'Plângerea trimisă cu succes',
        uk: 'Скаргу успішно надіслано'
      },
      SOURCE_SENT: {
        ru: 'Источник успешно отправлен на рассмотрение',
        en: 'Source successfully submitted for review',
        ro: 'Sursa trimisă cu succes pentru revizuire',
        uk: 'Джерело успішно відправлено на розгляд'
      },
      ALERT_CREATE_SUCCESS: {
        ru: 'Оповещение успешно создано',
        en: 'Alert created succesfully',
        ro: 'Alerta creată cu succes',
        uk: 'Сповіщення успішно створено'
      },
      ALERT_EDITED_SUCCESS: {
        ru: 'Оповещение отредактировано успешно',
        en: 'Alert edited succesfully',
        ro: 'Alertă editată cu succes',
        uk: 'Сповіщення успішно відредаговано'
      },
      ALERT_ON: {
        ru: 'Уведомление включено',
        en: 'Notification enabled',
        ro: 'Notificare activată',
        uk: 'Сповіщення ввімкнено'
      },
      ALERT_OFF: {
        ru: 'Уведомление отключено',
        en: 'Notification disabled',
        ro: 'Notificare dezactivată',
        uk: 'Сповіщення вимкнуто'
      },
      REPORT_ON: {
        ru: 'Отчет включен',
        en: 'Report enabled',
        ro: 'Raport activat',
        uk: 'Звіт ввімкнено'
      },
      REPORT_OFF: {
        ru: 'Отчет отключен',
        en: 'Report disabled',
        ro: 'Raport dezactivat',
        uk: 'Звіт вимкнуто'
      },
      ALERT_DELETED_SUCCESS: {
        ru: 'Оповещение успешно удалено',
        en: 'Alert deleted succesfully',
        ro: 'Alertă ștearsă cu succes',
        uk: 'Сповіщення успішно видалено'
      },
      REPORT_CREATE_SUCCESS: {
        ru: 'Отчет успешно создан',
        en: 'Report created succesfully',
        ro: 'Reporta creată cu succes',
        uk: 'Звіт успішно створено'
      },
      REPORT_EDITED_SUCCESS: {
        ru: 'Отчет отредактирован успешно',
        en: 'Report edited succesfully',
        ro: 'Raportul editată cu succes',
        uk: 'Звіт успішно відредаговано'
      },
      REPORT_DELETED_SUCCESS: {
        ru: 'Отчет успешно удалено',
        en: 'Report deleted succesfully',
        ro: 'Raportul ștearsă cu succes',
        uk: 'Звіт успішно видалено'
      },
      REPORT_TEMPLATE_DELETED_SUCCESS: {
        ru: 'Шаблон для отчета успешно удалено',
        en: 'Report for template successfully deleted',
        ro: 'Șablonul de raport a fost șters cu succes',
        uk: 'Шаблон для звіту успішно видалено'
      },
      REPORT_TEMPLATE_CREATED_SUCCESS: {
        ru: 'Шаблон для отчета успешно создан',
        en: 'The template for the report was successfully created',
        ro: 'Șablonul pentru raport a fost creat cu succes',
        uk: 'Шаблон для звіту успішно створено'
      },
      REPORT_TEMPLATE_EDITED_SUCCESS: {
        ru: 'Шаблон для отчета успешно изменен',
        en: 'The template for the report was successfully created',
        ro: 'Șablonul pentru raport a fost creat cu succes',
        uk: 'Шаблон для звіту успішно змінений'
      },
      QUERY_COPIED: {
        ru: 'Запрос скопирован',
        en: 'Query copied',
        ro: 'Interogare copiată',
        uk: 'Запит скопійовано'
      },
      SELECTED_ARTICLES_REMOVED: {
        ru: 'Выбранные статьи удалены из избранного',
        en: 'Selected articles removed from favorites',
        ro: 'Articolele selectate au fost eliminate din favorite',
        uk: 'Вибрані статті видалені з обраних'
      },
      SELECTED_ARTICLES_ADDED: {
        ru: 'Выбранные статьи добавлены в избранное',
        en: 'Selected articles added to favorites',
        ro: 'Articolele selectate au fost adăugate la favorite',
        uk: 'Вибрані статті додані до обраних'
      },
      SEARCH_RESULTS_EMAIL: {
        ru: '✅ Результаты поиска скоро будут отправлены на вашу почту',
        en: '✅ Search results will soon be sent to your email',
        ro: '✅ Rezultatele căutării vor fi trimise în curând pe emailul dvs.',
        uk: '✅ Результати пошуку незабаром надійдуть на вашу пошту'
      },
      FILTER_REMOVED: {
        ru: 'Фильтр удален',
        en: 'Filter removed',
        ro: 'Filtrul a fost eliminat',
        uk: 'Фільтр видалено'
      },
      NOT_IN_FILTER_PATTERN: {
        ru: 'Возникли технические проблемы. Не удалось получить данные.',
        en: 'Technical issues occurred. Failed to retrieve data.',
        ro: 'Au apărut probleme tehnice. Nu s-au putut obține datele.',
        uk: 'Виникли технічні проблеми. Не вдалося отримати дані.'
      },
      NOT_IN_FILTER_PATTERN_DETAIL: {
        ru: 'Эта часть контекста недоступна по специфике обработки данных или по настройке конфиденциальности на источнике. Мы делаем все возможное для обеспечения максимально релевантных результатов, однако, некоторая информация может не отображаться в данном контексте.',
        en: 'This part of the context is not available due to the specifics of data processing or due to privacy settings at the source. We do our best to provide the most relevant results, but some information may not be displayed in this context.',
        ro: 'Această parte a contextului nu este disponibilă din cauza specificului procesării datelor sau din cauza setărilor de confidențialitate la sursă. Facem tot posibilul pentru a oferi cele mai relevante rezultate, dar este posibil ca unele informații să nu fie afișate în acest context.',
        uk: 'Ця частина контексту недоступна через специфіку обробки даних або через налаштування конфіденційності на джерелі. Ми робимо все можливе для забезпечення максимально релевантних результатів, проте деяка інформація може не відображатися в даному контексті.'
      },
      ORIGINAL_POST: {
        ru: 'Оригинальный пост',
        en: 'Original post',
        ro: 'Postare originală',
        uk: 'Оригінальний пост'
      }
    },
    roundFn: {
      K: {
        ru: 'тыс',
        en: 'K',
        ro: 'K',
        uk: 'тис'
      },
      M: {
        ru: 'млн',
        en: 'M',
        ro: 'M',
        uk: 'млн'
      },
      B: {
        ru: 'млрд',
        en: 'B',
        ro: 'B',
        uk: 'млрд'
      }
    },
    btn: {
      ADD_CHART: {
        ru: 'Добавить график',
        en: 'Add Chart',
        ro: 'Adăugați grafic',
        uk: 'Додати графік'
      },
      EDIT_CHART: {
        ru: 'Редактировать график',
        en: 'Edit chart',
        ro: 'Editați graficul',
        uk: 'Редагувати графік'
      },
      BACK: {
        ru: 'Назад',
        en: 'Back',
        ro: 'Înapoi',
        uk: 'Назад'
      },
      ADDITIONAL_SETTING_NO: {
        ru: 'Свернуть дополнительные настройки графика',
        en: 'Collapse advanced chart settings',
        ro: 'Prăbușiți setările grafice avansate',
        uk: 'Згорнути додаткові налаштування графіка'
      },
      ADDITIONAL_SETTING: {
        ru: 'Дополнительные настройки графика',
        en: 'Additional chart settings',
        ro: 'Setări grafice suplimentare',
        uk: 'Додаткові налаштування графіка'
      },
      FURTHER: {
        ru: 'Далее',
        en: 'Next',
        ro: 'Următorul',
        uk: 'Далі'
      },
      DEL_TAG: {
        ru: 'Удалить тег',
        en: 'Delete tag',
        ro: 'Ștergeți eticheta',
        uk: 'Видалити тег'
      },
      ADD_TAG: {
        ru: 'Добавить тег',
        en: 'Add tag',
        ro: 'Adaugă etichetă',
        uk: 'Додати тег'
      },
      ADD_NEW_TAG: {
        ru: 'Добавить новый тег',
        en: 'Add new tag',
        ro: 'Adăugați o nouă etichetă',
        uk: 'Додати новий тег'
      },
      ADD: {
        ru: 'Добавить',
        en: 'Add',
        ro: 'Adăuga',
        uk: 'Додати'
      },
      VIEW: {
        ru: 'Просмотреть',
        en: 'Preview',
        ro: 'previzualizare',
        uk: 'Переглянути'
      },
      COLLAPSE: {
        ru: 'Свернуть',
        en: 'Collapse',
        ro: 'Colaps',
        uk: 'Згорнути'
      },
      SHOW_ALL_TAGS: {
        ru: 'Показати все теги',
        en: 'Show all tags',
        ro: 'Afișați toate etichetele',
        uk: 'Показати всі теги'
      },
      NEXT: {
        ru: 'Дальше',
        en: 'Next',
        ro: 'Următorul',
        uk: 'Далі'
      },
      SELECT_TEMPLATE: {
        ru: 'Выбрать шаблон',
        en: 'Select a template',
        ro: 'Selectați un șablon',
        uk: 'Обрати шаблон'
      },
      SWITCH: {
        ru: 'Переключить',
        en: 'Switch',
        ro: 'Comutator',
        uk: 'Перемкнути'
      },
      ADD_WIDGET: {
        ru: 'Добавить виджет',
        en: 'Add widget',
        ro: 'Adăugați widget',
        uk: 'Додати віджет'
      },
      UNREAD_MENTION: {
        ru: 'Непрочитаное упоминание',
        en: 'Unread mention',
        ro: 'Mențiune necitită',
        uk: 'Непрочитана згадка'
      },
      CREATE_NEW_PAGE: {
        ru: 'Создать новую страницу',
        en: 'Create a new page',
        ro: 'Creați o pagină nouă',
        uk: 'Створити нову сторінку'
      },
      PAGES_LIST: {
        ru: 'Список страниц',
        en: 'List of pages',
        ro: 'Lista de pagini',
        uk: 'Список сторінок'
      },
      CREATE_NEW_DASHBOARD: {
        ru: 'Создать новый дашборд',
        en: 'Create a new dashboard',
        ro: 'Creați un nou tablou de bord',
        uk: 'Створити новий дашборд'
      },
      CREATE_NEW_CHART: {
        ru: 'Добавить график',
        en: 'Add a chart',
        ro: 'Adăugați un grafic',
        uk: 'Додати графік'
      },
      CLEAR: {
        ru: 'Очистить',
        en: 'Clear',
        ro: 'Clar',
        uk: 'Очистити'
      },
      SEARCH: {
        ru: 'Пошук',
        en: 'Search',
        ro: 'Căutare',
        uk: 'Пошук'
      },
      FILTER: {
        ru: 'Отфильтровать',
        en: 'Filter',
        ro: 'Filtru',
        uk: 'Відфільтрувати'
      },
      DELETE: {
        ru: 'Удалить',
        en: 'Delete',
        ro: 'Șterge',
        uk: 'Видалити'
      },
      BLOCK_SOURCE: {
        ru: 'Заблокировать',
        en: 'Block',
        ro: 'Bloc',
        uk: 'Заблокувати'
      },
      FILTER_MENTIONS: {
        ru: 'Показать упоминания',
        en: 'Show mentions',
        ro: 'Mențiuni de spectacol',
        uk: 'Показати згадки'
      },
      EDIT: {
        ru: 'Редактировать',
        en: 'Edit',
        ro: 'Editați',
        uk: 'Редагувати'
      },
      CANCEL: { ru: 'Отменить', en: 'Cancel', uk: 'Скасувати', ro: 'Anulare' },
      CLOSE: { ru: 'Закрыть', en: 'Close', uk: 'Закрити', ro: 'Închide' },
      SAVE: { ru: 'Сохранить', en: 'Save', uk: 'Зберегти', ro: 'Salvați' },
      CHANGE: { ru: 'Изменить', en: 'Change', uk: 'Змінити', ro: 'Schimbare' },
      SURE_CHANGE: { ru: 'Да, редактировать', en: 'Yes, edit', uk: 'Так, редагувати', ro: 'Da editați' },
      EXIT_CONSTRUCTOR: { ru: 'Вийти з констуктора', en: 'Exit the constructor', uk: 'Вийти з констуктора', ro: 'Ieșiți din constructor' },
      SEND: { ru: 'Отправить', en: 'Send', uk: 'Відправити', ro: 'Trimite' },
      APPLY: { ru: 'Применить', en: 'Apply', uk: 'Застосувати', ro: 'aplica' },
      APPLY_CHANGES: { ru: 'Применить изменения', en: 'Apply changes', uk: 'Застосувати зміни', ro: 'Aplica schimbarile' },
      GO_BACK: { ru: 'Вернутся назад', en: 'Go back', uk: 'Повернутись назад', ro: 'Întoarce-te' },
      BACK_FEED: { ru: 'Вернуться к ленте', en: 'Go back to feed', uk: 'Повернутися до стрічки', ro: 'Du -te înapoi la Feed' },
      OKAY: { ru: 'Хорошо', en: 'Okay', uk: 'Гаразд', ro: 'Bine' },
      CREATE_DASHBOARD: {
        ru: 'Создать дашборд',
        en: 'Create dashboard',
        ro: 'Creați tabloul de bord',
        uk: 'Створити дашборд'
      },
      CLEAR_FILTER: {
        ru: 'Очистить фильтр',
        en: 'Clear filter',
        ro: 'Filtru clar',
        uk: 'Очистити фільтр'
      },
      RESET_PERIOD: {
        ru: 'Cбросить период',
        en: 'Reset the period',
        ro: 'Resetați perioada',
        uk: 'Скинути період'
      },
      DOWNLOAD: { ru: 'Загрузить', en: 'Download', uk: 'Завантажити', ro: 'Descarca' },
      DOWNLOAD_NEXT: {
        ru: 'Загрузить следующие',
        en: 'Download the following',
        uk: 'Завантажити наступні',
        ro: 'Descărcați următoarele'
      },
      DOWNLOAD_PREVIOUS: {
        ru: 'Загрузить предыдущие',
        en: 'Download previous',
        uk: 'Завантажити попередні',
        ro: 'Descărcați cele anterioare'
      },
      TRY_AGAIN: { ru: 'Попробовать еще', en: 'Try again', uk: 'Спробувати ще', ro: 'Încearcă din nou' },
      SUGGEST: { ru: 'Предложитть', en: 'Suggest', uk: 'Запропонувати', ro: 'Sugera' },
      CONTACT_MANAGER: { ru: 'Сообщить менеджеру', en: 'Report to manager', uk: 'Повідомити менеджера', ro: 'Raportează managerului' },
      GOOD: { ru: 'Хорошо', en: 'Good', uk: 'Добре', ro: 'Bun' },
      RESET_TABLE: { ru: 'Вернуться к полной таблице', en: 'Return to the full table', uk: 'Повернутись до повної таблиці', ro: 'Înapoi la tabelul complet' },
      CREATE_ALERT: { ru: 'Создать оповещение', en: 'Create an alert', uk: 'Створити сповіщення', ro: 'Creați o notificare' },
      LEARN_MORE: { ru: 'Создать оповещение', en: 'Learn more', uk: 'Дізнатись більше', ro: 'Aflați mai multe' },
      EDIT_ALERT: {
        ru: 'Редактировать оповещение',
        en: 'Edit alert',
        uk: 'Редагувати сповіщення',
        ro: 'Editați o notificare'
      },
      EDIT_REPORT: {
        ru: 'Редактировать отчет',
        en: 'Edit report',
        uk: 'Редагувати звіт',
        ro: 'Editați raportul'
      },
      CONFIRM_CHANGES: {
        ru: 'Подтвердить изменения',
        en: 'Confirm changes',
        uk: 'Підтвердити зміни',
        ro: 'Confirmați modificările'
      },
      CONFIRM: {
        ru: 'Подтвердить',
        en: 'Confirm',
        uk: 'Підтвердити',
        ro: 'Confirmați'
      },
      CANCEL_CHANGES: {
        ru: 'Отменить изменения',
        en: 'Cancel changes',
        uk: 'Cкасувати зміни',
        ro: 'Anulați modificările'
      },
      EDIT_QUERY: {
        ru: 'Редактировать запрос',
        en: 'Edit query',
        ro: 'Editați interogarea',
        uk: 'Редагувати запит'
      },
      CUSTOMIZE_QUERY: {
        ru: 'Настроить запрос',
        en: 'Customize query',
        ro: 'Personalizați interogarea',
        uk: 'Налаштувати запит'
      },
      PRIVATE_MESSAGE: {
        ru: 'Личные сообщения',
        en: 'Private messages',
        uk: 'Приватні повідомлення',
        ro: 'Mesajele private'
      },
      GROUP_MESSAGE: {
        ru: 'Групповые чаты',
        en: 'Group chats',
        uk: 'Груповий чат',
        ro: 'Discuțiile de grup'
      },
      ADD_RECIPIENT: {
        ru: 'Добавить получателя',
        en: 'Add recipient',
        uk: 'Додати отримувача',
        ro: 'Adăugați destinatar'
      },
      ADD_TIME: {
        ru: 'Добавить время',
        en: 'Add time',
        uk: 'Додати час',
        ro: 'Adăugați timp'
      },
      CLOSE_CANCEL: {
        ru: 'Закрыть и отменить подключение',
        en: 'Close and cancel the connection',
        uk: 'Закрити і скасувати підключення',
        ro: 'Închideți și anulați conexiunea'
      },
      CONTINUE_WAIT: {
        ru: 'Продолжать ждать',
        en: 'Continue to wait',
        uk: 'Продовжити очікувати',
        ro: 'Continuați să așteptați'
      },
      CREATE_REPORT: {
        ru: 'Создать отчет',
        en: 'Create report',
        ro: 'Creați raport',
        uk: 'Створити звіт'
      },
      CREATE_NEW_REPORT: {
        ru: 'Создать новый отчет',
        en: 'Create a new report',
        ro: 'Creați o pagină raport',
        uk: 'Створити новий звіт'
      },
      CREATE_NEW_TEMPLATE: {
        ru: 'Создать новый шаблон',
        en: 'Create a new template',
        ro: 'Creați un nou șablon',
        uk: 'Створити новий шаблон'
      },
      CHOOSE_FILE: {
        ru: 'Выбрать файл',
        en: 'Choose file',
        ro: 'Alegeți fișierul',
        uk: 'Вибрати файл'
      },
      EDIT_TEMLATE: {
        ru: 'Редактировать шаблон',
        en: 'Edit a template',
        ro: 'Editarea unui șablon',
        uk: 'Редагувати шаблон'
      },
      ADD_DAY: {
        ru: 'Добавить день',
        en: 'Add day',
        ro: 'Adăugați ziua',
        uk: 'Додати день'
      },
      TRIMITE: {
        ru: 'Подать',
        en: 'Trimite',
        ro: 'Trimite',
        uk: 'Подати'
      },
      SEARCH_AGAIN: {
        ru: 'Искать снова',
        en: 'Search again',
        ro: 'Căutare din nou',
        uk: 'Шукати знову'
      },
      SHOW_REPLIES: {
        ru: 'Показать ответы',
        en: 'Show replies',
        ro: 'Afișați răspunsurile',
        uk: 'Показати відповіді'
      },
      HIDE_REPLIES: {
        ru: 'Скрыть ответы',
        en: 'Hide replies',
        ro: 'Ascunde răspunsurile',
        uk: 'Сховати відповіді'
      }
    },
    modalWindow: {
      COPY_CHART: {
        ru: 'Скопировать график в...',
        en: 'Copy chart to',
        ro: 'Copiați graficul la',
        uk: 'Скопировать график в...'
      },
      DEL_CHART: {
        ru: 'Удаление графика',
        en: 'Deleting a chart',
        ro: 'Ștergerea unui grafic',
        uk: 'Видалення графіка'
      },
      EDIT_CHART: {
        ru: 'Редактировать график',
        en: 'Edit chart',
        ro: 'Editați graficul',
        uk: 'Редагувати графік'
      },
      ADD_CHART: {
        ru: 'Добавить график',
        en: 'Add Chart',
        ro: 'Adăugați grafic',
        uk: 'Додати графік'
      },
      CREATE_CHART: {
        ru: 'Создать новый график',
        en: 'Create a new chart',
        ro: 'Creați un nou grafic',
        uk: 'Створити новий графік'
      },
      CREATE_PAGE: {
        ru: 'Страница создана',
        en: 'Page created',
        ro: 'Pagina creată',
        uk: 'Сторінку створено'
      },
      LOADING_ARTICLES: {
        ru: 'Загрузка статей',
        en: 'Loading articles',
        ro: 'Încărcarea articolelor',
        uk: 'Завантаження статей'
      },
      EDIT_TEG: {
        ru: 'Редактировать тег',
        en: 'Edit Tag',
        ro: 'Editați eticheta',
        uk: 'Редагувати тег'
      },
      REMOVE_TEG: {
        ru: 'Удалить тег',
        en: 'Remove tag',
        ro: 'Scoate eticheta',
        uk: 'Видалити тег'
      },
      ADD_TEG: {
        ru: 'Добавить тег',
        en: 'Add tag',
        ro: 'Adaugă etichetă',
        uk: 'Додати тег'
      },
      ADD_KEYWORD: {
        ru: 'Добавить ключевое слово',
        en: 'Add keyword',
        ro: 'Adăugați cuvinte cheie',
        uk: 'Додати ключове слово'
      },
      EDIT_KEYWORD: {
        ru: 'Редактировать ключевое слово',
        en: 'Edit keyword',
        ro: 'Editați cuvântul cheie',
        uk: 'Редагувати ключове слово'
      },
      EDITING_KEYWORD: {
        ru: 'Редактирование ключевого слова',
        en: 'Editing keyword',
        ro: 'Editarea cuvântului cheie',
        uk: 'Редагування ключового слова'
      },
      CONFIRM_EDITING: {
        ru: 'Подтверждение редактирования',
        en: 'Confirmation of editing',
        ro: 'Confirmarea editării',
        uk: 'Підтвердження редагування'
      },
      REMOVE_KEYWORD: {
        ru: 'Удалить ключевое слово',
        en: 'Remove keyword',
        ro: 'Eliminați cuvântul cheie',
        uk: 'Видалити ключове слово'
      },
      ADD_SOURCE: {
        ru: 'Добавить источник',
        en: 'Add source',
        ro: 'Adăugați sursa',
        uk: 'Додати джерело'
      },
      CREATE_NEW_PAGE: {
        ru: 'Создать новую страницу',
        en: 'Create a new page',
        ro: 'Creați o pagină nouă',
        uk: 'Створити нову сторінку'
      },
      CREATE_NEW_DASHBOARD: {
        ru: 'Создать новый дашборд',
        en: 'Create a new dashboard',
        ro: 'Creați un nou tablou de bord',
        uk: 'Створити новий дашборд'
      },
      DEL_PAGE: {
        ru: 'Удаление страницы',
        en: 'Deleting a Page',
        ro: 'Ștergerea unei pagini',
        uk: 'Видалення сторінки'
      },
      RENAME_PAGE: {
        ru: 'Переименовать страницу',
        en: 'Rename Page',
        ro: 'Redenumiți pagina',
        uk: 'Перейменувати сторінку'
      },
      SAVE_CHAGES: {
        ru: 'Сохранение изменений',
        en: 'Save changes',
        ro: 'Salvează modificările',
        uk: 'Збереження змін'
      },
      DEL_DASHBOARD: {
        ru: 'Удаление дашборда',
        en: 'Deleting a dashboard',
        ro: 'Ștergerea unui tablou de bord',
        uk: 'Видалення дашборду'
      },
      RENAME_DASHBOARD: {
        ru: 'Переименовать дашборд',
        en: 'Rename dashboard',
        ro: 'Redenumiți tabloul de bord',
        uk: 'Перейменувати дашборд'
      },
      SEND_COMMENT: {
        ru: 'Отправить отзыв',
        en: 'Post a review',
        ro: 'Postează o recenzie',
        uk: 'Відправити відгук'
      },
      DEL_WIDGET: {
        ru: 'Удаление виджета',
        en: 'Deleting a widget',
        ro: 'Ștergerea unui widget',
        uk: 'Видалення віджету'
      },
      EDIT_WIDGETS: {
        ru: 'Редактировать виджет',
        en: 'Edit Widget',
        ro: 'Editați widget',
        uk: 'Редагувати віджет'
      },
      SEARCH_PUBLICATIONS: {
        ru: 'Поиск по публикациям',
        en: 'Publication search',
        ro: 'Căutare publicații',
        uk: 'Пошук за публікаціями'
      },
      ENTER_WORDS: {
        ru: 'Введите поисковые слова',
        en: 'Enter search words',
        ro: 'Introduceți cuvinte de căutare',
        uk: 'Введіть пошукові слова'
      },
      REPORT_PROBLEM: {
        ru: 'Сообщить о проблеме',
        en: 'Report a problem',
        ro: 'Raporteaza o problema',
        uk: 'Повідомити про проблему'
      },
      REPORT_DUPLICATE: {
        ru: 'Ошибка создания отчета',
        en: 'Error creating a report',
        ro: 'Eroare de creare a raportului',
        uk: 'Помилка створення звіту'
      },
      REPORT_PROBLEM_ENCODING: {
        ru: 'Сообщить о проблеме c кодировкой',
        en: 'Report a problem with encoding',
        ro: 'Raportați o problemă cu codificarea',
        uk: 'Повідомити про проблему з кодуванням'
      },
      SELECT_TELEGRAM_METHOD: {
        ru: 'Выберите способ подключения Telegram',
        en: 'Choose a Telegram connection method',
        ro: 'Alegeți o metodă de conectare la telegramă',
        uk: 'Оберіть спосіб підключення Telegram'
      },
      CONNECT_TELEGRAM: {
        ru: 'Подключить Telegram',
        en: 'Connect Telegram',
        ro: 'Conectare Telegram',
        uk: 'Підключити Telegram'
      },
      CONNECTING_TELEGRAM: {
        ru: 'Подключение',
        en: 'Connecting',
        ro: 'Conectarea',
        uk: 'Підключення'
      },
      CONNECT_WHATSAPP: {
        ru: 'Подключить WhatsApp',
        en: 'Connect WhatsApp',
        ro: 'Conectare WhatsApp',
        uk: 'Підключити WhatsApp'
      },
      DOWNLOAD_PUBLICATIONS: {
        ru: 'Скачать публикации',
        en: 'Download publications',
        ro: 'Descărcați publicațiile',
        uk: 'Завантажити публікації'
      },
      PROPOSE_SOURCE: {
        ru: 'Предложить добавить источник',
        en: 'Suggest to add a source',
        ro: 'Sugerați să adăugați o sursă',
        uk: 'Запропонувати додати джерело'
      },
      CONTACT_MANAGER: { ru: 'Сообщение менеджеру', en: 'Message to manager', uk: 'Повідомлення менеджеру', ro: 'Mesaj către manager' },
      EXIT_BUILDER: { ru: 'Вийти из констуктора', en: 'Exit the builder', uk: 'Вийти з констуктора', ro: 'Ieșiți din constructor' },
      MANY_MENTIONS: { ru: 'Большое количество упоминаний', en: 'Large amount of mentions', uk: 'Велика кількість новин', ro: 'Cantitate mare de mențiuni' },
      CONFIRM_EDIT_TAGS: { ru: 'Подтвердите изменения', en: 'Confirm changes', uk: 'Підтвердіть зміни', ro: 'Confirmați modificările' },
      REPORT: { ru: 'Скачать отчет', en: 'Download report', uk: 'Завантажити звіт', ro: 'Descărcați raportul' },
      ADD_QUERY_SEARCH: {
        ru: 'Добавить поисковый запрос',
        en: 'Add a search query',
        ro: 'Adăugați o interogare de căutare',
        uk: 'Додати пошуковий запит'
      },
      EDIT_QUERY_SEARCH: {
        ru: 'Редактировать поисковый запрос',
        en: 'Edit search query',
        ro: 'Editați-vă interogarea de căutare',
        uk: 'Редагувати пошуковий запит'
      },
      CREATE_ALERT: {
        ru: 'Создать оповещение',
        en: 'Create an alert',
        uk: 'Створити сповіщення',
        ro: 'Creați o notificare'
      },
      EDIT_ALERT: {
        ru: 'Редактировать оповещение',
        en: 'Edit alert',
        ro: 'Editare alertă',
        uk: 'Редагувати сповіщення'
      },
      REMOVE_ALERT: {
        ru: 'Удаление оповещения',
        en: 'Remove alert',
        ro: 'Ștergerea unui alertă',
        uk: 'Видалення сповіщення'
      },
      CREATE_REPORT: {
        ru: 'Создать отчет',
        en: 'Create report',
        ro: 'Creați raport',
        uk: 'Створити звіт'
      },
      EDIT_REPORT: {
        ru: 'Редактировать отчет',
        en: 'Edit report',
        ro: 'Editare raport',
        uk: 'Редагувати звіт'
      },
      REMOVE_REPORT: {
        ru: 'Удаление отчета',
        en: 'Remove report',
        ro: 'Ștergerea raport',
        uk: 'Видалення звіту'
      },
      REMOVE_TEMLATE: {
        ru: 'Удаление шаблона для отчета',
        en: 'Deleting a template for a report',
        ro: 'Ștergerea unui șablon pentru un raport',
        uk: 'Видаленяя шаблону для звіту'
      },
      CONFIRM_ACTION: {
        ru: 'Подтверждение действия',
        en: 'Action confirmation',
        ro: 'Confirmarea acțiunii',
        uk: 'Підтвердження дії'
      },
      SELECT_SOURCES: {
        ru: 'Выберите источники',
        en: 'Select sources',
        ro: 'Selectați sursele',
        uk: 'Виберіть джерела'
      },
      SEARCH_HISTORY: {
        ru: 'История поиска',
        en: 'Search history',
        ro: 'Istoricul cautarilor',
        uk: 'Історія пошуку'
      }
    },
    postTypes: {
      post: {
        ru: 'Пост',
        en: 'Post',
        ro: 'Post',
        uk: 'Пост'
      },
      repost: {
        ru: 'Репост',
        en: 'Repost',
        ro: 'Repostează',
        uk: 'Репост'
      },
      extendedRepost: {
        ru: 'Репост с дополнением',
        en: 'Repost with addition',
        ro: 'Repostează cu adăugare',
        uk: 'Репост із доповненням'
      },
      comment: {
        ru: 'Комментарий',
        en: 'Comment',
        ro: 'Cometariu',
        uk: 'Коментар'
      },
      history: {
        ru: 'История',
        en: 'History',
        ro: 'Istorie',
        uk: 'Історія'
      },
      reply: {
        ru: 'Ответ',
        en: 'Reply',
        ro: 'Răspuns',
        uk: 'Відповідь '
      },
      replyComment: {
        ru: 'Ответ на комментарий',
        en: 'Reply to comment',
        ro: 'Răspunde la comentariu',
        uk: 'Відповідь на коментар'
      },
      Undefined: {
        ru: 'Не определено',
        en: 'Not specified',
        ro: 'Nu este specificat',
        uk: 'Не визначено'
      }
    },
    textField: {
      ENTER_LINK: {
        ru: 'Введите ссылку',
        en: 'Enter link',
        ro: 'Introduceți link',
        uk: 'Введіть посилання'
      }
    },
    bodyAddSourceModal: {
      ENTER_LINK: {
        ru: 'Введите ссылку на источник',
        en: 'Enter link to source',
        ro: 'Introduceți linkul către sursă',
        uk: 'Введіть посилання на джерело'
      }
    },
    bodyEmptyReport: {
      TEXT: {
        ru: 'Не удается загрузить отчет. Для загрузки, выберите упоминания из ленты новостей, которые хотите видеть в отчете и попробуйте еще раз',
        en: 'Unable to download the report. To download, select the mentions from the news feed that you want to see in the report and try again',
        ro: 'Imposibil de descărcat raportul.Pentru a descărca selectați mențiunile din fluxul de știri pe care doriți să îl vedeți în raport și încercați din nou',
        uk: 'Невдається завантажити звіт. Для завантаження, оберіть згадки зі стрічки новин, які хочете бачити в звіті та спробуйте ще раз'
      }
    },
    bodyDuplicateReport: {
      TEXT: {
        ru: 'Невозможно обработать запрос, так как аналогичный отчет уже в процессе создания. Пожалуйста, подождите, вы получите уведомление, как только ваш отчет будет готов',
        en: 'Unable to process the request as a similar report is already in the process of being created. Please wait, you will receive a notification as soon as your report is ready',
        ro: 'Nu se poate procesa cererea deoarece un raport similar este deja în curs de creare. Vă rugăm să așteptați, veți fi notificat imediat ce raportul dvs. este gata',
        uk: 'Неможливо обробити запит, оскільки аналогічний звіт вже у процесі створення. Будь ласка, зачекайте, ви отримаєте повідомлення, як тільки ваш звіт буде готовий'
      }
    },
    bodyCopyChartModal: {
      SELECT_PAGE: {
        ru: 'Выберите страницу',
        en: 'Choose a page',
        ro: 'Alegeți o pagină',
        uk: 'Виберіть сторінку'
      }
    },
    bodyDownloadReports: {
      CHOOSE_TYPE: {
        ru: 'Выберите тип загрузки',
        en: 'Choose a download type',
        ro: 'Alegeți un tip de descărcare',
        uk: 'Оберіть тип завантаження'
      },
      DOWNLOAD_ALL: {
        ru: 'Загрузить все публикации',
        en: 'Download all publications',
        ro: 'Descărcați toate publicațiile',
        uk: 'Завантажити всі публікації'
      },
      DOWNLOAD_SELECTED: {
        ru: 'Загрузить выбранные',
        en: 'Download selected',
        ro: 'Descărcați selectat',
        uk: 'Завантажити вибрані'
      },
      PUBLICATIONS: {
        ru: 'публикации',
        en: 'publications',
        ro: 'publicații',
        uk: 'публікації'
      },
      CHOOSE_TEMPLATE: {
        ru: 'Выберите шаблон',
        en: 'Choose a template',
        ro: 'Alegeți un șablon',
        uk: 'Виберіть шаблон'
      },
      CHOOSE_FORMAT: {
        ru: 'Выберите формат',
        en: 'Choose a format',
        ro: 'Alegeți un format',
        uk: 'Виберіть формат'
      }
    },
    lineAnaliticChart: {
      HISTORY_INFO: {
        ru: 'Отображение истории публикаций, которые повлияли на всплеск в графике',
        en: 'Displays the history of publications that influenced the surge in the chart',
        ro: 'Afișează istoria publicațiilor care au influențat creșterea graficului',
        uk: 'Відображення історії публікацій, що вплинули на сплеск у графіку'
      }
    },
    bodyAnaliticsPageModal: {
      COLUMN_VIEW: {
        ru: 'Вид колонок',
        en: 'Column view',
        ro: 'Vizualizare coloană',
        uk: 'Вигляд стовпчиків'
      },
      SWITCHER: {
        ru: 'Нажмите на переключатель чтобы показать или скрыть историю публикаций',
        en: 'Tap the toggle to show or hide the post history',
        ro: 'Atingeți comutarea pentru a arăta sau ascunde istoricul postului',
        uk: 'Натисніть на перемикач, щоб показати або приховати історію публікацій'
      },
      PUBLICATION: {
        ru: 'История публикаций',
        en: 'History of publications',
        ro: 'Istoria publicațiilor',
        uk: 'Історія публікацій'
      },
      OFF: {
        ru: 'Скрыть',
        en: 'Hide',
        ro: 'Ascunde',
        uk: 'Приховати'
      },
      ON: {
        ru: 'Показать',
        en: 'Show',
        ro: 'Spectacol',
        uk: 'Показати'
      },
      ENTER_LNG: {
        ru: 'Введите язык',
        en: 'Enter language',
        ro: 'Introduceți limbajul',
        uk: 'Введіть мову'
      },
      LNG: {
        ru: 'Язык',
        en: 'Language',
        ro: 'Limba',
        uk: 'Мова'
      },
      ENTER_LEVEL_SOURCE: {
        ru: 'Введите уровень источника',
        en: 'Source level',
        ro: 'Nivel sursă',
        uk: 'Рівень джерела'
      },
      LEVEL_SOURCE: {
        ru: 'Уровень источника',
        en: 'Source level',
        ro: 'Nivel sursă',
        uk: 'Рівень джерела'
      },
      ENTER_COUNTRY: {
        ru: 'Введите страну',
        en: 'Enter country',
        ro: 'Intrați în țară',
        uk: 'Введіть країну'
      },
      COUNTRY: {
        ru: 'Страна',
        en: 'Country',
        ro: 'Țară',
        uk: 'Країна'
      },
      ENTER_CHARACTER: {
        ru: 'Введите роль',
        en: 'Enter role',
        ro: 'Introduceți rolul',
        uk: 'Введіть роль'
      },
      CHARACTER: {
        ru: 'Роль',
        en: 'Role',
        ro: 'Rolul',
        uk: 'Роль'
      },
      TONE: {
        ru: 'Тональность',
        en: 'Sentiment',
        ro: 'Tonalitate',
        uk: 'Тональність'
      },
      BUILD: {
        ru: 'Строить по',
        en: 'Build by',
        ro: 'Construiți de',
        uk: 'Будувати за'
      },
      ENTER_SOURCE_SUBTOPIC: {
        ru: 'Введите тематику источника',
        en: 'Enter the topic of the source',
        ro: 'Introduceți subiectul sursei',
        uk: 'Введіть тематику джерела'
      },
      SOURCE_SUBTOPIC: {
        ru: 'Тематика источника',
        en: 'Source subject',
        ro: 'Subiect sursă',
        uk: 'Тематика джерела'
      },
      ENTER_SOURCE: {
        ru: 'Введите источник',
        en: 'Enter source',
        ro: 'Introduceți sursa',
        uk: 'Введіть джерело'
      },
      SOURCE: {
        ru: 'Источник',
        en: 'Source',
        ro: 'Sursă',
        uk: 'Джерело'
      },
      ENTER_TYPE_MENTIONS: {
        ru: 'Введите тип упоминания',
        en: 'Enter mention type',
        ro: 'Introduceți tipul de mențiune',
        uk: 'Введіть тип згадки'
      },
      TYPE_MENTIONS: {
        ru: 'Тип упоминания',
        en: 'Mention Type',
        ro: 'Tipul menționat',
        uk: 'Тип згадки'
      },
      ENTER_TAG: {
        ru: 'Введите тег',
        en: 'Enter tag',
        ro: 'Introduceți eticheta',
        uk: 'Введіть тег'
      },
      TAG: {
        ru: 'Тег',
        en: 'Tag',
        ro: 'Etichetă',
        uk: 'Тег'
      },
      ENTER_TYPE_SOURCE: {
        ru: 'Введите тип источника',
        en: 'Enter source type',
        ro: 'Introduceți tipul sursă',
        uk: 'Введіть тип джерела'
      },
      TYPE_SOURCE: {
        ru: 'Тип источника',
        en: 'Source type',
        ro: 'Tip sursă',
        uk: 'Тип джерела'
      },
      ADD_CHART: {
        ru: 'Добавление графика',
        en: 'Adding a chart',
        ro: 'Adăugarea unui grafic',
        uk: 'Додавання графіка'
      },
      CREATE_PAGE: {
        ru: 'Создание страницы',
        en: 'Page creation',
        ro: 'Crearea de pagini',
        uk: 'Створення сторінки'
      },
      OK: {
        ru: 'Готово!',
        en: 'Done!',
        ro: 'Terminat!',
        uk: 'Готово!'
      },
      SUM: {
        ru: 'Сумма по',
        en: 'Amount per',
        ro: 'Suma pe',
        uk: 'Сума за'
      },
      SHOW_IN: {
        ru: 'Показывать в разрезе',
        en: 'Show in section',
        ro: 'Afișați în secțiune',
        uk: 'Показувати у розрізі'
      },
      KEYWORD: {
        ru: 'Ключевое слово',
        en: 'Keyword',
        ro: 'Cuvânt cheie',
        uk: 'Ключове слово'
      },
      ALL_KEY_WORDS: {
        ru: 'Все ключевые слова',
        en: 'All keyword',
        ro: 'Toate cuvintele cheie',
        uk: 'Всі ключові слова'
      },
      NO_CHANGE: {
        ru: 'Не выбрано',
        en: 'Not chosen',
        ro: 'Nu aleasă',
        uk: 'Не вибрано'
      },
      AUTO: {
        ru: 'Автоматически',
        en: 'Auto',
        ro: 'Auto',
        uk: 'Автоматично'
      },
      TYPE_CHART: {
        ru: 'Выберите тип графика',
        en: 'Select chart type',
        ro: 'Selectați tipul de diagramă',
        uk: 'Виберіть тип графіка'
      },
      ENTER_DISCRIPTION: {
        ru: 'Введите описание к графику',
        en: 'Enter a description for the chart',
        ro: 'Introduceți o descriere pentru grafic',
        uk: 'Введіть опис до графіка'
      },
      NOT_NECESSARY: {
        ru: 'необязательно',
        en: 'optional',
        ro: 'opțional',
        uk: 'не обов\'язково'
      },
      DISCRIPTION: {
        ru: 'Описание',
        en: 'Description',
        ro: 'Descriere',
        uk: 'Опис'
      },
      ENTER_NAME_CHART: {
        ru: 'Введите название графика',
        en: 'Enter the name of the graph',
        ro: 'Introduceți numele graficului',
        uk: 'Введіть назву графіка'
      },
      NAME_CHART: {
        ru: 'Название графика',
        en: 'Chart name',
        ro: 'Numele graficului',
        uk: 'Назва графіка'
      },
      NAME_PAGE: {
        ru: 'Название страницы',
        en: 'Page name',
        ro: 'Numele paginii',
        uk: 'Назва сторінки'
      },
      ENTER_NAME_PAGE: {
        ru: 'Введите название страницы',
        en: 'Enter page name',
        ro: 'Introduceți numele paginii',
        uk: 'Введіть назву сторінки'
      }
    },
    showBy: {
      AUTO: {
        ru: 'Автоматически',
        en: 'Auto',
        ro: 'Auto',
        uk: 'Автоматично'
      },
      DAY: {
        ru: 'По дням',
        en: 'By days',
        ro: 'De zile',
        uk: 'По днях'
      },
      WEEK: {
        ru: 'По неделям',
        en: 'By weeks',
        ro: 'De săptămâni',
        uk: 'По тижднях'
      },
      MONTH: {
        ru: 'По месяцам',
        en: 'By Monthses',
        ro: 'De luni',
        uk: 'По місяцях'
      }
    },
    bodyAddTagModal: {
      ASSIGN: {
        ru: 'Тегировать',
        en: 'Tagging',
        ro: 'Tagging',
        uk: 'Тегувати'
      },
      QUERY: {
        ru: 'Запрос для поиска',
        en: 'Search query',
        ro: 'Căutați interogare',
        uk: 'Запит для пошуку'
      },
      ENTER_QUERY: {
        ru: 'Введите свой запрос для поиска',
        en: 'Enter your search query',
        ro: 'Introduceți întrebarea dvs. de căutare',
        uk: 'Введіть свій запит для пошуку'
      },
      COLOR: {
        ru: 'Цвет',
        en: 'Color',
        ro: 'Culoare',
        uk: 'Колір'
      },
      ASSIGN_AUTO: {
        ru: 'Тегировать автоматически',
        en: 'Tagging automatically',
        ro: 'Etichetarea automată',
        uk: 'Тегувати автоматично'
      },
      ONLY_SELECTED: {
        ru: 'Только выбранной новости',
        en: 'Only for selected news',
        ro: 'Numai pentru știri selectate',
        uk: 'Тільки обраній новині'
      },
      ADD_ALL: {
        ru: 'Новости и всем ее дублям',
        en: 'The news and all of its duplicates',
        ro: 'Știrile și toate duplicatele sale',
        uk: 'Новині та усім її дублям'
      },
      NAME: {
        ru: 'Название',
        en: 'Name',
        ro: 'Nume',
        uk: 'Назва'
      },
      TAG: {
        ru: 'Тег',
        en: 'Tag',
        ro: 'Etichetă',
        uk: 'Тег'
      },
      KEYWORD: {
        ru: 'Ключевое слово',
        en: 'Keyword',
        ro: 'Cuvânt cheie',
        uk: 'Ключове слово'
      },
      ENTER_NAME_TAG: {
        ru: 'Введите название тега',
        en: 'Enter tag name',
        ro: 'Introduceți numele de etichetă',
        uk: 'Введіть назву тега'
      },
      ENTER_NAME_KEYWORD: {
        ru: 'Введите название ключевого слова',
        en: 'Enter keyword name',
        ro: 'Introduceți numele cuvintelor cheie',
        uk: 'Введіть назву ключового слова'
      },
      ALERT_NAME: {
        ru: 'Название оповещения',
        en: 'The name of alert',
        ro: 'Numele alertei',
        uk: 'Назва сповіщення'
      },
      ENTER_NAME_ALERT: {
        ru: 'Введите название оповещения',
        en: 'Enter alert name',
        ro: 'Introduceți numele alertei',
        uk: 'Введіть назву сповіщення'
      },
      QUERY_SEARCH: {
        ru: 'Поисковый запрос',
        en: 'Search query',
        ro: 'Căutați interogare',
        uk: 'Пошуковий запит'
      },
      ENTER_EXAMPLE: {
        ru: 'напр., Apple',
        en: 'e.g. Apple',
        ro: 'de exemplu. Apple',
        uk: 'напр., Apple'
      },
      USED: {
        ru: 'Использовано',
        en: 'Used',
        ro: 'Folosit',
        uk: 'Використано'
      },
      INFORM: {
        ru: 'Сообщите мне, когда теги будут проставлены',
        en: 'Notify me when the tags are applied',
        ro: 'Anunțați -mă când se aplică etichetele',
        uk: 'Повідомити мене, коли теги буде проставлено'
      },
      ENTER_ALTERNATIVE: {
        ru: 'Укажите через Enter альтернативные слова',
        en: 'Enter alternative keywords using Enter button',
        ro: 'Introduceți cuvinte cheie alternative folosind butonul Enter',
        uk: 'Вкажіть через Enter альтернативні слова'
      },
      ENTER_REQUIRED: {
        ru: 'Укажите через Enter обязательные слова',
        en: 'Enter the required words through Enter button',
        ro: 'Introduceți cuvintele necesare prin butonul Enter',
        uk: 'Вкажіть через Enter обов\'язкові слова'
      },
      SELECT_COUNTRIES: {
        ru: 'Выберите страны',
        en: 'Select countries',
        ro: 'Selectează țări',
        uk: 'Оберіть країни'
      },
      COUNTRIES: {
        ru: 'Cтраны',
        en: 'Countries',
        ro: 'Țări',
        uk: 'Країни'
      },
      ALL_COUNTRIES: {
        ru: 'Все страны',
        en: 'All countries',
        ro: 'Toate tarile',
        uk: 'Всі країни'
      },
      MEDIA_TYPE: {
        ru: 'Тип источника',
        en: 'Source type',
        ro: 'Tip sursă',
        uk: 'Тип джерела'
      },
      ENTER_SPECIFY: {
        ru: 'Укажите через Enter слова которых не должно быть',
        en: 'Enter the specify words that should not be there through Enter button',
        ro: 'Introduceți cuvintele specificați care nu ar trebui să fie acolo prin butonul Enter',
        uk: 'Вкажіть через Enter слова яких не має бути'
      },
      RESULT_HEADER: {
        ru: 'Этот результат не является полным выполнением поискового запроса, а лишь служит отображением ожидаемого результата.',
        en: 'This result is not the full execution of the search query, but only serves as a reflection of the expected result.',
        ro: 'Acest rezultat nu este execuția completă a interogării de căutare, ci servește doar ca o reflectare a rezultatului așteptat.',
        uk: 'Цей результат не є повним виконанням пошукового запиту, а лише слугує відображенням очікуваного результату.'
      },
      RESULT_KEYWORD_HEADER_INFO: {
        ru: 'Таким образом вы сможете настроить ваше ключевое слово максимально точно для получения наиболее релевантного результата. Если хотите более точный запрос или улучшить его - обращайтесь в поддержку в чате.',
        en: 'This way, you can customize your keyword as accurately as possible to get the most relevant result. If you want a more precise query or want to improve it, please contact our chat support.',
        ro: 'În acest fel, puteți personaliza cuvântul cheie cât mai exact posibil pentru a obține cel mai relevant rezultat.Dacă doriți o interogare mai precisă sau doriți să o îmbunătățiți, vă rugăm să contactați asistența noastră de chat.',
        uk: 'Таким чином ви зможете налаштувати ваше ключове слово максимально точно для отримання найбільш релевантного результату. Якщо хочете більш точний запит чи покращити його – звертайтесь до підтримки в чаті.'
      },
      RESULT_TAG_HEADER_INFO: {
        ru: 'Таким образом вы сможете настроить ваш тег максимально точно для получения наиболее релевантного результата. Если хотите более точный запрос или улучшить его - обращайтесь в поддержку в чате.',
        en: 'This way, you can customize your tag as accurately as possible to get the most relevant result. If you want a more precise query or want to improve it, please contact our chat support.',
        ro: 'În acest fel, vă puteți personaliza eticheta cât mai precis posibil pentru a obține cel mai relevant rezultat.Dacă doriți o interogare mai precisă sau doriți să o îmbunătățiți, vă rugăm să contactați asistența noastră de chat.',
        uk: 'Таким чином ви зможете налаштувати ваш тег максимально точно для отримання найбільш релевантного результату. Якщо хочете більш точний запит чи покращити його – звертайтесь до підтримки в чаті.'
      },
      SET_INTERVAL_KW: {
        ru: 'Установлен интервал поиска до 3 слов',
        en: 'Set search interval up to 3 words',
        ro: 'Setați intervalul de căutare până la 3 cuvinte',
        uk: 'Встановлено інтервал пошуку до 3 слів'
      },
      SET_INTERVAL_TAG: {
        ru: 'Установлен интервал поиска до 3 слов',
        en: 'Set search interval up to 3 words',
        ro: 'Setați intervalul de căutare până la 3 cuvinte',
        uk: 'Встановлено інтервал пошуку до 3 слів'
      },
      LOADING: {
        ru: 'Загрузка...',
        en: 'Loading...',
        ro: 'Se încarcă...',
        uk: 'Завантаження...'
      },
      NOT_ALL_DEFINED: {
        ru: 'Не все источники имеют определенную локацию.',
        en: 'Not all sources have a specific location.',
        ro: 'Nu toate sursele au o locație specifică.',
        uk: 'Не всі джерела мають визначену локацію.'
      },
      USED_KEYWORDS1: {
        ru: 'Вы использовали ',
        en: 'You have used ',
        ro: 'Ai folosit ',
        uk: 'Ви використали '
      },
      USED_KEYWORDS2: {
        ru: ' ключевых слов. Для увеличения количества ключевых слов. Обратитесь к своему менеджеру.',
        en: ' of the keywords. To increase the number of keywords. Contact your manager.',
        ro: ' a cuvintelor cheie.Pentru a crește numărul de cuvinte cheie.Contactați -vă managerul.',
        uk: ' ключових слів. Для збільшення кількості ключових слів. Зверніться до свого менеджера.'
      },
      DEFAULT_COUNTRIES_INFO: {
        ru: 'Применяется по умолчанию: все страны. Для изменения параметров географии выберите необходимые страны',
        en: 'Default: All countries. To change the geography settings, select the required countries',
        ro: 'Implicit: toate țările.Pentru a schimba setările de geografie, selectați țările necesare',
        uk: 'Застосовано по замовчуванню: всі країни. Для зміни параметрів географії виберіть необхідні країни'
      },
      DEFAULT_SOURCES_INFO: {
        ru: 'Применяется по умолчанию: все типы источников. Для изменения параметров выберите необходимые источники',
        en: 'Default: All source types. To change the settings, select the necessary sources',
        ro: 'Implicit: toate tipurile de sursă.Pentru a schimba setările, selectați sursele necesare',
        uk: 'Застосовано по замовчуванню: всі типи джерел. Для зміни параметрів виберіть необхідні джерела'
      },
      ALL_SOURCES: {
        ru: 'Все источники',
        en: 'All sources',
        ro: 'Toate sursele',
        uk: 'Всі джерела'
      },
      CONSTRUCTOR: {
        ru: 'Конструктор',
        en: 'Constructor',
        ro: 'Constructor',
        uk: 'Конструктор'
      },
      SEARCH_QUERY: {
        ru: 'Запрос для поиска',
        en: 'Search query',
        ro: 'Căutați interogare',
        uk: 'Запит для пошуку'
      },
      SHOW_MORE: {
        ru: ' Показать больше',
        en: ' Show more',
        ro: ' Arată mai mult',
        uk: ' Показати більше'
      },
      LAST_WEEK: {
        ru: 'за последнюю неделю',
        en: 'for the last week',
        ro: 'pentru ultima săptămână',
        uk: 'за останній тиждень'
      },
      LAST_2WEEK: {
        ru: 'за последние 2 недели',
        en: 'for the last 2 weeks',
        ro: 'în ultimele 2 săptămâni',
        uk: 'за останні 2 тижні'
      },
      ADD_WORD: {
        ru: '+ Слово',
        en: '+ Word',
        ro: '+ Cuvânt',
        uk: '+ Слово'
      },
      SWITCH_CONSTRUCTOR: {
        ru: 'Переключить на конструктор',
        en: 'Switch to the constructor',
        ro: 'Treceți la constructor',
        uk: 'Перемкнути на конструктор'
      },
      SWITCH_QUERY: {
        ru: 'Переключить на запрос для поиска',
        en: 'Switch to search query',
        ro: 'Treceți la interogarea de căutare',
        uk: 'Перемкнути на запит для пошуку'
      },
      CONSTRUCTOR_EXPLAIN: {
        ru: 'Конструктор дает возможность настроить запрос с помощью формы. Переключение в данный режим удалит все данные которые вы ввели в запросе для поиска.',
        en: 'The constructor allows you to customize a query using a form. Switching to this mode will delete all the data you entered in the search query.',
        ro: 'Constructorul vă permite să personalizați o interogare folosind un formular.Trecerea la acest mod va șterge toate datele pe care le -ați introdus în interogarea de căutare.',
        uk: 'Конструктор дає можливість налаштувати запит за допомогою форми. Перемикання в даний режим видалить всі дані які ви ввели в запиті для пошуку.'
      },
      QUERY_EXPLAIN: {
        ru: 'Запрос для поиска дает возможность прописать более расширенный запрос с помощью операторов. Переключение в данный режим удалит все данные которые вы ввели в форме.',
        en: 'The search query allows you to write a more advanced query using operators. Switching to this mode will delete all the data you entered in the form.',
        ro: 'Interogarea de căutare vă permite să scrieți o interogare mai avansată folosind operatori.Trecerea la acest mod va șterge toate datele pe care le -ați introdus în formular.',
        uk: 'Запит для пошуку дає можливість прописати більш розширений запит за допомогою операторів. Перемикання в даний режим видалить всі дані які ви ввели в формі.'
      },
      DISPLAY_MENTIONS: {
        ru: 'Выводить упоминания без данных о географии',
        en: 'Display mentions without geo data',
        ro: 'Mențiuni de afișare fără date Geo',
        uk: 'Виводити згадки без даних про географію'
      },
      KEYWORD_CREATED: {
        ru: 'Ключевое слово создано!',
        en: 'Keyword created!',
        ro: 'Cuvânt cheie creat!',
        uk: 'Ключове слово створене!'
      },
      NEED_TIME: {
        ru: 'Нам понадобится некоторое время, чтобы собрать по нему новости. Ожидайте оповещения на ваш e-mail. Не забудьте проверить спам.',
        en: 'It will take us some time to gather news on it. Expect a notification to your email. Don\'t forget to check your spam folder.',
        ro: 'Ne va lua ceva timp să adunăm știri despre asta.Așteptați -vă la o notificare la e -mailul dvs.Nu uitați să vă verificați folderul de spam.',
        uk: 'Нам знадобиться деякий час, щоб зібрати по ньому новини. Очікуйте сповіщення на ваш e-mail. Не забудьте перевірити спам.'
      },
      REPORT_FIXED: {
        ru: 'Запрос зафиксирован!',
        en: 'The request is fixed!',
        ro: 'Cererea este fixată!',
        uk: 'Запит зафіксовано!'
      },
      MANAGER_WORKING: {
        ru: 'Ваш менеджер уже работает над запросом и свяжется с вами в ближайшее время',
        en: 'Your manager is already working on your request and will contact you shortly',
        ro: 'Managerul dvs. lucrează deja la cererea dvs. și vă va contacta în scurt timp',
        uk: 'Ваш менеджер вже працює над запитом та зв\'яжеться з вами найближчим часом'
      },
      RECOMEND_TEXT: {
        ru: 'Вы использовали более 6 слов в этом поле. Для точности поиска, рекомендуем также использовать операторы OR, AND, NOT',
        en: 'You have used more than 6 words in this field. For search accuracy, we also recommend using the OR, AND, NOT operators',
        ro: 'Ați folosit mai mult de 6 cuvinte în acest domeniu.Pentru precizia căutării, vă recomandăm, de asemenea, utilizarea operatorilor sau nu',
        uk: 'Ви використали понад 6 слів в цьому полі. Для точності пошуку, радимо також використовувати оператори OR, AND, NOT'
      },
      WRONG_QUERY: {
        ru: 'Вы ввели неверный поисковый запрос',
        en: 'You entered an incorrect search query',
        ro: 'Ați introdus o interogare incorectă de căutare',
        uk: 'Ви ввели невірний пошуковий запит'
      },
      TO_ADVANCED_MODE: {
        ru: 'Перемкнути в Розширений режим',
        en: 'Switch to Advanced mode',
        ro: 'Treceți la modul avansat',
        uk: 'Перемкнути в Розширений режим'
      },
      ADVANCED_MODE: {
        ru: 'Розширений режим',
        en: 'Advanced mode',
        ro: 'Modul avansat',
        uk: 'Розширений режим'
      },
      SELECT_CONSTRUCTOR: {
        ru: 'Выберите конструктор поисковых запросов',
        en: 'Select the search query builder',
        ro: 'Selectați constructorul de interogare de căutare',
        uk: 'Оберіть конструктор пошукових запитів'
      },
      SIMPLE: {
        ru: 'Простой',
        en: 'Simple',
        ro: 'Simplu',
        uk: 'Простий'
      },
      BASIC: {
        ru: 'Базовый',
        en: 'Basic',
        ro: 'De bază',
        uk: 'Базовий'
      },
      SIMPLE_TEXT: {
        ru: 'Позволяет легко и быстро построить базовые поисковые запросы',
        en: 'Allows you to quickly and easily build basic search queries',
        ro: 'Vă permite să construiți rapid și ușor interogările de căutare de bază',
        uk: 'Дозволяє легко та швидко побудувати базові пошукові запити'
      },
      ADVANCED: {
        ru: 'Расширенный',
        en: 'Advanced',
        ro: 'Avansat',
        uk: 'Розширений'
      },
      ADVANCED_TEXT: {
        ru: 'Позволяет построить многофункциональные поисковые запросы',
        en: 'Allows you to build multifunctional search queries',
        ro: 'Vă permite să construiți interogări de căutare multifuncționale',
        uk: 'Дозволяє побудувати багатофункціональні пошукові запити'
      },
      EXAMPLE_APPLE: {
        ru: 'напр., Apple Inc',
        en: 'e.g. Apple Inc',
        ro: 'de exemplu. Apple Inc',
        uk: 'напр., Apple Inc'
      },
      EXAMPLE_iPHONE: {
        ru: 'напр., iPhone',
        en: 'e.g. iPhone',
        ro: 'de exemplu. iPhone',
        uk: 'напр., iPhone'
      },
      EXAMPLE_APPLEFRUIT: {
        ru: 'напр., Applefruit',
        en: 'e.g. Applefruit',
        ro: 'de exemplu. Applefruit',
        uk: 'напр., Applefruit'
      }
    },
    bodySendComplain: {
      INCORRECT: {
        ru: 'Неправильно прокодированное ключевое слово:',
        en: 'Incorrectly encoded keyword:',
        ro: 'Cuvânt cheie codificat incorect:',
        uk: 'Неправильно прокодоване ключове слово:'
      },
      PARAM: {
        ru: 'Параметр, в котором содержится ошибка:',
        en: 'The parameter that contains the error:',
        ro: 'Parametrul care conține eroarea:',
        uk: 'Параметр, у якому міститься помилка:'
      },
      TONE: {
        ru: 'Тональность',
        en: 'Tonality',
        ro: 'Tonalitate',
        uk: 'Тональність' },
      CHARACTER: {
        ru: 'Роль',
        en: 'Role',
        ro: 'Rolul',
        uk: 'Роль' },
      UNGROUPED: {
        ru: 'Не удалена нерелевантная информация',
        en: 'Irrelevant information has not been removed',
        ro: 'Informații irelevante nu au fost eliminate',
        uk: 'Не видалена нерелевантна інформація' },
      IRRELEVANT: {
        ru: 'Не сгруппированная новость',
        en: 'Ungrouped news',
        ro: 'Vești necompletate',
        uk: 'Не згрупована новина' },
      HOW_CORRECT: {
        ru: 'Как правильно:',
        en: 'How to correctly:',
        ro: 'Cum să corect:',
        uk: 'Як правильно:' }
    },
    bodyCreateDashboardModal: {
      NAME_DASHBOARD: {
        ru: 'Название дашбода',
        en: 'Dashboard name',
        ro: 'Numele tabloului de bord',
        uk: 'Назва дашбоду'
      },
      ENTER_NAME_DASHBOARD: {
        ru: 'Введите название дашборда',
        en: 'Enter the name of the dashboard',
        ro: 'Introduceți numele tabloului de bord',
        uk: 'Введіть назву дашборду'
      },
      KEYWORD: {
        ru: 'Ключевое слово',
        en: 'Keyword',
        ro: 'Cuvânt cheie',
        uk: 'Ключове слово'
      },
      TAG: {
        ru: 'Тег',
        en: 'Tag',
        ro: 'Etichetă',
        uk: 'Тег'
      },
      ALL_KEY_WORDS: {
        ru: 'Все ключевые слова',
        en: 'All keywords',
        ro: 'Toate cuvintele cheie',
        uk: 'Усі ключові слова'
      },
      ALL_TAGS: {
        ru: 'Все теги',
        en: 'All tags',
        ro: 'Toate etichetele',
        uk: 'Усі теги'
      }
    },
    bodyCreatePageModal: {
      NAME_PAGE: {
        ru: 'Название страницы',
        en: 'Page name',
        ro: 'Numele paginii',
        uk: 'Назва сторінки'
      },
      ENTER_NAME_PAGE: {
        ru: 'Введите название страницы',
        en: 'Enter page name',
        ro: 'Introduceți numele paginii',
        uk: 'Введіть назву сторінки'
      }
    },
    bodyDelChartModal: {
      SURE_DEL_CHART: {
        ru: 'Вы уверены, что хотите удалить график',
        en: 'Are you sure you want to delete the graph',
        ro: 'Sunteți sigur că doriți să ștergeți graficul',
        uk: 'Ви впевнені, що хочете видалити графік'
      }
    },
    bodyDelPageModal: {
      SURE_DEL_PAGE: {
        ru: 'Вы уверены, что хотите удалить страницу',
        en: 'Are you sure you want to delete the page',
        ro: 'Sunteți sigur că doriți să ștergeți pagina',
        uk: 'Ви впевнені, що хочете видалити сторінку'
      },
      SURE_DEL_TAG: {
        ru: 'Вы уверены, что хотите удалить тег ',
        en: 'Are you sure you want to delete the tag ',
        ro: 'Sunteți sigur că doriți să ștergeți eticheta ',
        uk: 'Ви впевнені, що хочете видалити тег '
      },
      SURE_SAVE_CHANGES: {
        ru: 'Вы уверены, что хотите сохранить изменения на странице',
        en: 'Are you sure you want to save changes to the page',
        ro: 'Sunteți sigur că doriți să salvați modificările la pagină',
        uk: 'Ви впевнені, що хочете зберегти зміни на сторінці'
      },
      SAVE_CHANGES: {
        ru: 'Хотите сохранить изменения в фильтрах на странице',
        en: 'Would you like to the filters on page',
        ro: 'Doriți să faceți filtrele pe pagină',
        uk: 'Бажаєте зберегти зміни у фільтрах на сторінці'
      }
    },
    bodyConfirmEditTags: {
      ADD: {
        ru: 'Вы добавили теги',
        en: 'You added tags',
        ro: 'Ai adăugat etichete',
        uk: 'Ви додали теги'
      },
      ADD_ONE: {
        ru: 'Вы добавили тег',
        en: 'You added tag',
        ro: 'Ai adăugat etichetă',
        uk: 'Ви додали тег'
      },
      DELETE: {
        ru: 'Вы удалили теги',
        en: 'You have removed',
        ro: 'Ai eliminat',
        uk: 'Ви видалили теги'
      },
      DELETE_ONE: {
        ru: 'Вы удалили тег',
        en: 'You have removed tag',
        ro: 'Ați eliminat eticheta',
        uk: 'Ви видалили тег'
      },
      AND_DELETE: {
        ru: 'и удалили теги',
        en: 'and removed tags',
        ro: 'și a eliminat etichetele',
        uk: 'та видалили теги'
      },
      AND_DELETE_ONE: {
        ru: 'и удалили тег',
        en: 'and removed tag',
        ro: 'și a eliminat eticheta',
        uk: 'та видалили тег'
      },
      TO_ARTICLE: {
        ru: 'к этой статье.',
        en: 'to checked articles.',
        ro: 'la articole verificate.',
        uk: 'до цієї статті.'
      },
      FROM_ARTICLE: {
        ru: 'из этой статьи',
        en: 'from this article',
        ro: 'din acest articol',
        uk: 'з цієї статті.'
      },
      TO_ARTICLES: {
        ru: 'к выбранным статьям.',
        en: 'to checked articles.',
        ro: 'la articole verificate.',
        uk: 'до обраних статей.'
      },
      FROM_ARTICLES: {
        ru: 'из выбранных статтей',
        en: 'from checked articles.',
        ro: 'din articole verificate.',
        uk: 'з обраних статей.'
      },
      APPLY_CHANGES: {
        ru: 'Хотите применить изменения?',
        en: 'Do you want to apply the changes?',
        ro: 'Doriți să aplicați modificările?',
        uk: 'Бажаєте застосувати зміни?'
      },
      NO_ACTIONS: {
        ru: 'Не выполнено никаких действий, которые необходимо применить',
        en: 'No actions have been taken that should be taken',
        ro: 'Nu a fost luată nicio măsură care ar trebui să fie luată',
        uk: 'Не виконано жодних дій, які необхідно застосувати'
      }

    },
    bodyDelKeyword: {
      CONFIRM_DELETE_1: {
        ru: 'Вы собираетесь удалить ключевое слово',
        en: 'You are about to delete a keyword',
        ro: 'Sunteți pe cale să ștergeți un cuvânt cheie',
        uk: 'Ви збираєтесь видалити ключове слово'
      },
      CONFIRM_DELETE_2: {
        ru: 'Чтобы подтвердить удаление введите название этого ключевого слова.',
        en: 'To confirm the deletion, enter the name of the keyword.',
        ro: 'Pentru a confirma ștergerea, introduceți numele cuvântului cheie.',
        uk: 'Щоб підтвердити видалення введіть назву цього ключового слова.'
      },
      KEYWORD: {
        ru: 'Ключевое слово',
        en: 'Keyword',
        ro: 'Cuvânt cheie',
        uk: 'Ключове слово'
      },
      ENTER_KEYWORD_NAME: {
        ru: 'Введите название ключевого слова',
        en: 'Enter the name of the keyword',
        ro: 'Introduceți numele cuvântului cheie',
        uk: 'Введіть назву ключового слова'
      }
    },
    bodyDelDashboardModal: {
      SURE_DEL_DASHBOARD: {
        ru: 'Вы уверены, что хотите удалить дашборд',
        en: 'Are you sure you want to delete the dashboard',
        ro: 'Sunteți sigur că doriți să ștergeți tabloul de bord',
        uk: 'Ви впевнені, що хочете видалити дашборд'
      }
    },
    bodyDelWidgetModal: {
      SURE_DEL_WIDGET: {
        ru: 'Вы уверены, что хотите удалить виджет',
        en: 'Are you sure you want to delete the widget',
        ro: 'Sunteți sigur că doriți să ștergeți widgetul',
        uk: 'Ви впевнені, що хочете видалити віджет'
      }
    },
    bodyRenamePageModal: {
      ENTER_NEW_NAME_PAGE: {
        ru: 'Введите новое название страницы',
        en: 'Enter a new page name',
        ro: 'Introduceți un nou nume de pagină',
        uk: 'Введіть нову назву сторінки'
      },
      ENTER_NAME_PAGE: {
        ru: 'Введите название страницы',
        en: 'Enter page name',
        ro: 'Introduceți numele paginii',
        uk: 'Введіть назву сторінки'
      },
      MAX_LENGTH: {
        ru: 'Максимальная длина названия страницы - 25 символов',
        en: 'The maximum length of the page title is 25 characters',
        ro: 'Lungimea maximă a titlului paginii este de 25 de caractere',
        uk: 'Максимальна довжина назви сторінки - 25 символів'
      },
      CHOOSE_FILTERS: {
        ru: 'Добавить выбранные фильтры',
        en: 'Add selected filters',
        ro: 'Adăugați filtre selectate',
        uk: 'Додати вибрані фільтри'
      },
      SURE_EDIT_1: {
        ru: 'Вы собираетесь отредактировать поисковый запрос. Новости по ключевому слову',
        en: 'You are about to edit a search query. News by keyword',
        ro: 'Sunteți pe cale să editați o interogare de căutare.Știri după cuvinte cheie',
        uk: 'Ви збираєтесь відредагувати пошуковий запит. Новини по ключовому слову '
      },
      SURE_EDIT_2: {
        ru: ' по предыдущему поисковому запросу будут сохранены. При подтверждении изменения поискового запроса новые упоминания будут подтягиваться в соответствии с новым запросом.',
        en: ' for the previous search query will be saved. When you confirm the change of the search query, new mentions will be pulled up in accordance with the new query.',
        ro: ' pentru interogarea anterioară de căutare va fi salvată.Când confirmați schimbarea interogării de căutare, noile mențiuni vor fi trase în conformitate cu noua interogare.',
        uk: ' по попередньому пошуковому запиту будуть збережені. При підтверженні зміни пошукового запиту нові згадки підтягуватимуться відповідно до нового запиту.'
      },
      SURE_EDIT_3: {
        ru: 'Как только вы начнете редактировать поисковый запрос переключение обратно в Базовый режим станет невозможным. Вы уверены что хотите редактировать поисковый запрос?',
        en: 'As soon as you start editing your search query, you won\'t be able to switch back to Basic mode. Are you sure you want to edit your search query?',
        ro: 'De îndată ce începeți să editați interogarea de căutare, ați reușit să vă puteți întoarce la modul de bază.Ești sigur că vrei să editezi interogarea de căutare?',
        uk: 'Як тільки ви почнете редагувати пошуковий запит перемикання назад в Базовий режим стане неможливим. Ви впевнені що хочете редагувати пошуковий запит?'
      },
      SURE_SAVE_KW: {
        ru: 'По вашему запросу собрано более 10 000 упоминаний за последнюю неделю. Вы уверены, что хотите добавить ключевое слово?',
        en: 'Your query has more than 10,000 mentions over the past week. Are you sure you want to add a keyword/tag?',
        ro: 'Interogarea dvs. are peste 10000 de mențiuni în ultima săptămână.Sunteți sigur că doriți să adăugați un cuvânt cheie/etichetă?',
        uk: 'За вашим запитом зібрано більше 10 000 згадок за останній тиждень. Ви впевнені, що хочете додати ключове слово?'
      },
      SURE_EDIT_KW: {
        ru: 'По вашему запросу собрано более 10 000 упоминаний за последнюю неделю. Вы уверены, что хотите редактировать ключевое слово?',
        en: 'Your query has more than 10,000 mentions over the past week. Are you sure you want to edit a keyword?',
        ro: 'Interogarea dvs. are peste 10000 de mențiuni în ultima săptămână.Ești sigur că vrei să editezi un cuvânt cheie?',
        uk: 'За вашим запитом зібрано більше 10 000 згадок за останній тиждень. Ви впевнені, що хочете редагувати ключове слово?'
      },
      SURE_SAVE_TAG: {
        ru: 'По вашему запросу собрано более 10 000 упоминаний за последнюю неделю. Вы уверены, что хотите добавить тег?',
        en: 'Your query has more than 10,000 mentions over the past week. Are you sure you want to add a tag?',
        ro: 'Interogarea dvs. are peste 10000 de mențiuni în ultima săptămână.Ești sigur că vrei să adaugi o etichetă?',
        uk: 'За вашим запитом зібрано більше 10 000 згадок за останній тиждень. Ви впевнені, що хочете додати тег?'
      },
      SURE_EDIT_TAG: {
        ru: 'По вашему запросу собрано более 10 000 упоминаний за последнюю неделю. Вы уверены, что хотите редактировать тег?',
        en: 'Your query has more than 10,000 mentions over the past week. Are you sure you want to edit a tag?',
        ro: 'Interogarea dvs. are peste 10000 de mențiuni în ultima săptămână.Ești sigur că vrei să editezi o etichetă?',
        uk: 'За вашим запитом зібрано більше 10 000 згадок за останній тиждень. Ви впевнені, що хочете редагувати тег?'
      },
      SURE_SAVE_QUERY: {
        ru: 'По вашему запросу собрано более 10 000 упоминаний за последнюю неделю. Вы уверены, что хотите добавить поисковый запрос?',
        en: 'Your query has more than 10,000 mentions over the past week. Are you sure you want to add a search query?',
        ro: 'Interogarea dvs. are peste 10000 de mențiuni în ultima săptămână.Ești sigur că vrei să adaugi o interogare de căutare?',
        uk: 'За вашим запитом зібрано більше 10 000 згадок за останній тиждень. Ви впевнені, що хочете додати пошуковий запит?'
      },
      SURE_EDIT_QUERY: {
        ru: 'По вашему запросу собрано более 10 000 упоминаний за последнюю неделю. Вы уверены, что хотите редактировать поисковый запрос?',
        en: 'Your query has more than 10,000 mentions over the past week. Are you sure you want to edit a search query?',
        ro: 'Interogarea dvs. are peste 10000 de mențiuni în ultima săptămână.Ești sigur că vrei să editezi o interogare de căutare?',
        uk: 'За вашим запитом зібрано більше 10 000 згадок за останній тиждень. Ви впевнені, що хочете редагувати пошуковий запит?'
      }
    },
    bodyRenameDashboardModal: {
      ENTER_NEW_NAME_DASHBOARD: {
        ru: 'Введите новое название дашборда',
        en: 'Enter a new name for the dashboard',
        ro: 'Introduceți un nou nume pentru tabloul de bord',
        uk: 'Введіть нову назву дашборду'
      }
    },
    bodyWhatsNewModal: {
      LEARN_MORE: {
        ru: 'Узнать больше',
        en: 'To learn more',
        ro: 'Pentru a afla mai multe',
        uk: 'Дізнатися більше'
      }
    },

    bodySendCommentModal: {
      ENTER_COMMENT: {
        ru: 'Поделитесь своим мнением. Если у вас есть вопросы, обратитесь в чат службы поддержки.',
        en: 'Share your opinion. If you have questions, please contact the support chat.',
        ro: 'Împărtășește -ți părerea.Dacă aveți întrebări, vă rugăm să contactați chatul de asistență.',
        uk: 'Поділіться своєю думкою. Якщо у вас є запитання, зверніться до чату служби підтримки.'
      },
      ATTACH_SCRIN: {
        ru: 'Прикрепить скриншот',
        en: 'Attach a screenshot',
        ro: 'Atașați o captură de ecran',
        uk: 'Прикріпити скріншот'
      }
    },
    bodyProposeSource: {
      ENTER_LINK: {
        ru: 'Введите ссылку',
        en: 'Enter the link',
        ro: 'Introduceți linkul',
        uk: 'Введіть посилання'
      },
      ENTER_URL: {
        ru: 'Введите URL источника',
        en: 'Enter the source URL',
        ro: 'Introduceți adresa URL sursă',
        uk: 'Введіть URL джерела'
      },
      MAX_LENGTH: {
        ru: 'Максимальная длина названия источника - 500 символов',
        en: 'The maximum length of the source name is 500 characters',
        ro: 'Lungimea maximă a numelui sursă este de 500 de caractere',
        uk: 'Максимальна довжина назви джерела - 500 символів'
      },
      MIN_LENGTH: {
        ru: 'Минимальная длина названия источника - 4 символа',
        en: 'The minimum length of the source name is 4 characters',
        ro: 'Lungimea minimă a numelui sursă este de 4 caractere',
        uk: 'Мінімальна довжина назви джерела - 4 символи'
      }
    },
    bodySelectConstructor: {
      SELECT_CONSTRUCTOR: {
        ru: 'Выберите конструктор поисковых запросов',
        en: 'Select the search query builder',
        ro: 'Selectați constructorul de interogare de căutare',
        uk: 'Оберіть конструктор пошукових запитів'
      },
      SIMPLE: {
        ru: 'Простой',
        en: 'Simple',
        ro: 'Simplu',
        uk: 'Простий'
      },
      BASIC: {
        ru: 'Базовый',
        en: 'Basic',
        ro: 'De bază',
        uk: 'Базовий'
      },
      SIMPLE_TEXT: {
        ru: 'Позволяет легко и быстро построить базовые поисковые запросы',
        en: 'Allows you to quickly and easily build basic search queries',
        ro: 'Vă permite să construiți rapid și ușor interogările de căutare de bază',
        uk: 'Дозволяє легко та швидко побудувати базові пошукові запити'
      },
      ADVANCED: {
        ru: 'Расширенный',
        en: 'Advanced',
        ro: 'Avansat',
        uk: 'Розширений'
      },
      ADVANCED_TEXT: {
        ru: 'Позволяет построить многофункциональные поисковые запросы',
        en: 'Allows you to build multifunctional search queries',
        ro: 'Vă permite să construiți interogări de căutare multifuncționale',
        uk: 'Дозволяє побудувати багатофункціональні пошукові запити'
      }
    },
    rightModal: {
      MENTIONS: {
        ru: 'Упоминания',
        en: 'Mentions',
        ro: 'Mențiuni',
        uk: 'Згадування'
      },
      NOTEBOOK: {
        ru: 'Мой блокнот',
        en: 'My notepad',
        ro: 'Carnețelul meu',
        uk: 'Мій блокнот'
      },
      PLACEHOLDER_NB: {
        ru: 'Запишите что-нибудь...',
        en: 'Write something...',
        ro: 'Scrie ceva...',
        uk: 'Запишіть щось...'
      }
    },
    creatorPlaceCard: {
      CREATOR: {
        ru: 'Автор',
        en: 'Author',
        ro: 'Autorul',
        uk: 'Автор'
      },
      CREATOR_AUDIENCE: {
        ru: 'Аудитория автора',
        en: 'Author\'s audience',
        ro: 'Autorul publicului',
        uk: 'Аудиторія автора'
      },
      PLACE_MENTION: {
        ru: 'Место публикации',
        en: 'Place of publication',
        ro: 'Locul publicării',
        uk: 'Місце публікації'
      },
      AUDIENCE_PLACE: {
        ru: 'Аудитория места публикации',
        en: 'Audience of the place of publication',
        ro: 'Publicul locului de publicare',
        uk: 'Аудиторія місця публікації'
      },
      TOTAL_AUDIENCE: {
        ru: 'Суммарная аудитория источника за месяц',
        en: 'The total audience of the source for the month',
        ro: 'Publicul total al sursei pentru lună',
        uk: 'Суммарная аудитория источника за месяц'
      },
      AVE: {
        ru: 'Ave',
        en: 'Ave',
        ro: 'Ave',
        uk: 'Ave'
      },
      CIRCULATION: {
        ru: 'Тираж',
        en: 'Circulation',
        ro: 'Circulaţie',
        uk: 'Тираж'
      },
      AVERAGE_VIEWS: {
        ru: 'Среднее кол-во просмотров материала на этом источнике',
        en: 'The average number of views of the material on this source',
        ro: 'Numărul mediu de vizualizări ale materialului pe această sursă',
        uk: 'Середнє кол-во переглядів матеріалу на цьому джерелі'
      },
      NUMBER_MENTIONS: {
        ru: 'Количество упоминаний: ',
        en: 'Number of mentions: ',
        ro: 'Numărul de mențiuni: ',
        uk: 'Кількість згадок: '
      },
      COUNTRY: {
        ru: 'Страна: ',
        en: 'Country: ',
        ro: 'Țară: ',
        uk: 'Країна: '
      },
      REGION: {
        ru: 'Регион: ',
        en: 'Region: ',
        ro: 'Regiune: ',
        uk: 'Регіон: '
      },
      CITY: {
        ru: 'Город: ',
        en: 'City: ',
        ro: 'Oraș: ',
        uk: 'Місто: '
      },
      AGE: {
        ru: 'Возраст: ',
        en: 'Age: ',
        ro: 'Vârstă: ',
        uk: 'Вік: '
      },
      GENDER: {
        ru: 'Пол: ',
        en: 'Gender: ',
        ro: 'Gen: ',
        uk: 'Стать: '
      },
      LEVEL: {
        ru: 'Уровень: ',
        en: 'Level: ',
        ro: 'Nivel: ',
        uk: 'Рівень: '
      },
      SUBJECT: {
        ru: 'Тематика: ',
        en: 'Subject: ',
        ro: 'Subiect: ',
        uk: 'Тематика: '
      },
      MEDIA_HOLDING: {
        ru: 'Медиа холдинг: ',
        en: 'Media holding: ',
        ro: 'Media Holding: ',
        uk: 'Медіа холдинг: '
      },
      UNDEFINED: {
        ru: 'не определено',
        en: 'undefined',
        ro: 'nedefinit',
        uk: 'не визначено'
      }

    },
    searchWindow: {
      SEARCH_PUBLICATIONS: {
        ru: 'Поиск по публикациям',
        en: 'Publication search',
        ro: 'Căutare publicații',
        uk: 'Пошук за публікаціями'
      },
      ENTER_WORDS: {
        ru: 'Введите поисковые слова',
        en: 'Enter search words',
        ro: 'Introduceți cuvinte de căutare',
        uk: 'Введіть пошукові слова'
      },
      INSTRUCTION: {
        ru: 'Инструкция по поисковым запросам',
        en: 'Search query instructions',
        ro: 'Căutați instrucțiuni de interogare',
        uk: 'Інструкція з пошукових запитів'
      },
      TAB: {
        ru: 'Укажите варианты написания названия через пробел:',
        en: 'Specify options for spelling the name separated by a space:',
        ro: 'Specificați opțiunile pentru a ortografia numele separat de un spațiu:',
        uk: 'Вкажіть варіанти написання назви через пробіл:'
      },
      QUOTES: {
        ru: 'Если название состоит из двух и более слов, возьмите его в кавычки:',
        en: 'If the name consists of two or more words, put it in quotation marks:',
        ro: 'Dacă numele este format din două sau mai multe cuvinte, puneți în ghilimele:',
        uk: 'Якщо назва складається з двох і більше слів, візьміть її в лапки:'
      },
      STARS: {
        ru: 'Вместо окончания которое может меняться, поставьте символ звёздочки:',
        en: 'Instead of an ending that can change, put an asterisk symbol:',
        ro: 'În loc de un final care se poate schimba, pune un simbol asterisc:',
        uk: 'Замість закінчення, яке може змінюватися, поставте символ зірочки:'
      },
      AND: {
        ru: 'Чтобы использовать в поиске несколько разных запросов, воспользуйтесь оператором AND между ними:',
        en: 'To use several different queries in your search, use the AND operator between them:',
        ro: 'Pentru a utiliza mai multe întrebări diferite în căutarea dvs., utilizați operatorul și între ele:',
        uk: 'Щоб використовувати кілька запитів, скористайтеся оператором AND між ними:'
      },
      MORE: {
        ru: 'Больше о работе с поисковыми запросами',
        en: 'More about working with search queries',
        ro: 'Mai multe despre colaborarea cu întrebările de căutare',
        uk: 'Більше про роботу з пошуковими запитами'
      }
    },
    datepicker: {
      COMPARE: {
        ru: 'Сравнить с',
        en: 'Compare with',
        ro: 'Compara cu',
        uk: 'Порівняти з'
      },
      TODAY: {
        ru: 'Сегодня',
        en: 'Today',
        ro: 'Astăzi',
        uk: 'Сьогодні'
      },
      YESTERDAY: {
        ru: 'Вчера',
        en: 'Yesterday',
        ro: 'Ieri',
        uk: 'Вчора'
      },
      DAY_BEFORE_YESTERDAY: {
        ru: 'Позавчера',
        en: 'Day before yesterday',
        ro: 'Înainte de ieri',
        uk: 'Позавчора'
      },
      WEEK: {
        ru: 'Неделя',
        en: 'Week',
        ro: 'Săptămână',
        uk: 'Тиждень'
      },
      MONTH: {
        ru: 'Месяц',
        en: 'Month',
        ro: 'Lună',
        uk: 'Місяць'
      },
      QUARTER: {
        ru: 'Квартал',
        en: 'Quarter',
        ro: 'Sfert',
        uk: 'Квартал'
      },
      SELECTED_PERIOD: {
        ru: 'Выбранный период',
        en: 'Selected period',
        ro: 'Perioada selectată',
        uk: 'Вибраний період'
      },
      COMPARISON_PERIOD: {
        ru: 'Период сравнения',
        en: 'Comparison period',
        ro: 'Perioada de comparație',
        uk: 'Період порівняння'
      },
      BY_DATE: {
        ru: 'По дате',
        en: 'By Date',
        ro: 'După dată',
        uk: 'За датою'
      },
      RELEASE: {
        ru: 'Выхода',
        en: 'Release',
        ro: 'Eliberare',
        uk: 'Виходу'
      },
      PRODUCTION: {
        ru: 'Постановки',
        en: 'Proceed',
        ro: 'Continua',
        uk: 'Постановки'
      },
      BEGIN_DATE: {
        ru: 'Дата начала',
        en: 'Start date',
        ro: 'Data de început',
        uk: 'Дата початку'
      },
      END_DATE: {
        ru: 'Дата окончания',
        en: 'Expiration date',
        ro: 'Data expirării',
        uk: 'Дата закінчення'
      },
      CHOOSE: {
        ru: 'Выбрать',
        en: 'Choose',
        ro: 'Alege',
        uk: 'Обрати'
      },
      PREVIOUS_PERIOD: {
        ru: 'Предыдущий период',
        en: 'Previous period',
        ro: 'Perioada anterioară',
        uk: 'Попередній період'
      },
      LAST_YEAR: {
        ru: 'Предыдущий год',
        en: 'Last year',
        ro: 'Anul trecut',
        uk: 'Попередній рік'
      }

    },
    mentionWidget: {
      positive: {
        ru: 'Позитив',
        en: 'Positive',
        ro: 'Pozitiv',
        uk: 'Позитив'
      },
      neutral: {
        ru: 'Нейтрал',
        en: 'Neutral',
        ro: 'Neutru',
        uk: 'Нейтрал'
      },
      negative: {
        ru: 'Негатив',
        en: 'Negative',
        ro: 'Negativ',
        uk: 'Негатив'
      }
    },
    mentions: {
      ALL_MENTIONS: {
        ru: 'Все упоминания',
        en: 'All mentions',
        ro: 'Toate mențiunile',
        uk: 'Всі згадки'
      },
      NEW_MENTIONS: {
        ru: ' новых упоминаний',
        en: ' new mentions',
        ro: ' noile mențiuni',
        uk: ' нових згадок'
      },
      TOTAL: {
        ru: ' всего',
        en: ' total',
        ro: ' total',
        uk: ' всього'
      },
      ORIGINALS: {
        ru: ' оригиналов',
        en: ' originals',
        ro: ' originale',
        uk: ' оригіналів'
      },
      SHOW_MORE1: {
        ru: ' Показать еще',
        en: ' Show more',
        ro: ' Arată mai mult',
        uk: ' Показати ще'
      },
      SHOW_MORE: {
        ru: ' Показать больше',
        en: ' Show more',
        ro: ' Arată mai mult',
        uk: ' Показати більше'
      }
    },
    subscribeDuplicates: {
      SUBSCRIBE_DUBLICATES_MENTIONS: {
        ru: 'Подписаться на дубли упоминания:',
        en: 'Subscribe to duplicate mentions:',
        ro: 'Abonați -vă la mențiuni duplicate:',
        uk: 'Підписатися на дублі згадки:'
      },
      SELECT_METHOD: {
        ru: 'Выберите способ получения оповещений:',
        en: 'Select the method of receiving the notification:',
        ro: 'Selectați metoda de a primi notificarea:',
        uk: 'Виберіть спосіб отримання оповіщень:'
      },
      SELECT_ACCOUNTS: {
        ru: 'Выберите аккаунты',
        en: 'Select accounts',
        ro: 'Selectați conturi',
        uk: 'Виберіть аккаунти'
      },
      ENTER_EMAIL: {
        ru: 'Введите email',
        en: 'Enter email',
        ro: 'Introduceți adresa de email',
        uk: 'Введіть email'
      },
      ENTER_CORRECT_EMAIL: {
        ru: 'Введите корректный email',
        en: 'Enter a valid email',
        ro: 'Introduceți un e -mail valid',
        uk: 'Введіть корректний email'
      },
      ENTER_FEW_EMAILS: {
        ru: 'Введите один или несколько email, разделяя их запятой или пробелом',
        en: 'Enter one or more emails, separating them with a comma or a space',
        ro: 'Introduceți unul sau mai multe e -mailuri care le separă cu o virgulă sau un spațiu',
        uk: 'Введіть один або декілька email, розділяючи їх комою або пробілом'
      },
      ENTER_ACCONT_NAME: {
        ru: 'Введіть назву аккаунту',
        en: 'Enter your account name',
        ro: 'Introduceți numele contului',
        uk: 'Введите название аккаунта'
      },
      CONNECT_TELEGRAM_ACCOUNT: {
        ru: '+ Подключить telegram-канал',
        en: '+ Connect Telegram channel',
        ro: '+ Conectați canalul Telegram',
        uk: '+ Підключити telegram-канал'
      },
      CONNECT_TELEGRAM_ACCOUNT_PLUS: {
        ru: '+ Подключить еще один telegram-канал',
        en: '+ Connect another Telegram channel',
        ro: '+ Conectați un alt canal Telegram',
        uk: '+ Підключити ще один telegram-канал'
      },
      CONNECT_SLACK_ACCOUNT: {
        ru: '+ Подключить Slack-аккаунт',
        en: '+ Connect a Slack account',
        ro: '+ Conectați un cont Slack',
        uk: '+ Підключити Slack-аккаунт'
      },
      CONNECT_SLACK_ACCOUNT_PLUS: {
        ru: '+ Подключить еще один Slack-аккаунт',
        en: '+ Connect another Slack account',
        ro: '+ Conectați un alt cont Slack',
        uk: '+ Підключити еще один Slack-аккаунт'
      },
      ALL_SUBSCRIBES: {
        ru: 'Все ваши подписки',
        en: 'All your subscriptions',
        ro: 'Toate abonamentele tale',
        uk: 'Всі ваші підписки'
      },
      INSTRUCTION: {
        ru: 'При подписке на дубли данного упоминания, вам будут приходить оповещения о новых дублях.',
        en: 'When subscribing to duplicates of this mention, you will receive notifications of new duplicates.',
        ro: 'Când vă abonați la duplicate de această mențiune, veți primi notificări despre noi duplicate.',
        uk: 'При підписці на дублі цієї згадки, вам будуть надходити оповіщення про нові дублі.'
      }
    },
    footer: {
      SELECTED: {
        ru: 'Выбрано ',
        en: 'Selected ',
        ro: 'Selectat ',
        uk: 'Вибрано '
      },
      PUBLICATIONS: {
        ru: ' публикаций',
        en: ' publications',
        ro: ' publicații',
        uk: ' публікацій'
      },
      ADD_FAVORITE: {
        ru: 'Добавить в избранное',
        en: 'Add to favorites',
        ro: 'Adauga la favorite',
        uk: 'Додати у обране'
      },
      REMOVE_FAVORITE: {
        ru: 'Удалить из избранного',
        en: 'Remove from Favorites',
        ro: 'Eliminați din favorite',
        uk: 'Видалити з обраного'
      },
      ASSIGN_TAG: {
        ru: 'Присвоить тег',
        en: 'Assign tag',
        ro: 'Alocați eticheta',
        uk: 'Присвоїти тег'
      },
      TAGS: {
        ru: 'Tеги',
        en: 'Tags',
        ro: 'Etichete',
        uk: 'Tеги'
      },
      EDIT_MENTIONS: {
        ru: 'Редактировать упоминания',
        en: 'Edit mentions',
        ro: 'Editare mențiuni',
        uk: 'Редагувати згадки'
      },
      DELETE: {
        ru: 'Удалить',
        en: 'Delete',
        ro: 'Șterge',
        uk: 'Видалити'
      },
      RESTORE: {
        ru: 'Восстановить',
        en: 'Restore',
        ro: 'Restabili',
        uk: 'Відновити'
      },
      OPEN_BROWSER_BLANK: {
        ru: 'Открыть в браузере(каждый линк отдельными окнами)',
        en: 'Open in browser (each link in separate windows)',
        ro: 'Deschideți în browser (fiecare link în ferestre separate)',
        uk: 'Відкрити в браузері(кожний лінк окремими вікнами)'
      },
      OPEN_BROWSER: {
        ru: 'Открыть в браузере',
        en: 'Open in browser',
        ro: 'Deschis în browser',
        uk: 'Відкрити в браузері'
      },
      ADD_PROCESSED: {
        ru: 'Добавить в обработанное',
        en: 'Add to unprocessed',
        ro: 'Adăugați la neprocesat',
        uk: 'Додати до обробленого'
      },
      ADD_UNPROCESSED: {
        ru: 'Добавить в необработанное',
        en: 'Add to unprocessed',
        ro: 'Adăugați la neprocesat',
        uk: 'Додати до необробленого'
      },
      EDIT: {
        ru: 'Редактировать упоминания (тональность и роль)',
        en: 'Edit mentions (tonality and role)',
        ro: 'Editarea mențiunilor (tonalitate și rolul)',
        uk: 'Редагувати згадки (тональність та роль)'
      },
      KNOCK_DOWN_DUBLICATES: {
        ru: 'Сбить дубли',
        en: 'Knock down duplicates',
        ro: 'Doborâți duplicate',
        uk: 'Збити дублі'
      },
      SUBSCRIBE_DUBLICATES: {
        ru: 'Подписаться на дубли',
        en: 'Subscribe to duplicates',
        ro: 'Abonați -vă la duplicate',
        uk: 'Підписатися на дублі'
      },
      UNSUBSCRIBE: {
        ru: 'Отписаться',
        en: 'Unsubscribe',
        ro: 'Dezabonați -vă',
        uk: 'Відписатися'
      },
      SET_ORIGINAL_DUPLICATES: {
        ru: 'Назначить оригиналом (для дубля)',
        en: 'Set as original (for duplicate)',
        ro: 'Set ca original (pentru duplicat)',
        uk: 'Призначити оригіналом (для дубля)'
      },
      SET_ORIGINAL: {
        ru: 'Назначить оригиналом',
        en: 'Set as original',
        ro: 'Setat ca original',
        uk: 'Призначити оригіналом'
      },
      CLOSE: {
        ru: 'Закрыть',
        en: 'Close',
        ro: 'Închide',
        uk: 'Закрити'
      }
    },
    filtersPanel: {
      FILTERS: {
        ru: 'Фильтры',
        en: 'Filters',
        ro: 'Filtre',
        uk: 'Фільтри'
      },
      CLEAR: {
        ru: 'Очистить',
        en: 'Clear',
        ro: 'Clar',
        uk: 'Очистити'
      },
      CANCEL: { ru: 'Отменить', en: 'Cancel', uk: 'Скасувати', ro: 'Anulare' },
      NO_FILTERS: {
        ru: 'Фильтрация не применена',
        en: 'No filtering applied',
        ro: 'Nu se aplică filtrare',
        uk: 'Фільтрування не застосовано'
      },
      KW: {
        ru: ' KC',
        en: ' Keyword',
        ro: ' Cuvânt cheie',
        uk: ' КС'
      },
      PUBLICATIONS: {
        ru: ' публикаций',
        en: ' publications',
        ro: ' publicații',
        uk: ' публікацій'
      }

    },
    filtrationPanel: {
      ENTER_NAME: {
        ru: 'Введите название',
        en: 'Enter name',
        ro: 'Introduceți numele',
        uk: 'Введіть назву'
      },
      KEYWORD: {
        ru: 'Ключевое слово',
        en: 'Keyword',
        ro: 'Cuvânt cheie',
        uk: 'Ключове слово'
      },
      KEYWORDS: {
        ru: 'Ключевых слов',
        en: 'Keywords',
        ro: 'Cuvintelor cheie',
        uk: 'Ключових слів'
      },
      ALL_KEYWORDS: {
        ru: 'Все ключевые слова',
        en: 'All keyword',
        ro: 'Toate cuvintele cheie',
        uk: 'Всі ключові слова'
      },
      MEDIA_TYPE: {
        ru: 'Тип источника',
        en: 'Source type',
        ro: 'Tip sursă',
        uk: 'Тип джерела'
      },
      MEDIA_TYPES: {
        ru: 'Типa(ов) источникa(ов)',
        en: 'Source types',
        ro: 'Tip de sursă',
        uk: 'Типа(ів) джерел(а)'
      },
      ALL_TONES: {
        ru: 'Все тональности',
        en: 'All sentiments',
        ro: 'Toate tonalitate',
        uk: 'Всі тональності'
      },
      TONES: {
        ru: 'Тональности',
        en: 'Sentiments',
        ro: 'Tonalitates',
        uk: 'Тональності'
      },
      TONE: {
        ru: 'Тональность',
        en: 'Sentiment',
        ro: 'Tonalitate',
        uk: 'Тональність'
      },
      ALL_CHARACTERS: {
        ru: 'Все роли',
        en: 'All roles',
        ro: 'Toate roluri',
        uk: 'Всі ролі'
      },
      CHARACTERS: {
        ru: 'Роли',
        en: 'Roles',
        ro: 'Roluri',
        uk: 'Ролі'
      },
      CHARACTER: {
        ru: 'Роль',
        en: 'Role',
        ro: 'Rolul',
        uk: 'Роль'
      },
      TAG: {
        ru: 'Тег',
        en: 'Tag',
        ro: 'Etichetă',
        uk: 'Тег'
      },
      TAGS: {
        ru: 'Тега',
        en: 'Tags',
        ro: 'Etichete',
        uk: 'Теги'
      },
      ALL_TAGS: {
        ru: 'Все теги',
        en: 'All tags',
        ro: 'Toate etichete',
        uk: 'Всі теги'
      },
      ALL_POST_TYPES: {
        ru: 'Все типы упоминаний',
        en: 'All types of mentions',
        ro: 'Toate tipul menționat',
        uk: 'Всі типи згадок'
      },
      POST_TYPE: {
        ru: 'Тип упоминания',
        en: 'Mention Type',
        ro: 'Tipul menționat',
        uk: 'Тип згадки'
      },
      POST_TYPES: {
        ru: 'Типы упоминаний',
        en: 'Mention types',
        ro: 'Tipul menționat',
        uk: 'Типи згадок'
      },
      CONTEXT: {
        ru: 'Контекст упоминаний',
        en: 'Context of references',
        ro: 'Contextul referințelor',
        uk: 'Контекст згадок'
      },
      AUDIENCE: {
        ru: 'Аудитория упоминания',
        en: 'Mention Audience',
        ro: 'Menționează publicul',
        uk: 'Аудиторія згадки'
      },
      AUDIENCE_FROM: {
        ru: 'Аудитория упоминания от',
        en: 'Mention Audience',
        ro: 'Menționează publicul',
        uk: 'Аудиторія згадки від'
      },
      AUDIENCE_TO: {
        ru: 'Аудитория упоминания до',
        en: 'Mention Audience to',
        ro: 'Menționează publicul pentru',
        uk: 'Аудиторія згадки до'
      },
      CREATOR_AGE_FROM: {
        ru: 'Возраст автора от',
        en: 'Author age from',
        ro: 'Epoca autorului de la',
        uk: 'Вік автора від'
      },
      CREATOR_AGE_TO: {
        ru: 'Возраст автора до',
        en: 'Author age to',
        ro: 'Epoca autorului pentru',
        uk: 'Вік автора до'
      },
      GEOGRAPHY: {
        ru: 'География',
        en: 'Geography',
        ro: 'Geografie',
        uk: 'Географія'
      },
      ALL_COUNTRIES: {
        ru: 'Все страны',
        en: 'All countries',
        ro: 'Toate țările',
        uk: 'Всі країни'
      },
      PUBLICATION_PLACE_RATE: {
        ru: 'Рейтинг места публикации',
        en: 'Rating of the publication place',
        ro: 'Ratingul locului de publicare',
        uk: 'Рейтинг місця публікації'
      },
      PUBLICATION_PLACE_RATE_RANGE: {
        ru: 'Рейтинг места публикации',
        en: 'Rating of the publication place',
        ro: 'Ratingul locului de publicare',
        uk: 'Рейтинг місця публікації'
      },
      COUNTRY: {
        ru: 'Страна',
        en: 'Country',
        ro: 'Țară',
        uk: 'Країна'
      },
      COUNTRIES: {
        ru: 'Стран',
        en: 'Countries',
        ro: 'Țări',
        uk: 'Країн'
      },
      ALL_REGIONS: {
        ru: 'Все регионы',
        en: 'All regions',
        ro: 'Toate regiunile',
        uk: 'Всі регіони'
      },
      REGION: {
        ru: 'Регион',
        en: 'Region',
        ro: 'Regiune',
        uk: 'Регіон'
      },
      REGIONS: {
        ru: 'Регионы',
        en: 'Regions',
        ro: 'Regiunile',
        uk: 'Регіони'
      },
      ALL_CITIES: {
        ru: 'Все города',
        en: 'All cities',
        ro: 'Toate orașe',
        uk: 'Всі міста'
      },
      CITY: {
        ru: 'Город',
        en: 'City',
        ro: 'Oraș',
        uk: 'Місто'
      },
      CITIES: {
        ru: 'Города',
        en: 'Cities',
        ro: 'Orașe',
        uk: 'Міста'
      },
      ALL_SOURCES: {
        ru: 'Все источники',
        en: 'All sources',
        ro: 'Toate sursele',
        uk: 'Всі джерела'
      },
      SOURCE: {
        ru: 'Источник',
        en: 'Source',
        ro: 'Sursă',
        uk: 'Джерело'
      },
      SOURCES: {
        ru: 'Источников',
        en: 'Sources',
        ro: 'Surse',
        uk: 'Джерела'
      },
      ALL_LEVELS: {
        ru: 'Все уровни источников',
        en: 'All source levels',
        ro: 'Toate nivelurile sursei',
        uk: 'Всі рівні джерел'
      },
      LEVEL: {
        ru: 'Уровень источника',
        en: 'Source level',
        ro: 'Nivel sursă',
        uk: 'Рівень джерела'
      },
      LEVELS: {
        ru: 'Уровня источников',
        en: 'Source levels',
        ro: 'Nivelurile sursei',
        uk: 'Рівня джерел'
      },
      ALL_SUBJECTS: {
        ru: 'Все тематики источников',
        en: 'All source topics',
        ro: 'Toate subiect sursei',
        uk: 'Всі тематики джерел'
      },
      SUBJECT: {
        ru: 'Тематика источника',
        en: 'Source topic',
        ro: 'Subiect sursă',
        uk: 'Тематика джерела'
      },
      SUBJECTS: {
        ru: 'Тематики источника',
        en: 'Source topics',
        ro: 'Subiect sursei',
        uk: 'Тематики джерела'
      },
      RATE: {
        ru: 'Оценка',
        en: 'Rate',
        ro: 'Rata',
        uk: 'Оцінка'
      },
      RATES: {
        ru: 'Оценки',
        en: 'Rates',
        ro: 'Rate',
        uk: 'Оцінки'
      },
      ALL_RATES: {
        ru: 'Все оценки',
        en: 'All rates',
        ro: 'Toate rate',
        uk: 'Всі оцінки'
      },
      ALL_CREATORS: {
        ru: 'Все автора',
        en: 'All authors',
        ro: 'Toate autors',
        uk: 'Всі автори'
      },
      CREATOR: {
        ru: 'Автор',
        en: 'Author',
        ro: 'Autor',
        uk: 'Автор'
      },
      CREATORS: {
        ru: 'Автора',
        en: 'Authors',
        ro: 'Autors',
        uk: 'Автори'
      },
      ALL_PUBLICATION_PLACES: {
        ru: 'Все места публикаций',
        en: 'All publication places',
        ro: 'Toate locuri de publicare',
        uk: 'Всі місця публікацій'
      },
      PUBLICATION_PLACE: {
        ru: 'Место публикации',
        en: 'Publication place',
        ro: 'Locul de publicare',
        uk: 'Місце публікації'
      },
      PUBLICATION_PLACES: {
        ru: 'Места публикаций',
        en: 'Publication places',
        ro: 'Locuri de publicare',
        uk: 'Місць публікацій'
      },
      CREATOR_GENDER: {
        ru: 'Пол автора',
        en: 'Author gender',
        ro: 'Genul autorului',
        uk: 'Стать автора'
      },
      ALL_LANGUAGES: {
        ru: 'Все языка',
        en: 'All languages',
        ro: 'Toate limbi',
        uk: 'Всі мови'
      },
      LANGUAGE: {
        ru: 'Язык',
        en: 'Language',
        ro: 'Limba',
        uk: 'Мова'
      },
      LANGUAGES: {
        ru: 'Языка',
        en: 'Languages',
        ro: 'Limbi',
        uk: 'Мови'
      },
      SUBTOPIC: {
        ru: 'Подтематика источника',
        en: 'Source subtopic',
        ro: 'Subtopic sursă',
        uk: 'Підтематика джерела'
      },
      ICONTAINS: {
        ru: 'Поиск',
        en: 'Search',
        ro: 'Căutare',
        uk: 'Пошук'
      },
      WORDS: {
        ru: 'Популярные слова',
        en: 'Popular words',
        ro: 'Cuvinte populare',
        uk: 'Популярні слова'
      },
      DUPLICATES: {
        ru: 'Дубли',
        en: 'Duplicates',
        ro: 'Duplicate',
        uk: 'Дублі'
      },
      ADDITIONAL_FILTERS_GEOGRAPHY: {
        ru: 'Дополнительные фильтры географии',
        en: 'Additional geography filters',
        ro: 'Filtre suplimentare de geografie',
        uk: 'Додаткові фільтри географії'
      },
      ADDITIONAL_FILTERS_SOURCE: {
        ru: 'Дополнительные фильтры источника',
        en: 'Additional source filters',
        ro: 'Filtre de sursă suplimentare',
        uk: 'Додаткові фільтри джерела'
      }
    },
    filterAudience: {
      FROM: {
        ru: 'От',
        en: 'From',
        ro: 'De la',
        uk: 'Від'
      },
      TO: {
        ru: 'До',
        en: 'To',
        ro: 'Sub',
        uk: 'До'
      }
    },
    filterContext: {
      WORDS: {
        ru: 'Популярные слова',
        en: 'Popular words',
        ro: 'Cuvinte populare',
        uk: 'Популярні слова'
      },
      LINKS: {
        ru: 'Популярные ссылки',
        en: 'Popular Links',
        ro: 'Link-uri populare',
        uk: 'Популярні посилання'
      },
      HASHTAGS: {
        ru: 'Популярные хэштеги',
        en: 'Popular hashtags',
        ro: 'Hashtag-uri populare',
        uk: 'Популярні хештеги'
      }
    },
    kSTegFilter: {
      ADD_TEG: {
        ru: 'Добавить тег',
        en: 'Add tag',
        ro: 'Adaugă etichetă',
        uk: 'Додати тег'
      },
      ADD_KEYWORD: {
        ru: 'Добавить ключевое слово',
        en: 'Add keyword',
        ro: 'Adăugați cuvinte cheie',
        uk: 'Додати ключове слово'
      },
      ENTER_KW: {
        ru: 'Введите КС',
        en: 'Enter keyword',
        ro: 'Introduceți cuvântul cheie',
        uk: 'Введіть КС'
      },
      ENTER_TEG: {
        ru: 'Введите тег',
        en: 'Enter a tag',
        ro: 'Introduceți o etichetă',
        uk: 'Введіть тег'
      },
      WITHOUT_TEG: {
        ru: 'Без тега',
        en: 'Without tag',
        ro: 'Fără etichetă',
        uk: 'Без тега'
      },
      KW_EDITED: {
        ru: 'Ключевое слово отредактировано. Для внесения изменений обратитесь в службу поддержки',
        en: 'The keyword has been edited. To make changes, please contact support',
        ro: 'Cuvântul cheie a fost editat.Pentru a face modificări, vă rugăm să contactați asistența',
        uk: 'Ключове слово відредаговане. Для внесення змін зверніться до підтримки'
      },
      NO_SEARCH: {
        ru: 'Не найдено',
        en: 'Not found',
        ro: 'Nu a fost găsit',
        uk: 'Не знайдено'
      },
      USED: {
        ru: 'Использовано ',
        en: 'Used ',
        ro: 'Folosit ',
        uk: 'Використано '
      },
      KEYWORDS: {
        ru: ' ключевых слов',
        en: ' of the keywords',
        ro: ' a cuvintelor cheie',
        uk: ' ключових слів'
      }
    },
    advancedBuilder: {
      ADD_KEYWORD: {
        ru: 'Добавить ключевое слово',
        en: 'Add keyword',
        ro: 'Adăugați cuvinte cheie',
        uk: 'Додати ключове слово'
      },
      EDIT_KEYWORD: {
        ru: 'Редактировать ключевое слово',
        en: 'Edit keyword',
        ro: 'Editați cuvântul cheie',
        uk: 'Редагувати ключове слово'
      },
      EDIT_TAG: {
        ru: 'Редактировать тег',
        en: 'Edit Tag',
        ro: 'Editați eticheta',
        uk: 'Редагувати тег'
      },
      ADD_QUERY_SEARCH: {
        ru: 'Добавить поисковый запрос',
        en: 'Add a search query',
        ro: 'Adăugați o interogare de căutare',
        uk: 'Додати пошуковий запит'
      },
      EDIT_QUERY_SEARCH: {
        ru: 'Редактировать поисковый запрос',
        en: 'Edit search query',
        ro: 'Editați-vă interogarea de căutare',
        uk: 'Редагувати пошуковий запит'
      },
      ADD_TAG: {
        ru: 'Добавить тег',
        en: 'Add tag',
        ro: 'Adaugă etichetă',
        uk: 'Додати тег'
      },
      BACK_TEXT: {
        ru: 'Вернуться к ленте новостей',
        en: 'Back to the news feed',
        ro: 'Înapoi la feedul de știri',
        uk: 'Повернутись до стрічки новин'
      },
      OPERATORS: {
        ru: 'Операторы',
        en: 'Operators',
        ro: 'Operatori',
        uk: 'Оператори'
      },
      PREVIEW: {
        ru: 'Предпросмотр результата',
        en: 'Preview the result',
        ro: 'Previzualizați rezultatul',
        uk: 'Передперегляд результату'
      },
      EXAMPLES: {
        ru: 'Примеры',
        en: 'Examples',
        ro: 'Exemple',
        uk: 'Приклади'
      },
      HINTS: {
        ru: 'Подсказки',
        en: 'Hints',
        ro: 'Sugestii',
        uk: 'Підказки'
      },
      TEMPLATES: {
        ru: 'Шаблоны',
        en: 'Templates',
        ro: 'Șabloane',
        uk: 'Шаблони'
      },
      SUPPORT: {
        ru: 'Поддержка',
        en: 'Support',
        ro: 'Suport',
        uk: 'Підтримка'
      },
      FIELDS: {
        ru: 'Поля',
        en: 'Fields',
        ro: 'Câmpuri',
        uk: 'Поля'
      },
      KEYWORD_NAME: {
        ru: 'Название ключевого слова',
        en: 'The name of the keyword',
        ro: 'Numele cuvântului cheie',
        uk: 'Назва ключового слова'
      },
      ENTER_NAME_KEYWORD: {
        ru: 'Введите название ключевого слова',
        en: 'Enter keyword name',
        ro: 'Introduceți numele cuvintelor cheie',
        uk: 'Введіть назву ключового слова'
      },
      ALERT_NAME: {
        ru: 'Название оповещения',
        en: 'The name of alert',
        ro: 'Numele alertei',
        uk: 'Назва сповіщення'
      },
      ENTER_NAME_ALERT: {
        ru: 'Введите название оповещения',
        en: 'Enter alert name',
        ro: 'Introduceți numele alertei',
        uk: 'Введіть назву сповіщення'
      },
      QUERY_SEARCH: {
        ru: 'Поисковый запрос',
        en: 'Search query',
        ro: 'Căutați interogare',
        uk: 'Пошуковий запит'
      },
      TAG_NAME: {
        ru: 'Название тега',
        en: 'The name of the tag',
        ro: 'Numele etichetei',
        uk: 'Назва тега'
      },
      ENTER_NAME_TAG: {
        ru: 'Введите название тега',
        en: 'Enter tag name',
        ro: 'Introduceți numele de etichetă',
        uk: 'Введіть назву тега'
      },
      ENTER_QUERY: {
        ru: 'Введите свой запрос для поиска',
        en: 'Enter your search query',
        ro: 'Introduceți întrebarea dvs. de căutare',
        uk: 'Введіть свій запит для пошуку'
      },
      USED: {
        ru: 'Использовано',
        en: 'Used',
        ro: 'Folosit',
        uk: 'Використано'
      },
      QUERY_ERRORS: {
        ru: 'В поисковом запросе имеются ошибки',
        en: 'There are errors in the search query',
        ro: 'Există erori în interogarea de căutare',
        uk: 'В пошуковому запиті наявні помилки'
      },
      TO_SIMPLE_MODE: {
        ru: 'Переключить в Базовый режим',
        en: 'Switch to Basic mode',
        ro: 'Treceți la modul de bază',
        uk: 'Перемкнути в Базовий режим'
      },
      BASE_KNOLEDGES: {
        ru: 'База знаний',
        en: 'Knowledge base',
        ro: 'Bază de cunoștințe',
        uk: 'База знань'
      },
      SURE_EXIT_TAG: {
        ru: 'Вы уверены, что хотите выйти из конструктора тегов? Весь прогресс будет потерян',
        en: 'Are you sure you want to exit the Tags Builder? All progress will be lost',
        ro: 'Ești sigur că vrei să ieși din constructor de etichete?Toate progresele se vor pierde',
        uk: 'Ви впевнені, що хочете вийти з конструктора тегів? Весь прогрес буде втрачено'
      },
      SURE_EXIT_KW: {
        ru: 'Вы уверены, что хотите выйти из конструктора ключевых слов? Весь прогресс будет потерян',
        en: 'Are you sure you want to exit the Keywords Builder? All progress will be lost',
        ro: 'Ești sigur că vrei să ieșiți din Builder de cuvinte cheie?Toate progresele se vor pierde',
        uk: 'Ви впевнені, що хочете вийти з конструктора ключових слів? Весь прогрес буде втрачено'
      },
      SURE_EXIT_SEARCH_QUERY: {
        ru: 'Вы уверены, что хотите выйти из конструктора поисковых запросов? Весь прогресс будет потерян',
        en: 'Are you sure you want to exit the search query builder? All progress will be lost',
        ro: 'Sunteți sigur că doriți să ieșiți din constructorul de interogări de căutare? Toate progresele vor fi pierdute',
        uk: 'Ви впевнені, що хочете вийти з конструктора пошукових запитів? Весь прогрес буде втрачено'
      },
      HINTS_TEXT: {
        ru: 'Для того чтобы упростить и ускорить ваши поиски мы подготовить шаблоны запросов.  \n Просто отредактируйте шаблон заменяя предложенные значения на собственные.',
        en: 'To make your searches easier and faster, we have prepared query templates. \n Just edit the template by replacing the suggested values with your own',
        ro: 'Pentru a vă face căutările mai ușoare și mai rapide, am pregătit șabloane de interogare. \n editați doar șablonul înlocuind valorile sugerate cu propriile dvs.',
        uk: 'Для того щоб спростити та пришвидшити ваші пошуки ми підготувати шаблони запитів. \n Просто відредагуйте шаблон заміняючи запропоновані значення на власні.'
      }

    },
    operatorExamples: {
      OR: {
        ru: 'Найти статьи в которых присутствует любое из указанных слов.',
        en: 'Find articles that contain any of the following words.',
        ro: 'Găsiți articole care conțin oricare dintre următoarele cuvinte.',
        uk: 'Знайти  статті у яких присутнє будь-яке з вказаних слів.'
      },
      OR_EXAMPLE: {
        ru: 'Киев OR Львов',
        en: 'Kyiv OR Lviv',
        ro: 'Kyiv OR Lviv',
        uk: 'Київ OR Львів'
      },
      OR_DESCRIPTION: {
        ru: 'Найдет статьи в которых присутствует либо "Киев", либо "Львов"',
        en: 'Finds articles that contain either "Kyiv" or "Lviv"',
        ro: 'Găsește articole care conțin „Kyiv” sau „Lviv”',
        uk: 'Знайде статті у яких присутній або “Київ”, або “Львів”'
      },
      AND: {
        ru: 'Найти статьи в которых присутствуют оба слова.',
        en: 'Find articles that contain both words.',
        ro: 'Găsiți articole care conțin ambele cuvinte.',
        uk: 'Знайти статті у яких присутні обидва слова.'
      },
      AND_EXAMPLE: {
        ru: 'Киев AND Львов',
        en: 'Kyiv AND Lviv',
        ro: 'Kyiv AND Lviv',
        uk: 'Київ AND Львів'
      },
      AND_DESCRIPTION: {
        ru: 'Найдет статьи в которых присутствует оба слова "Киев" и "Львов"',
        en: 'Finds articles that contain either "Kyiv" and "Lviv"',
        ro: 'Găsește articole care conțin „Kyiv” și „Lviv”',
        uk: 'Знайде статті у яких присутній обидва слова  “Київ” і “Львів”'
      },
      AND_NOT: {
        ru: 'Исключить из результатов поиска статьи, слово (фразу), указанное после оператора AND NOT.',
        en: 'Exclude from the article search results in the word (phrase) specified after the AND NOT operator.',
        ro: 'Excludeți rezultatele căutării articolului în cuvântul (frază) specificat după operatorul și nu și nu.',
        uk: 'Виключити з результатів пошуку статті, слово (фразу), вказане після оператора AND NOT.'
      },
      AND_NOT_EXAMPLE: {
        ru: 'Киев AND NOT Львов',
        en: 'Kyiv AND NOT Lviv',
        ro: 'Kyiv AND NOT Lviv',
        uk: 'Київ AND NOT Львів'
      },
      AND_NOT_DESCRIPTION: {
        ru: 'Найдет статьи, где есть Киев, но исключит все статьи, где есть Львов',
        en: 'Finds articles with Kyiv, but excludes all articles with Lviv',
        ro: 'Găsește articole cu Kyiv, dar exclude toate articolele cu LVIV',
        uk: 'Знайде статті, де є Київ, але виключить всі статті, де є Львів'
      },
      QUOTES: {
        ru: 'Искать упоминания не конкретного слова, а группы слов.',
        en: 'Search for mentions of a group of words rather than a specific word.',
        ro: 'Căutați mențiuni ale unui grup de cuvinte, mai degrabă decât al unui cuvânt specific.',
        uk: 'Шукати згадки не конкретного слова, а групи слів.'
      },
      QUOTES_EXAMPLE: {
        ru: '"столица Киев"',
        en: '"capital Kyiv"',
        ro: '"capitalul Kyiv"',
        uk: '"столиця Київ"'
      },
      QUOTES_DESCRIPTION: {
        ru: 'Найдутся варианты "столица Киев" и "Киев столица"',
        en: 'There are options "capital Kyiv" and "Kyiv capital"',
        ro: 'Există opțiuni „Capital Kyiv” și „Kyiv Capital”',
        uk: 'Знайдуться варіанти “столиця Київ” та “Київ столиця”'
      },
      AMOUNT: {
        ru: 'Искать несколько слов, которые в тексте находятся на определенном расстоянии между собой.',
        en: 'Search for several words in the text that are at a certain distance from each other.',
        ro: 'Căutați mai multe cuvinte în text care se află la o anumită distanță unul de celălalt.',
        uk: 'Шукати кілька слів, які у тексті знаходяться на певній відстані між собою.'
      },
      AMOUNT_EXAMPLE: {
        ru: '"Киев столица"~2',
        en: '"Kyiv the capital"~2',
        ro: '"Kyiv capitalul"~2',
        uk: '"Київ столиця"~2'
      },
      AMOUNT_DESCRIPTION: {
        ru: 'Найдет "Столица Украины - Киев", но не "Киев - замечательный город, как и столица Польши"',
        en: 'It will find "Kyiv is the capital of Ukraine", but not "Kyiv is a wonderful city, just like the capital of Poland"',
        ro: 'Va găsi „Kyiv este capitala Ucrainei”, dar nu „Kyiv este un oraș minunat la fel ca capitala Poloniei”',
        uk: 'Знайде "Столиця України - Київ", але не "Київ - чудове місто, як і столиця Польщі"'
      },
      BRACKETS: {
        ru: 'Группировка различных слов и условий.',
        en: 'Grouping different words and conditions.',
        ro: 'Gruparea diferitelor cuvinte și condiții.',
        uk: 'Групування різних слів і умов.'
      },
      BRACKETS_EXAMPLE: {
        ru: '((Киев AND метро) OR (Львов AND трамвай)) AND NOT Укрзализныця',
        en: '((Kyiv AND subway) OR (Lviv AND tram)) AND NOT Ukrzaliznytsia',
        ro: '((Kyiv și metrou) sau (lviv și tramvai)) și nu Ukrzaliznytsia',
        uk: '((Київ AND метро) OR (Львів AND трамвай)) AND NOT Укрзалізниця'
      },
      BRACKETS_DESCRIPTION: {
        ru: 'Будет искать статьи о метро в Киеве ИЛИ трамвай во Львове, но без упоминаний об Укрзализныце. То есть, "метро" будет относиться только к "Киев", "трамвай" только к "Львов", а Укрзализныця - к обоим.',
        en: 'It will search for articles about the subway in Kyiv OR the tram in Lviv, but without mentioning Ukrzaliznytsia. That is, "subway" will refer only to "Kyiv", "tram" only to "Lviv", and "Ukrzaliznytsia" to both.',
        ro: 'Acesta va căuta articole despre metroul din Kyiv sau tramvaiul din LVIV, dar fără a menționa Ukrzaliznytsia.Aceasta este „metrou” se va referi doar la „Kyiv” „Tram” doar la „LVIV” și „Ukrzaliznytsia” pentru ambele.',
        uk: 'Буде шукати статті про метро в Києві АБО трамвай у Львові,  але без згадувань про Укрзалізницю. Тобто, “метро” буде відноситись лише до “Київ”, “трамвай” лише до “Львів”, а Укрзалізниця - до обох.'
      },
      SLASHES: {
        ru: 'Нужны для выделения слова, в котором должны работать морфологические операторы',
        en: 'They are used to highlight the word in which morphological operators should work',
        ro: 'Sunt folosite pentru a evidenția cuvântul în care ar trebui să funcționeze operatorii morfologici',
        uk: 'Потрібні для виділення слова, в якому повинні працювати морфологічні оператори'
      },
      SLASHES_EXAMPLE: {
        ru: '/Ки[е]в/',
        en: '/K[yie]v/',
        ro: '/K[yie]v/',
        uk: '/Ки[їеє]в/'
      },
      SQUARE_QUOTES: {
        ru: 'Найти статьи, с любым вариантом написания',
        en: 'Find articles with any spelling variant',
        ro: 'Găsiți articole cu orice variantă de ortografie',
        uk: 'Знайти статті, з будь-яким варіантом написання'
      },
      SQUARE_QUOTES_EXAMPLE: {
        ru: '/Ки[еи]в/',
        en: '/Ky[ie]v/',
        ro: '/Ky[ie]v/',
        uk: '/Ки[їеє]в/'
      },
      SQUARE_QUOTES_DESCRIPTION: {
        ru: 'Найдет "Киев”, "Киив"',
        en: 'It will find "Kyiv", "Kiev"',
        ro: 'Va găsi „Kyiv” „Kiev”',
        uk: 'Знайде “Київ”, “Києв”, “Киев”'
      },
      WORD_END: {
        ru: 'Искать слова, задавая минимальное и максимальное количество символов в конце',
        en: 'Search for words by specifying the minimum and maximum number of characters at the end',
        ro: 'Căutați cuvinte specificând numărul minim și maxim de caractere la sfârșit',
        uk: 'Шукати слова, задаючи мінімальну та максимальну кількість символів в кінці'
      },
      WORD_END_EXAMPLE: {
        ru: '/телефон.{1,2}/',
        en: '/phone.{1,2}/',
        ro: '/telefon.{12}/',
        uk: '/телефон.{1,2}/'
      },
      WORD_END_DESCRIPTION: {
        ru: 'Найдет "телефонЫ", но не "телефон" потому что количество символов меньше 1, и не "телефонАМИ" потому что больше 2',
        en: 'It will find "phonE", but not "phonES" because the number of characters is less than 1, and not "phonE" because it is greater than 2',
        ro: 'Va găsi „telefon”, dar nu „telefoane”, deoarece numărul de caractere este mai mic de 1 și nu „telefon”, deoarece este mai mare de 2',
        uk: 'Знайде “телефонИ”, але не “телефон” бо кількість символів менша за 1, та не “телефонАМИ” бо більше за 2'
      },
      QUESTION_SIGN: {
        ru: 'Указать, что предыдущий символ не обязателен',
        en: 'Indicate that the preceding character is optional',
        ro: 'Indică faptul că caracterul precedent este opțional',
        uk: 'Вказати, що попередній символ не обов\'язковий'
      },
      QUESTION_SIGN_EXAMPLE: {
        ru: '/С?делать/',
        en: '/Make?do/',
        ro: '/Face?Fă/',
        uk: '/З?робити/'
      },
      QUESTION_SIGN_DESCRIPTION: {
        ru: 'Найдет и "сделать" и "делать"',
        en: 'It will find "make" and "do"',
        ro: 'Va găsi „Make” și „Do”',
        uk: 'Знайде і “робити” і “Зробити”'
      },
      DOT_SIGN: {
        ru: 'Заменить любую букву или символ в слове',
        en: 'Replace any letter or symbol in a word',
        ro: 'Înlocuiți orice literă sau simbol dintr-un cuvânt',
        uk: 'Замінити будь-яку літеру чи символ у слові'
      },
      DOT_SIGN_EXAMPLE: {
        ru: '/Ки.в/',
        en: '/Кy.v/',
        ro: '/Кy.v/',
        uk: '/Ки.в/'
      },
      DOT_SIGN_DESCRIPTION: {
        ru: 'Найдутся все возможные варианты написания: "Киев", "Киив", "Ки@в" и т.д.',
        en: 'It will find all possible spellings: "Kyiv", "Кiev", "Кi@v", etc.',
        ro: 'Va găsi toate ortografiile posibile: „Kyiv” „кiev” „кi@v” etc.',
        uk: 'Знайдуться всі можливі варіанти написання: "Київ", "Киев", "Ки@в" і т.д.'
      }
    },
    filtersExamples: {
      TITLE: {
        ru: 'Искать упоминания слова только в заголовках публикаций',
        en: 'Search for word mentions only in post titles',
        ro: 'Căutați mențiuni de cuvinte numai în titluri de post',
        uk: 'Шукати згадки слова лише в заголовках публікацій'
      },
      TITLE_EXAMPLE: {
        ru: 'title:Киев',
        en: 'title:Kyiv',
        ro: 'titlu:Kyiv',
        uk: 'title:Київ'
      },
      TEXT: {
        ru: 'Искать упоминания ключевого слова только в текстах публикаций',
        en: 'Search for mentions of a keyword only in the text of posts',
        ro: 'Căutați mențiuni despre un cuvânt cheie numai în textul postărilor',
        uk: 'Шукати згадки ключового слова лише в текстах публікацій'
      },
      TEXT_EXAMPLE: {
        ru: 'text:Киев',
        en: 'text:Kyiv',
        ro: 'text:Kyiv',
        uk: 'text:Київ'
      },
      AUTHOR_NAME: {
        ru: 'Ограничение выдачи именем автора',
        en: 'Search limits by the author\'s name',
        ro: 'Limite de căutare după numele autorului',
        uk: 'Обмеження видачі іменем автора'
      },
      AUTHOR_URL: {
        ru: 'Ограничения по URL автора',
        en: 'Search limits by author\'s URL',
        ro: 'Restricții privind adresa URL a autorului',
        uk: 'Обмеження по URL автора'
      },
      AUTHOR_GENDER: {
        ru: 'Ограничения по полу автора',
        en: 'Search limits by author\'s gender',
        ro: 'Limite de căutare după sex',
        uk: 'Обмеження по статі автора'
      },
      AUTHOR_SUBSCRIBERS: {
        ru: 'Ограничения по подписчикам автора',
        en: 'Search limits by author\'s subscribers',
        ro: 'Limite de căutare de către autorul abonaților',
        uk: 'Обмеження по підписникам автора'
      },
      PUBLICATION_PLACE_NAME: {
        ru: 'Искать в названии страниц соц сетей',
        en: 'Search in the name of social media title',
        ro: 'Căutați în numele titlului de socializare',
        uk: 'Шукати у назві сторінок соц мереж'
      },
      PUBLICATION_PLACE_NAME_EXAMPLE: {
        ru: 'publication_place_name:Киев',
        en: 'publication_place_name:Kyiv',
        ro: 'publication_place_name:Kyiv',
        uk: 'publication_place_name:Київ'
      },
      PUBLICATION_PLACE_NAME_DESCRIPTION: {
        ru: 'Найдутся все упоминания, где в названии страницы будет "Киев"',
        en: 'It will find all mentions of "Kyiv" in the page title',
        ro: 'Va găsi toate mențiunile „Kyiv” în titlul paginii',
        uk: 'Знайдуться всі згадки, де у назві сторінки буде “Київ”'
      },
      PUBLICATION_PLACE_URL: {
        ru: 'Ограничивает поиск по месту публикации по URL',
        en: 'Search limits by publication location by URL',
        ro: 'Limite de căutare prin locația publicării de către URL',
        uk: 'Обмежує пошук по місцю публікації за URL'
      },
      PUBLICATION_PLACE_SUBSCRIBERS: {
        ru: 'Количество подписчиков места публикации',
        en: 'Number of subscribers of the place of publication',
        ro: 'Numărul de abonați ai locului de publicare',
        uk: 'Кількість підписників місця публікації'
      },
      POST_TYPE: {
        ru: 'Выбрать тип контента: post, repost, comment, extended repost',
        en: 'Select a content type: post, repost, comment, extended repost',
        ro: 'Selectați un tip de conținut: post, repost, comment, extended repost',
        uk: 'Обрати тип контенту: post, repost, comment, extended repost'
      },
      POST_TYPE_EXAMPLE: {
        ru: 'Киев AND post_type:(repost comment)',
        en: 'Kyiv AND post_type:(repost comment)',
        ro: 'Kyiv AND post_type:(repost comment)',
        uk: 'Київ AND post_type:(repost comment)'
      },
      POST_TYPE_DESCRIPTION: {
        ru: 'Найдутся все упоминания "Киев" в репостах и комментариях из соцсетей',
        en: 'It will find all mentions of "Kyiv" in reposts and comments from social networks',
        ro: 'Va găsi toate mențiunile „Kyiv” în repostii și comentarii din rețelele sociale',
        uk: 'Знайдуться всі згадки “Київ” у репостах та коментарях з соцмереж'
      },
      LIKES_COUNT: {
        ru: 'Количество лайков',
        en: 'The number of likes',
        ro: 'Numărul de like-uri',
        uk: 'Кількість лайків'
      },
      COMMENTS_COUNT: {
        ru: 'Количество комментариев',
        en: 'The number of comments',
        ro: 'Numărul de comentarii',
        uk: 'Кількість коментарів'
      },
      SHARES_COUNT: {
        ru: 'Количество распространений',
        en: 'The number of distributions',
        ro: 'Numărul de distribuții',
        uk: 'Кількість поширень'
      },
      VIEWS_COUNT: {
        ru: 'Ограничения по количеству просмотров',
        en: 'Search limits by number of views',
        ro: 'Limite de căutare după numărul de vizualizări',
        uk: 'Обмеження по кількості переглядів'
      },
      SOURCE_TYPE: {
        ru: 'Выбрать типы СМИ (tv radio press internet agency socialnetwork)',
        en: 'Select the types of media (tv radio press Online agency socialnetwork)',
        ro: 'Selectați tipurile de media (TV Radio Press Online Agency SocialNetwork)',
        uk: 'Обрати типи ЗМІ (tv radio press internet agency socialnetwork)'
      },
      SOURCE_TYPE_EXAMPLE: {
        ru: 'Киев AND source_type:(tv agency socialnetwork)',
        en: 'Kyiv AND source_type:(tv agency socialnetwork)',
        ro: 'Kyiv AND source_type:(tv agency socialnetwork)',
        uk: 'Київ AND source_type:(tv agency socialnetwork)'
      },
      SOURCE_TYPE_DESCRIPTION: {
        ru: 'Найдутся все упоминания "Киев" из ТВ-сюжетов, информационных агентств и социальных сетей',
        en: 'It will find all mentions of "Kyiv" from TV stories, news agencies and social networks will be found',
        ro: 'Va găsi toate mențiunile „Kyiv” de la agențiile de știri TV și rețelele de socializare vor fi găsite',
        uk: 'Знайдуться всі згадки “Київ” з ТБ-сюжетів, інформаційних агентств та соціальних мереж'
      },
      LANG_CODE: {
        ru: 'Искать только статьи на определенном языке',
        en: 'Search only articles in a specific language',
        ro: 'Căutați doar articole într -un limbaj specific',
        uk: 'Шукати тільки статті певною мовою'
      },
      LANG_CODE_EXAMPLE: {
        ru: 'language_code:(uk en ro pl)',
        en: 'language_code:(uk en ro pl)',
        ro: 'language_code:(uk en ro pl)',
        uk: 'language_code:(uk en ro pl)'
      },
      LANG_CODE_DESCRIPTION: {
        ru: 'Найдутся только упоминания на украинском, английском, румынском и польском языках',
        en: 'It will only find references in Ukrainian, English, Romanian and Polish',
        ro: 'Va găsi referințe doar în limba engleză și poloneză ucraineană',
        uk: 'Знайдуться тільки згадки українською, англійською, румунською та польською мовами'
      },
      URL: {
        ru: 'Поиск по URL',
        en: 'Search by URL',
        ro: 'Căutare după adresă URL',
        uk: 'Пошук по URL'
      }
    },
    hintsList: {
      LIST_ALL_KW: {
        ru: 'Перечислите все варианты написания ключевого слова, также включайте ошибочные или сленговые варианты, таким образом вы максимально расширяете поисковую выдачу:',
        en: 'List all the variants of the keyword spelling, and also include misspellings or slang variants, so you maximize the search results:',
        ro: 'Enumerați toate variantele ortografiei de cuvinte cheie și includeți, de asemenea, scrieri greșite sau variante de argou, astfel încât să maximizați rezultatele căutării:',
        uk: 'Перерахуйте усі варіанти написання ключового слова, також включайте помилкові або сленгові варіанти, таким чином ви максимально розширюєте пошукову видачу:'
      },
      LIST_ALL_TAGS: {
        ru: 'Перечислите все варианты написания тега, также включайте ошибочные или сленговые варианты, таким образом вы максимально расширяете поисковую выдачу:',
        en: 'List all the variants of the tag spelling, and also include misspellings or slang variants, so you maximize the search results:',
        ro: 'Enumerați toate variantele ortografiei de etichete și includeți, de asemenea, scrieri greșite sau variante de argou, astfel încât să maximizați rezultatele căutării:',
        uk: 'Перерахуйте усі варіанти написання тегу, також включайте помилкові або сленгові варіанти, таким чином ви максимально розширюєте пошукову видачу:'
      },
      USE_CONDITIONS: {
        ru: 'С помощью скобок можно указать к какой части запроса будет относиться следующее условие:',
        en: 'Use parentheses to specify which part of the query the following condition applies to:',
        ro: 'Utilizați paranteze pentru a specifica ce parte a interogării se aplică următoarea condiție pentru:',
        uk: 'За допомогою дужок можна вказати до якої частини запиту буде відноситись наступна умова:'
      },
      USE_PREVIEW: {
        ru: 'Воспользуйтесь предпросмотром, если найдете там статьи, которые не хотите видеть в результатах запроса - уточните что именно ограничить:',
        en: 'Use the preview if you find articles that you don\'t want to see in the query results - specify what you want to limit:',
        ro: 'Utilizați previzualizarea dacă găsiți articole pe care nu doriți să le vedeți în rezultatele interogării - specificați ce doriți să limitați:',
        uk: 'Скористайтеся передпереглядом, якщо знайдете там статті, які не хочете бачити у результатах запиту - уточніть, що саме обмежити:'
      },
      CLARIFY_CONTEXT: {
        ru: 'Если в предпросмотре новостей все равно много, вы можете дополнительно уточнить контекст запроса, указав дополнительные слова, которые должны быть в статье',
        en: 'If there is still a lot of news in the news preview, you can further clarify the context of the query by specifying additional words that should be in the article',
        ro: 'Dacă mai există o mulțime de știri în previzualizarea știrilor, puteți clarifica în continuare contextul interogării, specificând cuvinte suplimentare care ar trebui să fie în articol',
        uk: 'Якщо у передперегляді новин все одно багато, ви можете додатково уточнити контекст запиту, вказавши додаткові слова, що мають бути у статті'
      },
      SPECIFY_WORDS: {
        ru: 'Чтобы дополнительно уточнить, что определенные слова должны находиться рядом в тексте - можете воспользоваться оператором ""~n',
        en: 'To further specify that certain words should be side by side in the text, you can use the ""~n operator',
        ro: 'Pentru a specifica în continuare că anumite cuvinte ar trebui să fie cot la cot în text, puteți utiliza operatorul ""~ n',
        uk: 'Щоб додатково уточнити, що певні слова мають знаходитись поряд у тексті - можете скористатись оператором ""~n'
      }
    },
    examplesList: {
      BRAND_LABEL: {
        ru: 'Добавьте название бренда или компании',
        en: 'Add a brand or company name',
        ro: 'Adăugați un nume de marcă sau companie',
        uk: 'Додайте назву бренду або компанії'
      },
      BRAND_CONTENT: {
        ru: 'ПриватБанк "Приват Банк" privatbank "privat bank"',
        en: 'PrivatBank "Privat Bank" privatbank "privat bank"',
        ro: 'PrivatBank "Privat Bank" privatbank "privat bank"',
        uk: 'ПриватБанк "Приват Банк" privatbank "privat bank"'
      },
      MISTAKE_LABEL: {
        ru: 'Добавьте возможные ошибки или сленговые или жаргонные названия',
        en: 'Add possible mistakes or slangs or jargon names',
        ro: 'Adăugați posibile greșeli sau argouri sau nume de jargon',
        uk: 'Додайте можливі помилки чи сленгові чи жаргонні назви'
      },
      MISTAKE_CONTENT: {
        ru: '(ПрыватБанк "Прыват Банк" "Приват Банк"',
        en: '(PryvatBank "Pryvat Bank" "Privat Bank"',
        ro: '(PryvatBank "Pryvat Bank" "Privat Bank"',
        uk: '(ПріватБанк "Пріват Банк" "Приват Банк"'
      },
      UNIQUE_LABEL: {
        ru: 'Добавьте уникальные хэштеги или медиакомпании',
        en: 'Add unique hashtags or media companies',
        ro: 'Adăugați hashtag -uri unice sau companii media',
        uk: 'Додайте унікальні хештеги чи медіакомпанії'
      },
      UNIQUE_CONTENT: {
        ru: '#беремиделаем privatbankua PrivatBankUKR',
        en: '#takeandmake privatbankua PrivatBankUKR',
        ro: '#takeandmake privatbankua PrivatBankUKR',
        uk: '#беремоіробимо privatbankua PrivatBankUKR'
      },
      BIND_LABEL: {
        ru: 'С помощью AND добавьте привязки для мониторинга конкретных тем (поиск будет по всему тексту)',
        en: 'Use AND to add bindings to monitor specific topics (the search will be throughout the text)',
        ro: 'Utilizați și pentru a adăuga legături pentru a monitoriza subiecte specifice (căutarea va fi în întregul text)',
        uk: 'За допомогою AND додайте прив\'язки для моніторингу конкретних тем (пошук буде по всьому тексту)'
      },
      BIND_CONTENT: {
        ru: '"ПриватБанк (мошенники украли)"~5 "Приват Банк (мошенники украли)"~5 "privatbank (мошенники украли)"~5 "privatbank (мошенники украли)"~5',
        en: '"PrivatBank (fraudsters stole)"~5 "Privat Bank (fraudsters stole)"~5 "privatbank (fraudsters stole)"~5 "privatbank (fraudsters stole)"~5',
        ro: '"Private Bank (Fraudsters Scaune)" ~ 5 "Banca privată (scaune de fraudatori)" ~ 5 "Banca privată (scaune de fraudatori)" ~ 5 "Privatbank (Fraudsters scaune)" ~ 5',
        uk: '"ПриватБанк (шахраї вкрали)"~5 "Приват Банк (шахраї вкрали)"~5 "privatbank (шахраї вкрали)"~5 "privatbank (шахраї вкрали)"~5'
      },
      TYPES_MONITORING_LABEL: {
        ru: 'Типы источников мониторинга - можно выбрать нужный источник, или оставить все',
        en: 'Types of monitoring sources - you can select the desired source or leave all of them',
        ro: 'Tipuri de surse de monitorizare - Puteți selecta sursa dorită sau le puteți lăsa pe toate',
        uk: 'Типи джерел моніторингу - можна обрати потрібне джерело, або залишити всі'
      },
      TYPES_MONITORING_CONTENT: {
        ru: '(ПриватБанк "Приват Банк" privatbank "privat bank") AND (source_type:(tv radio press internet agency blog) OR source_type:(socialnetwork forum review))',
        en: '(PrivatBank "Privat Bank" privatbank "privat bank") AND (source_type:(tv radio press internet agency blog) OR source_type:(socialnetwork forum review))',
        ro: '(PrivatBank "Privat Bank" privatbank "privat bank") AND (source_type:(tv radio press internet agency blog) OR source_type:(socialnetwork forum review))',
        uk: '(ПриватБанк "Приват Банк" privatbank "privat bank") AND (source_type:(tv radio press internet agency blog) OR source_type:(socialnetwork forum review))'
      },
      SPECIFIC_GEO_LABEL: {
        ru: 'Поиск по конкретной географии - СМИ и соцсети по Украине',
        en: 'Search by specific geography - media and social networks in Ukraine',
        ro: 'Căutare prin geografie specifică - media și rețele sociale în Ucraina',
        uk: 'Пошук за конкретною географією - ЗМІ та соцмережі по Україні'
      },
      SPECIFIC_GEO_CONTENT: {
        ru: '(ПриватБанк "Приват Банк" privatbank "privat bank") AND ((source_type:(tv radio press internet agency blog) AND source_country:Украина) OR (source_type:(socialnetwork forum review) AND country_name:Украина))',
        en: '(PrivatBank "Privat Bank" privatbank "privat bank") AND ((source_type:(tv radio press internet agency blog) AND source_country:Ukraine) OR (source_type:(socialnetwork forum review) AND country_name:Ukraine))',
        ro: '(PrivatBank "Privat Bank" privatbank "privat bank") AND ((source_type:(tv radio press internet agency blog) AND source_country:Ukraine) OR (source_type:(socialnetwork forum review) AND country_name:Ukraine))',
        uk: '(ПриватБанк "Приват Банк" privatbank "privat bank") AND ((source_type:(tv radio press internet agency blog) AND source_country:Україна) OR (source_type:(socialnetwork forum review) AND country_name:Україна))'
      },
      UNDEFINED_GEO_LABEL: {
        ru: 'Поиск по конкретной географии - СМИ и соцсети по Украине + неопределенная география для соцсетей',
        en: 'Search by specific geography - media and social networks in Ukraine + unspecified geography for social networks',
        ro: 'Căutare prin geografie specifică - media și rețele sociale în Ucraina + Geografie nespecificată pentru rețelele sociale',
        uk: 'Пошук за конкретною географією - ЗМІ та соцмережі по Україні +невизначена географія для соцмереж'
      },
      UNDEFINED_GEO_CONTENT: {
        ru: '(ПриватБанк "Приват Банк" privatbank "privat bank") AND ((source_type:(tv radio press internet agency blog) AND source_country:Украина) OR ((source_type:(socialnetwork forum review) AND country_name:Украина) OR (source_type:(socialnetwork forum review) AND NOT country_name:/[А-Я].{0,10}/)))',
        en: '(PrivatBank "Privat Bank" privatbank "privat bank") AND ((source_type:(tv radio press internet agency blog) AND source_country: Ukraine) OR ((source_type:social_network AND source_country:Ukraine) OR (source_type:social_network AND NOT source_country:/[A-Z]. {0,10}/)))',
        ro: '(PrivatBank "Privat Bank" privatbank "privat bank") AND ((source_type:(tv radio press internet agency blog) AND source_country: Ukraine) OR ((source_type:social_network AND source_country:Ukraine) OR (source_type:social_network AND NOT source_country:/[A-Z]. {0,10}/)))',
        uk: '(ПриватБанк "Приват Банк" privatbank "privat bank") AND ((source_type:(tv radio press internet agency blog) AND source_country:Україна) OR ((source_type:(socialnetwork forum review) AND country_name:Україна) OR (source_type:(socialnetwork forum review) AND NOT country_name:/[А-Я].{0,10}/)))'
      },
      SPECIFIC_PAGES_LABEL: {
        ru: 'Мониторинг конкретных страниц и авторов - https://prnt.sc/_6puaVmcGCFu',
        en: 'Monitoring of specific pages and authors - https://prnt.sc/_6puaVmcGCFu',
        ro: 'Monitorizarea unor pagini și autori specifici - https://prnt.sc/_6puaVmcGCFu',
        uk: 'Моніторинг конкретних сторінок та авторів - https://prnt.sc/_6puaVmcGCFu'
      },
      SPECIFIC_PAGES_CONTENT: {
        ru: 'publication_place_name:(ПриватБанк PrivatBank) author_name:(ПриватБанк PrivatBank)',
        en: 'publication_place_name:(privatbank PrivatBank) author_name:(privatbank PrivatBank)',
        ro: 'publication_place_name: (bancă privată privată bancă) autor_name: (bancă privată privată privată)',
        uk: 'publication_place_name:(ПриватБанк PrivatBank) author_name:(ПриватБанк PrivatBank)'
      },
      TOPIC_LABEL: {
        ru: 'Мониторинг конкретных страниц и авторов в привязке к теме',
        en: 'Monitoring of specific pages and authors by topic',
        ro: 'Monitorizarea unor pagini și autori specifici după subiect',
        uk: 'Моніторинг конкретних сторінок та авторів в прив\'язці до теми'
      },
      TOPIC_CONTENT: {
        ru: '(publication_place_name:(ПриватБанк PrivatBank) author_name:(ПриватБанк PrivatBank)) AND (выплаты соцвыплаты)',
        en: '(publication_place_name:(privatbank PrivatBank) author_name:(privatbank PrivatBank)) AND (payments social benefits)',
        ro: '(Publication_place_name: (privatbank privatbank) autor_name: (privatbank privatbank)) și (beneficii sociale de plăți)',
        uk: '(publication_place_name:(ПриватБанк PrivatBank) author_name:(ПриватБанк PrivatBank)) AND (виплати соцвиплати)'
      },
      LANG_LABEL: {
        ru: 'Поиск упоминаний только на русском или с исключением английского',
        en: 'Search for mentions in English only or with Russian excluded',
        ro: 'Căutați mențiuni doar în engleză sau cu excluse rusesc',
        uk: 'Пошук згадок лише українською мовою або з виключенням російської'
      },
      LANG_CONTENT: {
        ru: '(ПриватБанк "Приват Банк" privatbank "privat bank") AND language_code:ru (ПриватБанк "Приват Банк" privatbank "privat bank") AND NOT language_code:en',
        en: '(PrivatBank Privat Bank privatbank "privat bank") AND language_code:en (PrivatBank "Privat bank" privatbank "privat bank") AND NOT language_code:ru',
        ro: '(Bancă privată bancă privată bancă privată „bancă privată”) și limbă_code: ro (bancă privată „bancă privată” bancă privată „bancă privată”) și nu limbă_code: Ru',
        uk: '(ПриватБанк "Приват Банк" privatbank "privat bank") AND language_code:uk (ПриватБанк "Приват Банк" privatbank "privat bank") AND NOT language_code:ru'
      },
      EXCLISIONS_LABEL: {
        ru: 'Исключения имеют общие темы для исключений из мониторинга (спам, номера телефонов и корток), но можно добавить конкретную тему',
        en: 'Exclusions have general topics for monitoring exclusions (spam, phone numbers, and cortex), but you can add a specific topic',
        ro: 'Excluderile au subiecte generale pentru monitorizarea excluderilor (numere de telefon spam și cortex), dar puteți adăuga un subiect specific',
        uk: 'Виключення мають загальні теми для виключень з моніторингу (спам, номери телефонів та корток), але можна додати конкретну тему'
      },
      EXCLISIONS_CONTENT: {
        ru: '(ПриватБанк "Приват Банк" privatbank "privat bank") AND NOT (закон суд)',
        en: '(PrivatBank "Privat Bank" privatbank "privat bank") AND NOT (law court)',
        ro: '(Banca privată „bancă privată” bancă privată „bancă privată”) și notă (instanța de avocatură)',
        uk: '(ПриватБанк "Приват Банк" privatbank "privat bank") AND NOT (закон суд)'
      }
    },
    templatesList: {
      COMPANY_LABEL: {
        ru: 'Поиск компании',
        en: 'Company search',
        ro: 'Căutare a companiei',
        uk: 'Пошук компанії'
      },
      COMPANY_CONTENT: {
        ru: 'Этот шаблон позволяет искать упоминания о компаниях, организациях и тому подобное.',
        en: 'This template allows you to search for mentions about companies, organizations, etc.',
        ro: 'Acest șablon vă permite să căutați mențiuni despre organizațiile companiilor etc.',
        uk: 'Цей шаблон дозволяє шукати згадки про компанії, організації, тощо.'
      },
      PERSON_LABEL: {
        ru: 'Поиск лица',
        en: 'Person search',
        ro: 'Căutarea persoanei',
        uk: 'Пошук особи'
      },
      PERSON_CONTENT: {
        ru: 'Этот шаблон позволяет искать упоминания о лицах по имени или должности',
        en: 'This template allows you to search for mentions about people by name or position',
        ro: 'Acest șablon vă permite să căutați mențiuni despre oameni după nume sau poziție',
        uk: 'Цей шаблон дозволяє шукати згадки про осіб по імені або посаді'
      },
      PHRASE_LABEL: {
        ru: 'Поиск словосочетания',
        en: 'Search for a phrase',
        ro: 'Căutați o frază',
        uk: 'Пошук словосполучення'
      },
      PHRASE_CONTENT: {
        ru: 'Этот шаблон позволяет искать различные словосочетания и упоминания',
        en: 'This template allows you to search for different phrases and mentions',
        ro: 'Acest șablon vă permite să căutați diferite fraze și mențiuni',
        uk: 'Цей шаблон дозволяє шукати різні словосполучення та згадки'
      }

    },
    templateCompanyKeyword: {
      ADD_NAME: {
        ru: 'Добавьте название (варианты) компании или бренда',
        en: 'Add a company or brand name(s)',
        ro: 'Adăugați o companie sau un nume de marcă',
        uk: 'Додайте назву (варіанти) компанії або бренду'
      },
      ADD_ERRORS: {
        ru: 'Добавьте варианты с ошибками, сленговые или жаргонные названия или уникальные хэштеги или медиакампании',
        en: 'Add misspellings, slang or jargon, or unique hashtags or media campaigns',
        ro: 'Adăugați un argou de tip greșeală sau jargon sau hashtag -uri unice sau campanii media',
        uk: 'Додайте варіанти з помилками, сленгові або жаргонні назви або унікальні хештеги чи медіакампанії'
      },
      ADD_URL: {
        ru: 'Добавьте написание с url вашего сайта',
        en: 'Add the spelling from your website url',
        ro: 'Adăugați ortografia de pe adresa URL a site -ului dvs.',
        uk: 'Додайте написання з url вашого сайту'
      },
      ADD_MEDIA: {
        ru: 'Добавьте нужные типы СМИ или оставьте все',
        en: 'Add the media types you need or leave all of them',
        ro: 'Adăugați tipurile de medii de care aveți nevoie sau lăsați-le pe toate',
        uk: 'Додайте потрібні типи ЗМІ або залиште всі'
      },
      ADD_EXEP: {
        ru: 'Если нужно, добавьте исключения',
        en: 'Add exceptions if necessary',
        ro: 'Adăugați excepții, dacă este necesar',
        uk: 'Якщо потрібно, додайте виключення'
      }
    },
    templatePersonKeyword: {
      ADD_NAME: {
        ru: 'Добавьте варианты написания имени человека на нужных языках',
        en: 'Add options for spelling a person\'s name in the languages you need',
        ro: 'Adăugați opțiuni pentru ortografierea unei persoane în limbile de care aveți nevoie',
        uk: 'Додайте варіанти написання ім’я людини потрібними мовами'
      },
      ADD_ERRORS: {
        ru: 'Добавьте варианты с ошибками, сленговые или жаргонные названия или уникальные хэштеги или медиакампании',
        en: 'Add misspellings, slang or jargon, or unique hashtags or media campaigns',
        ro: 'Adăugați un argou de tip greșeală sau jargon sau hashtag -uri unice sau campanii media',
        uk: 'Додайте варіанти з помилками, сленгові або жаргонні назви або унікальні хештеги чи медіакампанії'
      },
      ADD_MEDIA: {
        ru: 'Добавьте нужные типы СМИ или оставьте все',
        en: 'Add the media types you need or leave all of them',
        ro: 'Adăugați tipurile de media de care aveți nevoie sau lăsați -le pe toate',
        uk: 'Додайте потрібні типи ЗМІ або залиште всі'
      },
      ADD_EXEP: {
        ru: 'Если нужно, добавьте исключения',
        en: 'Add exceptions if necessary',
        ro: 'Adăugați excepții, dacă este necesar',
        uk: 'Якщо потрібно, додайте виключення'
      }
    },
    templateCompanyTag: {
      ADD_NAME: {
        ru: 'Добавьте название (варианты) компании или бренда',
        en: 'Add a company or brand name(s)',
        ro: 'Adăugați o companie sau un nume de marcă',
        uk: 'Додайте назву (варіанти) компанії або бренду'
      },
      ADD_ERRORS: {
        ru: 'Добавьте варианты с ошибками, сленговые или жаргонные названия или уникальные хэштеги или медиакампании',
        en: 'Add misspellings, slang or jargon, or unique hashtags or media campaigns',
        ro: 'Adăugați un argou de tip greșeală sau jargon sau hashtag -uri unice sau campanii media',
        uk: 'Додайте варіанти з помилками, сленгові або жаргонні назви або унікальні хештеги чи медіакампанії'
      },
      ADD_MEDIA: {
        ru: 'Добавьте нужные типы СМИ или оставьте все',
        en: 'Add the media types you need or leave all of them',
        ro: 'Adăugați tipurile de media de care aveți nevoie sau lăsați -le pe toate',
        uk: 'Додайте потрібні типи ЗМІ або залиште всі'
      },
      ADD_EXEP: {
        ru: 'Если нужно, добавьте исключения',
        en: 'Add exceptions if necessary',
        ro: 'Adăugați excepții, dacă este necesar',
        uk: 'Якщо потрібно, додайте виключення'
      }
    },
    selectQueryTemplate: {
      HEADER: {
        ru: 'Выберите шаблон запроса',
        en: 'Select a request template',
        ro: 'Selectați un șablon de solicitare',
        uk: 'Оберіть шаблон запиту'
      },
      COMPANY_LABEL: {
        ru: 'Поиск компании',
        en: 'Company search',
        ro: 'Căutare a companiei',
        uk: 'Пошук компанії'
      },
      COMPANY_CONTENT: {
        ru: 'Шаблон позволяет искать упоминания о компаниях, организациях и т.д.',
        en: 'The template allows you to search for mentions about companies, organizations, etc.',
        ro: 'Șablonul vă permite să căutați mențiuni despre organizațiile companiilor etc.',
        uk: 'Шаблон дозволяє шукати згадки про компанії, організації, тощо'
      },
      COMPANY_CONTENT_PUBLICATIONS: {
        ru: 'Шаблон позволяет искать публикации о компаниях, организациях и т. д.',
        en: 'The template allows searching for publications about companies, organizations, etc.',
        ro: 'Șablonul permite căutarea publicațiilor despre companii, organizații etc.',
        uk: 'Шаблон дозволяє шукати публікації про компанії, організації тощо'
      },
      PERSON_LABEL: {
        ru: 'Поиск лица',
        en: 'Person search',
        ro: 'Căutarea persoanei',
        uk: 'Пошук особи'
      },
      PERSON_CONTENT: {
        ru: 'Шаблон позволяет искать упоминания о лицах по имени и фамилии',
        en: 'The template allows you to search for mentions about people by name and surname',
        ro: 'Șablonul vă permite să căutați mențiuni despre oameni pe nume și prenume',
        uk: 'Шаблон дозволяє шукати згадки про осіб за ім’ям та прізвищем'
      },
      PERSON_CONTENT_PUBLICATIONS: {
        ru: 'Шаблон позволяет искать публикации о людях по имени и фамилии',
        en: 'The template allows searching for publications about people by first and last name',
        ro: 'Șablonul permite căutarea publicațiilor despre persoane după nume și prenume',
        uk: 'Шаблон дозволяє шукати публікації про осіб за ім’ям та прізвищем'
      },
      WITHOUT_TEMPLATE_LABEL: {
        ru: 'Без шаблона',
        en: 'Without a template',
        ro: 'Fără un șablon',
        uk: 'Без шаблону'
      },
      WITHOUT_TEMPLATE_CONTENT: {
        ru: 'Позволяет написать поисковый запрос без использования шаблона',
        en: 'Allows you to write a search query without using a template',
        ro: 'Vă permite să scrieți o interogare de căutare fără a utiliza un șablon',
        uk: 'Дозволяє написати пошуковий запит без використання шаблону'
      },
      NEWS_LABEL: {
        ru: 'Поиск новостей',
        en: 'Search news',
        ro: 'Caută știri',
        uk: 'Пошук новини'
      },
      NEWS_CONTENT: {
        ru: 'Позволяет написать поисковый запрос без использования шаблона',
        en: 'Allows you to write a search query without using a template',
        ro: 'Vă permite să scrieți o interogare de căutare fără a utiliza un șablon',
        uk: 'Дозволяє написати пошуковий запит без використання шаблону'
      }
    },
    filterGeography: {
      COUNTRY: {
        ru: 'Страна',
        en: 'Country',
        ro: 'Țară',
        uk: 'Країна'
      },
      REGION: {
        ru: 'Регион',
        en: 'Region',
        ro: 'Regiune',
        uk: 'Регіон'
      },
      CITY: {
        ru: 'Город',
        en: 'City',
        ro: 'Oraș',
        uk: 'Місто'
      },
      ENTER_COUNTRY: {
        ru: 'Введите страну',
        en: 'Enter country',
        ro: 'Intrați în țară',
        uk: 'Введіть країну'
      },
      ENTER_REGION: {
        ru: 'Введите регион',
        en: 'Enter region',
        ro: 'Intrați în regiune',
        uk: 'Введіть регіон'
      },
      ENTER_CITY: {
        ru: 'Введите город',
        en: 'Enter city',
        ro: 'Intră în oraș',
        uk: 'Введіть місто'
      }
    },
    sourceFilter: {
      ENTER_SOURCE: {
        ru: 'Введите источник',
        en: 'Enter source',
        ro: 'Introduceți sursa',
        uk: 'Введіть джерело'
      },
      ADD_SOURCE: {
        ru: 'Добавить источник',
        en: 'Add source',
        ro: 'Adăugați sursa',
        uk: 'Додати джерело'
      },
      LEVEL: {
        ru: 'Уровень источника',
        en: 'Source level',
        ro: 'Nivel sursă',
        uk: 'Рівень джерела'
      },
      SUBJECT: {
        ru: 'Тематика источника',
        en: 'Source subject',
        ro: 'Subiect sursă',
        uk: 'Тематика джерела'
      },
      SUBTOPIC: {
        ru: 'Подтематика источника',
        en: 'Source subtopic',
        ro: 'Subtopic sursă',
        uk: 'Підтематика джерела'
      },
      PROPOSE_SOURCE: {
        ru: 'Предложить источник',
        en: 'Suggest a source',
        ro: 'Sugerează o sursă',
        uk: 'Запропонувати джерело'
      }
    },
    characterFilter: {
      SUBJECT: {
        ru: 'Главная',
        en: 'Main',
        ro: 'Principal',
        uk: 'Головна'
      },
      MAIN: {
        ru: 'Главная',
        en: 'Main',
        ro: 'Principal',
        uk: 'Головна'
      },
      SECONDARY: {
        ru: 'Второстепенная',
        en: 'Secondary',
        ro: 'Secundar',
        uk: 'Другорядна'
      },
      OBJECT: {
        ru: 'Эпизодическая',
        en: 'Episodic',
        ro: 'Episodic',
        uk: 'Епізодична'
      },
      EPISODIC: {
        ru: 'Эпизодическая',
        en: 'Episodic',
        ro: 'Episodic',
        uk: 'Епізодична'
      }
    },
    mediaTypesFilter: {
      INTERNET: {
        ru: 'Онлайн',
        en: 'Online',
        ro: 'Online',
        uk: 'Онлайн'
      },
      AGENCY: {
        ru: 'Информационные агентства',
        en: 'News Agency',
        ro: 'Agenții de informare',
        uk: 'Інформаційні агентства'
      },
      PRESS: {
        ru: 'Пресса',
        en: 'Press',
        ro: 'Presa',
        uk: 'Преса'
      },
      RADIO: {
        ru: 'Радио',
        en: 'Radio',
        ro: 'Radio',
        uk: 'Радіо'
      },
      SOCIALNETWORK: {
        ru: 'Социальные сети',
        en: 'Social media',
        ro: 'Rețele socială',
        uk: 'Соціальні мережі'
      },
      FORUM: {
        ru: 'Форумы',
        en: 'Forums',
        ro: 'Forums',
        uk: 'Форуми'
      },
      REVIEW: {
        ru: 'Отзывы',
        en: 'Reviews',
        ro: 'Recenzii',
        uk: 'Відгуки'
      },
      BLOG: {
        ru: 'Блоги',
        en: 'Blogs',
        ro: 'Bloguri',
        uk: 'Блоги'
      },
      TV: {
        ru: 'ТВ',
        en: 'TV',
        ro: 'TV',
        uk: 'ТБ'
      },
      SUM: {
        ru: 'Сумм',
        en: 'Amount',
        ro: 'Suma',
        uk: 'Сума'
      }
    },
    placePublicationFilter: {
      ENTER_PLACE: {
        ru: 'Введите место публикации',
        en: 'Enter place of publication',
        ro: 'Introduceți locul de publicare',
        uk: 'Введіть місце публікації'
      }
    },
    creatorLngFilter: {
      CREATOR: {
        ru: 'Автор',
        en: 'Author',
        ro: 'Autorul',
        uk: 'Автор'
      },
      LNG: {
        ru: 'Язык',
        en: 'Language',
        ro: 'Limba',
        uk: 'Мова'
      },
      ENTER_CREATOR: {
        ru: 'Введите имя автора',
        en: 'Enter Author Name',
        ro: 'Introduceți numele autorului',
        uk: 'Введіть ім\'я автора'
      },
      ENTER_LNG: {
        ru: 'Введите язык',
        en: 'Enter language',
        ro: 'Introduceți limbajul',
        uk: 'Введіть мову'
      },
      FROM: {
        ru: 'От',
        en: 'From',
        ro: 'Din',
        uk: 'Від'
      },
      TO: {
        ru: 'До',
        en: 'To',
        ro: 'La',
        uk: 'До'
      },
      GENDER: {
        ru: 'Пол автора',
        en: 'Author gender',
        ro: 'Genul autorului',
        uk: 'Стать автора'
      },
      AGE: {
        ru: 'Возраст автора',
        en: 'Author Age',
        ro: 'Epoca autorului',
        uk: 'Вік автора'
      },
      MALE: {
        ru: 'Мужской',
        en: 'Male',
        ro: 'Masculin',
        uk: 'Чоловіча'
      },
      FEMALE: {
        ru: 'Женский',
        en: 'Female',
        ro: 'Femeie',
        uk: 'Жіноча'
      }

    },
    notebookEditor: {
      ADD: {
        ru: 'Добавить',
        en: 'Add',
        ro: 'Adăuga',
        uk: 'Додати'
      },
      CANCEL: { ru: 'Отменить', en: 'Cancel', uk: 'Скасувати', ro: 'Anulare' },
      H1: {
        ru: 'Огромный',
        en: 'Huge',
        ro: 'Imens',
        uk: 'Величезний'
      },
      H2: {
        ru: 'Крупный',
        en: 'Large',
        ro: 'Mare',
        uk: 'Великий'
      },
      NORMAL: {
        ru: 'Обычный',
        en: 'Normal',
        ro: 'Normal',
        uk: 'Звичайний'
      },
      BOLD: {
        ru: 'Жирний',
        en: 'Bold',
        ro: 'Îndrăzneţ',
        uk: 'Жирний'
      },
      ITALIC: {
        ru: 'Курсив',
        en: 'Italic',
        ro: 'Cursiv',
        uk: 'Курсив'
      },
      UNDERLINE: {
        ru: 'Подчеркнутый',
        en: 'Underline',
        ro: 'Sublinia',
        uk: 'Підкреслений'
      },
      LINK_TITLE: {
        ru: 'Текст ссылки',
        en: 'Link Title',
        ro: 'Titlul linkului',
        uk: 'Текст посилання'
      },
      LINK_TARGET: {
        ru: 'Адрес ссылки',
        en: 'Link Title',
        ro: 'Titlul linkului',
        uk: 'Адреса посилання'
      },
      LINK_TARGET_OPTION: {
        ru: 'Открыть в новом окне',
        en: 'Open link in new window',
        ro: 'Deschide link-ul într-o fereastră nouă',
        uk: 'Відкрити в новому вікні'
      },
      LINK: {
        ru: 'Ccылка',
        en: 'Link',
        ro: 'Legătură',
        uk: 'Посилання'
      },
      LIST: {
        ru: 'Список',
        en: 'List',
        ro: 'Listă',
        uk: 'Список'
      },
      UNORDERED: {
        ru: 'Неупорядоченный',
        en: 'Unordered',
        ro: 'Neordonat',
        uk: 'Невпорядкований'
      },
      ORDERED: {
        ru: 'Упорядоченный',
        en: 'Ordered',
        ro: 'Ordonat',
        uk: 'Впорядкований'
      },
      PLACEHOLDER: {
        ru: 'Запишите что нибудь...',
        en: 'Write something...',
        ro: 'Scrie ceva...',
        uk: 'Запишіть що-небудь...'
      },
      CLEAR: {
        ru: 'Очистить',
        en: 'Clear',
        ro: 'Clar',
        uk: 'Очистити'
      }
    },
    auth: {
      REDIRECT: {
        ru: 'Перенаправление на страницу авторизации...',
        en: 'Redirecting to authorization page...',
        ro: 'Redirecționarea către pagina de autorizare ...',
        uk: 'Перенаправлення на сторінку авторизації...'
      },
      BEGIN: {
        ru: 'Начинаем...',
        en: 'Begin...',
        ro: 'Începeți...',
        uk: 'Починаємо...'
      },
      LEAVE: {
        ru: 'Выходим...',
        en: 'Leave...',
        ro: 'Părăsi...',
        uk: 'Виходимо...'
      }
    },
    downloads: {
      REPORT_NAME: {
        ru: 'Название отчета',
        en: 'Report name',
        ro: 'Titlul raportului',
        uk: 'Назва звіту'
      },
      RECEIVED: {
        ru: 'Получено',
        en: 'Received',
        ro: 'Primită',
        uk: 'Отримано'
      },
      SEARCH_BY_NAME: {
        ru: 'Поиск по названию',
        en: 'Search by name',
        ro: 'Căutare după nume',
        uk: 'Пошук за назвою'
      },
      NODOWNLOADS: {
        ru: 'У вас пока что нет загрузок',
        en: 'You have no downloads yet',
        ro: 'Nu aveți încă nici o descărcare',
        uk: 'У вас поки що немає завантажень'
      },
      NODOWNLOADS_DESCRIPTION: {
        ru: 'На этой странице вы сможете легко найти и загрузить отчеты уведомлений на основе выбранных вами параметров в течение определенного периода.',
        en: 'On this page, you can easily find and download alert reports based on the parameters you have selected for a specified period.',
        ro: 'Pe această pagină, puteți găsi și descărca cu ușurință rapoarte de alertă pe baza parametrilor pe care i-ați selectat pentru o anumită perioadă.',
        uk: 'На цій сторінці ви зможете легко знайти та завантажити звіти сповіщень на основі обраних вами параметрів протягом визначеного періоду.'
      },
      NODOWNLOADS_BY_FILTER: {
        ru: 'Загрузок с такими параметрами фильтрации не найдено',
        en: 'No downloads were found with the following filtering options',
        ro: 'Nu au fost găsite descărcări cu următorii parametri de filtrare',
        uk: 'Завантажень з такими параметрами фільтрації не знайдено'
      },
      CHECK_FILTERS: {
        ru: 'Перевірте на правильність пошуковий запит. Також ви можете розширити налаштовані фільтри.',
        en: 'Перевірте на правильність пошуковий запит. Також ви можете розширити налаштовані фільтри.',
        ro: 'Перевірте на правильність пошуковий запит. Також ви можете розширити налаштовані фільтри.',
        uk: 'Перевірте на правильність пошуковий запит. Також ви можете розширити налаштовані фільтри.'
      },
      FORMAT: {
        ru: 'Формат',
        en: 'Format',
        ro: 'Format',
        uk: 'Формат'
      },
      ALL: {
        ru: 'Все',
        en: 'All',
        ro: 'Toate',
        uk: 'Всі'
      },
      DONE: {
        ru: 'Готово',
        en: 'Done',
        ro: 'S-a făcut',
        uk: 'Готово'
      },
      ERROR: {
        ru: 'Ошибка',
        en: 'Error',
        ro: 'Eroare',
        uk: 'Помилка'
      },
      PENDING: {
        ru: 'В ожидании',
        en: 'Pending',
        ro: 'În așteptare',
        uk: 'В очікуванні'
      },
      FORMING: {
        ru: 'Формируется',
        en: 'Forming',
        ro: 'Formarea',
        uk: 'Формується'
      },
      CANCELLED: {
        ru: 'Отменено',
        en: 'Canceled',
        ro: 'Anulat',
        uk: 'Скасовано'
      },
      ARCHIVED: {
        ru: 'Архивировано',
        en: 'Archived',
        ro: 'Arhivat',
        uk: 'Архівовано'
      },
      RESET_FILTERS: {
        ru: 'Cбросить все фильтры',
        en: 'Reset all filters',
        ro: 'Resetați toate filtrele',
        uk: 'Скинути всі фільтри'
      },
      PERIOD: {
        ru: 'Период',
        en: 'Period',
        ro: 'Perioada',
        uk: 'Період'
      },
      STATUS: {
        ru: 'Статус',
        en: 'Status',
        ro: 'Stare',
        uk: 'Статус'
      },
      DATE_RECEIPT: {
        ru: 'Дата получения',
        en: 'Date of receipt',
        ro: 'Data primirii',
        uk: 'Дата отримання'
      }

    },
    alerts: {
      ALERT: {
        ru: 'Оповещение',
        en: 'Alert',
        ro: 'Alertelor',
        uk: 'Сповіщеня'
      },
      SETTING_ALERTS: {
        ru: 'Настройка оповещений',
        en: 'Setting alerts',
        ro: 'Setarea alertelor',
        uk: 'Налаштування сповіщень'
      },
      SETTING_REPORTS: {
        ru: 'Настройка отчетов',
        en: 'Setting reports',
        ro: 'Setarea rapoartelor',
        uk: 'Налаштування звітів'
      },
      CREATE_ALERT: {
        ru: 'Создать оповещение',
        en: 'Create an alert',
        uk: 'Створити сповіщення',
        ro: 'Creați o notificare'
      },
      EDIT_ALERT: {
        ru: 'Редактировать оповещение',
        en: 'Edit an alert',
        uk: 'Редагувати сповіщення',
        ro: 'Editați o notificare'
      },
      TELEGRAM_RECIVE: {
        ru: 'Получайте упоминания с LOOQME в личные сообщения или групповые чаты в Telegram',
        en: 'Receive mentions from LOOQME in private messages or group chats in Telegram.',
        uk: 'Отримуйте згадки з LOOQME в приватні повідомлення чи групові чати в Telegram',
        ro: 'Primiți mențiuni de la LOOQME în mesajele private sau în discuțiile de grup din Telegram.'
      },
      TELEGRAM_RECIVE_M: {
        ru: 'Получайте упоминания с METRICOM в личные сообщения или групповые чаты в Telegram',
        en: 'Receive mentions from METRICOM in private messages or group chats in Telegram.',
        uk: 'Отримуйте згадки з METRICOM в приватні повідомлення чи групові чати в Telegram',
        ro: 'Primiți mențiuni de la METRICOM în mesajele private sau în discuțiile de grup din Telegram.'
      },
      TELEGRAM_RECIVE_METHOD: {
        ru: 'Каким образом вы бы хотели получать упоминания?',
        en: 'How would you like to receive mentions?',
        uk: 'Яким чином ви б хотіли отримувати згадки?',
        ro: 'Cum ați dori să fiți menționat?'
      },
      TELEGRAM_WAITING: {
        ru: 'Мы сейчас подключаем твою учетную запись в Telegram к нашей системе. Просьба не закрывать это сообщение, пока процесс не будет завершен',
        en: 'We are currently connecting your Telegram account to our system. Please do not close this message until the process is complete',
        uk: 'Ми зараз підключаємо твій обліковий запис у Telegram до нашої системи. Прохання не закривати це повідомлення, доки процес не буде завершено',
        ro: 'În prezent, vă conectăm contul Telegram la sistemul nostru. Vă rugăm să nu închideți acest mesaj până când procesul nu este finalizat'
      },
      WHATSAPP_RECEIVE: {
        ru: 'Получайте упоминания с LOOQME в личные сообщения WhatsApp.',
        en: 'Receive mentions from LOOQME in private WhatsApp messages.',
        uk: 'Отримуйте згадки з LOOQME в приватні повідомлення WhatsApp.',
        ro: 'Primiți mențiuni de la LOOQME în mesajele private WhatsApp.'
      },
      WHATSAPP_RECEIVE_M: {
        ru: 'Получайте упоминания с METRICOM в личные сообщения WhatsApp.',
        en: 'Receive mentions from METRICOM in private WhatsApp messages.',
        uk: 'Отримуйте згадки з METRICOM в приватні повідомлення WhatsApp.',
        ro: 'Primiți mențiuni de la METRICOM în mesajele private WhatsApp.'
      },
      WHATSAPP_CONFIRM: {
        ru: 'Подтвердите согласие на получение личных сообщений в WhatsApp. Нажмите "Да" в сообщении.',
        en: 'Confirm your consent to receive private messages on WhatsApp. Click "Yes" on the message.',
        uk: 'Підтвердьте згоду на отримання особистих повідомлень у WhatsApp. Натисніть "Так" у повідомленні.',
        ro: 'Confirmați-vă consimțământul pentru a primi mesaje private pe WhatsApp. Faceți clic pe „Da” pe mesaj.'
      },
      CONGRATULATIONS: {
        ru: 'Поздравляем!',
        en: 'Congratulations!',
        uk: 'Вітаємо!',
        ro: 'Felicitări!'
      },
      ACCOUNT_CONNECTED: {
        ru: 'Аккаунт был успешно подключен',
        en: 'The account has been successfully connected',
        uk: 'Аккаунт було успішно підключено',
        ro: 'Contul a fost conectat cu succes'
      },
      TELEGRAM_SUCCESS_DESCRIPTION: {
        ru: 'Теперь вы будете получать упоминания в ваш Telegram',
        en: 'Now you will receive mentions in your Telegram',
        uk: 'Тепер ви будете отримувати згадки у ваш Telegram',
        ro: 'Acum veți primi mențiuni în Telegram'
      },
      SAVE_CHANGES: {
        ru: 'Хотите ли вы сохранить изменения в уведомлении ',
        en: 'Do you want to save the changes in the notification ',
        uk: 'Хотіли би ви зберегти зміни у сповіщенні ',
        ro: 'Doriți să salvați modificările aduse notificării '
      },
      CONFIRM_DELETE_ALERT: {
        ru: 'Вы уверены, что хотите удалить оповещение ',
        en: 'Are you sure you want to delete an alert ',
        uk: 'Ви впевнені, що хочете видалити сповіщення ',
        ro: 'Sunteți sigur că doriți să ștergeți o notificare '
      },
      NO_NAME: {
        ru: 'Без названия',
        en: 'Untitled',
        uk: 'Без назви',
        ro: 'Fără titlu'
      },
      HAVE_UNSAVED_CHANGES: {
        ru: 'Ваше текущее уведомление содержит несохраненные изменения. Пожалуйста, подтвердите эти изменения или отмените их перед продолжением работы',
        en: 'Your current notification contains unsaved changes. Please confirm or discard these changes before continuing',
        uk: 'Ваше поточне сповіщення містить незбережені зміни. Будь ласка, підтвердіть ці зміни або скасуйте їх перед тим, як продовжити роботу',
        ro: 'Notificarea dvs. actuală conține modificări nesalvate. Vă rugăm să confirmați sau să renunțați la aceste modificări înainte de a continua'
      },
      ALERT_HAS_ERROR: {
        ru: 'Ваше текущее уведомление содержит ошибки. Пожалуйста, отредактируйте уведомления перед тем, как сохранить изменения',
        en: 'Your current notification contains errors. Please edit the notification before saving the changes',
        uk: 'Ваше поточне сповіщення містить помилки. Будь ласка, відредагуйте сповіщення перед тим як зберегти зміни',
        ro: 'Notificarea dvs. actuală conține erori. Vă rugăm să editați notificarea înainte de a salva modificările'
      },
      ALERTS: {
        ru: 'Оповещения',
        en: 'Alerts',
        ro: 'Alerte',
        uk: 'Сповіщення'
      },
      ALERTS_NEGATIVE: {
        ru: 'Оповещения по негативу',
        en: 'Alerts on negativity',
        ro: 'Alerte pentru negativitate',
        uk: 'Сповіщення по негативу'
      },
      ALERTS_ACTIVITY: {
        ru: 'Оповещения по активности',
        en: 'Alerts on activity',
        ro: 'Alerte de activitate',
        uk: 'Сповіщення по активності'
      },
      ACTIVE: {
        ru: 'активных',
        en: 'active',
        uk: 'активних',
        ro: 'activ'
      },
      FROM: {
        ru: 'с',
        en: 'from',
        uk: 'з',
        ro: 'din'
      },
      BY_ACTIVITY: {
        ru: 'По активности',
        en: 'By activity',
        ro: 'Prin activitate',
        uk: 'По активності'
      },
      BY_NEGATIVE: {
        ru: 'По негативу',
        en: 'By negative',
        ro: 'Prin negativ',
        uk: 'По негативу'
      },
      ALERT_NAME: {
        ru: 'Название оповещения',
        en: 'Alert name',
        ro: 'Numele alerte',
        uk: 'Назва cповіщення'
      },
      BY_KEYWORD: {
        ru: 'по ключевому слову',
        en: 'by keyword',
        ro: 'prin cuvânt cheie',
        uk: 'за ключовим словом'
      },
      BY_QUERY: {
        ru: 'по запросу',
        en: 'by query',
        ro: 'prin interogarea',
        uk: 'за запитом'
      },
      CHOOSE_KEYWORD: {
        ru: 'Выберите ключевое слово',
        en: 'Choose a keyword',
        ro: 'Selectați un cuvânt cheie',
        uk: 'Оберіть ключове слово'
      },
      SET_QUERY: {
        ru: 'Настройте запрос',
        en: 'Personalizați interogarea',
        ro: 'Selectați un cuvânt cheie',
        uk: 'Налаштуйте запит'
      },
      MADE_CONSTRUCTOR: {
        ru: 'сделанным через наш конструктор',
        en: 'made through our constructor',
        ro: 'făcută prin constructorul nostru',
        uk: 'зробленим через наш конструктор'
      },
      KEEP_HAND: {
        ru: 'Держите руку на пульсе',
        en: 'Keep up with the pulse',
        ro: 'Țineți pasul cu pulsul',
        uk: 'Тримайте руку на пульсі'
      },
      KEEP_HAND_TEXT: {
        ru: 'Отслеживайте упоминания в социальных сетях, СМИ, на телевидении и других платформах, чтобы всегда быть в курсе',
        en: 'Track mentions on social media, media, TV, and other platforms to stay informed',
        ro: 'Urmăriți mențiunile din social media, mass-media, televiziune și alte platforme pentru a fi informați.',
        uk: 'Відстежуйте згадки в соціальних мережах, ЗМІ, на телебаченні та інших платформах, щоб завжди бути в курсі'
      },
      COMPETITORS_ALERTS: {
        ru: 'Оповещение о конкурентах',
        en: 'Competitor alerts',
        ro: 'ȚAlerte privind concurența',
        uk: 'Сповіщення про конкурентів'
      },
      COMPETITORS_ALERTS_TEXT: {
        ru: 'Отслеживайте негативные упоминания о конкурентах, анализируйте их ошибки и всегда оставайтесь на шаг впереди',
        en: 'Track negative mentions of competitors, analyze their mistakes and always stay one step ahead',
        ro: 'Urmăriți mențiunile negative ale concurenților, analizați-le greșelile și fiți mereu cu un pas înainte',
        uk: 'Відстежуйте негативні згадки про конкурентів, аналізуйте їх помилки та завжди залишайтеся на крок попереду'
      },
      EXAMPLE_ALERTS: {
        ru: 'Например, оповещение',
        en: 'For example, alerts',
        ro: 'De exemplu, alerte',
        uk: 'Наприклад, сповіщення'
      },
      ENTER_NAME_ALERT: {
        ru: 'Введите название оповещения',
        en: 'Enter alert name',
        ro: 'Introduceți numele alertei',
        uk: 'Введіть назву сповіщення'
      },
      SEE_RECIVER_MAIL: {
        ru: 'а вот здесь видно кому на почту оно придет  ',
        en: 'and here you can see to whom it will be sent by mail ',
        ro: 'iar aici puteți vedea cui va fi trimisă  ',
        uk: 'а ось тут видно кому на пошту воно прийде  '
      },
      COMPANY_NAME: {
        ru: 'здесь может быть название вашей компании',
        en: 'your company name can be here',
        ro: 'numele companiei dvs. poate fi aici',
        uk: 'тут може бути назва вашої компанії'
      },
      COLLEAGUE_MAIL: {
        ru: 'Почта коллеги',
        en: 'Colleague mail',
        ro: 'Poșta unui coleg',
        uk: 'Пошта колеги'
      },
      YOUR_MAIL: {
        ru: 'Ваша почта',
        en: 'Your mail',
        ro: 'Poșta ta',
        uk: 'Ваша пошта'
      },
      LETS_TRY: {
        ru: 'Давайте попробуем! Нажмите на кнопку',
        en: 'Let\'s try it! Click the button',
        ro: 'Haideți să încercăm! Apăsați pe buton',
        uk: 'Давайте спробуємо! Тицяйте на кнопку'
      },
      FEW_MINUTES: {
        ru: 'Несколько минут настроек, что сохранят вам несколько часов в месяц',
        en: 'A few minutes of settings will save you several hours a month',
        ro: 'Câteva minute de setări vă vor economisi câteva ore pe lună',
        uk: 'Кілька хвилин налаштувань, що збережуть вам кілька годин в місяць'
      },
      PREVENT_CRISIS: {
        ru: 'Предотвращайте кризис',
        en: 'Prevent a crisis',
        ro: 'Preveniți o criză',
        uk: 'Запобігайте кризі'
      },
      PREVENT_CRISIS_DESCRIPTION: {
        ru: 'Используя быстрые оповещения о любых негативных упоминаниях, касающихся вашего бренда, и эффективно применяйте вашу стратегию кризисного управления',
        en: 'Utilize quick alerts for any negative mentions related to your brand and effectively implement your crisis management strategy',
        ro: 'Folosiți alerte rapide pentru orice mențiuni negative legate de brandul dvs. și implementați eficient strategia de gestionare a crizei.',
        uk: 'Використовуючи швидкі сповіщення про будь-які негативні згадки, що стосуються вашого бренду, та ефективно застосовуйте вашу стратегію кризового управління'
      },
      COMPANY_NAME_SYMBOLS: {
        ru: 'Название компании -#!@$$%',
        en: 'Name of the company -#!@$$$%',
        ro: 'Numele companiei -#!@$$$$%',
        uk: 'Назва компанії —#!@$$%'
      },
      REPORT_TEAM: {
        ru: 'Сообщите хоть всей команде о проблеме',
        en: 'Let the whole team know about the problem',
        ro: 'Anunțați întreaga echipă despre problemă',
        uk: 'Повідомте хоч всю команду про проблему'
      },
      INFORM: {
        ru: 'Информация, которая всегда под рукой',
        en: 'Information at your fingertips',
        ro: 'Informații la îndemâna dumneavoastră',
        uk: 'Інформація, що завжди під рукою'
      },
      INFORM_TEXT: {
        ru: 'Получайте уведомления удобным для вас способом',
        en: 'Get alerts in a way that suits you best',
        ro: 'Primiți notificări în modul care vi se potrivește cel mai bine',
        uk: 'Отримуйте сповіщення у зручний для вас спосіб'
      },
      IN_TELEGRAM: {
        ru: 'Telegram',
        en: 'Telegram',
        ro: 'Telegram',
        uk: 'Telegram'
      },
      EMAIL: {
        ru: 'Почта',
        en: 'Mail',
        ro: 'Poșta',
        uk: 'Пошта'
      },
      SURGE_ACTIVITY: {
        ru: 'Всплеск активности',
        en: 'A surge in activity',
        ro: 'O intensificare a activității',
        uk: 'Сплеск активності'
      },
      SURGE_ACTIVITY_TEXT: {
        ru: 'Будьте в курсе внезапных пиков упоминаний, которые могут сигнализировать о распространении вирусного контента или внезапных вызовах. Опережайте ситуацию, будучи всегда готовыми к непредвиденным обстоятельствам',
        en: 'Be aware of sudden peaks in mentions that may signal the spread of viral content or sudden challenges. Stay ahead of the curve by always being prepared for unforeseen circumstances',
        ro: 'Fiți atenți la creșterile bruște de mențiuni care ar putea semnala răspândirea unui conținut viral sau provocări bruște. Rămâneți în fața curbei, fiind întotdeauna pregătiți pentru circumstanțe neprevăzute',
        uk: 'Будьте в курсі раптових піків згадок, що можуть сигналізувати про поширення вірусного контенту або раптові виклики. Випереджайте ситуацію, будучи завжди готовими до непередбачених обставин'
      },
      ENGAGEMENT: {
        ru: 'Вовлеченность',
        en: 'Engagement',
        ro: 'Logodnă',
        uk: 'Залученість'
      },
      ENGAGEMENT_TEXT: {
        ru: 'Оценивайте успешность вашей кампании или вовлеченность в контент, анализируя количество упоминаний и интенсивность обсуждений',
        en: 'Measure the success of your campaign or engagement with your content by analyzing the number of mentions and the intensity of discussions',
        ro: 'Măsurați succesul campaniei dvs. sau angajamentul față de conținutul dvs. prin analizarea numărului de mențiuni și a intensității discuțiilor',
        uk: 'Оцінюйте успішність вашої кампанії або залученість до контенту, аналізуючи кількість згадок та інтенсивність обговорень'
      },
      SEARCH_BY: {
        ru: 'Поиск по',
        en: 'Search by',
        ro: 'Căutare după',
        uk: 'Пошук за'
      },
      ON_KEYWORD: {
        ru: 'ключевому слову',
        en: 'keyword',
        ro: 'cuvânt cheie',
        uk: 'ключовим словом'
      },
      ON_QUERY: {
        ru: 'запросу',
        en: 'query',
        ro: 'interogarea',
        uk: 'запитом'
      },
      SEND_BY: {
        ru: 'Доставка через',
        en: 'Delivery by',
        ro: 'Livrare prin',
        uk: 'Доставка через'
      },
      BASIC: {
        ru: 'Базовый',
        en: 'Basic',
        ro: 'De bază',
        uk: 'Базовий'
      },
      ADVANCED: {
        ru: 'Расширенный',
        en: 'Advanced',
        ro: 'Avansat',
        uk: 'Розширений'
      },
      REFINE_SEARCH_KEYWORD: {
        ru: 'Уточните поиск по ключевому слову с помощью: ⚖️ альтернативных слов, ✳️ обязательных слов, ❌ слов которых не должно быть или же постройте расширенные поисковые запросы с помощью операторов.',
        en: 'Refine your keyword search by using: ⚖️ alternative words, ✳️ required words, ❌ words that should not be there, or build advanced searches using operators.',
        ro: 'Rafinează-ți căutarea de cuvinte cheie folosind: ⚖️ cuvinte alternative, ✳️ cuvinte obligatorii, ❌ cuvinte care nu ar trebui să se afle acolo, sau construiți căutări avansate folosind operatorii.',
        uk: 'Уточніть пошук за ключовим словом за допомогою: ⚖️ альтернативних слів, ✳️ обовʼязкових слів, ❌ слів яких не має бути або ж побудуйте розширені пошукові запити за допомогою операторів.'
      },
      GET_ALERTS: {
        ru: 'Получайте упоминания с LOOQME в личные сообщения или групповые чаты в Telegram Выберите каким образом вы бы хотели получать упоминания?',
        en: 'Receive alerts from LOOQME in private messages or group chats in Telegram. Choose how you would like to receive mentions?',
        ro: 'Primiți mențiuni de la LOOQME în mesajele private sau în discuțiile de grup din Telegram. Alegeți cum doriți să primiți mențiuni?',
        uk: 'Отримуйте згадки з LOOQME в приватні повідомлення чи групові чати в Telegram. Оберіть яким чином ви б хотіли отримувати згадки?'
      },
      GET_ALERTS_M: {
        ru: 'Получайте упоминания с METRICOM в личные сообщения или групповые чаты в Telegram Выберите каким образом вы бы хотели получать упоминания?',
        en: 'Receive alerts from METRICOM in private messages or group chats in Telegram. Choose how you would like to receive mentions?',
        ro: 'Primiți mențiuni de la METRICOM în mesajele private sau în discuțiile de grup din Telegram. Alegeți cum doriți să primiți mențiuni?',
        uk: 'Отримуйте згадки з METRICOM в приватні повідомлення чи групові чати в Telegram. Оберіть яким чином ви б хотіли отримувати згадки?'
      },
      GET_ALERTS_WHATSAPP: {
        ru: 'Получайте упоминания с LOOQME в личные сообщения в WhatsApp.',
        en: 'Receive alerts from LOOQME in private messages in WhatsApp.',
        ro: 'Primiți mențiuni de la LOOQME în mesajele private WhatsApp.',
        uk: 'Отримуйте згадки з LOOQME в приватні повідомлення в WhatsApp.'
      },
      GET_ALERTS_WHATSAPP_M: {
        ru: 'Получайте упоминания с METRICOM в личные сообщения в WhatsApp.',
        en: 'Receive alerts from METRICOM in private messages in WhatsApp.',
        ro: 'Primiți mențiuni de la METRICOM în mesajele private WhatsApp.',
        uk: 'Отримуйте згадки з METRICOM в приватні повідомлення в WhatsApp.'
      },
      SELECT_ACCOUNTS_TELEGRAM: {
        ru: 'Добавьте уже подключенный профиль или чат из списка',
        en: 'Add an already connected profile or chat from the list',
        ro: 'Adăugați un profil sau un chat deja conectat din listă',
        uk: 'Додайте вже підключений профіль або чат зі списку'
      },
      RECIPIENTS: {
        ru: 'получателей',
        en: 'recipients',
        uk: 'отримувачів',
        ro: 'destinatarii'
      }
    },
    reports: {
      NO_NAME: {
        ru: 'Без названия',
        en: 'Untitled',
        uk: 'Без назви',
        ro: 'Fără titlu'
      },
      REPORTS: {
        ru: 'Отчеты',
        en: 'Reports',
        uk: 'Звіти',
        ro: 'Rapoarte'
      },
      REPORT_NAME: {
        ru: 'Название отчета',
        en: 'Report name',
        ro: 'Titlul raportului',
        uk: 'Назва звіту'
      },
      BY_KEYWORD: {
        ru: 'по ключевому слову',
        en: 'by keyword',
        ro: 'prin cuvânt cheie',
        uk: 'за ключовим словом'
      },
      TEMPLATE: {
        ru: 'Шаблон',
        en: 'Template',
        ro: 'Șablon',
        uk: 'Шаблон'
      },
      CHOOSE_KEYWORD: {
        ru: 'Выберите ключевое слово',
        en: 'Choose a keyword',
        ro: 'Selectați un cuvânt cheie',
        uk: 'Оберіть ключове слово'
      },
      SEND_TEMPLATE: {
        ru: 'Шаблон для рассылки',
        en: 'Template for mailing',
        ro: 'Șablon pentru buletinul informativ',
        uk: 'Шаблон для розсилки'
      },
      CHOOSE_TEMPLATE: {
        ru: 'Выберите шаблон',
        en: 'Choose template',
        ro: 'Alegeți șablonul',
        uk: 'Оберіть шаблон'
      },
      SET_QUERY: {
        ru: 'Настройте запрос',
        en: 'Personalizați interogarea',
        ro: 'Selectați un cuvânt cheie',
        uk: 'Налаштуйте запит'
      },
      SEND_BY: {
        ru: 'Доставка через',
        en: 'Delivery by',
        ro: 'Livrare prin',
        uk: 'Доставка через'
      },
      ENTER_NAME_REPORT: {
        ru: 'Введите название отчета',
        en: 'Enter report name',
        ro: 'Introduceți numele raportului',
        uk: 'Введіть назву звіту'
      },
      CREATE_REPORT: {
        ru: 'Создать отчет',
        en: 'Create report',
        ro: 'Creați raportul',
        uk: 'Створити звіт'
      },
      EDIT_REPORT: {
        ru: 'Редактировать отчет',
        en: 'Edit report',
        ro: 'Editare raportul',
        uk: 'Редагувати звіт'
      },
      SELECT_ACCOUNTS_TELEGRAM: {
        ru: 'Добавьте уже подключенный профиль или чат из списка',
        en: 'Add an already connected profile or chat from the list',
        ro: 'Adăugați un profil sau un chat deja conectat din listă',
        uk: 'Додайте вже підключений профіль або чат зі списку'
      },
      CONFIRM_DELETE_REPORT: {
        ru: 'Вы уверены, что хотите удалить отчет ',
        en: 'Are you sure you want to delete a report ',
        ro: 'Sunteți sigur că doriți să ștergeți un raport ',
        uk: 'Ви впевнені, що хочете видалити звіт '
      },
      CONFIRM_DELETE_REPORT_TEMPLATE: {
        ru: 'Удаляя данный шаблон из отчета, вы автоматически удалите его из всех отчетов, где он используется. Вы уверены, что хотите выполнить это действие?',
        en: 'By removing this template from a report, you automatically remove it from all reports where it is used. Are you sure you want to perform this action?',
        ro: 'Prin eliminarea acestui șablon dintr-un raport, îl eliminați automat din toate rapoartele în care este utilizat. Sunteți sigur că doriți să efectuați această acțiune?',
        uk: 'Видаляючи даний шаблон зі звіту, ви автоматично видалите його з усіх звітів, де він використовується. Ви впевнені, що хочете виконати цю дію?'
      },
      KEYWORD: {
        ru: 'Ключевое слово',
        en: 'Keyword',
        ro: 'Cuvânt cheie',
        uk: 'Ключове слово'
      },
      CHOOSE_PORTAL: {
        ru: 'Выберите ленту',
        en: 'Choose newsfeed',
        ro: 'Alegeți fluxul de știri',
        uk: 'Оберіть стрічку'
      },
      REPORT_HAS_ERROR: {
        ru: 'Ваш текущий отчет содержит ошибки. Пожалуйста, отредактируйте отчет перед тем, как сохранить изменения',
        en: 'Your current report contains errors. Please edit the report before saving the changes',
        uk: 'Ваш поточний звіт містить помилки. Будь ласка, відредагуйте звіт перед тим як зберегти зміни',
        ro: 'Raportul dvs. actual conține erori. Vă rugăm să editați raportul înainte de a salva modificările'
      },
      SAVE_CHANGES: {
        ru: 'Хотите ли вы сохранить изменения в отчете ',
        en: 'Do you want to save the changes in the report ',
        uk: 'Хотіли би ви зберегти зміни у звіті ',
        ro: 'Doriți să salvați modificările în raport '
      },
      HAVE_UNSAVED_CHANGES: {
        ru: 'Ваше текущее отчет содержит несохраненные изменения. Пожалуйста, подтвердите эти изменения или отмените их перед продолжением работы',
        en: 'Your current report contains unsaved changes. Please confirm or discard these changes before continuing',
        uk: 'Ваш поточний звіт містить незбережені зміни. Будь ласка, підтвердіть ці зміни або скасуйте їх перед тим, як продовжити роботу',
        ro: 'Raportul dvs. actuală conține modificări nesalvate. Vă rugăm să confirmați sau să renunțați la aceste modificări înainte de a continua'
      },
      OBJECT: {
        ru: 'Объект',
        en: 'Object',
        uk: 'Об\'єкт',
        ro: 'Obiect'
      },
      CHOOSE_MAILING_OBJECT: {
        ru: 'Выберите объект рассылки или страницу',
        en: 'Choose a mailing object or page',
        ro: 'Alegeți un obiect sau o pagină de corespondență',
        uk: 'Оберіть об\'єкт розсилки або сторінку'
      },
      CHOOSE_OBJECT: {
        ru: 'Выберите объект',
        en: 'Choose object',
        ro: 'Selectați obiect',
        uk: 'Оберіть об\'єкт'
      },
      OBJECTS: {
        ru: 'Объекты',
        en: 'Objects',
        uk: 'Об\'єкти',
        ro: 'Obiecte'
      },
      PAGES: {
        ru: 'Cтраницы',
        en: 'Pages',
        ro: 'Pagini',
        uk: 'Сторінки'
      },
      BOOKMARKS: {
        ru: 'Закладки',
        en: 'Bookmarks',
        uk: 'Закладки',
        ro: 'Marcaje'
      },
      FORMAT: {
        ru: 'Формат',
        en: 'Format',
        ro: 'Format',
        uk: 'Формат'
      },
      LANGUAGE: {
        ru: 'Язык',
        en: 'Language',
        ro: 'Limba',
        uk: 'Мова'
      },
      PERIODICITY: {
        ru: 'Периодичность',
        en: 'Periodicity',
        ro: 'Periodicitate',
        uk: 'Періодичність'
      },
      CHOOSE_PERIODICITY: {
        ru: 'Выберите периодичность',
        en: 'Choose periodicity',
        ro: 'Alegeți periodicitate',
        uk: 'Оберіть періодичність'
      },
      TIME_PLACEHOLDER: {
        ru: 'ЧЧ:ММ',
        en: 'HH:MM',
        ro: 'OO:MM',
        uk: 'ГГ:ХХ '
      },
      CREATE_TEMLATE: {
        ru: 'Создать шаблон для отчета',
        en: 'Create a template for a report',
        ro: 'Creați un șablon pentru un raport',
        uk: 'Створити шаблон для звіту'
      },
      EDIT_REPORT_TEMLATE: {
        ru: 'Редактировать шаблон для отчета',
        en: 'Edit a template for a report',
        ro: 'Editarea unui șablon pentru un raport',
        uk: 'Редагувати шаблон для звіту'
      },
      REMOVE_TEMLATE: {
        ru: 'Удаление шаблона для отчета',
        en: 'Deleting a template for a report',
        ro: 'Ștergerea unui șablon pentru un raport',
        uk: 'Видаленяя шаблону для звіту'
      },
      EVERY_DAY: { ru: 'Ежедневно', en: 'Everyday', ro: 'În fiecare zi', uk: 'Щодня' },
      BY_WEEK_DAYS: { ru: 'По дням недели', en: 'By days of the week', ro: 'Pe zilele săptămânii', uk: 'По днях тижня' },
      BY_MONTH_DAYS: { ru: 'По дням месяца', en: 'By days of the month', ro: 'Pe zilele lunii', uk: 'По днях місяця' },
      ONE_HOUR: { ru: '1 час', en: '1 hour', ro: '1 oră', uk: '1 година' },
      TWO_HOURS: { ru: '2 часа', en: '2 hours', ro: '2 ore', uk: '2 години' },
      FOUR_HOURS: { ru: '4 часа', en: '4 hours', ro: '4 ore', uk: '4 години' },
      SEVERAL_TIMES_DAY: { ru: 'Несколько раз в день', en: 'Several times a day', ro: 'De mai multe ori pe zi', uk: 'Кілька разів на день' },
      ONCE_A_DAY: { ru: 'Раз в день', en: 'Once a day', ro: 'O singură dată pe zi', uk: 'Раз на день' },
      ONCE_A_WEEK: { ru: 'Раз в неделю', en: 'Once a week', ro: 'O dată pe săptămână', uk: 'Раз на тиждень' },
      EVERY_HOUR: { ru: 'Каждый час', en: 'Every hour', ro: 'În fiecare oră', uk: 'Кожнy годину' },
      EVERY_2HOURS: { ru: 'Каждые 2 часа', en: 'Every 2 hours', ro: 'La fiecare 2 ore', uk: 'Кожні 2 години' },
      EVERY_4HOURS: { ru: 'Каждые 4 часа', en: 'Every 4 hours', ro: 'La fiecare 4 ore', uk: 'Кожні 4 години' },
      THROUGH_WEEK_DAYS: { ru: 'За днями недели', en: 'Through the days of the week', ro: 'Prin zilele săptămânii', uk: 'За днями тижня' },
      THROUGH_MONTH_DAYS: { ru: 'За днями месяца', en: 'Through the days of the month', ro: 'Prin zilele lunii', uk: 'За днями місяця' },
      MONDAY: { ru: 'Понедельник', en: 'Monday', ro: 'Luni', uk: 'Понеділок' },
      TUESDAY: { ru: 'Вторник', en: 'Tuesday', ro: 'Marți', uk: 'Вівторок' },
      WEDNESDAY: { ru: 'Среда', en: 'Wednesday', ro: 'Miercuri', uk: 'Середа' },
      THURSDAY: { ru: 'Четверг', en: 'Thursday', ro: 'Joi', uk: 'Четвер' },
      FRIDAY: { ru: 'Пятница', en: 'Friday', ro: 'Vineri', uk: 'П\'ятниця' },
      SATURDAY: { ru: 'Суббота', en: 'Saturday', ro: 'Sâmbătă', uk: 'Субота' },
      SUNDAY: { ru: 'Воскресенье', en: 'Sunday', ro: 'Duminică', uk: 'Неділя' },
      MONDAY_AT: { ru: 'Понедельник в', en: 'Monday at', ro: 'Luni la ora', uk: 'Понеділок о' },
      TUESDAY_AT: { ru: 'Вторник в', en: 'Tuesday at', ro: 'Marți la ora', uk: 'Вівторок о' },
      WEDNESDAY_AT: { ru: 'Среда в', en: 'Wednesday at', ro: 'Miercuri la ora', uk: 'Середа о' },
      THURSDAY_AT: { ru: 'Четверг в', en: 'Thursday at', ro: 'Joi la ora', uk: 'Четвер о' },
      FRIDAY_AT: { ru: 'Пятница в', en: 'Friday at', ro: 'Vineri la ora', uk: 'П\'ятниця о' },
      SATURDAY_AT: { ru: 'Суббота в', en: 'Saturday at', ro: 'Sâmbătă la ora', uk: 'Субота о' },
      SUNDAY_AT: { ru: 'Воскресенье в', en: 'Sunday at', ro: 'Duminică la ora', uk: 'Неділя о' },
      REPORT_LOOK: {
        ru: 'Вот как будет выглядеть карточка',
        en: 'Here’s how the card will look',
        ro: 'Așa va arăta cardul',
        uk: 'Ось як буде виглядати картка'
      },
      PERSONALIZED_NEEDS: {
        ru: 'Персонализированный под ваши потребности',
        en: 'Personalized to your needs',
        ro: 'Personalizat pentru nevoile tale',
        uk: 'Персоналізований під ваші потреби'
      },
      CERTAIN_DAYS: {
        ru: 'или в определенные дни недели или месяца',
        en: 'or on certain days of the week or month',
        ro: 'sau în anumite zile ale săptămânii sau lunii',
        uk: 'aбо ж в певні дні тижня чи місяця'
      },
      CUSTOMIZE_REPORT: {
        ru: 'Настройте отчет под себя',
        en: 'Customize the report to your needs',
        ro: 'Personalizați raportul conform nevoilor dvs.',
        uk: 'Налаштуйте звіт під себе'
      },
      CHOOSE_FORMAT: {
        ru: 'Выберите подходящий формат',
        en: 'Select the appropriate format',
        ro: 'Alegeți formatul potrivit',
        uk: 'Виберіть відповідний формат'
      },
      LANGUAGE_REPORT: { ru: 'и язык отчета', en: 'and report language', ro: 'și limbajul raportului', uk: 'та мова звіту' },
      AT: { ru: 'в ', en: 'at ', ro: 'la ora ', uk: 'о ' },
      DETAILED_REPORTS: {
        ru: 'Получайте детальные отчеты о ваших маркетинговых кампаниях, чтобы видеть, какие стратегии работают лучше всего. Отчеты включают данные о охвате, взаимодействии и конверсиях',
        en: 'Receive detailed reports on your marketing campaigns to see which strategies work best. Reports include data on reach, engagement, and conversions',
        ro: 'Primiți rapoarte detaliate despre campaniile dvs. de marketing pentru a vedea care strategii funcționează cel mai bine. Rapoartele includ date despre acoperire, interacțiune și conversii',
        uk: 'Отримуйте детальні звіти про ваші маркетингові кампанії, щоб бачити, які стратегії працюють найкраще. Звіти включають дані про охоплення, взаємодію та конверсії'
      },
      CAMPAIGN_PERFORMANCE_REPORT: {
        ru: 'Отчет о эффективности кампании',
        en: 'Campaign Performance Report',
        ro: 'Raportul privind performanța campaniei',
        uk: 'Звіт про ефективність кампанії'
      },
      FULL_PICTURE: {
        ru: 'Увидьте полную картину',
        en: 'See the full picture',
        ro: 'Vedeți imaginea completă',
        uk: 'Побачте повну картину'
      },
      FULL_ANALYSIS_AND_VISUALIZATION: {
        ru: 'Получите полный анализ и визуализацию данных мониторинга вашего бренда. Принимайте обоснованные решения с помощью информативных отчетов и понятных графиков, а также делитесь инсайтами с коллегами во время презентаций',
        en: 'Get a full analysis and visualization of your brand monitoring data. Make informed decisions with insightful reports and clear charts, and share insights with colleagues during presentations',
        ro: 'Obțineți o analiză completă și o vizualizare a datelor de monitorizare a mărcii dvs. Luați decizii informate cu ajutorul rapoartelor informative și a graficelor clare și împărtășiți ideile colegilor în timpul prezentărilor',
        uk: 'Отримайте повний аналіз та візуалізацію даних моніторингу вашого бренду. Приймайте обґрунтовані рішення за допомогою інформативних звітів та зрозумілих графіків, а також діліться інсайтами з колегами під час презентацій'
      },
      DEFAULT: {
        ru: 'По умолчанию',
        en: 'Default',
        ro: 'Mod implicit',
        uk: 'За замовчуванням'
      },
      NO_DAY_ADDED: {
        ru: 'Не добавлено ни одного дня',
        en: 'No day added',
        ro: 'Nu a fost adăugată nicio zi',
        uk: 'Не додано жодного дня'
      },
      SELECT_INTERVAL: {
        ru: 'Выберите интервал',
        en: 'Select interval',
        ro: 'Selectați intervalul',
        uk: 'Виберіть інтервал'
      },
      EVERY_N_HOURS: {
        ru: 'каждые N часов',
        en: 'every N hours',
        ro: 'fiecare N ore',
        uk: 'кожні N годин'
      },
      SPECIFIC_HOURS: {
        ru: 'в конкретные часы',
        en: 'at specific hours',
        ro: 'la ore specifice',
        uk: 'в конкретні години'
      },
      LOGO: {
        ru: 'Логотип',
        en: 'Logo',
        ro: 'Logo',
        uk: 'Логотип'
      },
      NO_FILE_SELECTED: {
        ru: 'Файл не выбран',
        en: 'No file selected',
        ro: 'Niciun fișier selectat',
        uk: 'Файл не вибрано'
      },
      CONTENT: {
        ru: 'Наполнение',
        en: 'Content',
        ro: 'Conținut',
        uk: 'Наповнення'
      },
      CHART_SELECTION: {
        ru: 'Выбор графиков',
        en: 'Chart selection',
        ro: 'Selecția diagramelor',
        uk: 'Вибір графіків'
      },
      HOVER_AND_PREVIEW: {
        ru: 'Наведите на пункт меню и нажмите на иконку глаза, чтобы посмотреть предварительный просмотр.',
        en: 'Hover over the menu item and click on the eye icon to preview.',
        ro: 'Treceți cursorul peste elementul de meniu și faceți clic pe pictograma ochiului pentru a previzualiza.',
        uk: 'Наведіть на пункт меню і натисніть на іконку ока, щоб подивитись попередній вигляд.'
      },
      CUSTOM_PAGES: {
        ru: 'Cтраницы:',
        en: 'Pages:',
        ro: 'Pagini:',
        uk: 'Сторінки:'
      },
      DASHBOARDS: {
        ru: 'Дашборды:',
        en: 'Dashboards:',
        ro: 'Tablouri de bord:',
        uk: 'Дашборди:'
      },
      SEPARATE_MEDIA_AND_SOCIAL: {
        ru: 'Разделять СМИ и социальные сети',
        en: 'Separate media and social',
        ro: 'Separă media și socialul',
        uk: 'Розділяти ЗМІ та соцмережі'
      },
      SHOW_ARTICLE_CONTENT: {
        ru: 'Показывать содержание статей',
        en: 'Show article content',
        ro: 'Afișați conținutul articolului',
        uk: 'Показувати зміст статей'
      },
      SHOW_TONE: {
        ru: 'Показать тональность в содержании',
        en: 'Show tone in content',
        ro: 'Afișați tonul în conținut',
        uk: 'Показати тон у змісті'
      },
      SHOW_SOURCE_TITLE: {
        ru: 'Показать название источника в содержании',
        en: 'Show source title in content',
        ro: 'Afișați titlul sursei în conținut',
        uk: 'Показати назву джерела у змісті'
      },
      SHOW_DUPLICATES: {
        ru: 'Показывать дубли',
        en: 'Show duplicates',
        ro: 'Afișați duplicați',
        uk: 'Показувати дублі'
      },
      ORIGINAL_GROUP: {
        ru: 'Группировать под оригиналом',
        en: 'Group under original',
        ro: 'Grupați sub original',
        uk: 'Групувати під оригіналом'
      },
      MESSAGE_DISPLAY_MODE: {
        ru: 'Способ отображения сообщения',
        en: 'Message display mode',
        ro: 'Modul de afișare a mesajului',
        uk: 'Спосіб відображення повідомлення'
      },
      MESSAGE_GROUPING: {
        ru: 'Группировка сообщений в отчете',
        en: 'Message grouping in report',
        ro: 'Gruparea mesajelor în raport',
        uk: 'Угрупування повідомлень у звіті'
      },
      MESSAGE_GROUPING_TYPE: {
        ru: 'Выберите тип группировки сообщений в отчете',
        en: 'Select the type of message grouping in the report',
        ro: 'Selectați tipul de grupare a mesajelor în raport',
        uk: 'Виберіть тип групування повідомлень у звіті'
      },
      IF_NEWS_REPEATS: {
        ru: 'Если новости повторяются',
        en: 'If news repeats',
        ro: 'Dacă știrile se repetă',
        uk: 'Якщо новини повторюються'
      },
      TYPE_NEWS_REPEATS: {
        ru: 'Выберите тип, когда новости повторяются',
        en: 'Select the type when news repeats',
        ro: 'Selectați tipul atunci când știrile se repetă',
        uk: 'Виберіть тип, коли новини повторюються'
      },
      SORT_GROUPED_MESSAGES: {
        ru: 'Сортировка сгруппированных упоминаний',
        en: 'Sort grouped mentions',
        ro: 'Sortare mențiuni grupate',
        uk: 'Сортування згрупованих згадок'
      },
      TYPE_SORTING_GROUPED_MESSAGES: {
        ru: 'Выберите тип сортировки сгруппированных сообщений',
        en: 'Select type of sorting grouped messages',
        ro: 'Selectați tipul de sortare a mesajelor grupate',
        uk: 'Виберіть тип cортування згрупованих повідомлень'
      },
      SORT_DIRECTION: {
        ru: 'Направление сортировки',
        en: 'Sort direction',
        ro: 'Direcția de sortare',
        uk: 'Напрям сортування'
      },
      SELECT_TEXT_DISPLAY_MODE: {
        ru: 'Выберите способ отображения текста',
        en: 'Select text display mode',
        ro: 'Selectați modul de afișare a textului',
        uk: 'Виберіть cпосіб відображення тексту'
      },
      TEXT_DISPLAY_MODE: {
        ru: 'Способ отображения текста',
        en: 'Text display mode',
        ro: 'Modul de afișare a textului',
        uk: 'спосіб відображення тексту'
      },
      FILTER_ARTICLES_AUTOREPORTS: {
        ru: 'Фильтровать статьи для автоматических отчетов',
        en: 'Filter articles for automated reports',
        ro: 'Filtrare articole pentru rapoartele automate',
        uk: 'Для автоматичних звітів фільтрувати статті'
      },
      ARTICLE_INFO: {
        ru: 'Информация о статье',
        en: 'Article information',
        ro: 'Informații despre articol',
        uk: 'Інформація про статтю'
      },
      SELECT_ARTICLE_INFO: {
        ru: 'Выберите информацию о статье',
        en: 'Select article information',
        ro: 'Selectați informațiile despre articol',
        uk: 'Виберіть інформація про статтю'
      },
      CHART_SIZE: {
        ru: 'Размер графиков',
        en: 'Chart size',
        ro: 'Dimensiunea graficelor',
        uk: 'Розмір графіків'
      },
      FONT_SIZE: {
        ru: 'Размер шрифта',
        en: 'Font size',
        ro: 'Dimensiunea fontului',
        uk: 'Розмір шрифту'
      },
      SELECT_FONT_SIZE: {
        ru: 'Выберите размер шрифта',
        en: 'Select font size',
        ro: 'Selectați dimensiunea fontului',
        uk: 'Виберіть розмір шрифту'
      },
      HIGHLIGHT_KEYWORDS: {
        ru: 'Выделять ключевые слова',
        en: 'Highlight keywords',
        ro: 'Evidențiați cuvintele cheie',
        uk: 'Виділяти ключові слова'
      },
      HIGHLIGHT_COLOR: {
        ru: 'Цвет подсветки',
        en: 'Highlight color',
        ro: 'Culoarea evidențierii',
        uk: 'Колір підсвічування'
      },
      BLOCK_ORDER: {
        ru: 'Порядок блоков',
        en: 'Block order',
        ro: 'Ordinea blocurilor',
        uk: 'Порядок блоків'
      },
      TEMPLATE_NAME: {
        ru: 'Название шаблона',
        en: 'Template name',
        ro: 'Numele șablonului',
        uk: 'Назва шаблону'
      },
      ENTER_TEMPLATE_NAME: {
        ru: 'Введите название шаблона',
        en: 'Enter template name',
        ro: 'Introduceți numele șablonului',
        uk: 'Введіть назву шаблону'
      },
      FIND_AND_APPLY_TEMPLATES: {
        ru: 'Необходимо находить и применять шаблоны к отчетам',
        en: 'Need to find and apply templates to reports',
        ro: 'Este nevoie să găsiți și să aplicați șabloane la rapoarte',
        uk: 'Потрібно для того, щоб знаходити та застосовувати шаблони до звітів'
      },
      DOCUMENT_NAME: {
        ru: 'Название документа',
        en: 'Document name',
        ro: 'Numele documentului',
        uk: 'Назва документу'
      },
      ENTER_DOCUMENT_NAME: {
        ru: 'Введите название документа',
        en: 'Enter document name',
        ro: 'Introduceți numele documentului',
        uk: 'Введіть назву документу'
      },
      FILE_NAME_REPORT: {
        ru: 'Название файла для вашего отчета',
        en: 'File name for your report',
        ro: 'Numele fișierului pentru raportul dvs.',
        uk: 'Назва файлу для вашого звіту'
      },
      EXPECTED_RESULT: {
        ru: 'Ожидаемый результат:',
        en: 'Expected result:',
        ro: 'Rezultatul așteptat:',
        uk: 'Очікуваний результат:'
      },
      MAIN: {
        ru: 'Основное',
        en: 'Main',
        ro: 'Principal',
        uk: 'Основне'
      },
      BASIC_REPORT_INFO: {
        ru: 'Базовая информация о отчете',
        en: 'Basic report information',
        ro: 'Informații de bază despre raport',
        uk: 'Базова інформація про звіт'
      },
      REPORT_INFORMATION: {
        ru: 'Какая информация будет отображаться в отчете?',
        en: 'What information will be displayed in the report?',
        ro: 'Ce informații vor fi afișate în raport?',
        uk: 'Яка інформація буде виводитись у звіті?'
      },
      CHARTS_INFORMATION: {
        ru: 'Графики, которые будут выводиться в отчете',
        en: 'Charts to be displayed in the report',
        ro: 'Diagrame care vor fi afișate în raport',
        uk: 'Графіки, які будуть виводитися в звіті'
      },
      DISPLAY: {
        ru: 'Отображение',
        en: 'Display',
        ro: 'Afișare',
        uk: 'Відображення'
      },
      REPORT_DISPLAYED_INFO: {
        ru: 'Информация, которая будет отображаться в отчете',
        en: 'Information to be displayed in the report',
        ro: 'Informații care vor fi afișate în raport',
        uk: 'Інформація, яка буде відображатися у звіті'
      },
      PREVIEW: {
        ru: 'Предпросмотр',
        en: 'Preview',
        ro: 'Previzualizare',
        uk: 'Передперегляд'
      },
      TEMPLATE_PREVIEW_AND_SAVE: {
        ru: 'Просмотр шаблона и сохранение',
        en: 'Template preview and save',
        ro: 'Previzualizare și salvare șablon',
        uk: 'Перегляд шаблону та збереження'
      },
      FIRST_TEXT: {
        ru: 'Первый абзац',
        en: 'First paragraph',
        ro: 'Primul paragraf',
        uk: 'Перший абзац'
      },
      FULL_TEXT: {
        ru: 'Полный текст',
        en: 'Full text',
        ro: 'Text complet',
        uk: 'Повний текст'
      },
      OBJ_TEXT: {
        ru: 'Часть текста с ключевым словом',
        en: 'Part of text with keyword',
        ro: 'Partea de text cu cuvânt cheie',
        uk: 'Частина тексту з ключовим словом'
      },
      WITHOUT_TEXT: {
        ru: 'Без текста',
        en: 'Without text',
        ro: 'Fără text',
        uk: 'Без тексту'
      },
      WITHOUT_NEWS: {
        ru: 'Без новостей',
        en: 'Without news',
        ro: 'Fără știri',
        uk: 'Без новин'
      },
      BY_KEYWORDS: {
        ru: 'По ключевым словам',
        en: 'By keywords',
        ro: 'După cuvinte cheie',
        uk: 'За ключовими словами'
      },
      BY_SUBTOPICS: {
        ru: 'По подтемам (если включено)',
        en: 'By subtopics (if enabled)',
        ro: 'Dupa subteme (dacă este activat)',
        uk: 'За підтемами (якщо увімкнено)'
      },
      BY_MEDIATYPES: {
        ru: 'По типам носителей',
        en: 'By media types',
        ro: 'După tipurile de media',
        uk: 'За типами носіїв'
      },
      BY_TAGS: {
        ru: 'По тегам',
        en: 'By tags',
        ro: 'După etichete',
        uk: 'За тегами'
      },
      BY_REGIONS: {
        ru: 'По регионам',
        en: 'By regions',
        ro: 'După regiuni',
        uk: 'За регіонами'
      },
      BY_COUNTRIES: {
        ru: 'По странам',
        en: 'By countries',
        ro: 'După țări',
        uk: 'За країнами'
      },
      BY_CITIES: {
        ru: 'По городам',
        en: 'By cities',
        ro: 'După orașe',
        uk: 'По містах'
      },
      BY_TONE: {
        ru: 'По тональности',
        en: 'By tone',
        ro: 'După tonalitate',
        uk: 'За тональністю'
      },
      BY_ROLE: {
        ru: 'По роли',
        en: 'By role',
        ro: 'După rol',
        uk: 'За роллю'
      },
      DISPLAY_FOR_EACH_GROUP: {
        ru: 'Отображение для каждой группы',
        en: 'Display for each group',
        ro: 'Afișare pentru fiecare grup',
        uk: 'Відображення для кожної групи'
      },
      ONLY_FIRSTGROUP_HIDE_OTHER: {
        ru: 'Отображать только для первой группы, скрывать для последующих',
        en: 'Show only for the first group, hide for subsequent',
        ro: 'Afișați doar pentru primul grup, ascundeți pentru cele ulterioare',
        uk: 'Відображати лише для першої групи, приховувати для наступних'
      },
      ONLY_FIRSTGROUP_LOOKABOVE_OTHER: {
        ru: 'Отображение только для первой группы и написание "смотреть выше" для последующих',
        en: 'Show only for the first group and write "look above" for subsequent',
        ro: 'Afișați doar pentru primul grup și scrieți „priviți mai sus” pentru cele ulterioare',
        uk: 'Відображення лише для першої групи та написання «дивитися вище» для наступних'
      },
      RELEASE_DATE: {
        ru: 'Дата выхода',
        en: 'Release date',
        ro: 'Data lansării',
        uk: 'Дата виходу'
      },
      PROCESSING_DATE: {
        ru: 'Дата обработки',
        en: 'Processing date',
        ro: 'Data procesării',
        uk: 'Дата обробки'
      },
      TITLE: {
        ru: 'Заголовок',
        en: 'Title',
        ro: 'Titlu',
        uk: 'Заголовок'
      },
      DUPLICATES: {
        ru: 'Дубли',
        en: 'Duplicates',
        ro: 'Dubluri',
        uk: 'Дублі'
      },
      SOURCE_VISITATION: {
        ru: 'Посещаемость источника',
        en: 'Source visitation',
        ro: 'Vizitare sursă',
        uk: 'Відвідуваність джерела'
      },
      AVERAGE_VIEWS: {
        ru: 'Средняя посещаемость',
        en: 'Average visitation',
        ro: 'Vizitare medie',
        uk: 'Середня відвідуваність'
      },
      TONALITY: {
        ru: 'Тональность',
        en: 'Tone',
        ro: 'Tonalitate',
        uk: 'Тональність'
      },
      ROLE: {
        ru: 'Роль',
        en: 'Role',
        ro: 'Rol',
        uk: 'Роль'
      },
      MEDIA_TYPE: {
        ru: 'Тип СМИ',
        en: 'Media type',
        ro: 'Tipul de media',
        uk: 'Тип ЗМІ'
      },
      SMALL: {
        ru: 'Малый',
        en: 'Small',
        ro: 'Mic',
        uk: 'Малий'
      },
      MEDIUM: {
        ru: 'Средний',
        en: 'Medium',
        ro: 'Mediu',
        uk: 'Середній'
      },
      LARGE: {
        ru: 'Большой',
        en: 'Large',
        ro: 'Mare',
        uk: 'Великий'
      },
      DATE: {
        ru: 'Дата',
        en: 'Date',
        ro: 'Data',
        uk: 'Дата'
      },
      TIME: {
        ru: 'Время',
        en: 'Time',
        ro: 'Timp',
        uk: 'Час'
      },
      SOURCE: {
        ru: 'Источник',
        en: 'Source',
        ro: 'Sursă',
        uk: 'Джерело'
      },
      DUPLICATES_COUNT: {
        ru: 'Количество повторов',
        en: 'Repeat count',
        ro: 'Număr de repetări',
        uk: 'К-сть повторів'
      },
      VISITATION: {
        ru: 'Посещаемость',
        en: 'Visitation',
        ro: 'Vizitare',
        uk: 'Відвідуваність'
      },
      AVERAGE_VISITATION: {
        ru: 'Средняя посещаемость',
        en: 'Average visitation',
        ro: 'Vizitare medie',
        uk: 'Середня відвідуваність'
      },
      CREATOR: {
        ru: 'Автор',
        en: 'Author',
        ro: 'Autor',
        uk: 'Автор'
      },
      COUNTRY: {
        ru: 'Страна',
        en: 'Country',
        ro: 'Țară',
        uk: 'Країна'
      },
      REGION: {
        ru: 'Регион: ',
        en: 'Region: ',
        ro: 'Regiune: ',
        uk: 'Регіон: '
      },
      CITY: {
        ru: 'Город',
        en: 'City',
        ro: 'Oraș',
        uk: 'Місто'
      },
      LEVEL: {
        ru: 'Уровень',
        en: 'Level',
        ro: 'Nivel',
        uk: 'Рівень'
      },
      SUBJECT: {
        ru: 'Тематика',
        en: 'Subject',
        ro: 'Subiect',
        uk: 'Тематика'
      },
      TAGS: {
        ru: 'Теги',
        en: 'Tags',
        ro: 'Etichete',
        uk: 'Теги'
      },
      SUBTOPICS: {
        ru: 'Подтемы',
        en: 'Subtopics',
        ro: 'Subteme',
        uk: 'Підтеми'
      },
      SOCIAL_MEDIA_AUDIENCE: {
        ru: 'Аудитория социальных сетей',
        en: 'Social audience',
        ro: 'Audiență pe rețele sociale',
        uk: 'Аудиторія соціальних мереж'
      },
      ASCEND: {
        ru: 'По возрастанию',
        en: 'Ascending',
        ro: 'Crescător',
        uk: 'За зростанням'
      },
      DESCEND: {
        ru: 'По убыванию',
        en: 'Descending',
        ro: 'Descrescător',
        uk: 'За спаданням'
      },
      BY_RELEASE_DATE: {
        ru: 'По дате выхода',
        en: 'By release date',
        ro: 'După data lansării',
        uk: 'За датою виходу'
      },
      BY_POSTING_DATE: {
        ru: 'По дате постановки',
        en: 'By posting date',
        ro: 'După data postării',
        uk: 'За датою постановки'
      },
      SMALLS: {
        ru: 'Маленькие',
        en: 'Small',
        ro: 'Mici',
        uk: 'Маленькі'
      },
      LARGES: {
        ru: 'Большие',
        en: 'Large',
        ro: 'Mari',
        uk: 'Великі'
      },
      CONTENT_BLOCK: {
        ru: 'Блок содержания',
        en: 'Content block',
        ro: 'Bloc de conținut',
        uk: 'Блок змісту'
      },
      CHART_BLOCK: {
        ru: 'Блок диаграмм',
        en: 'Chart block',
        ro: 'Bloc de diagrame',
        uk: 'Блок діаграм'
      },
      ARTICLE_BLOCK: {
        ru: 'Блок статей',
        en: 'Article block',
        ro: 'Bloc de articole',
        uk: 'Блок статей'
      },
      ALL_OBJECTS: {
        ru: 'Все объекты',
        en: 'All objects',
        ro: 'Toate obiectele',
        uk: 'Всі об’єкти'
      },
      GET_REPORTS: {
        ru: 'Получайте отчеты LOOQME в частных сообщениях или групповых чатах в Telegram. Выберите каким образом вы хотели бы получать отчеты?',
        en: 'Receive LOOQME reports in private messages or group chats on Telegram. Choose how you would like to receive reports?',
        ro: 'Primiți rapoarte LOOQME în mesaje private sau chat-uri de grup pe Telegram. Alegeți cum doriți să primiți rapoarte?',
        uk: 'Отримуйте звіти LOOQME в приватні повідомлення чи групові чати в Telegram. Оберіть яким чином ви б хотіли отримувати звіти?'
      },
      GET_REPORTS_M: {
        ru: 'Получайте отчеты METRICOM в частных сообщениях или групповых чатах в Telegram. Выберите каким образом вы хотели бы получать отчеты?',
        en: 'Receive METRICOM reports in private messages or group chats on Telegram. Choose how you would like to receive reports?',
        ro: 'Primiți rapoarte METRICOM în mesaje private sau chat-uri de grup pe Telegram. Alegeți cum doriți să primiți rapoarte?',
        uk: 'Отримуйте звіти METRICOM в приватні повідомлення чи групові чати в Telegram. Оберіть яким чином ви б хотіли отримувати звіти?'
      },
      GET_REPORTS_WHATSAPP: {
        ru: 'Получайте отчеты LOOQME в частных сообщениях WhatsApp.',
        en: 'Receive LOOQME reports in private WhatsApp messages.',
        ro: 'Primiți rapoarte LOOQME în mesaje private WhatsApp.',
        uk: 'Отримуйте звіти LOOQME у приватних повідомленнях WhatsApp.'
      },
      GET_REPORTS_WHATSAPP_M: {
        ru: 'Получайте отчеты METRICOM в частных сообщениях WhatsApp.',
        en: 'Receive METRICOM reports in private WhatsApp messages.',
        ro: 'Primiți rapoarte METRICOM în mesaje private WhatsApp.',
        uk: 'Отримуйте звіти METRICOM у приватних повідомленнях WhatsApp.'
      },
      RECIPIENTS: {
        ru: 'получателей',
        en: 'recipients',
        uk: 'отримувачів',
        ro: 'destinatarii'
      }
    },
    search: {
      SEARCH: {
        ru: 'Поиск',
        en: 'Search',
        ro: 'Căutare',
        uk: 'Пошук'
      },
      FAVORITE: {
        ru: 'Избранные',
        en: 'Favorites',
        ro: 'Favorite',
        uk: 'Обрані'
      },
      FILTERS: {
        ru: 'Фильтры',
        en: 'Filters',
        ro: 'Filtre',
        uk: 'Фільтри'
      },
      ALL_SOURCES: {
        ru: 'Все источники',
        en: 'All sources',
        ro: 'Toate sursele',
        uk: 'Усі джерела'
      },
      TYPE_SOURCE: {
        ru: 'Тип источника',
        en: 'Source type',
        ro: 'Tip sursă',
        uk: 'Тип джерела'
      },
      ALL_COUNTRIES: {
        ru: 'Все страны',
        en: 'All countries',
        ro: 'Toate tarile',
        uk: 'Всі країни'
      },
      COUNTY_SEARCH: {
        ru: 'Поиск страны...',
        en: 'Country search...',
        ro: 'Toate sursă...',
        uk: 'Пошук країни...'
      },
      LOADING: {
        ru: 'Загрузка...',
        en: 'Loading...',
        ro: 'Se încarcă...',
        uk: 'Завантаження...'
      },
      GEOGRAPHY: {
        ru: 'География',
        en: 'Geography',
        ro: 'Geografie',
        uk: 'Географія'
      },
      SOURCES: {
        ru: 'Источники',
        en: 'Sources',
        ro: 'Sursele',
        uk: 'Джерела'
      },
      SOURCE_SEARCH: {
        ru: 'Поиск источника...',
        en: 'Source search...',
        ro: 'Căutarea sursă...',
        uk: 'Пошук джерела...'
      },
      LANGUAGE: {
        ru: 'Язык',
        en: 'Language',
        ro: 'Limba',
        uk: 'Мова'
      },
      COUNTRY: {
        ru: 'Страна',
        en: 'Country',
        ro: 'Țară',
        uk: 'Країна'
      },
      NUMBER_OF_SOURCES: {
        ru: 'Количество источников',
        en: 'Number of sources',
        ro: 'Număr de surse',
        uk: 'Кількість джерел'
      },
      SOURCE_S: {
        ru: 'Источников',
        en: 'Sources',
        ro: 'Surse',
        uk: 'Джерел'
      },
      NUMBER_OF_EXCLUDED_SOURCES: {
        ru: 'Количество исключенных источников',
        en: 'Number of excluded sources',
        ro: 'Număr de surse excluse',
        uk: 'Кількість виключених джерел'
      },
      SAVE_AS_NEW: { ru: 'Сохранить изменения как новый фильтр', en: 'Save changes as new filter', ro: 'Salvați modificările ca un filtru nou', uk: 'Зберегти зміни як новий фільтр' },
      RESTORE_DEFAULT: { ru: 'Восстановить до сохраненных настроек', en: 'Restore to saved settings', ro: 'Restabiliți la setările salvate', uk: 'Відновити до збережених налаштувань' },
      DELETE_FILTER: {
        ru: 'Удалить фильтр',
        en: 'Delete filter',
        ro: 'Ștergeți filtrul',
        uk: 'Видалити фільтр'
      },
      ENTER_FILTER_NAME: {
        ru: 'Введите название для фильтра',
        en: 'Enter name for filter',
        ro: 'Introduceți un nume pentru filtru',
        uk: 'Введіть назву для фільтру'
      },
      SAVE_CHANGES_TOTHIS: {
        ru: 'Сохранить изменения в этот фильтр',
        en: 'Save changes to this filter',
        ro: 'Salvați modificările în acest filtru',
        uk: 'Зберегти зміни до цього фільтру'
      },
      SAVE_FILTER: {
        ru: 'Сохранить фильтр',
        en: 'Save filter',
        ro: 'Salvați filtrul',
        uk: 'Зберегти фільтр'
      },
      FROM: {
        ru: 'От',
        en: 'From',
        ro: 'De la',
        uk: 'Від'
      },
      SUGGEST_SOURCE: { ru: 'Предложить источник', en: 'Suggest source', ro: 'Sugerați sursa', uk: 'Запропонувати джерело' },
      TYPE_SOURCE_END: {
        ru: 'тип источникa',
        en: 'source type',
        ro: 'tip sursă',
        uk: 'тип джерела'
      },
      TYPE_SOURCES_END: {
        ru: 'типa(ов) источникa(ов)',
        en: 'source types',
        ro: 'tip de sursă',
        uk: 'типа(ів) джерел(а)'
      },
      SOURCE_END: {
        ru: 'источник',
        en: 'source',
        ro: 'surse',
        uk: 'джерело'
      },
      SOURCES_END: {
        ru: 'источника(ов)',
        en: 'sources',
        ro: 'sursă',
        uk: 'джерел(а)'
      },
      COUNTRY_END: { ru: 'страна', en: 'country', ro: 'Țară', uk: 'країна' },
      COUNTRIES_END: {
        ru: 'стран(ы)',
        en: 'countries',
        ro: 'tări',
        uk: 'країн(и)'
      },
      LANGUAGE_END: {
        ru: 'язык',
        en: 'language',
        ro: 'limbă',
        uk: 'мова'
      },
      LANGUAGES_END: {
        ru: 'языка(ов)',
        en: 'languages',
        ro: 'limbi',
        uk: 'мов(и)'
      },
      SEARCH_LANGUAGE: { ru: 'Поиск языка...', en: 'Language searching...', ro: 'Căutare limbă...', uk: 'Пошук мови...' },
      TYPE_MENTIONS: {
        ru: 'Тип упоминания',
        en: 'Mention Type',
        ro: 'Tipul menționat',
        uk: 'Тип згадки'
      },
      SEARCH_MENTION_TYPE: {
        ru: 'Поиск типа упоминания...',
        en: 'Mention type searching ...',
        ro: 'Căutare tip mențiune...',
        uk: 'Пошук типу згадки...'
      },
      MENTION_TYPE_END: {
        ru: 'тип упоминания',
        en: 'mention type',
        ro: 'tip mențiune',
        uk: 'тип згадки'
      },
      MENTION_TYPES_END: { ru: 'типа(ов) упоминаний', en: 'mention types', ro: 'tipuri de mențiuni', uk: 'типа(ів) згадок' },
      ADDITIONAL_FILTERS: {
        ru: 'Дополнительные фильтры',
        en: 'Additional filters',
        ro: 'Filtre suplimentare',
        uk: 'Додаткові фільтри'
      },
      LEVEL: {
        ru: 'Уровень источника',
        en: 'Source level',
        ro: 'Nivel sursă',
        uk: 'Рівень джерела'
      },
      SEARCH_SOURCE_LEVEL: {
        ru: 'Поиск уровня источника...',
        en: 'Source level searching ...',
        ro: 'Căutare nivel sursă...',
        uk: 'Пошук рівня джерела...'
      },
      LEVEL_END: {
        ru: 'уровень',
        en: 'level',
        ro: 'nivel',
        uk: 'рівень'
      },
      LEVELS_END: {
        ru: 'уровня(ей)',
        en: 'levels',
        ro: 'niveluri',
        uk: 'рівня(ів)'
      },
      SOCIAL_MEDIA_AUDIENCE: {
        ru: 'Аудитория соц. сетей',
        en: 'Social audience',
        ro: 'Audiență pe rețelele sociale',
        uk: 'Аудиторія соц. мереж'
      },
      SOURCE_AUDIENCE: {
        ru: 'Аудитория источников',
        en: 'Source audience',
        ro: 'Audiență sursă',
        uk: 'Аудиторія джерел'
      },
      NEW_FILTER: {
        ru: 'Новый фильтр',
        en: 'New filter',
        ro: 'Filtru nou',
        uk: 'Новий фільтр'
      },
      ENTER_QUERY: { ru: 'Введите название 🏢 компании, 🙂 лицо, 🔎 поисковый запрос...', en: 'Enter name 🏢 company, 🙂 person, 🔎 search query...', ro: 'Introduceți numele 🏢 companiei, 🙂 persoanei, 🔎 interogarea de căutare...', uk: 'Введіть назву 🏢 компанії, 🙂 особи, 🔎 пошуковий запит...' },
      SEARCH_HISTORY: { ru: 'История поиска', en: 'Search history', ro: 'Istoricul căutărilor', uk: 'Історія пошуку' },
      INSTRUCTION: { ru: 'Инструкция', en: 'Instruction', ro: 'Instrucțiune', uk: 'Інструкція' },
      EXAMPLE_OF_POTENTIAL_ERROR: {
        ru: 'пример потенциальной ошибки',
        en: 'example of a potential error',
        uk: 'приклад потенційної помилки',
        de: 'Beispiel für einen möglichen Fehler'
      },
      ROLL_UP: {
        uk: 'Згорнути',
        ru: 'Свернуть',
        en: 'Roll up',
        ro: 'Rulla'
      },
      COPY_TEXT: {
        ru: 'Скопировать текст',
        en: 'Copy text',
        ro: 'Copiază textul',
        uk: 'Скопіювати текст'
      },
      ADDED_TO_SYSTEM: {
        ru: 'Добавлено в систему',
        en: 'Added to the system',
        ro: 'Adăugat în sistem',
        uk: 'Додано в систему'
      },
      ADD_TO_SYSTEM: {
        ru: 'Добавить в систему',
        en: 'Add to the system',
        ro: 'Adăugați în sistem',
        uk: 'Додати в систему'
      },
      RESULTS_SELECTED: {
        ru: 'Выбрано результатов',
        en: 'Results selected',
        ro: 'Rezultate selectate',
        uk: 'Вибрано результатів'
      },
      ADD_FAVORITE: {
        ru: 'Добавить в избранное',
        en: 'Add to favorites',
        ro: 'Adauga la favorite',
        uk: 'Додати у обране'
      },
      REMOVE_FAVORITE: {
        ru: 'Удалить из избранного',
        en: 'Remove from Favorites',
        ro: 'Eliminați din favorite',
        uk: 'Видалити з обраного'
      },
      SOCIAL_NETWORKS: {
        ru: 'Социальные сети',
        en: 'Social media',
        ro: 'Rețele socială',
        uk: 'Соціальні мережі'
      },
      MEDIA: {
        ru: 'СМИ (Медиа)',
        en: 'Media',
        ro: 'Media',
        uk: 'ЗМІ (Медіа)'
      },
      ALL: {
        ru: 'Все',
        en: 'All',
        ro: 'Toate',
        uk: 'Всі'
      },
      EXTENDED_XLS: {
        ru: 'Расширенный xls, содержащий тексты новостей',
        en: 'Extended xls containing news texts',
        ro: 'Ekhtended hls care conțin nvs techs',
        uk: 'Розширений xls, який містить тексти новин'
      },
      SHORT_EXPLANATION_OF_DIFFERENCE: {
        ru: 'Краткое объяснение различий',
        en: 'Short explanation of the difference',
        ro: 'Explicație scurtă a diferenței',
        uk: 'Коротке пояснення різниці'
      },
      ANALYTICS: {
        ru: 'Аналитика',
        en: 'Analytics',
        ro: 'Analitică',
        uk: 'Аналітика'
      },
      DOWNLOAD_REPORT: {
        ru: 'Скачать отчет',
        en: 'Download report',
        ro: 'Descarcă raportul',
        uk: 'Завантажити звіт'
      },
      MATERIALS_FOUND: {
        ru: 'материалов найдено',
        en: 'materials found',
        ro: 'materiale găsite',
        uk: 'матеріалів знайдено'
      },
      QUERY: {
        ru: 'запрос',
        en: 'query',
        ro: 'interogare',
        uk: 'запит'
      },
      QUERIES: {
        ru: 'запросов',
        en: 'queries',
        ro: 'interogări',
        uk: 'запитів'
      },
      SOCIAL_NETWORK_S: {
        ru: 'Социальных сетей',
        en: 'Social media',
        ro: 'Rețele socială',
        uk: 'Соціальних мереж'
      },
      NUMBER_OF_ARTICLES_IN_MEDIA: {
        ru: 'Количество статей в медиа',
        en: 'Number of articles in media',
        ro: 'Număr de articole în media',
        uk: 'Кількість статей у медіа'
      },
      NUMBER_OF_ARTICLES_IN_SN: {
        ru: 'Количество статей в социальных сетях',
        en: 'Number of articles in social networks',
        ro: 'Număr de articole în rețele sociale',
        uk: 'Кількість статей у соціальних мережах'
      },
      COPY_QUERY: {
        ru: 'Скопировать запрос',
        en: 'Copy query',
        ro: 'Copiază interogarea',
        uk: 'Скопіювати запит'
      },
      SELECT_QUERY_TEMPLATE: {
        ru: 'Выберите шаблон запроса',
        en: 'Select query template',
        ro: 'Selectați șablonul de interogare',
        uk: 'Оберіть шаблон запиту'
      },
      ALL_TIPS: {
        ru: 'Все подсказки',
        en: 'All tips',
        ro: 'Toate sfaturile',
        uk: 'Всі підказки'
      },
      TEMPLATES: {
        ru: 'Шаблоны',
        en: 'Templates',
        ro: 'Șabloane',
        uk: 'Шаблони'
      },
      EXAMPLES_OF_OPERATORS: {
        ru: 'Примеры операторов',
        en: 'Examples of operators',
        ro: 'Exemple de operatori',
        uk: 'Приклади операторів'
      },
      FIELDS: {
        ru: 'Поля',
        en: 'Fields',
        ro: 'Câmpuri',
        uk: 'Поля'
      },
      TIPS: {
        ru: 'Советы',
        en: 'Tips',
        ro: 'Sfaturi',
        uk: 'Поради'
      },
      SEARCH_QUERY_INSTRUCTIONS: {
        ru: 'Инструкция по поисковым запросам',
        en: 'Search query instructions',
        ro: 'Instrucțiuni pentru interogări de căutare',
        uk: 'Інструкція з пошукових запитів'
      },
      SEARCH_QUERY_GUIDE: {
        ru: 'Здесь вы узнаете, как легко найти искомые объекты самостоятельно. Запросы могут использоваться для тега, поиска или оповещения. Чтобы написать более сложный запрос, ознакомьтесь с основными операторами языка запросов.',
        en: 'Here you will learn how to easily find the objects you are looking for on your own. Queries can be used for tagging, searching, or alerting. To write a more complex query, familiarize yourself with the basic query language operators.',
        ro: 'Aici veți învăța cum să găsiți ușor obiectele căutate pe cont propriu. Interogările pot fi utilizate pentru etichete, căutare sau alerte. Pentru a scrie o interogare mai complexă, familiarizați-vă cu operatorii de bază ai limbajului de interogare.',
        uk: 'Тут ви дізнаєтеся, як просто знайти шукані об\'єкти самостійно. Запити можуть бути використані для тега, пошуку або алерту. Щоб написати складніший запит, ознайомтеся з основними операторами мови запитів.'
      },
      FIND_OBJECTS_QUICKLY: {
        ru: 'Чтобы быстро найти объекты в поиске:',
        en: 'To find objects quickly in search:',
        ro: 'Pentru a găsi rapid obiectele în căutare:',
        uk: 'Щоб швидко знайти об\'єкти в пошуку:'
      },
      CREATE_COMPLEX_QUERY: {
        ru: 'Чтобы самостоятельно составить более сложный запрос:',
        en: 'To create a more complex query on your own:',
        ro: 'Pentru a crea o interogare mai complexă pe cont propriu:',
        uk: 'Щоб самостійно скласти складніший запит:'
      },
      EXAMPLES_OF_COMPLEX_QUERY: {
        ru: 'Примеры написания запросов в сложной морфологии:',
        en: 'Examples of constructing queries in complex morphology:',
        ro: 'Exemple de scriere a interogărilor în morfologie complexă:',
        uk: 'Приклади написання запитів у складній морфології:'
      },
      ADDITIONAL_HELP: {
        ru: 'Если вам нужна дополнительная помощь, вы всегда можете обратиться к своему менеджеру или нашему чату поддержки.',
        en: 'If you need additional help, you can always contact your manager or our support chat.',
        ro: 'Dacă aveți nevoie de asistență suplimentară, puteți contacta întotdeauna managerul dvs. sau chatul nostru de suport.',
        uk: 'Якщо вам потрібна додаткова допомога, ви завжди можете звернутися до свого менеджера або нашого чату підтримки.'
      },
      OPTION: {
        ru: 'Вариант',
        en: 'Option',
        ro: 'Opțiune',
        uk: 'Варіант'
      },
      OPTIONS_WITH_SPACE: {
        ru: 'укажите варианты написания названия через пробел',
        en: 'specify options for writing the name with spaces',
        ro: 'specificați opțiunile pentru scrierea numelui cu spații',
        uk: 'вкажіть варіанти написання назви через пробіл'
      },
      OPTIONS_WITH_SPACE_EXAMPLE: {
        ru: 'приватбанк* "приват банк*" privatbank "privat bank"',
        en: 'privatbank* "privat bank*" privatbank "privat bank"',
        ro: 'privatbank* "privat bank*" privatbank "privat bank"',
        uk: 'приватбанк* "приват банк*" privatbank "privat bank"'
      },
      USE_QUOTES: {
        ru: 'если название состоит из двух и более слов, возьмите его в кавычки (" ")',
        en: 'if the name consists of two or more words, enclose it in quotes (" ")',
        ro: 'dacă numele este format din două sau mai multe cuvinte, învăluiți-l în ghilimele (" ")',
        uk: 'якщо назва складається з двох і більше слів, візьміть її в лапки (" ")'
      },
      USE_ASTERISK: {
        ru: 'вместо окончания, которое может меняться, поставьте символ звездочки ().',
        en: 'replace variable endings with an asterisk ().',
        ro: 'in locul unui sufix variabil, folosiți simbolul asterisc ().',
        uk: 'замість закінчення, яке може змінюватися, поставте символ зірочки ().'
      },
      COMBINATION_OPERATORS: {
        ru: 'Операторы сложения:',
        en: 'Combination operators:',
        ro: 'Operatori de combinare:',
        uk: 'Оператори складання:'
      },
      SEARCH_PUBLICATIONS_CONTAINING_ALL_WORDS: {
        ru: 'поиск публикаций, в которых присутствуют все слова (разделённые оператором) на любом расстоянии друг от друга.',
        en: 'search for publications containing all words (separated by the operator) at any distance from each other.',
        ro: 'căutați publicații care conțin toate cuvintele (separate prin operator) la orice distanță unul de altul.',
        uk: 'пошук публікацій, у яких присутні всі слова (прописані через оператор) на будь-якій відстані один від одного.'
      },
      SEARCH_PUBLICATIONS_CONTAINING_ALL_WORDS_EXAMPLE: {
        ru: '(rolex ролекс*) AND продаж*',
        en: '(rolex) AND sale*',
        ro: '(rolex) AND vânzări*',
        uk: '(rolex ролекс*) AND продаж*'
      },
      SEARCH_PUBLICATIONS_EXCLUDING_WORDS: {
        ru: 'поиск публикаций, в которых нет ни одного из (указанных через оператор) слов.',
        en: 'search for publications that do not contain any of the (specified by the operator) words.',
        ro: 'căutați publicații care nu conțin niciunul dintre (specificați prin operator) cuvinte.',
        uk: 'пошук публікацій, у яких немає жодного з (вказаних через оператор) слів.'
      },
      SEARCH_PUBLICATIONS_EXCLUDING_WORDS_EXAMPLE: {
        ru: '(bmw бмв) AND NOT (авар* дтп)',
        en: '(bmw audi) AND NOT (accid* accident*)',
        ro: '(bmw audi) AND NOT (accid* accident*)',
        uk: '(bmw бмв) AND NOT (авар* дтп)'
      },
      GROUPING_OPERATORS: {
        ru: 'Операторы группировки:',
        en: 'Grouping operators:',
        ro: 'Operatori de grupare:',
        uk: 'Оператори групування:'
      },
      GROUPING_WORDS: {
        ru: 'объединение слов в группы:',
        en: 'grouping words into sets:',
        ro: 'gruparea cuvintelor în seturi:',
        uk: 'об\'єднання слів у групи:'
      },
      GROUPING_WORDS_EXAMPLE: {
        ru: '((выставк* виставок*) AND (арт картин* худож*)) AND NOT (школ* школьн*)',
        en: '((exhibition*) AND (art paintings* artists*)) AND NOT (school*)',
        ro: '((expoziții*) AND (artă picturi* artiști*)) AND NOT (școală* școli*)',
        uk: '((виставк* виставоч*) AND (арт картин* худож*)) AND NOT (школ* шкіл*)'
      },
      SEARCH_WORDS_WITHIN_QUOTES: {
        ru: 'поиск слов внутри кавычек, расположенных рядом',
        en: 'search for words within adjacent quotes',
        ro: 'căutare cuvinte în interiorul ghilimelelor adiacente',
        uk: 'пошук слів усередині лапок поруч'
      },
      SEARCH_WORDS_WITHIN_QUOTES_EXAMPLE: {
        ru: '"показ* мод"',
        en: '"fashion show*"',
        ro: '"spectacol de modă*"',
        uk: '"показ* мод"'
      },
      SEARCH_ALL_OBJECTS: {
        ru: 'поиск всех объектов внутри кавычек на расстоянии N слов.',
        en: 'search for all objects within quotes at a distance of N words.',
        ro: 'căutare a tuturor obiectelor în interiorul ghilimelelor la o distanță de N cuvinte.',
        uk: 'пошук всіх об\'єктів усередині лапок на відстані N слів.'
      },
      SEARCH_ALL_OBJECTS_EXAMPLE: {
        ru: '" (интерв*) (Huawei Хуав*) "~5',
        en: '" (interv*) (Huawei Huawe*) "~5',
        ro: '" (interv*) (Huawei Huawe*) "~5',
        uk: '" (інтерв* интерв*) (Huawei Хуав*) "~5'
      },
      SIMPLE_MORPHOLOGY_OPERATORS: {
        ru: 'Операторы простой морфологии:',
        en: 'Simple morphology operators:',
        ro: 'Operatori de morfologie simplă:',
        uk: 'Оператори простої морфології:'
      },
      REPLACES_ANY_NUMBER_OF_CHARACTERS: {
        ru: 'заменяет любое количество любых символов, обычно добавляется в качестве окончания:',
        en: 'replaces any number of characters, usually added as a suffix:',
        ro: 'inlocuiește orice număr de caractere, de obicei adăugat ca sufix:',
        uk: 'заміна будь-якої кількості будь-яких символів, зазвичай додається як закінчення:'
      },
      REPLACES_ANY_NUMBER_OF_CHARACTERS_EXAMPLE: {
        ru: 'Артикуляц*',
        en: 'Articulat*',
        ro: 'Articulat*',
        uk: 'Артикуляц*'
      },
      REPLACES_ONE_CHARACTER: {
        ru: 'заменяет один любой символ, чаще всего используется для чередующихся букв в словах:',
        en: 'replaces one character, commonly used for alternating letters in words:',
        ro: 'inlocuiește un caracter, cel mai adesea folosit pentru litere alternate în cuvinte:',
        uk: 'заміна одного будь-якого символу, найчастіше використовується для чергування букв у словах:'
      },
      REPLACES_ONE_CHARACTER_EXAMPLE: {
        ru: 'укра?н*',
        en: 'ukr?n*',
        ro: 'ukr?n*',
        uk: 'укра?н*'
      },
      COMPLEX_MORPHOLOGY_OPERATORS: {
        ru: 'Операторы сложной морфологии:',
        en: 'Complex morphology operators:',
        ro: 'Operatori de morfologie complexă:',
        uk: 'Оператори складної морфології:'
      },
      DIFFERENCE_MORPHOLOGY: {
        ru: 'Главное отличие использования простой морфологии от сложной заключается в том, что в сложной каждое слово нужно брать в слэши - /слово/.',
        en: 'The main difference between using simple and complex morphology is that with complex morphology, each word must be enclosed in slashes - /word/.',
        ro: 'Diferența principală între utilizarea morfologiei simple și celei complexe este că, în morfologia complexă, fiecare cuvânt trebuie să fie închis între slash-uri - /cuvânt/.',
        uk: 'Головна відмінність використання простої морфології від складної у тому, що в складній кожне слово треба брати у слеші - /слово/.'
      },
      PROVIDE_ALTERNATING_LETTER: {
        ru: 'обеспечивают чередование одной буквы в слове, что используется для более точного поиска вместо ? в простой морфологии:',
        en: 'provide letter alternation in a word, used for more precise searching instead of ? in simple morphology:',
        ro: 'oferă alternarea unei litere în cuvânt, utilizată pentru o căutare mai precisă în loc de ? în morfologia simplă:',
        uk: 'забезпечують чергування однієї літери в слові, що використовується для більш точного пошуку замість ? у простій морфології:'
      },
      PROVIDE_ALTERNATING_LETTER_EXAMPLE: {
        ru: '/Он[еє]шко/',
        en: '/On{e|i}shko/',
        ro: '/On{e|i}shko/',
        uk: '/Он[еє]шко/'
      },
      COMPLEX_VERSION_OF_ASTERISK: {
        ru: 'более сложная версия * в простой морфологии, ограничивает минимальное и максимальное количество любых символов цифрами min, max:',
        en: 'a more complex version of * in simple morphology, restricting the minimum and maximum number of any characters with min, max digits:',
        ro: 'o versiune mai complexă a * în morfologia simplă, limitând numărul minim și maxim de caractere cu cifrele min, max:',
        uk: 'більш складна версія * у простій морфології, обмежує мінімальну та максимальну кількість будь-яких символів цифрами min, max:'
      },
      COMPLEX_VERSION_OF_ASTERISK_EXAMPLE: {
        ru: '/Укра[иії]н. {1,2}/',
        en: '/Ukrain{e|ia}. {1,2}/',
        ro: '/Ucrain{e|i}. {1,2}/',
        uk: '/Укра[иії]н.{1,2}/'
      },
      MAKES_PRECEDING_CHARACTER_OPTIONAL: {
        ru: 'делает предыдущий символ или их группу необязательными:',
        en: 'makes the preceding character or group optional:',
        ro: 'face caracterul sau grupul anterior opțional:',
        uk: 'робить попередній символ або їх групу необов\'язковими:'
      },
      MAKES_PRECEDING_CHARACTER_OPTIONAL_EXAMPLE: {
        ru: '/комм?ентар. {0,3}/',
        en: '/comm?ent.{0,3}/',
        ro: '/coment?ar.{0,3}/',
        uk: '/комм?ентар.{0,3}/'
      },
      GEOGRAPHY_OPERATOR: {
        ru: 'Оператор географии:',
        en: 'Geography operator:',
        ro: 'Operator de geografie:',
        uk: 'Оператор географії:'
      },
      GEOGRAPHY_OPERATOR_EXAMPLE: {
        ru: '(iqos /айкос. {0,3}/) AND (source_country:Украина source_country:Беларусь)',
        en: '(iqos /aykos. {0,3}/) AND (source_country:Poland source_country:Slovenia)',
        ro: '(iqos /aykos. {0,3}/) AND (source_country:Romania source_country:Hungary)',
        uk: '(iqos /айкос. {0,3}/) AND (source_country:Україна source_country:Білорусь)'
      },
      COUNTRY_NAME: {
        ru: 'Название страны',
        en: 'Country name',
        ro: 'Numele țării',
        uk: 'Назва країни'
      },
      PERSONALITY_ALTERNATION: {
        ru: 'Персоналия с чередованием апострофа',
        en: 'Personality with apostrophe alternation',
        ro: 'Personalitate cu alternarea apostrofului',
        uk: 'Персоналія з чергуванням апострофа'
      },
      PERSONALITY_ALTERNATION_HEADER_EXAMPLE: {
        ru: 'Вячеслав Лызарь. Вячеслав Лизар. Примеры написания других имен для персоналий можно найти',
        en: 'Vyacheslav Lyzary. Vyacheslav Lizar. Examples of other names for personalities can be found',
        ro: 'Viacheslav Lyzary. Viacheslav Lizar. Exemple de alte nume pentru personalități pot fi găsite',
        uk: 'Вячеслав Лизарь. В\'ячеслав Лізар. Приклади написання інших імен для персоналій можна знайти'
      },
      PERSONALITY_ALTERNATION_EXAMPLE: {
        ru: '"/В.?ячеслав.{0,4}/ /Л[иы]зар.{0,3}/"',
        en: '"/V[iy]acheslav.{0,2}/ /L[yi]zar.{0,2}/"',
        ro: '"/V[iy]acheslav.{0,2}/ /L[yi]zar.{0,2}/"',
        uk: '"/В.?ячеслав.{0,4}/ /Л[иі]зар.{0,3}/"'
      },
      HERE: {
        ru: 'тут',
        en: 'here',
        ro: 'aici',
        uk: 'тут'
      },
      MINISTRY_NAME: {
        ru: 'Название министерства',
        en: 'Ministry name',
        ro: 'Numele ministerului',
        uk: 'Назва міністерства'
      },
      MINISTRY_OF_HEALTH_HEADER_EXAMPLE: {
        ru: 'МОЗ',
        en: 'Ministry of Health',
        ro: 'Ministerul Sănătății',
        uk: 'МОЗ'
      },
      MINISTRY_OF_HEALTH_EXAMPLE: {
        ru: '"/м[и]н[и]стерств. {1,2}/ /ох?р[а]н[ы]/ /здоров.?я/" МОЗ "/м[и]н[и]стерств. {1,2}/ /охроны здоровья.{1,2}/"',
        en: '"/minist{r|e|ie}r.{1,2}/ /health?care / /health/" MOH "/minist{r|e|ie}r.{1,2}/ /healthcare.{1,2}/"',
        ro: '"/minister{iu|ie} / /sănătate/ /sănătate/" MS "/minister{iu|ie}/ /sănătate.{1,2}/"',
        uk: '"/м[іі]н[іі]стерств. {1,2}/ /охор[оа]н[ии]/ /здоров.?я/" МОЗ "/м[іі]н[іі]стерств. {1,2}/ /охорони здоров\'я.{1,2}/"'
      },
      SEARCH_WORDS_DISTANCE: {
        ru: 'Поиск слов на расстоянии',
        en: 'Search for words at a distance',
        ro: 'Căutare cuvinte la distanță',
        uk: 'Пошук слів на відстані'
      },
      SEARCH_WORDS_DISTANCE_HEADER_EXAMPLE: {
        ru: 'премьера фильма или сериала',
        en: 'movie or series premiere',
        ro: 'premiera filmului sau a serialului',
        uk: 'прем\'єра фільму чи серіалу'
      },
      SEARCH_WORDS_DISTANCE_EXAMPLE: {
        ru: '"/прем.?[ееэ]р.{1,3}/ (/ф[и]льм.{1,2}/ /сер[и]ал.{1,2}/) "~54"',
        en: '"/prem.?[eeé]re.{1,3}/ (/film.{1,2}/ /series.{1,2}/) "~54"',
        ro: '"/prem.?[eeé]r.{1,3}/ (/film.{1,2}/ /serial.{1,2}/) "~54"',
        uk: '"/прем.?[ееє]р.{1,3}/ (/ф[іі]льм.{1,2}/ /серіал.{1,2}/) "~54"'
      },
      SEARCH_PHRASE: {
        ru: 'Поиск словосочетания',
        en: 'Search phrase',
        ro: 'Căutare frază',
        uk: 'Пошук словосполучення'
      },
      SEARCH_PHRASE_HEADER_EXAMPLE: {
        ru: 'объединение территориальных громад, объединенные территориальные громады',
        en: 'merging of territorial communities, merged territorial communities',
        ro: 'fuzionarea comunităților teritoriale, comunități teritoriale fuzionate',
        uk: 'об\'єднання територіальних громад, об\'єднані територіальні громади'
      },
      SEARCH_PHRASE_EXAMPLE: {
        ru: '"(/об[ьъ]един[ее]н.{1,5}/ /об\'єднання.{1,3}/ ) /терр?итор[іи]альн.{1,5}/ (/общин .{0,4}/ /громад.{0,4}/)"~1',
        en: '"(/merging.{1,3}/ /merg[ed].{1,5}/) /territori[al].{1,5}/ (/communit{y|ies} .{0,4}/ /communities.{0,4}/)"~1',
        ro: '"(/fuzionare.{1,3}/ /fuzionat.{1,5}/) /teritor[ia]l.{1,5}/ (/comunități .{0,4}/ /comunități.{0,4}/)"~1',
        uk: '"(/об\'єднання.{1,3}/ /об[ьъ]един[ее]н.{1,5}/) /терр?итор[іи]альн.{1,5}/ (/громад.{0,4}/ /общин .{0,4}/)"~1'
      },
      NO_METRICS_SELECTED: {
        ru: 'Не выбрано ни одного показателя',
        en: 'No metrics selected',
        ro: 'Niciun indicator selectat',
        uk: 'Не обрано жодного показника'
      },
      SELECT_METRICS_TO_DISPLAY: {
        ru: 'Для отображения выберите один или несколько показателей',
        en: 'Select one or more metrics to display',
        ro: 'Selectați unul sau mai multe indicatori pentru a afișa',
        uk: 'Для відображення оберіть один або декілька показників'
      },
      NO_MENTIONS_FOUND: {
        ru: 'Не найдено ни одного упоминания',
        en: 'No mentions found',
        ro: 'Nicio mențiune găsită',
        uk: 'Не знайдено жодної згадки'
      },
      NEXT_STEPS: {
        ru: 'Следующие шаги могут помочь:',
        en: 'The following steps may help:',
        ro: 'Următorii pași pot ajuta:',
        uk: 'Наступні кроки можуть допомогти:'
      },
      CHECK_SEARCH_QUERY: {
        ru: 'Проверьте поисковый запрос или попробуйте более общую версию;',
        en: 'Check the search query or try a more general version;',
        ro: 'Verificați interogarea de căutare sau încercați o versiune mai generală;',
        uk: 'Перевірте пошуковий запит або спробуйте більш загальну версію;'
      },
      ADJUST_FILTERS: {
        ru: 'Частично или полностью отключите фильтры;',
        en: 'Partially or completely disable filters;',
        ro: 'Dezactivați parțial sau complet filtrele;',
        uk: 'Частково або повністю виключіть фільтри;'
      },
      EXTEND_SEARCH_PERIOD: {
        ru: 'Расширьте период поиска;',
        en: 'Extend the search period;',
        ro: 'Extindeți perioada de căutare;',
        uk: 'Розширте період пошуку;'
      }
    },
    COPILOT: {
      INFO_MESSAGE_1: {
        ru: '👋 Привет! Я <b>Q Insight</b> – твой помощник по анализу упоминаний',
        en: '👋 Hi, I\'m <b>Q Insight</b> - your mentions analysis assistant',
        ro: '👋 Salutări! Sunt <b>Q Insight</b>, menționezul tău asistent de analiză',
        uk: '👋 Привіт! Я <b>Q Insight</b> — твій помічник з аналізу згадок'
      },
      INFO_MESSAGE_2: {
        ru: '✍️ Для получения качественных ответов важно указать, что именно вас интересует, и применить фильтры в новостной ленте<br>✅ "Что писали в отношении [персоны/продукта/события]?"<br>❌ "О чем эти статьи?"',
        en: '✍️ To get quality answers, it is important to specify what exactly you are interested in and apply filters in the news feed<br>✅ “What was written about [person/product/event]?”<br>❌ “What are these articles about?”',
        ro: '✍️ Pentru a primi răspunsuri de calitate, este important să specificați ce vă interesează și să aplicați filtre în fluxul de știri<br>✅ „Ce s-a scris despre [persoană/produs/eveniment]?”<br>❌ „Despre ce sunt aceste articole?”',
        uk: '✍️ Для отримання якісних відповідей важливо вказати, що саме вас цікавить, та застосувати фільтри в стрічці новин<br>✅ "Що писали відносно [персони/продукту/події]?"<br>❌ "Про що ці статті?"'
      },
      INFO_MESSAGE_3: {
        ru: '⚠️ Один анализ может включать до 5&nbsp;000 упоминаний',
        en: '⚠️ One analysis can cover up to 5&nbsp;000 mentions',
        ro: '⚠️ O singură analiză poate acoperi până la 5&nbsp;000 de mențiuni',
        uk: '⚠️ Один аналіз може охоплювати до 5&nbsp;000 згадок'
      },
      INFO_MESSAGE_4: {
        ru: '🧐 <b>Примеры запросов:</b><ul><li>"Что говорят пользователи о последних изменениях в [компании]?</li><li>"Приведи основные положения из упоминаний где главной темой является [лицо/событие]?"</li><li>"Как обсуждают наши услуги по сравнению с [конкурентом]?"</li></ul>',
        en: '🧐 <b>Examples of queries:</b><ul><li>“What do users say about the latest changes in [company]?</li><li>“What are the main points from mentions where the main topic is [person/event]?”</li><li>“How are our services discussed compared to [competitor]?”</li></ul>',
        ro: '🧐 <b>Exemple de cereri:</b><ul><li>„Ce spun utilizatorii despre cele mai recente modificări la [companie]?</li><li>„Dați tezele principale din referințe unde subiectul principal este [persoană/eveniment]?”</li><li>„Cum se compară serviciile noastre cu cele ale [concurenței]?”</li></ul>',
        uk: '🧐 <b>Приклади запитів:</b><ul><li>"Що говорять користувачі про останні зміни у [компанії]?</li><li>"Наведи основні тези зі згадок де головною темою є [особа/подія]?"</li><li>"Як обговорюють наші послуги у порівнянні з [конкурентом]?"</li></ul>'
      },
      WAITING_ANSWER: {
        ru: 'Принято. Анализирую информацию – это может занять несколько минут. Пора сделать себе кофе или посмотреть новые упоминания',
        en: 'Accepted. Analyzing the information - it may take a few minutes. It\'s time to make yourself a coffee or check out some new mentions',
        ro: 'Admis. Analizarea informațiilor - poate dura câteva minute. Este timpul să vă pregătiți o cafea sau să vedeți câteva mențiuni noi',
        uk: 'Прийнято. Аналізую інформацію – це може зайняти кілька хвилин. Пора зробити собі каву або переглянути нові згадки'
      },
      INPUT_TEXT: {
        ru: 'Введите свой вопрос ...',
        en: 'Enter your mention question here...',
        ro: 'Introduceți aici întrebarea de mențiune...',
        uk: 'Введіть своє питання...'
      },
      CHATGPT_WARNING: {
        ru: 'ChatGPT может ошибаться. Проверяйте важную информацию.',
        en: 'ChatGPT can be wrong. Check important information.',
        ro: 'ChatGPT poate fi greșit. Verificați informațiile importante.',
        uk: 'ChatGPT може помилятися. Перевіряйте важливу інформацію.'
      },
      HISTORY: {
        ru: 'История',
        en: 'History',
        ro: 'Istorie',
        uk: 'Історія'
      },
      USING: {
        ru: 'Использование',
        en: 'Using',
        ro: 'Folosind',
        uk: 'Використання'
      },
      INFO: {
        ru: 'Информация',
        en: 'Info',
        ro: 'Info',
        uk: 'Інформація'
      },
      CHAT_NEW: {
        ru: 'Создать новый чат',
        en: 'Create a new chat',
        ro: 'Creați un nou chat',
        uk: 'Створити новий чат'
      },
      CHAT_HISTORY: {
        ru: 'История запросов',
        en: 'Request history',
        ro: 'Istoricul cererilor',
        uk: 'Історія запитів'
      },
      CHAT_USING: {
        ru: 'Использование AI Помощника',
        en: 'Using the AI Assistant',
        ro: 'Istoricul interogărilor',
        uk: 'Використання AI Помічника'
      },
      CHAT_INFO: {
        ru: 'Узнать больше о AI Помощнике',
        en: 'Learn more about Assistant AI',
        ro: 'Aflați mai multe despre Assistant AI',
        uk: 'Дізнатись більше про AI Помічника'
      },
      TODAY: {
        ru: 'сегодня',
        en: 'today',
        ro: 'astăzi',
        uk: 'сьогодні'
      },
      YESTERDAY: {
        ru: 'вчера',
        en: 'yesterday',
        ro: 'ieri',
        uk: 'учора'
      },
      PREVIOUS_7_DAYS: {
        ru: 'предыдущие 7 дней',
        en: 'previous 7 days',
        ro: 'ultimele 7 zile',
        uk: 'попередні 7 днів'
      },
      PREVIOUS_30_DAYS: {
        ru: 'предыдущие 30 дней',
        en: 'previous 30 days',
        ro: 'ultimele 30 zile',
        uk: 'попередні 30 днів'
      },
      ERROR_MESSAGE: {
        ru: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
        en: 'Something went wrong. Please, try again.',
        ro: 'Ceva n-a mers bine. Vă rugăm să încercați din nou.',
        uk: 'Щось пішло не так. Будь ласка, спробуйте ще раз.'
      },
      DELETE: {
        ru: 'Удалить чат',
        en: 'Delete chat',
        ro: 'Șterge chatul',
        uk: 'Видалити чат'
      },
      DELETE_CHAT: {
        ru: 'Вы уверены, что хотите удалить этот чат?',
        en: 'Are you sure you want to delete this chat?',
        ro: 'Sigur vrei să ștergi acest chat?',
        uk: 'Ви впевнені, що хочете видалити цей чат?'
      },
      NO_CHATS: {
        ru: 'У вас нет чатов',
        en: 'You have no chats',
        ro: 'Nu ai chat-uri',
        uk: 'У вас немає чатів'
      },
      AI_USING: {
        ru: 'Использование AI Помощника',
        en: 'Using the AI Assistant',
        ro: 'Folosind Asistentul AI',
        uk: 'Використання AI Помічника'
      },
      AI_USING_AVAILABLE: {
        ru: 'доступно',
        en: 'available',
        ro: 'disponibile',
        uk: 'доступно'
      },
      AI_USING_OUT_OF: {
        ru: 'из',
        en: 'out of',
        ro: 'din',
        uk: 'з'
      },
      AI_USING_REQUESTS: {
        ru: 'запросов в месяц',
        en: 'requests per month',
        ro: 'cereri pe lună',
        uk: 'запитів на місяць'
      },
      AI_USING_UP_TITLE: {
        ru: 'Достигнут лимит запросов',
        en: 'Request limit reached',
        ro: 'Limita de cereri a fost atinsă',
        uk: 'Досягнуто ліміту запитів'
      },
      AI_USING_UP_TEXT: {
        ru: 'Подпишитесь на PRO, чтобы получить более высокую квоту на редактирование сообщений, генерацию ответов и регенерацию текста.',
        en: 'Subscribe to PRO for a higher quota for editing message, generating responses, and text regeneration.',
        ro: 'Abonați-vă la PRO pentru o cotă mai mare pentru editarea mesajelor, generarea de răspunsuri și regenerarea textului.',
        uk: 'Підпишіться на PRO, щоб отримати більш високу квоту на редагування повідомлень, генерацію відповідей та регенерацію тексту.'
      },
      AI_USING_UP_BTN: {
        ru: 'Увеличить лимит',
        en: 'Increase limit',
        ro: 'Măriți limita',
        uk: 'Збільшити ліміт'
      },
      FILTER_WAS_CHANGED: {
        ru: 'Вы изменили фильтры ленты – продолжаем работать в этом чате или...',
        en: 'You\'ve changed your feed filters - are we still working on this chat or...',
        ro: 'Ți-ai schimbat filtrele de feed - mai lucrăm la acest chat sau...',
        uk: 'Ви змінили фільтри стрічки – продовжуємо працювати в цьому чаті чи...'
      },
      START_NEW_DIALOG: {
        ru: 'Начать новый чат',
        en: 'Start a new chat',
        ro: 'Începeți un nou chat',
        uk: 'Розпочати новий чат'
      },
      CONTINUE_CURRENT_DIALOG: {
        ru: 'Продолжить в текущем',
        en: 'Continue in current',
        ro: 'Continuați în curent',
        uk: 'Продовжити в поточному'
      },
      INCREASE_LIMITS_ANSWER: {
        ru: 'Привет! Запрос на увеличение лимита направлен. Ожидайте сообщения от менеджера LOOQME.',
        en: 'Greetings! Limit increase request sent. Wait for a message from your LOOQME manager.',
        ro: 'Salutări! Solicitare de creștere limită trimisă. Așteptați un mesaj de la managerul dvs. LOOQME.',
        uk: 'Привіт! Запит на збільшення ліміту надіслано. Очікуйте повідомлення від свого менеджера LOOQME.'
      }
    },
    pages: {
      SEARCH: {
        ru: 'Поиск',
        en: 'Search',
        ro: 'Căutare',
        uk: 'Пошук'
      },
      DOWNLOADS: {
        ru: 'Загрузки',
        en: 'Downloads',
        ro: 'Descărcări',
        uk: 'Завантаження'
      },
      ALERTS: {
        ru: 'Оповещения',
        en: 'Alerts',
        ro: 'Alerte',
        uk: 'Сповіщення'
      },
      MENTIONS: {
        ru: 'Упоминания',
        en: 'Mentions',
        ro: 'Mențiuni',
        uk: 'Згадування'
      }
    }
  }
};
