import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as requests from 'storage/pages/requests';
import * as actions from 'storage/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { openNotification } from 'Components';
import t from 'utils/translateFunc';
import { useSelector, useDispatch } from 'react-redux';
import { RATE_CONFIG } from 'data/filterConfig';

export const useGetPages = () => {
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const { portal } = useParams();
  const portalsList = useSelector(state => state.portals?.portalsList);
  const hasPortal = !!portal && !!portalsList?.find(el => el?.slug === portal);
  return useQuery({
    enabled: hasPortal,
    queryKey: ['getPages', portal_slug],
    queryFn: () => requests.getPages({ portal_slug }),
    onSuccess: (resp) => { dispatch(actions.addPages(resp)); },
    onError: () => { dispatch(actions.addPages([])); }
  });
};

export const useGetPageId = () => {
  const dispatch = useDispatch();
  const { pageId, articleId } = useParams();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const language = useSelector(state => state?.userInfo?.language);
  const { ordering, duplicates } = useSelector(state => state?.filters);
  const history = useNavigate();
  return useQuery({
    enabled: !!pageId && pageId !== 'all',
    queryKey: ['getPageId', portal_slug, pageId],
    queryFn: () => requests.getPageId({ portal_slug, id: pageId }),
    onSuccess: (resp) => {
      dispatch(actions.clearFilterList());
      dispatch(actions.clearCurrentPage());
      dispatch(actions.addCurrentPage(resp));
      resp?.portal?.slug !== portal_slug && actions.changeCurrentPortal(resp?.portal);
      if (resp?.filter) {
        const filtersArr = resp?.filter && Object.entries(resp?.filter)?.filter(el => (Array.isArray(el[1]) ? !!el[1]?.length : el[1] !== null));
        resp?.filter && dispatch(actions.savedFilters(resp?.filter));
        !!filtersArr?.length && filtersArr.forEach((el) => {
          const key = el[0];
          const value = el[1];
          const arrayValue = Array.isArray(value);
          key === 'duplicates' && duplicates !== value && dispatch(actions.toggleDuplicates(value));
          key === 'ordering' && ordering !== value && dispatch(actions.changeOrdering(value));
          const rangeKeys = ['publication_place_rate_range', 'audience_from', 'audience_to'];
          rangeKeys.includes(key) && dispatch(actions.addFilterItem({ id: key, name: String(value), filterName: key, type: t('filtrationPanel', key.toUpperCase(), language) }));
          !arrayValue && !['ordering', 'duplicates', ...rangeKeys].includes(key)
          && dispatch(actions.addFilterItem({ id: value, name: value, filterName: key, type: t('filtrationPanel', key.toUpperCase(), language) }));
          if (arrayValue) {
            const clearKey = String(key).replace(/_exclude/g, '');
            value.forEach((item) => {
              let filterItem;
              if (key === 'rate') {
                filterItem = { id: item, name: RATE_CONFIG.filter(rate => rate?.id === item)?.[0]?.title || 5, filterName: key, type: t('filtrationPanel', key.toUpperCase(), language) };
              } else {
                filterItem = item?.id
                  ? { id: item?.id, name: item?.name || item?.title, filterName: key, type: t('filtrationPanel', clearKey.toUpperCase(), language), isExclude: key.includes('exclude') }
                  : { id: item, name: item, filterName: key, type: t('filtrationPanel', clearKey.toUpperCase(), language), isExclude: key.includes('exclude') };
              }
              dispatch(actions.addFilterItem(filterItem));
            });
          }
        });
      }
    },
    onError: () => {
      const id = pageId === 'all' ? '' : `${articleId}/`;
      portal_slug && history(`/${portal_slug}/mentions/all/${id}`);
    }
  });
};

export const useCreatePage = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const language = useSelector(state => state?.userInfo?.language);
  const { filtersQuery, ordering, duplicates, tabItem } = useSelector(state => state?.filters);
  const order = useSelector(state => state?.pages?.pageData)?.length || 0;
  return useMutation({
    mutationKey: 'createPage',
    mutationFn: ({ name, selected }) => requests.createPage({ portal_slug, body: { name, order: order + 1, filter: selected ? { ...filtersQuery } : { ordering, duplicates } } }),
    onSuccess: (resp) => {
      if (resp?.id) {
        dispatch(actions.clearFilterList());
        dispatch(actions.clearCurrentPage());
        dispatch(actions.addNewPage(resp));
        tabItem?.action && dispatch(actions.onChangeTab({ name: t('tabsMenu', 'ALL_MENTIONS', language), icon: '', action: '' }));
        history(`/${portal_slug}/mentions/${resp?.id}`);
        openNotification({ message: t('notif', 'CREATE_PAGE', language) });
      }
    },
    onError: (err) => { openNotification({ message: err?.response?.data?.name?.[0] || t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useDeletePage = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const language = useSelector(state => state?.userInfo?.language);
  const date = useSelector(state => state?.datePicker?.dateObj);
  const queryClient = useQueryClient();
  const { pageId } = useParams();
  return useMutation({
    mutationKey: 'deletePage',
    mutationFn: id => requests.deletePage({ portal_slug, id }),
    onSuccess: (_, id) => {
      dispatch(actions.deletePage(id));
      if (pageId !== 'all') {
        dispatch(actions.clearFilterList());
        dispatch(actions.clearNewsList());
        dispatch(actions.clearCurrentPage());
        queryClient.refetchQueries({ queryKey: ['getNewsList', portal_slug, { duplicates: 'false', ordering: '-date' }, date, pageId, language], exact: true });
        history(`/${portal_slug}/mentions/all`);
      }
      openNotification({ message: t('notif', 'PAGE_DELETED', language) });
    },
    onError: () => { openNotification({ message: t('notif', 'SOMETHING_WRONG', language) }); }
  });
};

export const useChangePage = () => {
  const dispatch = useDispatch();
  const portal_slug = useSelector(state => state?.portals?.currentPortal?.slug) || '';
  const language = useSelector(state => state?.userInfo?.language);
  return useMutation({
    mutationKey: 'changePage',
    mutationFn: ({ id, body }) => (body?.filter ? requests.savePageChanges({ portal_slug, body, id }) : requests.changePage({ portal_slug, body, id })),
    onSuccess: (resp, { body }) => {
      if (resp?.id) {
        dispatch(actions.changePage(resp));
        resp?.filter && dispatch(actions.savedFilters(resp?.filter));
        openNotification({ message: t('notif', body?.filter ? 'CHANGES_SAVED' : 'PAGE_RENAME', language) });
      }
    },
    onError: (err) => {
      openNotification({ message: err?.response?.data?.name?.[0] || t('notif', 'SOMETHING_WRONG', language) });
    },
    onSettled: (_, _err, { page_id, navigateToPage }) => { page_id && navigateToPage(page_id); }
  });
};
